import { ChatConfigurationFieldNames } from '@chat-bot/entities/ChatConfigurationFormFields/config';
import { CreateChatFormValues } from '../lib';

export const getCreateChatFormDefaultValues: (courseName: string) => CreateChatFormValues = (
  courseName,
) => ({
  [ChatConfigurationFieldNames.TITLE]: courseName,
  [ChatConfigurationFieldNames.DESCRIPTION]: '',
  [ChatConfigurationFieldNames.SLOW_MODE]: false,
  [ChatConfigurationFieldNames.ALL_REACTIONS]: 'false',
  [ChatConfigurationFieldNames.CHANNEL_TYPE]: '',
});

import React from 'react';
import './Preloader.css';

const Preloader = () => {
  return (
    <div className='preloader'>
      <span className='loader'></span>
    </div>
  );
};

export default Preloader;

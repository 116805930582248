import { Chat } from '@chat-bot/entities/API-service';
import ChatImg from '@shared/assets/icons/chat.svg';
import TA from '@shared/assets/icons/teacher-assistant.svg';
import Channel from '@shared/assets/icons/channel.svg';
import { ActionButtons, ImageAndType, Status, Title, Wrapper } from './CreatedChat.styles';
import { FC } from 'react';
import TrashCan from '@shared/assets/icons/trashcan.svg';
import Edit from '@shared/assets/icons/edit.svg';

const chatUIInfo: { [key in Chat['type']]: { title: string; img: string } } = {
  Students: { title: 'Course chat', img: ChatImg },
  TA: { title: `TA's chat`, img: TA },
  Channel: { title: 'Channel', img: Channel },
};

interface CreatedChatProps {
  chat: Chat;
  actionButtons: JSX.Element;
}

export const CreatedChat: FC<CreatedChatProps> = ({ chat, actionButtons }) => {
  return (
    <Wrapper>
      <ImageAndType>
        <img
          src={chatUIInfo[chat.type].img}
          alt=''
        />
        {chatUIInfo[chat.type].title}
      </ImageAndType>

      <Title>{chat.title}</Title>
      <Status status={chat.status}>{chat.status}</Status>
      {chat.status !== 'deleting' && <ActionButtons>{actionButtons}</ActionButtons>}

      {chat.invite_link && (
        <a
          href={chat.invite_link}
          target='_blank'
        >
          Link
        </a>
      )}
    </Wrapper>
  );
};

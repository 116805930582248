import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  top: 66px;
  left: 0;
  padding: 0 20px;
  color: black;
  gap: 14px;
  padding: 20px;
  background-color: white;
  z-index: 1;
`;


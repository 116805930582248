import React, { useState, useEffect, useMemo, memo } from 'react';
import styles from './Notes.module.scss';
import { getCookie } from 'react-use-cookie';
import { useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from '../../../images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../images/delete.svg';
import Editor from './Editor/Editor';
import { useGetNotesQuery, useGetStudentNotesQuery } from '../../../redux/Api';
import DateComponent from '../../common/DateComponent/DateComponent';
import EmptyNotes from '../../stubs/studentStubs/EmptyNotes';
import Alert from './Alert/Alert';
import { useArchivedNotesMutation, useArchivedStudentNotesMutation } from '../../../redux/Api';
const Notes = memo(function Notes({ dataNotes, position }) {

  const [archivedNotes] = useArchivedNotesMutation();
  const [archivedStudent] = useArchivedStudentNotesMutation();
  const [permissionAccess, setPermissionAccess] = useState(false);
  const [tokenPlusId, setTokenPlusId] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const permissions = useSelector((state) => state.token.role);
  const [indicatePopap, setIndicatePopup] = useState('');
  const [indicateAlert, setIndicateAlert] = useState('');
  const [toggleAlert, setToggleAlert] = useState('');
  const [sortData, setSortData] = useState([]);
  const [filter, setFilter] = useState('all');
  const [data, setData] = useState('')
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  useMemo(() => {
    if (permissions.includes('Administration')) {
      setPermissionAccess(true);
    }
    if (dataNotes) {
      let email = dataNotes;
      let tokenPlusId = { getAccess, email };
      setTokenPlusId(tokenPlusId);
    }
  }, [permissions, dataNotes]);

  const { data: dataInstructor = [], isSuccess: successInstructor } = useGetNotesQuery(tokenPlusId, {
    skip: permissionAccess == false || position !== 'instructor',
  });
  const { data: dataStudents = [], isSuccess: successStudents} = useGetStudentNotesQuery(tokenPlusId, {
    skip: permissionAccess == false || position !== 'student',
  });

  useMemo(()=>{
    if(dataInstructor.length > 0 || successInstructor){
      setData(dataInstructor)
      if(dataInstructor.length === 0){
        setSortData(dataInstructor);
      }
    }
  },[dataInstructor, successInstructor])
  useMemo(()=>{
   if(dataStudents.length > 0 || successStudents){
    setData(dataStudents)
    if(dataStudents.length === 0){
      setSortData(dataStudents);
    }
   }
  },[dataStudents, successStudents])
  const handleDelete = async (id) => {
    setIndicateAlert(id);
    setToggleAlert('delete');
    setShowAlert(true);
  };
  const handleArchived = async (item) => {
    if (item.status == 'active') {
      setIndicateAlert(item);
      setToggleAlert('active');
      setShowAlert(true);
    } else {
      let status = 'active';
      let body = {
        status: status,
      };
      let id = item.id;
      let tokenPlusBody = { getAccess, body, id };
      if(position === 'instructor'){
        await archivedNotes(tokenPlusBody).unwrap();
      }
      if(position === 'student'){
      await archivedStudent(tokenPlusBody).unwrap();
      }
    }
  };
  const handleAdd = () => {
    setShowPopup(true);
    setIndicatePopup('add');
  };
  const handleEdit = (item) => {
    setShowPopup(true);
    setIndicatePopup(item);
  };

  const sort = (e) => {
    let arr = JSON.parse(JSON.stringify(e));
    let result = arr.sort((a, b) => new Date(b.modified_date) - new Date(a.modified_date));
    return result;
  };

  useMemo(() => {
    if(data.length > 0){
    if (filter == 'all') {
      let sortData = sort(data);
      setSortData(sortData);
    } else if (filter == 'active') {
      let sortData = sort(data);
      let sortDataFilter = sortData.filter((item) => item.status == 'active');
      setSortData(sortDataFilter);
    } else if (filter == 'archived') {
      let sortData = sort(data);
      let sortDataFilter = sortData.filter((item) => item.status == 'archived');
      setSortData(sortDataFilter);
    }}
  }, [data, filter]);

  return (
    <div className={styles.Notes}>
      <h4 className={styles.title}>Notes</h4>
      <div className={styles.handlerBar}>
        <div className={styles.sortDiv}>
          <p className={styles.showText}>show:</p>
          <div className={filter == 'all' ? styles.sortItemActive : styles.sortItem}>
            <p
              className={filter == 'all' ? styles.sortTextActive : styles.sortText}
              onClick={() => setFilter('all')}
            >
              all
            </p>
          </div>
          <div className={filter == 'active' ? styles.sortItemActive : styles.sortItem}>
            <p
              className={filter == 'active' ? styles.sortTextActive : styles.sortText}
              onClick={() => setFilter('active')}
            >
              active notes
            </p>
          </div>
          <div className={filter == 'archived' ? styles.sortItemActive : styles.sortItem}>
            <p
              className={filter == 'archived' ? styles.sortTextActive : styles.sortText}
              onClick={() => setFilter('archived')}
            >
              archive notes
            </p>
          </div>
        </div>
        <p
          className={styles.addNote}
          onClick={() => handleAdd()}
        >
          add note
        </p>
      </div>

      <section className={styles.notesSection}>
        {sortData.length > 0 &&
          sortData.map((item) => {
            return (
              <div
                className={styles.divNate}
                key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                onClick={() => handleArchived(item)}
              >
                <div className={styles.firstLine}>
                  <div className={styles.firstLineMain}>
                    <div className={styles.noteNameDiv}>
                      <p
                        className={item.status == 'active' ? styles.noteName : styles.noteNameArch}
                      >
                        {item.note_name}
                      </p>
                    </div>
                    <div className={styles.editedDiv}>
                      <p className={styles.editedTitle}>last edited by:</p>
                      <p className={styles.editedName}>{item.last_edited_by}</p>
                    </div>
                  </div>

                  <div
                    className={styles.firstLineIcons}
                    onClick={(e) => e.stopPropagation()}
                  >
                     <EditIcon   className={styles.icon} onClick={() => handleEdit(item)} />
                     <DeleteIcon   className={styles.icon}  onClick={() => handleDelete(item.id)}/>
                  </div>
                </div>
                <p
                  className={item.status == 'active' ? styles.description : styles.descriptionArch}
                >
                  {item.description}
                </p>
                <p className={styles.date}>
                  {item.modified_date.slice(11, 16)}, <DateComponent item={item.modified_date} />
                </p>
                
              </div>
            );
          })}
      </section>
      {sortData.length == 0 && <EmptyNotes />}
      {showAlert && (
        <Alert
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          indicateAlert={indicateAlert}
          tokenPlusId={tokenPlusId}
          toggleAlert={toggleAlert}
          position={position}
        />
      )}
      {showPopup && (
        <Editor
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          indicatePopap={indicatePopap}
          tokenPlusId={tokenPlusId}
          position={position}
        />
      )}
    </div>
  );
})

export default Notes;

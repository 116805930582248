import React from 'react';
import styles from './GenerateError.module.css';
import generateError from '../../../../images/generateError.svg';

function GenerateError() {
  return (
    <div className={styles.studentStubs}>
      <img
        src={generateError}
        alt='img'
        className={styles.img}
      />
      <h5 className={styles.title}>Service is temporarily unavailable</h5>
      <p className={styles.secondTitle}>maintenance work in progress please come back later</p>
    </div>
  );
}

export default GenerateError;

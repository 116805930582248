import { API } from '@shared/api';
import { Chat, Course, CreateChatDetails } from '../lib';

export const fetchAllCourses = async () =>
  await (
    await API.get<{ courses: Course[] }>('/courses')
  ).data;

export const fetchChatsByCourseID = async (course_id: string) =>
  await (
    await API.get<{ chats: Chat[] }>('/chats', {
      params: {
        course_id,
      },
    })
  ).data;

export const createChat = async (chatDetails: CreateChatDetails) =>
  await (
    await API.put<Chat>('/chats', chatDetails)
  ).data;

export const loadCoursesViaCSV = async (file: FormData) =>
  await (
    await API.post('/courses', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;

export const loadChatsViaCSV = async (file: FormData) =>
  (
    await API.post('/chats', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  ).data;

export const getCreatedChatsInCSV = async () =>
  (
    await API.get('/chats', {
      headers: {
        accept: 'text/csv',
      },
    })
  ).data;

export const deleteChat = async (id: Chat['id']) => await API.delete(`/chats/${id}`);

export const updateChatInfo = async ({id, ...rest}: Omit<CreateChatDetails, 'course_id' | 'type'> & {id: Chat['id']}) =>  await API.patch(`/chats/${id}`, rest)
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: relative;
  padding: 0 60px 0 144px;
  width: 100%;
  height: 120px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 0px 12px rgba(175, 175, 175, 0.03);
  display: flex;
  justify-content: space-between;
`;

export const ChatCategoryImage = styled.img`
  position: absolute;
  left: 51px;
`

export const Category = styled.div`
  height: 38px;
  display: inline-flex;
  padding: 13px;
  justify-content: center;
  align-items: center;
  color: #3852DE;
  border: 1px solid #3852DE;
  border-radius: 11px;
  margin-right: 24px;
`

export const Title = styled.span`
    font-size: 18px;
`
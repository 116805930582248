import React, { memo, forwardRef } from 'react';
import { splitBoxProps } from 'ui-box';
import style from './Badge.module.scss';
import { Text } from '../Text/Text';

const pseudoSelectors = {};

const internalStyles = {
  display: 'inline-block',
  boxSizing: 'border-box',
  verticalAlign: 'middle',
};

/**
 * This is a dummy component for display Badge
 * @param { string } className - Class name passed to the component
 * @param { 'default' | 'secondary' | 'primary'  } appearance - The appearance used for the badge.
 * @param { string  } color - Custom color for the text
 * @param { bool } isInteractive - Whether or not to apply hover/focus/active styles
 */
export const Badge = memo(
  forwardRef(function Badge(props, ref) {
    const {
      appearance = 'default',
      size = 's',
      className,
      isInteractive = false,
      ...restProps
    } = props;

    const badgeClassName = [className, style.Badge, style[appearance], style[size]].join(' ');

    const { className: themedClassName, ...styleProps } = splitBoxProps(
      { appearance },
      pseudoSelectors,
      internalStyles,
    );

    return (
      <Text
        ref={ref}
        size={300}
        className={[badgeClassName, themedClassName, isInteractive && style.interactive].join(' ')}
        {...styleProps}
        {...restProps}
      />
    );
  }),
);

import { css } from '@emotion/react';
import { InterFont, PlatformFont } from './fonts';


export const fonts = css`
  @font-face {
    font-family: 'Platform LC Web';
    src: local('Platform LC Web'), url(${PlatformFont}) format('opentype');
    font‑display: block;
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter'), url(${InterFont}) format('opentype');
    font‑display: block;
  }
`;

import styled from '@emotion/styled';

export const FormBody = styled.form`
  padding: 40px 70px 40px 40px;
  background-color: white;
  margin-bottom: 50px;

  @media (max-width: 1440px) {
    padding: 40px 30px;
  }
`;

export const DraftedChats = styled.div`
  margin-bottom: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
`;
export const FormTitle = styled.h2`
  font-family: Platform LC Web;
  font-size: 34px;
`;

export const Title = styled.h3`
  display: block;
  font-size: 20px;
  color: rgba(35, 35, 35);
  margin: 0;
`;

export const FieldsWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  padding-top: 40px;
  gap: 111px;
  margin-bottom: 40px;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const Wrapper = styled.div`
  background-color: rgba(246, 246, 246, 0.53);
  padding: 70px 195px 184px;

  @media (max-width: 1440px) {
    padding: 70px 105px 184px;
  }

  @media (max-width: 1280px) {
    padding: 70px 55px 184px;
  }
`;

export const AddAnotherCourseButton = styled.button`
  border: 1px solid rgba(129, 129, 129);
  display: flex;
  align-items: center;
  gap: 63px;
  width: 100%;
  height: 120px;
  border-radius: 8px;
  padding: 0 49px;
  color: #818181;
  font-weight: 500;
`;
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

import React, { forwardRef, useState, useCallback, useMemo } from 'react';
import styles from './ModifyProjectSideCard.module.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {ReactComponent as FIleCollab} from '../../../images/collaboration/FIleCollab.svg'
import {ReactComponent as Cross} from  '../../../images/crossNew.svg';
import { Text } from '../../common/Text/Text';
import { Button } from '../../common/Button/Button';
import { Checkbox } from '../../common/Checkbox/Checkbox';
import { Badge } from '../../common/Badge/Badge';
import { Stack } from '@mui/material';
import { ReactComponent as Chevron }  from '../../../images/25.svg';
import Dialog from './Dialog/Dialog';
import { getCookie } from 'react-use-cookie';
import { useGetStudentsQuery } from '../../../redux/Api';
import { useSelector } from 'react-redux';

const filter = createFilterOptions();

export const ModifyProjectSideCard = forwardRef(function ProjectTableCard(
  { className, onSubmit, projectData, create, handlers, options, onDelete, onUpdate, onArchive, fileData, setFileHandler, setShowFile},
  ref,
) {
  const [openDialog, setOpenDialog] = useState('');
  const [file, setFile] = useState(fileData ?? []);
  const [showMore, setShowMore] = useState(false);
  const [contacts, setContacts] = useState(false)
  const [validation, setValidation] = useState(false)
  const [errorEmailValidation, setErrorEmailValidation] = useState(false)
  const [errorSize, setErrorSize] = useState(false)
  const [supervisor, setsSpervisor] = useState(true)
  const [supervisorName, setsSpervisorName] = useState('')
  const [dataFilter, setDataFilter] = useState({});
  const[openSupervisor, setOpenSupervisor] = useState(false)
  const [supervisorData, ] = useState(projectData)
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  let email = useSelector((state) => state.token.email);
  let family = useSelector((state) => state.token.family);
  let name = useSelector((state) => state.token.name);
  const {
  data = [],
} = useGetStudentsQuery(getAccess, {
  skip: !getCookie('access_token') || supervisor,
});

useMemo(() => {
  if (data?.users) {
    setDataFilter(
      data.users.filter((item) => item.status !== 'student' && item.full_name_latin.toUpperCase().includes(supervisorName.toUpperCase())
      ),
    );
  }
}, [data, supervisorName]);


useMemo(() => {
  if(supervisorName){
    handlers.onSupervisorName(supervisorName)
  }
}, [supervisorName]);


const emailHandler = useCallback(
  (item)=>{
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    handlers.handleEmailChange(item)
    if(!supervisor){
      handlers.onSupervisorEmail(item)
    }
    if(!emailRegex.test(item)){
      setErrorEmailValidation(true)
      scrollIntoCurrent('#email')
    }else{
      setErrorEmailValidation(false)
    }
  },[]
)


const supervisorHandler = (item) =>{
  setsSpervisorName(item)
  setOpenSupervisor(false)
}
const toggleSupervisor = () =>{
  setsSpervisor(!supervisor)
}
useMemo(() => {
  if(!supervisor){
    handlers.handleEmailChange(null)
    handlers.handleOfficeChange(null)
    handlers.handleTelegramChange(null)
    handlers.handleHoursChange(null)
    handlers.onSupervisorName(supervisorName)
    handlers.onSupervisorEmail(projectData.email)
     }else{
    handlers.handleEmailChange(supervisorData?.email)
    handlers.handleOfficeChange(supervisorData?.office)
    handlers.handleTelegramChange(supervisorData?.telegram)
    handlers.handleHoursChange(supervisorData?.open_hours)

    handlers.onSupervisorName(`${family} ${name}`)
    handlers.onSupervisorEmail(email)
  }
}, [supervisor]);
  const handleSubbmit = useCallback(
    (event) => {
      event.preventDefault();

      if(!contacts && create){
        delete projectData.telegram;
        delete projectData.office;
        delete projectData.open_hours;
      }
      let storage = {};
      for (let item in projectData) {
        if(item == 'active' || item == 'count_participants' || item == 'is_thesis_suitable' || item == 'supervisor' || (!create && item == 'email') || (!create && item == 'telegram') || (!create && item == 'office') || (!create && item == 'open_hours') ){
          storage[item] = projectData[item];
        }
        for (let prop in projectData[item]) {
          if (projectData[item][prop]?.length != 0 || item == 'supervisor') {
            storage[item] = projectData[item];
          }
        }
      }
      projectData = storage;
      let formData = new FormData();
      formData.append(
        'project',
        JSON.stringify({ ...projectData}),
      );
      if(file[0]?.name || file?.name){
        formData.append('attachment',  file[0]);

      }
      onSubmit?.(formData);
    },
    [onSubmit, projectData, file, contacts],
  );
  const selectFile = (e) => {
    if(e.target?.files[0]?.size){
      if(e.target?.files[0]?.size < 5000000 ){
        setErrorSize(false)
        if(!create){
          setFileHandler(e.target.files[0])
          }
        setFile(e.target.files)
      }else{
        setErrorSize(true)
        if(!create){
          setFileHandler(null)
          }
      }
    }
  }
    const deleteFile = () => {
        setErrorSize(false)
        setFile('')
        setFileHandler(null)
          }
    const showMoreHandler = () => {
      if(!create){
        setShowFile(true)
      }
      setShowMore(true)
        }

  const renderTagsInAutocompleat = useCallback(
    (tags) =>
      tags.map((tag) => (
        <Badge
          appearance='primary'
          key={tag.value}
          className={styles.selectTag}
        >
          {tag.value}
        </Badge>
      )),
    [],
  );

  const filterOptions = useCallback((options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.label);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        value: inputValue,
        label: `Add "${inputValue}"`,
      });
    }

    return filtered;
  }, []);
  const renderOptionsInAutocompleat = useCallback((option) => option.label, []);

  const fromListToObject = useCallback((list) =>{
   return list.map((item) => ({
      value: item,
      label: item,
    }))
  },[]
  );
  const handleDelete = useCallback(() => {
    onDelete?.();
    setOpenDialog('delete');
  }, [onDelete]);

  const handleOpenDialog = useCallback(() => {
    setOpenDialog('delete');

  }, []);
  const handleOpenArchive = useCallback(() => {
    setOpenDialog('archive');

  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog('');
  }, []);
  document.querySelectorAll('textarea').forEach(el => {
    el.style.height = el.setAttribute('style', 'height: ' + el.scrollHeight + 'px');
    el.classList.add('auto');
    el.addEventListener('input', () => {
        el.style.height = 'auto';
        el.style.height = (el.scrollHeight) + 'px';
    });
});
function scrollIntoCurrent (current) {
  document.querySelector(`${current}`).scrollIntoView({behavior: "smooth"});
}
function scrollIntoCurrentNoSmooth (current) {
  document.querySelector(`${current}`).scrollIntoView();
}
const valid =() =>{
  setValidation(true)
  scrollIntoCurrent('#valid')
}
  return (
    <>
      <Box
        ref={ref}
        component='form'
        noValidate
        autoComplete='off'
        className={[className, styles.CreateProjectSideCard].join(' ')}
        onSubmit={handleSubbmit}
      >
        <div className={styles.supervisorDiv}>
        <p className={supervisor ? styles.supervisorTextActive : styles.supervisorText}>I’m a supervisor</p>
        <label className={styles.switch}>
                <input
                  type='checkbox'
                  onChange={() => toggleSupervisor()}
                />
                <span className={`${styles.slider} ${styles.round}`}></span>
              </label>
        <p className={!supervisor ? styles.supervisorTextActive : styles.supervisorText}>I’m not a supervisor</p>
        </div>
        <Text
          fontWeight={400}
          fontSize={16}
          className={styles.textCheckbox}
          id='valid'
        >
          Title*
        </Text>

         <input
          required
          id='title'
          placeholder='Project title'
          value={projectData.title}
          onChange={handlers.handleTitleChange}
          className={validation && projectData?.title?.length < 3 ? styles.inputError : styles.input}
          maxLength={250}
        />
        <Checkbox
          label='suitable for thesis'
          id='is_thesis_sutable'
          onChange={handlers.handleThesisSuitableChange}
          checked={projectData.is_thesis_suitable}
          className={styles.textCheckbox}
        />
        <Text
          fontWeight={400}
          fontSize={16}
          className={styles.textCheckbox}
        >
          Description*
        </Text>

          <textarea
          required
          id='description'
          placeholder='Project description'
          value={projectData.description}
          onChange={handlers.handleDescriptionChange}
          className={validation && projectData?.description?.length < 3 ? styles.textareaError : styles.textarea}
        />
        <Text
          fontWeight={400}
          fontSize={16}
          className={styles.textCheckbox}
        >
          Expected outcome*
        </Text>
  
          <textarea
          required
          id='expected_outcome'
          placeholder='Expected outcome'
          value={projectData.expected_outcome}
          onChange={handlers.handleExpectedOutcomeChange}
          className={validation && projectData?.expected_outcome?.length < 3 ? styles.textareaError : styles.textarea}
        />

  <Stack
              direction='row'
              gap={3}
            >
              <Stack
                width='100%'
                gap={2}
              >

              <Text
          fontWeight={400}
          fontSize={16}
          className={styles.textCheckbox}
        >
          Prefer contacts*
        </Text>

        {create && <Checkbox
          label='more contacts'
          id='imore_contacts'
          onChange={()=>setContacts(!contacts)}
          checked={contacts}
          className={styles.moreContacts}
        />}
          </Stack>
          </Stack>
          {supervisor && <div 
             className={(validation && (projectData?.email?.length < 5 || errorEmailValidation) )  ? styles.areaContactsError : styles.areaContacts}>
          <div className={styles.divInput} id='email'>
            <p className={styles.textInput}>email*:</p>
            <input type='email' maxLength={48} className={styles.inputContacts} defaultValue={email}
                 onChange={(e)=>{emailHandler(e.target.value)}}/></div>
            {(contacts || !create) && <>
              <div className={styles.divInput}>
            <p className={styles.textInput}>telegram:</p>
            <input type='text' maxLength={48} className={styles.inputContacts} defaultValue={projectData.telegram}
            onChange={handlers.handleTelegramChange}/></div>
          <div className={styles.divInput}>
            <p className={styles.textInput}>office:</p>
            <input type='text' maxLength={48} className={styles.inputContacts} defaultValue={projectData.office}
           onChange={handlers.handleOfficeChange}/></div>
          <div className={styles.divInput}>
            <p className={styles.textInput}>open hours:</p>
            <input type='text' maxLength={48} className={styles.inputContacts} defaultValue={projectData.open_hours} 
           onChange={handlers.handleHoursChange}/></div>
            </>}

          </div>}
         {!supervisor &&<div 
             className={(validation && (projectData?.email?.length < 5 || errorEmailValidation) ) ? styles.areaContactsError : styles.areaContacts}>
          <div className={styles.divInput} id='email'>
            <p className={styles.textInput}>email*:</p>
            <input type='email' maxLength={48} className={styles.inputContacts} defaultValue={''}
            onChange={(e)=>{emailHandler(e.target.value)}}/></div>
            {(contacts || !create) && <>
              <div className={styles.divInput}>
            <p className={styles.textInput}>telegram:</p>
            <input type='text' maxLength={48} className={styles.inputContacts} defaultValue={''}
            onChange={handlers.handleTelegramChange}/></div>
          <div className={styles.divInput}>
            <p className={styles.textInput}>office:</p>
            <input type='text' maxLength={48} className={styles.inputContacts} defaultValue={''}
           onChange={handlers.handleOfficeChange}/></div>
          <div className={styles.divInput}>
            <p className={styles.textInput}>open hours:</p>
            <input type='text' maxLength={48} className={styles.inputContacts} defaultValue={''}
           onChange={handlers.handleHoursChange}/></div>
            </>}

          </div>}

           {!supervisor && <div>
          <Text
              fontWeight={400}
              fontSize={16}
              className={styles.textCheckbox}
            >
              Supervisor*
            </Text>
            <div className={validation && supervisorName?.length < 5 ? styles.inputDivError : styles.inputDiv}>
            <input  className={styles.inputSupervisor} type= 'text' 
            onClick={()=>setOpenSupervisor(true)}
            onChange={(e)=>setsSpervisorName(e.target.value)} 
            value={supervisorName}
            placeholder='Latin name'/>
            <div className={openSupervisor ? styles.ChevronRevers : styles.Chevron}  onClick={()=>setOpenSupervisor(!openSupervisor)}><Chevron/></div>
 
            </div>
            {(dataFilter.length && openSupervisor) 
            ? <div className={styles.dropdown}>
              {dataFilter.length ? dataFilter.map(item=>{
                return <p
                className={styles.dropdownText}
                onClick={()=>supervisorHandler(item.full_name_latin)}
                 key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}>{item.full_name_latin}</p>
              })
            :<></>}
            </div>
             :<></>}
          </div>}
        {!showMore && (
          <Text
            fontWeight={500}
            fontSize={16}
            color='#3852DE'
            className={styles.detailsButton}
            onClick={()=>showMoreHandler()}
          >
            more details
          </Text>
        )}

         {showMore && <>
            <Text
              fontWeight={400}
              fontSize={16}
              className={styles.textCheckbox}
            >
              Fields
            </Text>
            <div onClick={()=> scrollIntoCurrentNoSmooth('#files')}>
            <Autocomplete
              multiple
              id='fields'
              
              className={styles.autocompleteFields}
              popupIcon={<Chevron />}
              options={options?.subjectAreas ?? []}
              getOptionLabel={renderOptionsInAutocompleat}
              filterSelectedOptions
              defaultValue={fromListToObject(projectData.subject_areas)}
              renderTags={renderTagsInAutocompleat}
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, values) =>
                handlers.handleSubjectAreasChange(values.map((item) => item.value))
              }
              size='small'
              filterOptions={filterOptions}
            /></div>
          <Text
              fontWeight={400}
              fontSize={16}
              className={styles.textCheckbox}
            >
              Technological stack
            </Text>
            <div onClick={()=> scrollIntoCurrentNoSmooth('#files')}>
            <Autocomplete
              multiple
              id='stack'
              popupIcon={<Chevron />}
              options={options?.skills ?? []}
              getOptionLabel={renderOptionsInAutocompleat}
              renderTags={renderTagsInAutocompleat}
              defaultValue={fromListToObject(projectData.skills)}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} />}
              onChange={(event, values) =>
                handlers.handleSkillsChange(values.map((item) => item.value))
              }
              size='small'
              filterOptions={filterOptions}
            />
            </div>
            <Stack
              direction='row'
              gap={2}
            >
              <Stack
                width='50%'
                gap={2}
              >
                <Text
                  fontWeight={400}
                  fontSize={16}
                  className={styles.textCheckbox}
                >
                  Team Size
                </Text>
                <TextField
                  id='team_size'
                  defaultValue={projectData.count_participants}
                  onChange={handlers.handleCountParticipantsChange}
                  type='number'
                  fullWidth
                  size='small'
                />
              </Stack>
              <Stack
                width='50%'
                gap={2}
              >
                <Text
                  fontWeight={400}
                  fontSize={16}
                  className={styles.textCheckbox}
                >
                  Study Level
                </Text>
                <div onClick={()=> scrollIntoCurrentNoSmooth('#files')}>
                <Autocomplete
                  multiple
                  id='level'
                  popupIcon={<Chevron />}
                  options={options?.studyLevels ?? []}
                  getOptionLabel={renderOptionsInAutocompleat}
                  renderTags={renderTagsInAutocompleat}
                  filterSelectedOptions
                  defaultValue={fromListToObject(projectData.study_levels)}
                  renderInput={(params) => <TextField {...params} />}
                  size='small'
                  filterOptions={filterOptions}
                  onChange={(event, values) =>
                    handlers.handleLevelsChange(values.map((item) => item.value))
                  }
                /></div>
              </Stack>
            </Stack>
            <Stack
              direction='row'
              gap={2}
            >
              <Stack
                width='50%'
                gap={2}
              >
                <Text
                  fontWeight={400}
                  fontSize={16}
                  className={styles.textCheckbox}
                >
                  Deadline
                </Text>
                <TextField
                  id='deadline'
                  placeholder='No limit'
                  type='date'
                  fullWidth
                  size='small'
                  inputProps={{ min: "2019-01-24", max: "2040-05-31" }}
                  defaultValue={projectData?.deadline?.slice(0,10)}
                  onChange={handlers.handleDurationChange}
                />
              </Stack>
            </Stack>

          <label className={styles.customFileLabel}>
          <input
            type='file'
            onClick={deleteFile}
            onChange={selectFile}
            className={styles.ainputFile}
          />
          {errorSize && <p className={styles.errorSize}>the maximum file size is 5mb</p>}
          <div className={styles.attachDiv}>
          <FIleCollab className={styles.icon}/>
          <p className={styles.textAttach}  id='files'>attach a file</p>
          </div>
          </label>
         {(file[0]?.name || file?.name) && <div className={styles.fileDiv}><p className={styles.textFilename}>{file[0]?.name || file?.name}</p> 
         <span
          className={styles.spanDeleteFile}
          onClick={deleteFile}
        >
            <Cross
            className={styles.cross}
          />
          <p className={styles.textDeleteFile}>delete a file</p> 
        </span>
        </div>}
          
          </>}
        {create ? (
          <>
          {(supervisor || supervisorName.length > 4  )&& projectData.title.length > 2  && projectData.description.length > 2  && projectData.expected_outcome.length > 2  && projectData?.email.length > 4 && !errorEmailValidation
          ? <Button
            type='submit'
            className={styles.button}
          >
            <p>create</p>
            
          </Button>     
          :<div
          onClick={valid}
          className={styles.button}
        >
          <p>create</p>
          
        </div>}
          </>
        ) : (
          <Stack
            direction='row'
            gap={1}
          >
             {(supervisor || supervisorName.length > 4  ) && projectData.title.length > 2  && projectData.description.length > 2  && projectData.expected_outcome.length > 2  && projectData?.email.length > 4 && !errorEmailValidation
             ? <Button
             type='submit'
             className={styles.button}
             onClick={onUpdate}
            >
              <p>edit</p>
            </Button>
           :<Button
           onClick={valid}
           className={styles.button}
         >
           <p>edit</p>
           
         </Button>}
            <Button
              className={styles.buttonSecondary}
              type='submit'
              onClick={ projectData.active ? handleOpenArchive : onArchive }
            ><p>
     {projectData.active ? "archive" : "active"} </p>
            </Button>
            <Button
              className={styles.buttonSecondary}
              type='submit'
              onClick={handleOpenDialog}
            >
              <p>delete</p>
            </Button>
          </Stack>
        )}
      </Box>
     {openDialog && <Dialog
      onClose={handleCloseDialog}
      handleDelete={handleDelete}
      onArchive={onArchive}
      openDialog={openDialog}/>} 

    </>
  );
});

import React, { FC, useEffect, useRef, useState } from "react";
import { ArrowIcon, TitleWrapper, Wrapper } from "./SelectFilter.styles";
import { Option } from "./lib";
import { Dropdown } from "./ui";
import { useOutsideClick } from "rooks";
import Arrow from "@shared/assets/icons/arrow.svg";

interface SelectFilterProps {
  icon: string;
  title: string;
  options: Option[];
  onOptionClick: (value: any) => void;
}

export const SelectFilter: FC<SelectFilterProps> = ({ icon, title, options, onOptionClick }) => {
  const [value, setValue] = useState<string | null>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [titleWidth, setTitleWidth] = useState<number>();
  const titleRef = useRef<HTMLSpanElement | null>(null);
  const dropdownRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setTitleWidth(titleRef.current?.offsetWidth);
  }, []);

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false)
  })

  return (
    <Wrapper
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      hasSelected={!!value}
      ref={dropdownRef}
    >
      <img src={icon} alt="" />
      <TitleWrapper style={{ width: titleWidth! + 1 }}>
        <span ref={titleRef}>{value || title} </span>
      </TitleWrapper>

      <ArrowIcon src={Arrow} />
      {isOpen && (
        <Dropdown
          onOptionClick={(optionValue: string | null) => {
            setValue(optionValue)
            onOptionClick(optionValue)
          }}
          options={options}
        />
      )}
    </Wrapper>
  );
};

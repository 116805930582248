import React, { useEffect, useState } from 'react';
import styles from '../RPD.module.css';
import arrow from '../../../../images/32.svg';
import checkboxON from '../../../../images/Component 5.svg';
import checkboxOFF from '../../../../images/Component 6.svg';

function SecondStep({
  search,
  study_plansID,
  setStudy_plansID,
  clearPlans,
  newDataPlans,
  isLoadingStudyPlans,
}) {
  const [newDate, setNewDate] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  useEffect(() => {
    let date = new Date();
    let output = String(date.getMonth() + 1).padStart(2, '0');
    let outputNew = date.getFullYear();
    if (output > 8) {
      outputNew = date.getFullYear() + 1;
    }
    setNewDate(outputNew);
  }, [newDataPlans]);

  const soldCheckbox = (item) => {
    if (study_plansID && study_plansID.includes(item)) {
      setStudy_plansID(study_plansID.filter((element) => element !== item));
    } else {
      setStudy_plansID([...study_plansID, item]);
    }
    setCheckbox(!checkbox);
  };
  return (
    <div>
      <div className={styles.steps}>
        {newDataPlans.length > 0 && newDataPlans[0] == undefined ? (
          <div>
            <h5 className={styles.headerTitle}>this course is temporarily unavailable</h5>
          </div>
        ) : (
          <div>
            <div className={styles.stepsHeader}>
              <div className={styles.stepsNumber}>
                <p className={styles.stepsNumberText}>2</p>
              </div>
              <h5 className={styles.headerTitle}>Choose the course details</h5>
              <img
                src={arrow}
                className={styles.arrow}
                alt='arrow'
              />
            </div>

            {newDataPlans && search && clearPlans && !isLoadingStudyPlans ? (
              <div className={styles.stepsBody}>
                <div className={styles.stepsBody2}>
                  {newDataPlans.map((item) => {
                    return (
                      <div
                        key={Math.random()}
                        className={styles.studyPlansDiv}
                      >
                        {!study_plansID.includes(item.id) && (
                          <img
                            src={checkboxOFF}
                            onClick={() => soldCheckbox(item.id)}
                            type='checkbox'
                            className={styles.checkbox2}
                          />
                        )}
                        {study_plansID.includes(item.id) && (
                          <img
                            src={checkboxON}
                            onClick={() => soldCheckbox(item.id)}
                            type='checkbox'
                            className={styles.checkbox2}
                          />
                        )}

                        <div className={styles.checkboxTextDiv}>
                          <h5 className={styles.checkboxText}>
                            {' '}
                            {item.degree} {item.year} - {item.profile}
                          </h5>
                          {item.degree == 'бакалавр' && Number(newDate) - Number(item.year) < 5 ? (
                            <div className={styles.courseActual}>
                              <p className={styles.textActual}>
                                {Number(newDate) - Number(item.year)} курс
                              </p>
                            </div>
                          ) : item.degree == 'бакалавр' &&
                            Number(newDate) - Number(item.year) > 4 ? (
                            <div className={styles.courseNoActual}>
                              <p className={styles.textNoActual}>не актуален</p>
                            </div>
                          ) : item.degree == 'магистр' &&
                            Number(newDate) - Number(item.year) < 3 ? (
                            <div className={styles.courseActual}>
                              <p className={styles.textActual}>
                                {Number(newDate) - Number(item.year)} курс
                              </p>
                            </div>
                          ) : (
                            <div className={styles.courseNoActual}>
                              <p className={styles.textNoActual}>не актуален</p>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className={styles.stepsBodyHidden}></div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SecondStep;

import React, { useState, useMemo } from 'react';
import styles from './BlockTA.module.scss';
import humans from '../../../../images/instractor/humans.svg';
import TAname from '../../../../images/instractor/TAname.svg';
import { useGetFeedbacksByResponsesRelatedToStuffCommonQuery } from '../../../../redux/Api';
import Feedback from '../../../InstractorComponents/Courses/Feedback/Feedback';
import { useDispatch } from 'react-redux';
import { getfeedbackData } from '../../../../redux/instructorSlice';
function BlockTA({
  name,
  nameTA,
  staff_id,
  questionsId,
  getAccess,
  feedbackId,
  question_id,
  newDataAnswers,
}) {
  const [paramsData, setParamsData] = useState('');
  const [newData, setNewData] = useState('');
  const [grades, setGrades] = useState('');
  const dispatch = useDispatch();
  useMemo(() => {
    if ((feedbackId && staff_id && question_id, getAccess)) {
      let roles_ids = '12';
      let tokenPlusId = {
        getAccess,
        feedbackId,
        question_id,
        roles_ids,
        staff_id,
      };
      setParamsData(tokenPlusId);
    }
  }, [question_id]);

  const { data = [] } = useGetFeedbacksByResponsesRelatedToStuffCommonQuery(paramsData, {
    skip: !paramsData,
  });
  useMemo(() => {
    if (data.length > 0) {
      let newData = data.filter((e) => {
        return (
          (e.printed_value == 'agree' ||
            e.printed_value == 'strongly agree' ||
            e.printed_value == 'strongly disagree' ||
            e.printed_value == 'disagree' ||
            e.printed_value == 'neutral') &&
          questionsId.includes(e.item)
        );
      });
      setNewData(newData);
    }
  }, [data]);
  useMemo(() => {
    if (newDataAnswers) {
      let newData = newDataAnswers.filter((e) => {
        return (
          (e.printed_value == 'agree' ||
            e.printed_value == 'strongly agree' ||
            e.printed_value == 'strongly disagree' ||
            e.printed_value == 'disagree' ||
            e.printed_value == 'neutral') &&
          questionsId.includes(e.item)
        );
      });
      setNewData(newData);
    }
  }, [newDataAnswers]);
  useMemo(() => {
    if (newData.length > 0 && !newDataAnswers) {
      dispatch(getfeedbackData([staff_id, Number(newData.length / 8)]));
    }
  }, [newData]);
  useMemo(() => {
    if (newData.length > 0) {
      let strongly_agree = newData.reduce((accum, item) => {
        return item.printed_value == 'strongly agree' ? accum + 1 : accum;
      }, 0);
      let agree = newData.reduce((accum, item) => {
        return item.printed_value == 'agree' ? accum + 1 : accum;
      }, 0);
      let neutral = newData.reduce((accum, item) => {
        return item.printed_value == 'neutral' ? accum + 1 : accum;
      }, 0);
      let disagree = newData.reduce((accum, item) => {
        return item.printed_value == 'disagree' ? accum + 1 : accum;
      }, 0);
      let strongly_disagree = newData.reduce((accum, item) => {
        return item.printed_value == 'strongly disagree' ? accum + 1 : accum;
      }, 0);
      setGrades({
        strongly_agree,
        agree,
        neutral,
        disagree,
        strongly_disagree,
      });
    }
  }, [newData]);

  return (
    <div className={nameTA == name ? styles.BlockTAActive : styles.BlockTA}>
      <div className={styles.line}>
        <img
          src={TAname}
          className={styles.img}
        />
        <p className={nameTA == name ? styles.nameActive : styles.name}>{name}</p>
      </div>
      <div className={styles.line}>
        <img
          src={humans}
          className={styles.img}
        />
        <p className={styles.answers}>answers:{newData.length / 8}</p>
      </div>
      <div className={styles.grades}>
        {grades && <p className={styles.text}>TA’s total number of answers, %</p>}
        <Feedback
          grades={grades}
          name={name}
        />
      </div>
    </div>
  );
}

export default BlockTA;

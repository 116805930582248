import React, { useMemo, useState, useEffect } from 'react';
import styles from './FeedbackAnswers.module.scss';
import { useGetFeedbackAnswersQuery } from '../../../../../redux/Api';
import { useDispatch } from 'react-redux';
import { getFeedback } from '../../../../../redux/instructorSlice';

function FeedbackAnswers({ answer, getAccess, newData }) {
  const dispatch = useDispatch();
  const [tokenPlusId, setTokenPlusId] = useState('');
  useEffect(() => {
    if (answer && getAccess) {
      let feedbackId = answer;
      let tokenPlusId = { getAccess, feedbackId };
      setTokenPlusId(tokenPlusId);
    }
  }, [answer]);

  const [data, setData] = useState('');
  const [resultStronglyAgree, setResultStronglyAgree] = useState('');
  const [resultAgree, setResultAgree] = useState('');
  const [resultNeutral, setResultNeutral] = useState('');
  const [resultDisagree, setResultDisagree] = useState('');
  const [resultStronglyDisagree, setResultStronglyDisagreee] = useState('');

  const { data: dataAnswers = [] } = useGetFeedbackAnswersQuery(tokenPlusId, {
    skip: !tokenPlusId,
  });

  useMemo(() => {
    if (dataAnswers.length > 0) {
      setData(dataAnswers);
    }
  }, [dataAnswers]);
  useMemo(() => {
    if (newData && answer) {
      let itemData = newData.filter((e) => {
        return e.item == answer;
      });
      setData(itemData);
    }
  }, [answer]);
  useMemo(() => {
    if (data.length > 0) {
      let stronglyAgree = data.reduce((accum, item) => {
        return item.printed_value === 'strongly agree' ? accum + 1 : accum;
      }, 0);
      let agree = data.reduce((accum, item) => {
        return item.printed_value === 'agree' ? accum + 1 : accum;
      }, 0);
      let neutral = data.reduce((accum, item) => {
        return item.printed_value === 'neutral' ? accum + 1 : accum;
      }, 0);
      let disagree = data.reduce((accum, item) => {
        return item.printed_value === 'disagree' ? accum + 1 : accum;
      }, 0);
      let stronglyDisagree = data.reduce((accum, item) => {
        return item.printed_value === 'strongly disagree' ? accum + 1 : accum;
      }, 0);

      let resultStronglyAgree = ((100 * stronglyAgree) / data.length).toFixed(1);
      let resultAgree = ((100 * agree) / data.length).toFixed(1);
      let resultNeutral = ((100 * neutral) / data.length).toFixed(1);
      let resultDisagree = ((100 * disagree) / data.length).toFixed(1);
      let resultStronglyDisagree = ((100 * stronglyDisagree) / data.length).toFixed(1);

      setResultStronglyAgree(resultStronglyAgree);
      setResultAgree(resultAgree);
      setResultNeutral(resultNeutral);
      setResultDisagree(resultDisagree);
      setResultStronglyDisagreee(resultStronglyDisagree);
      dispatch(getFeedback(data.length));
    }
  }, [data]);

  return (
    <div className={styles.FeedbackAnswers}>
      {resultStronglyAgree && resultStronglyAgree !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.green2M}
            style={{ width: resultStronglyAgree * 1.8 }}
          ></div>
          <p className={styles.textM}>{resultStronglyAgree}%</p>
        </div>
      )}
      {resultAgree && resultAgree !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.greenM}
            style={{ width: resultAgree * 1.8 }}
          ></div>
          <p className={styles.textM}>{resultAgree}%</p>
        </div>
      )}
      {resultNeutral && resultNeutral !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.yellowM}
            style={{ width: 1.8 * resultNeutral }}
          ></div>
          <p className={styles.textM}>{resultNeutral}%</p>
        </div>
      )}
      {resultDisagree && resultDisagree !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.orangeM}
            style={{ width: 1.8 * resultDisagree }}
          ></div>
          <p className={styles.textM}>{resultDisagree}%</p>
        </div>
      )}
      {resultStronglyDisagree && resultStronglyDisagree !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.redM}
            style={{ width: 1.8 * resultStronglyDisagree }}
          ></div>
          <p className={styles.textM}>{resultStronglyDisagree}%</p>
        </div>
      )}
    </div>
  );
}

export default FeedbackAnswers;

import React, { useState, useEffect, useRef } from 'react';
import styles from '../Syllabus.module.css';
import arrow from '../../../../images/32.svg';
import head from '../../../../images/head.svg';
import books from '../../../../images/books.svg';
import { useMatchMedia } from '../../../../hooks/useMatchMedia';
import radioON from '../../../../images/Frame 6634.svg';
import radioOFF from '../../../../images/offb.svg';
import { useGetEduwikiQuery } from '../../../../redux/eduwiki';
import { useDebounce } from '../../../../hooks/useDebounce';
import created from '../../../../images/created.svg';
import oldLink from '../../../../images/oldLink.svg';
import chevron from '../../../../images/25.svg';

function FirstStep({ degree, setDegree, courseType, setCourseType, sesCourseName, setshowStep }) {
  const { isMobile } = useMatchMedia();
  const [filterClicked, setFilterClicked] = useState(false);
  const [, setSelectCourse] = useState('');
  const [hint, setHint] = useState('BSTE:');
  const [search, setSearch] = useState('');
  const [, setClearPlans] = useState(false);
  const [obsticle, setObsticle] = useState('');
  const [newData, setNewData] = useState([]);
  let newSearch = `${hint}${search}`;
  let newSearchSecond = `${hint} ${search}`;
  const debounced = useDebounce(newSearch);
  const debouncedSecond = useDebounce(newSearchSecond);
  const { data = [] } = useGetEduwikiQuery(debounced, {
    skip: debounced.length < 1,
  });
  const { data: secondData = [] } = useGetEduwikiQuery(debouncedSecond, {
    skip: debounced.length < 1,
  });
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  useEffect(() => {
    setHint(`${degree}${courseType}:`);
  }, [courseType, degree]);

  const listHandle = (item) => {
    setSearch(courseType == 'c' ? item.title.slice(4) : item.title.slice(5));
    setSelectCourse(item.title);
    setClearPlans(true);
  };

  const inputHandle = (event) => {
    setClearPlans(false);
    setSearch(event.target.value);
    setFilterClicked(true);
    setSelectCourse('');
  };

  useEffect(() => {
    if (
      data.query &&
      data.query.prefixsearch.length > 0 &&
      secondData.query &&
      secondData.query.prefixsearch.length > 0
    ) {
      setNewData([...data.query.prefixsearch]);
    } else if (data.query && data.query.prefixsearch.length > 0) {
      setNewData([...data.query.prefixsearch]);
    } else if (secondData.query && secondData.query.prefixsearch.length > 0) {
      setNewData([...secondData.query.prefixsearch]);
    } else if (
      data.query &&
      data.query.prefixsearch.length == 0 &&
      secondData.query &&
      secondData.query.prefixsearch.length == 0
    ) {
      setNewData([]);
    }
  }, [data, secondData]);

  useEffect(() => {
    let obsticle = [];
    setTimeout(() => {
      if (newData) {
        obsticle = newData.filter(
          (item) =>
            item.title.slice(4) == search ||
            item.title.slice(5) == search ||
            item.title.slice(3) == search,
        );
        setObsticle(obsticle);
      } else {
        setObsticle(false);
      }
      if (search) {
        sesCourseName(search);
      }
      setshowStep(search);
    }, '1000');
  }, [newData]);
  return (
    <div>
      <div className={styles.steps}>
        <div className={styles.stepsHeader}>
          <div className={styles.stepsNumber}>
            <p className={styles.stepsNumberText}>1</p>
          </div>
          <h5 className={styles.headerTitle}>Specify the course details</h5>
          <img
            src={arrow}
            className={styles.arrow}
            alt='arrow'
          />
        </div>

        <div className={styles.stepsBody}>
          {!isMobile && (
            <div>
              <div className={styles.toggleDiv}>
                <img
                  src={head}
                  className={styles.img}
                  alt='head'
                />
                <p className={styles.toggleText}>Degree</p>
                <button
                  className={
                    degree == 'BS' ? styles.toggleButtonDegree1Active : styles.toggleButtonDegree1
                  }
                  onClick={() => setDegree('BS')}
                >
                  <p
                    className={
                      degree == 'BS' ? styles.toggleButtonTextActive : styles.toggleButtonText
                    }
                  >
                    bachelor
                  </p>
                </button>
                <button
                  className={
                    degree == 'MS' ? styles.toggleButtonDegree2Active : styles.toggleButtonDegree2
                  }
                  onClick={() => setDegree('MS')}
                >
                  <p
                    className={
                      degree == 'MS' ? styles.toggleButtonTextActive : styles.toggleButtonText
                    }
                  >
                    master
                  </p>
                </button>
              </div>

              <div className={styles.toggleDivType}>
                <img
                  src={books}
                  className={styles.img}
                  alt='books'
                />
                <p className={styles.toggleText}>Course type</p>
                <button
                  className={
                    courseType == 'TE'
                      ? styles.toggleButtonCourse1Active
                      : styles.toggleButtonCourse1
                  }
                  onClick={() => setCourseType('TE')}
                >
                  <p
                    className={
                      courseType == 'TE' ? styles.toggleButtonTextActive : styles.toggleButtonText
                    }
                  >
                    technical elective
                  </p>
                </button>
                <button
                  className={
                    courseType == 'HE'
                      ? styles.toggleButtonCourse2Active
                      : styles.toggleButtonCourse2
                  }
                  onClick={() => setCourseType('HE')}
                >
                  <p
                    className={
                      courseType == 'HE' ? styles.toggleButtonTextActive : styles.toggleButtonText
                    }
                  >
                    humanitarian elective
                  </p>
                </button>
                <button
                  className={
                    courseType == 'c'
                      ? styles.toggleButtonCourse3Active
                      : styles.toggleButtonCourse3
                  }
                  onClick={() => setCourseType('c')}
                >
                  <p
                    className={
                      courseType == 'c' ? styles.toggleButtonTextActive : styles.toggleButtonText
                    }
                  >
                    core course
                  </p>
                </button>
              </div>
            </div>
          )}
          {isMobile && (
            <div>
              <div className={styles.toggleDiv}>
                <div className={styles.toggleFlex}>
                  <img
                    src={head}
                    className={styles.img}
                    alt='head'
                  />
                  <p className={styles.toggleText}>Degree</p>
                </div>

                <div className={styles.radioDiv}>
                  <div className={styles.radioinputDiv}>
                    {degree == 'BS' && (
                      <img
                        src={radioON}
                        onClick={() => setDegree('BS')}
                        className={styles.checkboxAct}
                      />
                    )}
                    {degree != 'BS' && (
                      <img
                        src={radioOFF}
                        onClick={() => setDegree('BS')}
                        className={styles.checkboxAct}
                      />
                    )}

                    <label className={styles.radiolabel}>bachelor</label>
                  </div>
                  <div className={styles.radioinputDiv}>
                    {degree != 'MS' && (
                      <img
                        src={radioOFF}
                        onClick={() => setDegree('MS')}
                        className={styles.checkboxAct}
                      />
                    )}
                    {degree == 'MS' && (
                      <img
                        src={radioON}
                        onClick={() => setDegree('MS')}
                        className={styles.checkboxAct}
                      />
                    )}
                    <label className={styles.radiolabel}>master</label>
                  </div>
                </div>
              </div>

              <div className={styles.toggleDivType}>
                <div className={styles.toggleFlex}>
                  <img
                    src={books}
                    className={styles.img}
                    alt='books'
                  />
                  <p className={styles.toggleText}>Course type</p>
                </div>

                <div className={styles.radioDiv}>
                  <div className={styles.radioinputDiv}>
                    {courseType != 'TE' && (
                      <img
                        src={radioOFF}
                        onClick={() => setCourseType('TE')}
                        className={styles.checkboxAct}
                      />
                    )}
                    {courseType == 'TE' && (
                      <img
                        src={radioON}
                        onClick={() => setCourseType('TE')}
                        className={styles.checkboxAct}
                      />
                    )}
                    <label className={styles.radiolabel}>technical elective</label>
                  </div>

                  <div className={styles.radioinputDiv}>
                    {courseType == 'HE' && (
                      <img
                        src={radioON}
                        onClick={() => setCourseType('HE')}
                        className={styles.checkboxAct}
                      />
                    )}
                    {courseType != 'HE' && (
                      <img
                        src={radioOFF}
                        onClick={() => setCourseType('HE')}
                        className={styles.checkboxAct}
                      />
                    )}

                    <label className={styles.radiolabel}>humanitarian elective</label>
                  </div>

                  <div className={styles.radioinputDiv}>
                    {courseType != 'c' && (
                      <img
                        src={radioOFF}
                        onClick={() => setCourseType('c')}
                        className={styles.checkboxAct}
                      />
                    )}
                    {courseType == 'c' && (
                      <img
                        src={radioON}
                        onClick={() => setCourseType('c')}
                        className={styles.checkboxAct}
                      />
                    )}
                    <label className={styles.radiolabel}>core course</label>
                  </div>
                </div>
              </div>
            </div>
          )}

          <section className={styles.sectionCourseName}>
            <p className={styles.textCourseName}>Enter the course name</p>
            <div className={styles.divInput}>
              <div className={styles.divHint}>
                <p className={styles.textHint}>{hint}</p>
              </div>
              <input
                type='text'
                className={styles.input}
                placeholder='IT_Product_Development'
                value={search}
                onChange={inputHandle}
                ref={inputRef}
              />
              <img
                src={chevron}
                className={styles.chevron}
                onClick={() => setFilterClicked(!filterClicked)}
              />
            </div>
          </section>
          <div
            className={
              filterClicked && debounced.length && newData.length && search.length > 1
                ? styles.listSearch
                : styles.listSearchHidden
            }
          >
            {filterClicked &&
              search.length > 1 &&
              newData.length &&
              newData.map((item) => {
                return (
                  <div
                    key={Math.random()}
                    className={styles.items}
                    onClick={() => listHandle(item)}
                  >
                    <div
                      onClick={() => setFilterClicked(false)}
                      className={styles.itemsFlex}
                    >
                      <p className={styles.itemsText}>
                        {courseType == 'c' ? item.title.slice(4) : item.title.slice(5)}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
          {(newData.length == 0 || ((obsticle.length == 0 || !obsticle) && search.length > 0)) && (
            <div className={styles.flexDiv}>
              <img
                src={created}
                className={styles.hintImg}
              />
              <p className={styles.hintImgText}>A new page will be created</p>
            </div>
          )}
          {obsticle.length > 0 && search.length > 0 && newData.length > 0 && (
            <div className={styles.flexDiv}>
              <img
                src={oldLink}
                className={styles.hintImg}
              />
              <p className={styles.hintImgText}>
                The page with the same name already exists. If you put the button upload, page
                contents will be replaced
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FirstStep;

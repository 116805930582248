import React from 'react';
import styles from './Alert.module.scss';
import plusClose from '../../../../images/plusClose.svg';
import { useDeleteNotesMutation, useArchivedNotesMutation } from '../../../../redux/Api';
import { useArchivedStudentNotesMutation, useDeleteStudentNotesMutation } from '../../../../redux/Api';
function Alert({ setShowAlert, showAlert, indicateAlert, tokenPlusId, toggleAlert, position }) {
  const [deleteNotes] = useDeleteNotesMutation();
  const [deleteStudent] = useDeleteStudentNotesMutation();
  const [archivedNotes] = useArchivedNotesMutation();
  const [archivedStudent] = useArchivedStudentNotesMutation();
  let getAccess = tokenPlusId.getAccess;

  const handleDelete = async (id) => {
    let tokenPlusId = { getAccess, id };
    if(position === 'instructor'){
    await deleteNotes(tokenPlusId).unwrap();
    }
    if(position === 'student'){
      await deleteStudent(tokenPlusId).unwrap();
      }
    setShowAlert(false);
  };
  const handleArchived = async (item) => {
    let status = 'archived';
    let body = {
      status: status,
    };
    let id = item.id;
    let tokenPlusBody = { getAccess, body, id };
    if(position === 'instructor'){
      await archivedNotes(tokenPlusBody).unwrap();
    }
    if(position === 'student'){
      await archivedStudent(tokenPlusBody).unwrap();
    }
    setShowAlert(false);
  };
  return (
    <div
      className={showAlert ? styles.Alert : styles.AlertHidden}
      onClick={() => setShowAlert(false)}
    >
      <div
        className={showAlert ? styles.popup : styles.popupHidden}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={plusClose}
          className={styles.img}
          onClick={() => setShowAlert(false)}
        />
        <h4 className={styles.title}>
          {toggleAlert == 'delete'
            ? 'Аre you sure you want to delete this note?'
            : 'Аre you sure you want to move this note to the archive?'}
        </h4>
        <div className={styles.mainSection}>
          <button
            className={styles.button}
            onClick={
              toggleAlert == 'delete'
                ? () => handleDelete(indicateAlert)
                : () => handleArchived(indicateAlert)
            }
          >
            yes
          </button>
          <button
            className={styles.button}
            onClick={() => setShowAlert(false)}
          >
            no
          </button>
        </div>
      </div>
    </div>
  );
}

export default Alert;

import React from 'react';
import styles from '../Grades.module.css';
import GradeColor from '../InternalComponents/GradeColor';

function PostgraduateMoodle({ postgraduate, semestrArrPG, filter, handlerHint, setHint }) {
  return (
    <div>
      {semestrArrPG.includes('First semester') &&
        (filter.semestr == 'First semester' || filter.semestr == 'allSemesters') && (
          <div className={styles.oneSemester}>
            {postgraduate.length && (
              <div className={styles.postgraduateSemester}>
                <h6 className={styles.titleSemester}>
                  1 semester <span className={styles.titleSemesterDegre}>postgraduate</span>
                </h6>

                <div className={styles.trSemester}>
                  <p className={styles.courseTh}>Course</p>
                  <p className={styles.sourceTh}>Source</p>
                  <p className={styles.gradeTh}>Grade</p>
                </div>
                {postgraduate &&
                  postgraduate.map((item) => {
                    if (item.control_period == 'First semester') {
                      return (
                        <div
                          className={styles.list}
                          key={Math.random()}
                        >
                          <p
                            className={styles.course}
                            onMouseEnter={() => handlerHint(item)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {item.moodle_course_name}
                          </p>
                          <p className={styles.source}>Record book</p>
                          <div className={styles.grade}>
                            <GradeColor item={item.system_grade} />
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            )}
          </div>
        )}
      {semestrArrPG.includes('Second semester') &&
        (filter.semestr == 'Second semester' || filter.semestr == 'allSemesters') && (
          <div className={styles.oneSemester}>
            <h6 className={styles.titleSemester}>
              2 semester <span className={styles.titleSemesterDegre}>postgraduate</span>
            </h6>

            <div className={styles.trSemester}>
              <p className={styles.courseTh}>Course</p>
              <p className={styles.sourceTh}>Source</p>
              <p className={styles.gradeTh}>Grade</p>
            </div>
            {postgraduate &&
              postgraduate.map((item) => {
                if (item.control_period == 'Second semester') {
                  return (
                    <div
                      className={styles.list}
                      key={Math.random()}
                    >
                      <p
                        className={styles.course}
                        onMouseEnter={() => handlerHint(item)}
                        onMouseLeave={() => {
                          setHint('');
                        }}
                      >
                        {item.moodle_course_name}
                      </p>
                      <p className={styles.source}>Record book</p>
                      <div className={styles.grade}>
                        <GradeColor item={item.system_grade} />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}

      {semestrArrPG.includes('Third semester') &&
        (filter.semestr == 'Third semester' || filter.semestr == 'allSemesters') && (
          <div className={styles.oneSemester}>
            <h6 className={styles.titleSemester}>
              3 semester <span className={styles.titleSemesterDegre}>postgraduate</span>
            </h6>

            <div className={styles.trSemester}>
              <p className={styles.courseTh}>Course</p>
              <p className={styles.sourceTh}>Source</p>
              <p className={styles.gradeTh}>Grade</p>
            </div>
            {postgraduate &&
              postgraduate.map((item) => {
                if (item.control_period == 'Third semester') {
                  return (
                    <div
                      className={styles.list}
                      key={Math.random()}
                    >
                      <p
                        className={styles.course}
                        onMouseEnter={() => handlerHint(item)}
                        onMouseLeave={() => {
                          setHint('');
                        }}
                      >
                        {item.moodle_course_name}
                      </p>
                      <p className={styles.source}>Record book</p>
                      <div className={styles.grade}>
                        <GradeColor item={item.system_grade} />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}

      {semestrArrPG.includes('Fourth semester') &&
        (filter.semestr == 'Fourth semester' || filter.semestr == 'allSemesters') && (
          <div className={styles.oneSemester}>
            <h6 className={styles.titleSemester}>
              4 semester <span className={styles.titleSemesterDegre}>postgraduate</span>
            </h6>

            <div className={styles.trSemester}>
              <p className={styles.courseTh}>Course</p>
              <p className={styles.sourceTh}>Source</p>
              <p className={styles.gradeTh}>Grade</p>
            </div>
            {postgraduate &&
              postgraduate.map((item) => {
                if (item.control_period == 'Fourth semester') {
                  return (
                    <div
                      className={styles.list}
                      key={Math.random()}
                    >
                      <p
                        className={styles.course}
                        onMouseEnter={() => handlerHint(item)}
                        onMouseLeave={() => {
                          setHint('');
                        }}
                      >
                        {item.moodle_course_name}
                      </p>
                      <p className={styles.source}>Record book</p>
                      <div className={styles.grade}>
                        <GradeColor item={item.system_grade} />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}

      {semestrArrPG.includes('Fifth semester') &&
        (filter.semestr == 'Fifth semester' || filter.semestr == 'allSemesters') && (
          <div className={styles.oneSemester}>
            <h6 className={styles.titleSemester}>
              5 semester <span className={styles.titleSemesterDegre}>postgraduate</span>
            </h6>

            <div className={styles.trSemester}>
              <p className={styles.courseTh}>Course</p>
              <p className={styles.sourceTh}>Source</p>
              <p className={styles.gradeTh}>Grade</p>
            </div>
            {postgraduate &&
              postgraduate.map((item) => {
                if (item.control_period == 'Fifth semester') {
                  return (
                    <div
                      className={styles.list}
                      key={Math.random()}
                    >
                      <p
                        className={styles.course}
                        onMouseEnter={() => handlerHint(item)}
                        onMouseLeave={() => {
                          setHint('');
                        }}
                      >
                        {item.moodle_course_name}
                      </p>
                      <p className={styles.source}>Record book</p>
                      <div className={styles.grade}>
                        <GradeColor item={item.system_grade} />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}

      {semestrArrPG.includes('Sixth semester') &&
        (filter.semestr == 'Sixth semester' || filter.semestr == 'allSemesters') && (
          <div className={styles.oneSemester}>
            <h6 className={styles.titleSemester}>
              6 semester <span className={styles.titleSemesterDegre}>postgraduate</span>
            </h6>

            <div className={styles.trSemester}>
              <p className={styles.courseTh}>Course</p>
              <p className={styles.sourceTh}>Source</p>
              <p className={styles.gradeTh}>Grade</p>
            </div>
            {postgraduate &&
              postgraduate.map((item) => {
                if (item.control_period == 'Sixth semester') {
                  return (
                    <div
                      className={styles.list}
                      key={Math.random()}
                    >
                      <p
                        className={styles.course}
                        onMouseEnter={() => handlerHint(item)}
                        onMouseLeave={() => {
                          setHint('');
                        }}
                      >
                        {item.moodle_course_name}
                      </p>
                      <p className={styles.source}>Record book</p>
                      <div className={styles.grade}>
                        <GradeColor item={item.system_grade} />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}

      {semestrArrPG.includes('Seventh semester') &&
        (filter.semestr == 'Seventh semester' || filter.semestr == 'allSemesters') && (
          <div className={styles.oneSemester}>
            <h6 className={styles.titleSemester}>
              7 semester <span className={styles.titleSemesterDegre}>postgraduate</span>
            </h6>

            <div className={styles.trSemester}>
              <p className={styles.courseTh}>Course</p>
              <p className={styles.sourceTh}>Source</p>
              <p className={styles.gradeTh}>Grade</p>
            </div>
            {postgraduate &&
              postgraduate.map((item) => {
                if (item.control_period == 'Seventh semester') {
                  return (
                    <div
                      className={styles.list}
                      key={Math.random()}
                    >
                      <p
                        className={styles.course}
                        onMouseEnter={() => handlerHint(item)}
                        onMouseLeave={() => {
                          setHint('');
                        }}
                      >
                        {item.moodle_course_name}
                      </p>
                      <p className={styles.source}>Record book</p>
                      <div className={styles.grade}>
                        <GradeColor item={item.system_grade} />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}

      {semestrArrPG.includes('Eighth semester') &&
        (filter.semestr == 'Eighth semester' || filter.semestr == 'allSemesters') && (
          <div className={styles.oneSemester}>
            <h6 className={styles.titleSemester}>
              8 semester <span className={styles.titleSemesterDegre}>postgraduate</span>
            </h6>

            <div className={styles.trSemester}>
              <p className={styles.courseTh}>Course</p>
              <p className={styles.sourceTh}>Source</p>
              <p className={styles.gradeTh}>Grade</p>
            </div>
            {postgraduate &&
              postgraduate.map((item) => {
                if (item.control_period == 'Eighth semester') {
                  return (
                    <div
                      className={styles.list}
                      key={Math.random()}
                    >
                      <p
                        className={styles.course}
                        onMouseEnter={() => handlerHint(item)}
                        onMouseLeave={() => {
                          setHint('');
                        }}
                      >
                        {item.moodle_course_name}
                      </p>
                      <p className={styles.source}>Record book</p>
                      <div className={styles.grade}>
                        <GradeColor item={item.system_grade} />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}
    </div>
  );
}

export default PostgraduateMoodle;

import styled from '@emotion/styled';

export const ChatDescriptionBlock = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 30px;
  flex-direction: column;
`;

export const AdvancedOptionsBlock = styled.div`
  flex-grow: 0;
`;

export const TopFieldsWrapper = styled.div`
  display: flex;
  gap: 50px;
  justify-content: space-between;

  & > div {
    flex: 1 1 0px;
  }
`;

export const CheckboxBlock = styled.div`
  display: flex;
  gap: 14px;
  flex-direction: column;
  padding-top: 20px;
`;

export const ReactionsSubtext = styled.span`
  font-size: 14px;
  color: rgba(35, 35, 35, 0.69);
`;

export const ReactionsTextWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  gap: 9px;
`;

export const Heading = styled.span`
  font-weight: 500;
  margin-bottom: 20px;
  font-family: Inter;
  font-size: 18px;
`;

import { getAllCourses, getChatsByCourseID } from '@chat-bot/entities/API-service';
import { CreateChatForm, CreateChatViaTemplate } from '@chat-bot/features';
import { Global } from '@emotion/react';
import { fonts } from '@shared/assets/fonts.styles';
import { globalStyles } from '@shared/assets/global.styes';
import { Loader } from '@shared/ui';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Title, Wrapper } from './CourseChats.styles';
import { createdChatsState } from './model';
import { CreatedChatsAndChannels } from './ui';
import { AllCoursesState } from '../CourseSelecting/model';
import { FilteredCourses } from '@chat-bot/features/SearchCourses/model';

export const CourseChats = () => {
  const { state } = useLocation<{ courseName: string }>();
  const [courseName, setCourseName] = useState(state?.courseName);
  const [createdChats, setCreatedChats] = useRecoilState(createdChatsState);
  const { courseID } = useParams<{ courseID: string }>();
  const [, setCourses] = useRecoilState(AllCoursesState);
  const [, setFilteredCourses] = useRecoilState(FilteredCourses);

  useEffect(() => {
    const getCoursesIfAbsent = async () => {
      if (!state?.courseName) {
        let response = await getAllCourses();
        setCourses(response);
        setFilteredCourses(response);
        response.find((course) => {
          if (course.id === courseID) {
            setCourseName(course.full_name);
            return true;
          }
        });
      }
    };
    getCoursesIfAbsent();
  }, []);

  const reloadChatsAndChannels = async () => {
    setCreatedChats({ isLoading: true, chats: [] });
    const createdChats = (await getChatsByCourseID(courseID)).chats;
    setCreatedChats({ chats: createdChats, isLoading: false });
  }

  useEffect(() => {
    const fetchChats = async () => {
      const createdChats = (await getChatsByCourseID(courseID)).chats;
      setCreatedChats({ chats: createdChats, isLoading: false });
    };
    fetchChats();
    // Загружаем существующие чаты только при маунте
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chooseProperChatsBlock = () => {
    if (createdChats.isLoading) {
      return <Loader />;
    }

    if (createdChats.chats.length === 0) {
      return <CreateChatViaTemplate courseName={courseName} />;
    }

    return <CreatedChatsAndChannels chatsAndChannels={createdChats.chats} reloadChatsAndChannels={reloadChatsAndChannels}/>;
  };

  if (!courseName){
    return <Loader />
  }


  return (
    <>
      <Global styles={[globalStyles, fonts]} />
      <Wrapper>
        <Title>{courseName}</Title>
        {chooseProperChatsBlock()}
      </Wrapper>
      <CreateChatForm courseName={courseName} />
    </>
  );
};

import React, { useState, memo, useMemo, useCallback } from 'react';
import AnswersLineComponent from '../../common/elements/AnswersLineComponent/AnswersLineComponent';
import FeedbackAnswersStaff from '../../InstractorComponents/Courses/PersonalFitback/FeedbackAnswersStaff/FeedbackAnswersStaff';
import BlockTA from './BlockTA/BlockTA';
import styles from './TAEvaluationComponent.module.scss';
import TAname from '../../../images/instractor/TAname.svg';
import { useSelector } from 'react-redux';
import IndividualAnswers from '../IndividualAnswers/IndividualAnswers';
import { useGetFeedbacksByResponsesRelatedToStuffCommonQuery } from '../../../redux/Api';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import chevron from '../../../images/25.svg';
import FeedbackAnswersAll from '../../FeedbackComponents/SummaryFeedback/FeedbackAnswersAll';
// eslint-disable-next-line react/display-name
const TAEvaluationComponent = memo(function ({
  dataQuestion,
  getAccess,
  arrTA,
  filterTA,
  newDataAnswers,
  setNameTA,
  nameTA,
}) {
  const [moodleIdTA, setMoodleIdTA] = useState(arrTA[0].user.moodle_id);
  const [filter, setFilter] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [arrTA2, setArrTA2] = useState('');
  const loadingAnswers = useSelector((state) => state.instructor.loadingData);
  const dataAnswers = useSelector((state) => state.instructor.feedbackData);
  const [questionsId, setQuestionsId] = useState([]);
  const [questionsTextId, setQuestionsTextId] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [paramsData, setParamsData] = useState('');
  const [newData, setNewData] = useState('');
  const [filterTextHelped, setfilterTextHelped] = useState('');
  const [filterTextHelpful, setfilterTextHelpful] = useState('');
  const [filterTextOptional, setfilterTextOptional] = useState('');
  const { isMobile } = useMatchMedia();

  const selectTA = useCallback((e) => {
    if (e == 'All TA') {
      setNameTA('All TA');
    } else {
      setMoodleIdTA(e.moodle_id);
      setNameTA(e.full_name);
    }
  }, []);

  useMemo(() => {
    setArrTA2(arrTA);
  }, [arrTA]);

  useMemo(() => {
    if (dataQuestion) {
      let filter = dataQuestion.filter((item) => {
        return (
          (item.question_label.includes('lab') ||
            item.question_label.includes('TA') ||
            item.question_label.includes('Techer assistant') ||
            item.question_label.includes('Teacher assistant') ||
            item.question_label.includes('Moodle') ||
            item.question_label.includes('Activities') ||
            item.question_label.includes('teacher assistant') ||
            item.question_name.includes('name') ||
            item.question_name.includes('lab') ||
            item.question_name.includes('eacher assistant')) &&
          item.question_type == 'multichoice'
        );
      });
      setFilter(filter);

      let filterName = dataQuestion.filter((item) => {
        return item.question_name.includes('name');
      });
      let filterTextHelped = dataQuestion.filter((item) => {
        return item.question_name.includes(
          'Provide 1-3 examples of tasks and activities that helped you learn in this course',
        );
      });
      let filterTextHelpful = dataQuestion.filter((item) => {
        return item.question_name.includes(
          "Provide 1-3 examples of tasks and activities that weren't helpful",
        );
      });
      let filterTextOptional = dataQuestion.filter((item) => {
        return item.question_name.includes('Additional comments (optional)');
      });

      if (filterName.length > 0) {
        setFilterName(filterName[0].moodle_feedback_item_id);
      }

      if (filterTextHelped.length > 0) {
        setfilterTextHelped(filterTextHelped[0].moodle_feedback_item_id);
        setQuestionsTextId([
          filterTextHelped[0].moodle_feedback_item_id,
          filterTextHelpful[0].moodle_feedback_item_id,
          filterTextOptional[0].moodle_feedback_item_id,
        ]);
      }
      if (filterTextHelpful.length > 0) {
        setfilterTextHelpful(filterTextHelpful[0].moodle_feedback_item_id);
      }
      if (filterTextOptional.length > 0) {
        setfilterTextOptional(filterTextOptional[0].moodle_feedback_item_id);
      }
    }
  }, [dataQuestion]);

  useMemo(() => {
    let questionsId = filter.reduce((accum, item) => {
      return accum.includes(item.moodle_feedback_item_id)
        ? accum
        : [...accum, item.moodle_feedback_item_id];
    }, []);

    setQuestionsId(questionsId);
  }, [filter]);

  useMemo(() => {
    if (filter.length > 0 && moodleIdTA && filterName && getAccess) {
      let roles_ids = '12';
      let feedbackId = filter[0].feedback;
      let staff_id = moodleIdTA;
      let question_id = filterName;
      let tokenPlusId = {
        getAccess,
        feedbackId,
        question_id,
        roles_ids,
        staff_id,
      };
      setParamsData(tokenPlusId);
    }
  }, [moodleIdTA, filterName]);

  const { data = [] } = useGetFeedbacksByResponsesRelatedToStuffCommonQuery(paramsData, {
    skip: !paramsData,
  });

  useMemo(() => {
    if (data.length > 0 && questionsTextId) {
      let newData = data.filter((e) => {
        return questionsTextId.includes(e.item);
      });
      setNewData(newData);
    }
  }, [data]);

  return (
    <div className={styles.Feedback}>
      <div className={styles.firsLine}>
        <h5 className={styles.title}>View by TA</h5>

        <p className={styles.totalAnswers}>
          total answers: <span className={styles.count}>{dataAnswers}</span>
        </p>
        <AnswersLineComponent />
      </div>
      {!isMobile && (
        <section className={styles.blocksSections}>
          <div onClick={() => selectTA('All TA')}>
            <BlockTA
              name={'All TA'}
              nameTA={nameTA}
              newDataAnswers={newDataAnswers}
              questionsId={questionsId}
            />
          </div>

          {filter.length > 0 &&
            arrTA2.map((item) => {
              return (
                <div
                  key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                  onClick={() => selectTA(item.user)}
                >
                  <BlockTA
                    name={item.user.full_name}
                    staff_id={item.user.moodle_id}
                    nameTA={nameTA}
                    questionsId={questionsId}
                    feedbackId={filter[0].feedback}
                    question_id={filterName}
                    getAccess={getAccess}
                  />
                </div>
              );
            })}
        </section>
      )}
      {isMobile && (
        <div>
          <div
            className={styles.selectionDiv}
            onClick={() => setShowSelect(!showSelect)}
          >
            <p className={styles.selectionText}>{nameTA}</p>
            <img
              src={chevron}
              className={styles.chevron}
            />
          </div>
          {showSelect && (
            <div className={styles.selection}>
              <div onClick={() => selectTA('All TA')}>
                <p
                  className={styles.selectionTextStltct}
                  onClick={() => setShowSelect(false)}
                >
                  All TA
                </p>
              </div>
              {filter.length > 0 &&
                arrTA2.map((item) => {
                  return (
                    <div
                      key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                      onClick={() => selectTA(item.user)}
                    >
                      <p
                        className={styles.selectionTextStltct}
                        onClick={() => setShowSelect(false)}
                      >
                        {item.user.full_name}
                      </p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}

      {isMobile && (
        <section className={styles.blocksSections}>
          {nameTA == 'All TA' && (
            <div onClick={() => selectTA('All TA')}>
              <BlockTA
                name={'All TA'}
                nameTA={nameTA}
                newDataAnswers={newDataAnswers}
                questionsId={questionsId}
              />
            </div>
          )}

          {filter.length > 0 &&
            arrTA2.map((item) => {
              return (
                item.user.full_name == nameTA && (
                  <div
                    key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                    onClick={() => selectTA(item.user)}
                  >
                    <BlockTA
                      name={item.user.full_name}
                      staff_id={item.user.moodle_id}
                      nameTA={nameTA}
                      questionsId={questionsId}
                      feedbackId={filter[0].feedback}
                      question_id={filterName}
                      getAccess={getAccess}
                    />
                  </div>
                )
              );
            })}
        </section>
      )}

      {(newDataAnswers || data.length > 0) && (
        <section
          className={loadingAnswers || newDataAnswers ? styles.answers : styles.answersHidden}
        >
          {nameTA !== 'All TA' && data.length == 0 ? (
            <></>
          ) : (
            <div className={styles.line}>
              <img
                src={TAname}
                className={styles.img}
              />
              <p className={styles.name}>{nameTA}</p>
            </div>
          )}
          {nameTA !== 'All TA' && data.length > 0 && (
            <section className={styles.sectionIssue}>
              {filter &&
                filter.map((item) => {
                  return (
                    item.question_type == 'multichoice' &&
                    item.question_name !== "Course lector's name" &&
                    item.question_name !== 'Ta name' &&
                    item.question_name !== 'Teacher assistant name' &&
                    item.question_name !== '(TA evaluation) TA name' && (
                      <div
                        className={styles.particularIssue}
                        key={
                          Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
                        }
                      >
                        <p className={styles.questionName}>{item.question_name}</p>
                        <FeedbackAnswersStaff
                          item={item}
                          instructorId={moodleIdTA}
                          newData={data}
                        />
                      </div>
                    )
                  );
                })}
            </section>
          )}

          {nameTA == 'All TA' && filterTA && (
            <section className={styles.sectionIssue}>
              {filterTA &&
                filterTA.map((item) => {
                  return (
                    item.question_type == 'multichoice' &&
                    item.question_name !== "Course lector's name" &&
                    item.question_name !== 'Ta name' &&
                    item.question_name !== 'Teacher assistant name' &&
                    item.question_name !== '(TA evaluation) TA name' && (
                      <div
                        className={styles.particularIssue}
                        key={
                          Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
                        }
                      >
                        <p className={styles.questionName}>{item.question_name}</p>
                        <FeedbackAnswersAll
                          answer={item.moodle_feedback_item_id}
                          newData={newDataAnswers}
                        />
                      </div>
                    )
                  );
                })}
            </section>
          )}

          {filter.length > 0 && nameTA !== 'All TA' && data.length > 0 && (
            <IndividualAnswers
              newData={newData}
              filterTextHelped={filterTextHelped}
              filterTextHelpful={filterTextHelpful}
              filterTextOptional={filterTextOptional}
            />
          )}
        </section>
      )}
    </div>
  );
});

export default TAEvaluationComponent;

import { Category, ChatCategoryImage, Title, Wrapper } from './PendingCreationChat.styles';
import TrashCan from '@shared/assets/icons/trashcan.svg';
import ChatImg from '@shared/assets/icons/chat.svg';
import { FC } from 'react';
import { Chat } from '@chat-bot/entities/API-service';
import TA from '@shared/assets/icons/teacher-assistant.svg';
import Channel from '@shared/assets/icons/channel.svg';

interface PendingCreationChatProps {
  title: string;
  onDelete: () => void;
  courseType: Chat['type'];
}

const uiTextsForType = {
  'Students': {
    category: 'Course community',
    img: ChatImg
  }, 'TA': {
    category: 'TA’s chat',
    img: TA
  }, 'Channel': {
    category: 'Channel',
    img: Channel
  }
}

export const PendingCreationChat: FC<PendingCreationChatProps> = ({ title, onDelete, courseType }) => (
  <Wrapper>
    <ChatCategoryImage
      src={uiTextsForType[courseType].img}
      alt=''
    />
    <div>
      <Category>{uiTextsForType[courseType].category}</Category>
      <Title>{title}</Title>
    </div>

    <button onClick={onDelete}>
      <img
        src={TrashCan}
        alt=''
      />
    </button>
  </Wrapper>
);

import React, { useState, useCallback, useMemo } from 'react';
import { Button } from '../../common/Button/Button';
import { SideCard } from '../../common/SideCard/SideCard';
import { Filter } from '../../Projects/Filter/Filter';
import { ProjectsTable } from '../../Projects/ProjectsTable/ProjectsTable';
import { ProjectSideCard } from '../../Projects/ProjectSideCard/ProjectSideCard';
import { useFilter } from './useFilter';
import Preloader from '../../stubs/Preloader/Preloader';
import EmptySearch from '../../stubs/studentStubs/EmptySearch';
import { useGetStudentProjectsQuery } from '../../../redux/Api';
import { useDebounce } from '../../../hooks/useDebounce';
import filterIcon from '../../../images/filter.svg';
import styles from './Projects.module.scss';
import { getCookie } from 'react-use-cookie';
import {ReactComponent as SearchIcon} from '../../../images/24.svg';
import { TitleComponent } from '../../helpers/TitleComponent/TitleComponent';
import { useSelector } from 'react-redux';
import { useCreateProjectMutation, useCreateResponsesMutation, useGetResponsesQuery } from '../../../redux/Api';
import { ModifyProjectSideCard } from '../../Projects/ModifyProjectSideCard/ModifyProjectSideCard';
import { useProject } from '../../Projects/useProject';
import { useProjectOptions } from '../../Projects/useProjectOptions';
import { ReactComponent as Logo } from '../../../images/instractor/Edits.svg';
import { usePermission } from '../../../hooks/usePermission';

export const Projects = () => {
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  const [sideCardOpen, setSideCardOpen] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 400);
  const [sideCardContent, setSideCardContent] = useState({ type: 'filter' });
  const [filterProperty, setFilterProperty] = useState([]);
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  const handleCloseSideCard = useCallback(() => setSideCardOpen(false), []);
  const { isLoading: isFilterLoading, filter, onFilterChange, getFilterQueryData } = useFilter(getAccess);
  const [filterQuery, setFilterQuery] = useState(undefined);
  const [page, setPage] = useState(1);
  let email = useSelector((state) => state.token.email);
  let permit = usePermission(
    process.env.REACT_APP_PERMISSION_AD,
     process.env.REACT_APP_PERMISSION_PR)
let supervisor = {
  data: {
    email:email
  },
  getAccess: getAccess
}
const [createProject] = useCreateProjectMutation();
const [createResponsest] = useCreateResponsesMutation()
  const { projectData, clearProjectData, ...handlers } = useProject(supervisor);
  const { projectOptions } = useProjectOptions(supervisor.getAccess);
  TitleComponent(`DP - projects`);

  const {data: dataResponses = []} = useGetResponsesQuery(getAccess, {
    skip: permit == false,
  })

  const responrHandler =useCallback( async (id) =>{
    let tokenPlusid = {
      id: {"project_id": id},
     getAccess}
    await createResponsest(tokenPlusid).unwrap();
  },[projectData]
  )
  useMemo(()=>{
  let arr =[]
  let storage = {};
  for (let property in filterQuery) {
  if(typeof(filterQuery[property]) == "boolean"){
  storage[property] = filterQuery[property]
  arr.push(`${property}=${filterQuery[property]}`)
  }
  for (let prop in filterQuery[property]) {
  if (property == 'supervisor'  && filterQuery[property][prop].length != 0) {
    arr.push(`supervisor_full_name=${filterQuery[property][prop]}`)
  }
  if(property == 'skills' && filterQuery[property][prop].length != 0){
    arr.push(`${property}=${filterQuery[property][prop]}`)
  }
  if(property == 'subject_areas' && filterQuery[property][prop].length != 0){
    arr.push(`${property}=${filterQuery[property][prop]}`)
  }
  if(property == 'study_levels' && filterQuery[property][prop].length == 1){
    arr.push(`${property}=${filterQuery[property]}`)
  }
  }
  }
  let uniqueArr = [...new Set(arr)]
  let string = uniqueArr.join('&')
  setFilterProperty(string)

  },[filterQuery])

  let tokenPlusData = { getAccess, filterProperty };
  const projects = useGetStudentProjectsQuery(
    tokenPlusData,
  );

  const [dataFilter, setDataFilter] = useState(projects.data?.results ?? [])
  const handleOpenFilterSideCard = useCallback(() => {
    setSideCardContent({ type: 'filter' });
    setSideCardOpen(true);
  }, []);

  const handleProjectClick = useCallback(
    (projectData) => () => {
      setSideCardContent({ type: 'project', ...projectData });
      setSideCardOpen(true);
    },
    [],
  );

  const handleFilterApply = useCallback(
    (newFilter) => {
      setSideCardOpen(false);
      setFilterQuery(getFilterQueryData(newFilter));
    },
    [getFilterQueryData],
  );
 useMemo(()=>{
  if(projects.data?.results && debouncedSearch.length > 1){
    setDataFilter(projects.data?.results.filter(item=>item.title.includes(debouncedSearch)))
  }
  if(projects.data?.results && debouncedSearch.length <= 1){
    setDataFilter(projects.data?.results)
  }
  setPage(1)
 },[debouncedSearch, projects.data?.results])

 useMemo(()=>{
  setTimeout(() => {
    setIsOpenLazy(true);
  }, '1100');
},[])

  const handleProjectCreate = useCallback(
    async (formData) => {
      let dataToken = {getAccess: supervisor.getAccess, formData}
      await createProject(dataToken).then((data) => {
        if (data.error === undefined) {
          setSideCardOpen(false);
          clearProjectData();
        }
      });
    },
    [projectData],
  );
 
  return (
    <>
     {isOpenLazy 
     ?<div>
      <div className={styles.Projects}>
        <section className={styles.titleSection}>
          <h4 className={styles.title}>Academic collaboration service</h4>
        </section>
        <section className={styles.filterSection}>
          <div className={styles.filterIconMobDiv}
          onClick={handleOpenFilterSideCard}>
            <img src={filterIcon}/></div>
          <Button
            iconBefore={filterIcon}
            size='m'
            onClick={handleOpenFilterSideCard}
            className={styles.filterButton}
          >
            filter
          </Button>
          <div  className={styles.rightSection}>
           {permit && <div className={styles.createButton} onClick={() => setSideCardOpen('create')} >
          <p className={styles.createText}>create project</p>
          <Logo  className={styles.notessvg} />
          </div>}
          <div className={styles.searchArea}>

          <input  className={search ? (((debouncedSearch.length > 1 && dataFilter.length == 0) || (debouncedSearch.length < 2 && projects.data?.results.length == 0)) ? styles.ErrorInput : styles.serachActive) : styles.serachInput}
            value={search}
            placeholder='Search projects'
            onChange={(event) => setSearch(event.target.value)}/>
            
           <SearchIcon className={search ? (((debouncedSearch.length > 1 && dataFilter.length == 0) || (debouncedSearch.length < 2 && projects.data?.results.length == 0)) ? styles.ErrorSearchIcon : styles.searchIconActive) : styles.searchIcon}/>
            </div></div>
        </section>
        <section className={styles.projectsSection}>
          {projects.isLoading ? (
            <Preloader />
          ) : (
            <>
              {((debouncedSearch.length > 1 && dataFilter.length == 0) || (debouncedSearch.length < 2 && projects.data?.results.length == 0))? (
                <EmptySearch />
              ) : (
                <ProjectsTable
                onProjectClick={handleProjectClick}
                setPage={setPage}
                page={page}
                projects={debouncedSearch.length < 2 ? projects.data?.results : dataFilter}
              />
              )}
            </>
          )}
        </section>
      </div>
      {sideCardOpen !== 'create' && 
      <SideCard
        isOpen={sideCardOpen}
        onClose={handleCloseSideCard}
        header={sideCardContent.type === 'filter' ? 'Filters' : sideCardContent.title}
      >
        {sideCardContent.type === 'filter' && !isFilterLoading ? (
          <Filter
            filter={filter}
            onFilterChange={onFilterChange}
            onApply={handleFilterApply}
          />
        ) : (
          <>
            {sideCardContent.id ? <ProjectSideCard item={sideCardContent} responrHandler={responrHandler} dataResponses={dataResponses} /> : <div>loading</div>}
          </>
        )}
      </SideCard>}
      </div>
      : <div className={styles.Preloader}>
      <Preloader />
    </div>}


{sideCardOpen == 'create' && 
<SideCard
        isOpen={sideCardOpen}
        onClose={() => setSideCardOpen(false)}
        header={'Create new project for collaboration'}
      >
        <ModifyProjectSideCard
          create
          projectData={projectData}
          handlers={handlers}
          onSubmit={handleProjectCreate}
          options={projectOptions}
          supervisor={supervisor?.data.email}
        />
      </SideCard>}
    </>
  );
};

export default Projects;

import React, { useState } from 'react';
import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Route from './components/common/Route/Route';
import TokenHandler from './components/common/TokenHandler/TokenHandler';
import CookiesPopup from './components/common/CookiesPopup/CookiesPopup';
import LandingPage from './Pages/LandingPage/LandingPage';
// import LandingPage from './Pages/LandingPage/LandingPage';
import { RecoilRoot } from 'recoil';

function App() {
  const [logInEnter, setLogInEnter] = useState(false);
  const [navBurger, setNavBurger] = useState('');
  const [url, setUrl] = useState('');
  return (
    <div className='App'>
      <RecoilRoot>
        <TokenHandler
          logInEnter={logInEnter}
          setUrl={setUrl}
          setLogInEnter={setLogInEnter}
        />

        {!logInEnter && <LandingPage url={url} />}
        {logInEnter && (
          <Header
            setLogInEnter={setLogInEnter}
            navBurger={navBurger}
            setNavBurger={setNavBurger}
          />
        )}
        <Route
          logInEnter={logInEnter}
          navBurger={navBurger}
          setNavBurger={setNavBurger}
        />

        {logInEnter && <CookiesPopup />}

        {logInEnter && <Footer setLogInEnter={setLogInEnter} />}
      </RecoilRoot>
    </div>
  );
}

export default App;

function YearOfStudy({ item }) {
  switch (item) {
    case 'Первый':
      return '1';
    case 'Второй':
      return '2';
    case 'Третий':
      return '3';
    case 'Четвертый':
      return '4';
    default:
      return '-';
  }
}

export default YearOfStudy;

import styled from '@emotion/styled';

export const StyledInput = styled.input`
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  padding: 17px 20px;
  font-size: 16px;
  width: 100%;
  height: 53px;
`;

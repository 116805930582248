import React, { useState } from 'react';
import footer from './Footer.module.css';
import { NavLink } from 'react-router-dom';
import { setCookie } from 'react-use-cookie';
import { useSelector } from 'react-redux';
import LegalInformation from '../common/LegalInformation/LegalInformation';
function Footer({ setLogInEnter }) {
  const [showPopup, setShowPopup] = useState(false);
  const linkLOGOUT = `${process.env.REACT_APP_LOGOUT}`;
  const permissions = useSelector((state) => state.token.role);
  const logOut = () => {
    setCookie('access_token', '');
    setCookie('refresh_token', '');
    setLogInEnter(false);
  };
  return (
    <div className={footer.footerColor}>
      <footer className={footer.footer}>
        <div className={footer.sectionA}>
          <h1 className={footer.title}>DIGITAL PROFILE</h1>
          <p
            className={footer.text3}
            onClick={() => setShowPopup(true)}
          >
            Legal information
          </p>
          <p className={footer.Copyright}>
            Copyright © 2022 Innopolis&nbsp;University All Rights Reserved
          </p>
        </div>
        <nav className={footer.sectionB}>
        {permissions !== process.env.REACT_APP_PERMISSION_STU && <NavLink
            to='/profile_search'
            className={footer.link}
          >
            search
          </NavLink>}
          {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && permissions !== process.env.REACT_APP_PERMISSION_STU && (
            <NavLink
              to='/generate'
              className={footer.link}
            >
              eduwiki&nbsp;tools
            </NavLink>
          )}
        </nav>
        <div className={footer.sectionC}>
          <a
            href={`https://t.me/IU_DP_support`}
            className={footer.contactValueA}
            target='_blank'
            rel='noreferrer'
          >
            <div className={footer.logo}></div>
          </a>

          <p className={footer.text}>
            Any questions or suggestions? Contact us by email or telegram
          </p>
          <p className={footer.textEmail}>BMP@innopolis.ru</p>
          <div className={footer.logos}></div>
        </div>
        <div className={footer.sectionD}>
          <a
            href={linkLOGOUT}
            className={footer.logout}
            target='_blank'
            onClick={logOut}
            rel='noreferrer'
          >
            <p className={footer.logoutText}>logout</p>
          </a>
          <p
            className={footer.text2}
            onClick={() => setShowPopup(true)}
          >
            Legal information
          </p>
        </div>
      </footer>
      <LegalInformation
        setShowPopup={setShowPopup}
        showPopup={showPopup}
      />
    </div>
  );
}

export default Footer;

import React, { useMemo, useState } from 'react';
import styles from './IndividualAnswers.module.scss';
import one from '../../../images/instractor/1.svg';
import two from '../../../images/instractor/2.svg';
import three from '../../../images/instractor/3.svg';
function IndividualAnswers({ newData, filterTextHelped, filterTextHelpful, filterTextOptional }) {
  const [oneArr, setOneArr] = useState('');
  const [twoArr, setTwoArr] = useState('');
  const [threeArr, setThreeArr] = useState('');

  useMemo(() => {
    if (newData.length > 0) {
      let oneArr = newData.filter((e) => e.item === filterTextHelped);
      setOneArr(oneArr);
      let twoArr = newData.filter((e) => e.item === filterTextHelpful);
      setTwoArr(twoArr);
      let threeArr = newData.filter((e) => e.item === filterTextOptional);
      setThreeArr(threeArr);
    }
  }, [newData]);

  return (
    <div className={styles.IndividualAnswers}>
      <h5 className={styles.title}>Individual answers</h5>
      <section className={styles.textSection}>
        {oneArr.length > 0 &&
          oneArr.map((obj, index) => {
            return (
              <div
                key={obj.id}
                className={styles.divStudent}
              >
                <p className={styles.titleStudent}>Student {index + 1}</p>

                <div>
                  <div className={styles.line}>
                    <img
                      src={one}
                      className={styles.img}
                    />
                    <p className={styles.textTitle}>
                      Provide 1-3 examples of tasks and activities that helped you learn in this
                      course
                    </p>
                  </div>
                  <h4 className={styles.name}>{oneArr[index].printed_value}</h4>
                </div>

                <div>
                  <div className={styles.line}>
                    <img
                      src={two}
                      className={styles.img}
                    />
                    <p className={styles.textTitle}>
                      Provide 1-3 examples of tasks and activities that weren&apos;t helpful
                    </p>
                  </div>
                  <h4 className={styles.name}>{twoArr[index].printed_value}</h4>
                </div>

                <div>
                  <div className={styles.line}>
                    <img
                      src={three}
                      className={styles.img}
                    />
                    <p className={styles.textTitle}>Additional comments (optional)</p>
                  </div>
                  <h4 className={styles.name}>{threeArr[index].printed_value}</h4>
                </div>
              </div>
            );
          })}
      </section>
    </div>
  );
}
export default IndividualAnswers;

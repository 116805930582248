import { Button } from '@shared/ui';
import { FC } from 'react';
import {
  Title,
  FormBody,
  FieldsWrapper,
  Wrapper,
  DraftedChats,
  FormTitle,
  AddAnotherCourseButton,
  TitleWrapper,
} from './CreateChatForm.styles';

import { useCreateChatFormController } from './useCreateChatFormController';
import { PendingCreationChat } from '@chat-bot/entities/Chat';
import Plus from '@shared/assets/icons/plus.svg';
import { ChatConfigurationFormFields } from '@chat-bot/entities/ChatConfigurationFormFields';

interface CreateChatFormProps {
  courseName: string;
}

export const CreateChatForm: FC<CreateChatFormProps> = ({ courseName }) => {
  const {
    formControls,
    onSubmit,
    pendingCreationChats,
    isFormVisible,
    onSaveAllButtonClick,
    deletePendingCreationChat,
    channelTypeFieldError,
    onTypeSelect,
    onAddAnotherCourseButtonClick,
  } = useCreateChatFormController(courseName);

  return (
    <Wrapper>
      <TitleWrapper>
        <FormTitle>Manual chat creation</FormTitle>
        <Button onClick={onSaveAllButtonClick}>save all</Button>
      </TitleWrapper>
      <DraftedChats>
        {pendingCreationChats.map((chat, index) => (
          <PendingCreationChat
            title={chat.title}
            courseType={chat.type}
            onDelete={() => deletePendingCreationChat(index)}
          />
        ))}
      </DraftedChats>
      {isFormVisible && (
        <FormBody onSubmit={onSubmit}>
          <Title>{courseName}</Title>
          <FieldsWrapper>
            <ChatConfigurationFormFields
              onTypeSelect={onTypeSelect}
              channelTypeFieldError={channelTypeFieldError}
              isEditing={false}
              formControls={formControls}
            />
          </FieldsWrapper>
          <Button type='submit'>done</Button>
        </FormBody>
      )}
      <AddAnotherCourseButton onClick={onAddAnotherCourseButtonClick}>
        <img
          src={Plus}
          alt=''
        />
        Add another course
      </AddAnotherCourseButton>
    </Wrapper>
  );
};

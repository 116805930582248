import styled from '@emotion/styled';

export const StyledTextarea = styled.textarea`
  border: 1px solid rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  padding: 17px 20px;
  font-size: 16px;
  resize: none;
  height: 110px;
  width: 100%;
`;

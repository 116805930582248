import React from 'react';
import styles from './PopupDowload.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDataGenerator, getError } from '../../../redux/generatorSlice';
import { useEffect, useState } from 'react';
import successPopup from './../../../images/rpdSuccess.svg';
import wrongSyllabus from './../../../images/wrongSyllabus.svg';

function PopupDowload({ popup, sesPopup }) {
  const dispatch = useDispatch();
  const [errorPopup, setErrorPopup] = useState(false);
  const link = useSelector((state) => state.generator.error);
  useEffect(() => {
    if (link != 'the selected file is not suitable') {
      setErrorPopup(false);
    } else {
      setErrorPopup(true);
    }
  }, [link]);

  const btnOK = () => {
    sesPopup(false);
    dispatch(getDataGenerator(''));
    dispatch(getError(''));
  };

  return (
    <section
      className={popup ? styles.active : styles.hidden}
      onClick={btnOK}
    >
      <div className={styles.overlay}>
        {!errorPopup ? (
          <div
            className={popup ? styles.container : styles.hiddenContainer}
            id='successRpd'
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={styles.btnClose}
              type='button'
              onClick={btnOK}
            />
            <img
              src={successPopup}
              className={styles.img}
            />
            <h3 className={styles.title}>The syllabus downloaded successfully</h3>
            <div className={styles.block}>
              <button
                className={styles.btnOk}
                type='button'
                onClick={btnOK}
              >
                done
              </button>
            </div>
          </div>
        ) : (
          <div
            className={popup ? styles.container : styles.hiddenContainer}
            id='errorRpd'
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={styles.btnClose}
              type='button'
              onClick={btnOK}
            />
            <img
              src={wrongSyllabus}
              className={styles.img}
            />
            <h3 className={styles.title}>Something went wrong...</h3>
            <div className={styles.block}>
              <p className={styles.text}>Check the link and try again</p>
              <button
                className={styles.btnOk}
                type='button'
                onClick={btnOK}
              >
                try again
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default PopupDowload;

import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useProject = (supervisor, startProjectData) => {
  let emailState = useSelector((state) => state.token.email);
  let familyState = useSelector((state) => state.token.family);
  let nameState = useSelector((state) => state.token.name);
  const [projectData, setProjectData] = useState({
    title: startProjectData?.title ?? '',
    description: startProjectData?.description ?? '',
    supervisor:  {email: emailState,
                  full_name: `${familyState} ${nameState}`},
    skills: startProjectData?.skills ?? [],
    subject_areas: startProjectData?.subject_areas ?? [],
    study_levels: startProjectData?.study_levels ?? [],
    is_thesis_suitable: startProjectData?.is_thesis_suitable ?? false,
    expected_outcome: startProjectData?.expected_outcome ?? '',
    count_participants: startProjectData?.count_participants ?? 1,
    deadline: startProjectData?.deadline ?? '',
    active: startProjectData?.active !== undefined ? startProjectData?.active : true,
    telegram: supervisor?.contactData?.telegram ?? '',
    email: emailState ?? '',
    office: supervisor?.contactData?.office ?? '',
    open_hours: supervisor?.contactData?.open_hours ?? '',
  });

  useEffect(() => {
    setProjectData({
      title: startProjectData?.title ?? '',
      description: startProjectData?.description ?? '',
      supervisor:  {email: emailState,
        full_name: `${familyState} ${nameState}`},
      skills: startProjectData?.skills ?? [],
      subject_areas: startProjectData?.subject_areas ?? [],
      study_levels: startProjectData?.study_levels ?? [],
      is_thesis_suitable: startProjectData?.is_thesis_suitable ?? false,
      expected_outcome: startProjectData?.expected_outcome ?? '',
      count_participants: startProjectData?.count_participants ?? 1,
      deadline: startProjectData?.deadline ?? '',
      active: startProjectData?.active !== undefined ? startProjectData?.active : true,
      telegram: supervisor?.contactData?.telegram ?? '',
      email: emailState ?? '',
      office: supervisor?.contactData?.office ?? '',
      open_hours: supervisor?.contactData?.open_hours ?? '',
    });
  }, [startProjectData]);

  const handleTitleChange = useCallback((title) => {
    setProjectData((prev) => ({ ...prev, title: title.target.value }));
  }, []);

  const handleDescriptionChange = useCallback((description) => {
    setProjectData((prev) => ({ ...prev, description: description.target.value }));
  }, []);

  const handleSkillsChange = useCallback((skills) => {
    setProjectData((prev) => ({ ...prev, skills }));
  }, []);

  const handleSubjectAreasChange = useCallback((subject_areas) => {
    setProjectData((prev) => ({ ...prev, subject_areas }));
  }, []);

  const handleLevelsChange = useCallback((study_levels) => {
    setProjectData((prev) => ({ ...prev, study_levels }));
  }, []);

  const handleThesisSuitableChange = useCallback((is_thesis_suitable) => {
    setProjectData((prev) => ({ ...prev, is_thesis_suitable: is_thesis_suitable.target.checked}));
  }, []);

  const handleExpectedOutcomeChange = useCallback((expected_outcome) => {
    setProjectData((prev) => ({ ...prev, expected_outcome: expected_outcome.target.value }));
  }, []);

  const handleCountParticipantsChange = useCallback((count_participants) => {
    setProjectData((prev) => ({ ...prev, count_participants: count_participants.target.value }));
  }, []);

  const handleDurationChange = useCallback((deadline) => {
    setProjectData((prev) => ({ ...prev, deadline: deadline.target.value.length < 5 ? null : `${deadline.target.value}T00:00` }));
  }, []);

  const handleTelegramChange = useCallback((telegram) => {

    if(telegram?.target?.value === '' || telegram == null){
      setProjectData((prev) => ({...prev, telegram: " " }));
    }else{
    setProjectData((prev) => ({ ...prev, telegram: telegram?.target?.value ? telegram?.target?.value : telegram }));
    }
  }, [])
  const handleEmailChange = useCallback((email) => {

    if(email?.target?.value === '' || email == null){
      setProjectData((prev) => ({...prev, email: '' }));
    }else{
      setProjectData((prev) => ({ ...prev, email: email?.target?.value ? email?.target?.value : email}));
    }

  }, [])
  const handleOfficeChange = useCallback((office) => {

    if(office?.target?.value === '' || office == null){
      setProjectData((prev) => ({ ...prev, office: " " }));
    }else{
    setProjectData((prev) => ({ ...prev, office: office?.target?.value ? office?.target?.value : office }));
    }
  }, [])
  const handleHoursChange = useCallback((open_hours) => {

  if(open_hours?.target?.value === '' || open_hours == null){
    setProjectData((prev) => ({ ...prev, open_hours: " " }));
  }else{
    setProjectData((prev) => ({ ...prev, open_hours: open_hours?.target?.value ? open_hours?.target?.value : open_hours }));
  }

  }, [])
  const onArchive = useCallback((status) => {
    setProjectData((prev) => ({ ...prev, active: !status }));
  }, []);

  const onSupervisorName = useCallback((name) => {
    setProjectData((prev) => ({ ...prev, supervisor: {email:prev.supervisor.email, full_name: name } }));
  }, []);
  const onSupervisorEmail = useCallback((email) => {
    setProjectData((prev) => ({ ...prev, supervisor: { email: email, full_name:prev.supervisor.full_name} }));
  }, []);

  const clearProjectData = useCallback(() => {
    setProjectData({
      title: startProjectData?.title ?? '',
      description: startProjectData?.description ?? '',
      supervisor:  {email: emailState,
        full_name: `${familyState} ${nameState}`},
      skills: startProjectData?.skills ?? [],
      subject_areas: startProjectData?.subject_areas ?? [],
      study_levels: startProjectData?.study_levels ?? [],
      is_thesis_suitable: startProjectData?.is_thesis_suitable ?? false,
      expected_outcome: startProjectData?.expected_outcome ?? '',
      count_participants: startProjectData?.count_participants ?? 1,
      deadline: startProjectData?.deadline ?? '',
      active: startProjectData?.active !== undefined ? startProjectData?.active : true,
      telegram: supervisor?.contactData?.telegram ?? '',
      email: emailState ?? '',
      office: supervisor?.contactData?.office ?? '',
      open_hours: supervisor?.contactData?.open_hours ?? '',
    });
  });

  return {
    projectData,
    handleTitleChange,
    handleDescriptionChange,
    handleSkillsChange,
    handleSubjectAreasChange,
    handleLevelsChange,
    handleThesisSuitableChange,
    handleExpectedOutcomeChange,
    handleCountParticipantsChange,
    handleDurationChange,
    clearProjectData,
    onArchive,
    handleTelegramChange,
    handleEmailChange,
    handleOfficeChange,
    handleHoursChange,
    onSupervisorName,
    onSupervisorEmail,
  };
};

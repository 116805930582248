import * as React from "react";
import { SearchInput, SearchIcon, Wrapper } from "./Search.styles";
import Input from "../../assets/icons/input.svg";

interface SearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
}

export const Search: React.FC<SearchProps> = ({ placeholder, ...rest }) => {
  return (
    <Wrapper>
      <SearchIcon src={Input} />
      <SearchInput type="text" placeholder={placeholder} {...rest} />
    </Wrapper>
  );
};

import React, { useState, useEffect } from 'react';
import styles from '../Grades.module.css';
import Preloader from '../../../stubs/Preloader/Preloader';
import BachelorMoodle from './BachelorMoodle';
import MasterMoodle from './MasterMoodle';
import PostgraduateMoodle from './PostgraduateMoodle';
import EmptySearch from '../../../stubs/studentStubs/EmptySearch';
import NotSortedMoodle from './NotSortedMoodle';

function GradesMoodle({ filterData, isLoading, filter, NotSort, setHint, handlerHint }) {
  const [semestrArrBS, setSemestrSetBS] = useState('');
  const [semestrArrMS, setSemestrSetMS] = useState('');
  const [semestrArrPG, setSemestrSetPG] = useState('');

  const [bachelor, setBachelor] = useState('');
  const [master, setMaster] = useState('');
  const [postgraduate, setPostgraduate] = useState('');

  useEffect(() => {
    setBachelor(
      filterData.filter((item) => item.study_plan.split('').slice(0, 2).join('') == 'BS'),
    );
    setMaster(filterData.filter((item) => item.study_plan.split('').slice(0, 2).join('') == 'MS'));
    setPostgraduate(
      filterData.filter((item) => item.study_plan.split('').slice(0, 2).join('') == 'PG'),
    );
  }, [filterData, filter]);

  useEffect(() => {
    if (bachelor) {
      const semestrArrBS = bachelor.reduce((accum, item) => {
        return (accum.includes(item.control_period) &&
          item.study_plan.split('').slice(0, 2).join('') == 'BS') ||
          item.control_period == ''
          ? accum
          : [...accum, item.control_period];
      }, []);
      setSemestrSetBS([...semestrArrBS, 'allSemesters']);
    }
  }, [bachelor]);
  useEffect(() => {
    if (master) {
      const semestrArrMS = master.reduce((accum, item) => {
        return (accum.includes(item.control_period) &&
          item.study_plan.split('').slice(0, 2).join('') == 'MS') ||
          item.control_period == ''
          ? accum
          : [...accum, item.control_period];
      }, []);
      setSemestrSetMS([...semestrArrMS, 'allSemesters']);
    }
  }, [master]);
  useEffect(() => {
    if (postgraduate) {
      const semestrArrPG = postgraduate.reduce((accum, item) => {
        return (accum.includes(item.control_period) &&
          item.study_plan.split('').slice(0, 2).join('') == 'PG') ||
          item.control_period == ''
          ? accum
          : [...accum, item.control_period];
      }, []);
      setSemestrSetPG([...semestrArrPG, 'allSemesters']);
    }
  }, [postgraduate]);

  return (
    <section className={styles.semesters}>
      {!isLoading ? (
        <div>
          <BachelorMoodle
            bachelor={bachelor}
            semestrArrBS={semestrArrBS}
            filter={filter}
            handlerHint={handlerHint}
            setHint={setHint}
          />
          <MasterMoodle
            master={master}
            semestrArrMS={semestrArrMS}
            filter={filter}
            handlerHint={handlerHint}
            setHint={setHint}
          />
          <PostgraduateMoodle
            postgraduate={postgraduate}
            semestrArrPG={semestrArrPG}
            filter={filter}
            handlerHint={handlerHint}
            setHint={setHint}
          />
          {filter.semestr == 'allSemesters' && (
            <NotSortedMoodle
              NotSort={NotSort}
              handlerHint={handlerHint}
              setHint={setHint}
            />
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
      {((((!semestrArrBS.includes(filter.semestr) &&
        !semestrArrMS.includes(filter.semestr) &&
        !semestrArrPG.includes(filter.semestr)) ||
        !filterData.length) &&
        NotSort &&
        filter.semestr != 'allSemesters') ||
        (NotSort.length == 0 && filterData.length == 0)) && <EmptySearch />}
    </section>
  );
}

export default GradesMoodle;

import { useEffect, useState } from 'react';
import {
  useGetProjectsSkillFilterDataQuery,
  useGetProjectsStudyLevelFilterDataQuery,
  useGetProjectsSubjectAreaFilterDataQuery,
  useGetProjectsSupervisorFilterDataQuery,
} from '../../../redux/Api';

const baseFilterConfig = [
  {
    title: 'Is suitable for thesis',
    type: 'checkbox',
    items: [
      {
        label: 'yes',
        checked: false,
      },
      {
        label: 'no',
        checked: false,
      },
    ],
  },
];

export const useFilter = (getAccess) => {
  const [filter, setFilter] = useState(undefined);
  const skills = useGetProjectsSkillFilterDataQuery(getAccess);
  const studyLevels = useGetProjectsStudyLevelFilterDataQuery(getAccess);
  const subjectAreas = useGetProjectsSubjectAreaFilterDataQuery(getAccess);
  const supervisors = useGetProjectsSupervisorFilterDataQuery(getAccess);

  let tempArray = []

  const itemCheck = (item) =>{
    if(tempArray.indexOf(item.full_name.trim()) === -1){
      tempArray.push(item.full_name.trim())
      return true
    }
    return false
  }
  useEffect(() => {

    if (
      !skills.isLoading &&
      !studyLevels.isLoading &&
      !subjectAreas.isLoading &&
      !supervisors.isLoading
    ) {
      setFilter([
        ...baseFilterConfig,
        {
          title: 'Learning level',
          type: 'checkbox',
          items: studyLevels.data?.map((level) => ({
            label: level,
            checked: false,
          })),
        },
        {
          title: 'Stack',
          type: 'select',
          placeholder: 'Choose fields name',
          options: skills.data?.map((item) => ({
            label: item,
            value: item,
          })),
          items: [],
        },
        {
          title: 'Fields',
          type: 'select',
          placeholder: 'Choose stack name',
          options: subjectAreas.data?.map((item) => ({
            label: item,
            value: item,
          })),
          items: [],
        },
        {
          title: 'Supervisor',
          type: 'select',
          placeholder: 'Choose supervisors',
          options: supervisors.data?.filter(el=>itemCheck(el)).map((item) => ({
            label: item.full_name.trim(),
            value: item.full_name.trim()
          })),
          items: [],
        },
      ]);
    }
  }, [skills, studyLevels, subjectAreas, supervisors]);

  const onFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const getThesisSuitableState = (filter) => {
    const thesisFilter = filter?.find((item) => item.title === 'Is suitable for thesis')?.items;

    if (
      (thesisFilter?.[0].checked && thesisFilter?.[1].checked) ||
      (!thesisFilter?.[0].checked && !thesisFilter?.[1].checked)
    ) {
      return undefined;
    }

    return thesisFilter?.[0].checked;
  };

  const getStudyLevelState = (filter) => {
    const levelFilter = filter?.find((item) => item.title === 'Learning level')?.items;

    if (levelFilter?.[0].checked) {
      return levelFilter?.[0].label;
    }
    if (levelFilter?.[1].checked) {
      return levelFilter?.[1].label;
    }
    if (levelFilter?.[2].checked) {
      return levelFilter?.[2].label;
    }

    return undefined;
  };

  const getFilterQueryData = (newFilter) => {

    return {
      skills: newFilter?.find((item) => item.title === 'Stack').items.map((item) => item.value),
      subject_areas: newFilter
        ?.find((item) => item.title === 'Fields')
        .items.map((item) => item.value),
      supervisor: newFilter
        ?.find((item) => item.title === 'Supervisor')
        .items.map((item) => item.value),
      is_thesis_suitable: getThesisSuitableState(newFilter),
      study_levels: getStudyLevelState(newFilter),
    };
  };

  return {
    isLoading:
      skills.isLoading || studyLevels.isLoading || subjectAreas.isLoading || supervisors.isLoading,
    filter,
    onFilterChange,
    getFilterQueryData,
  };
};

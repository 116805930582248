import React from 'react';
import styles from './AnswersLineComponent.module.scss';
function AnswersLineComponent() {
  return (
    <div className={styles.hints}>
      <span className={styles.hintColorGreen2}></span>
      <p className={styles.hintText}>strongly agree</p>
      <span className={styles.hintColorGreen}></span>
      <p className={styles.hintText}>agree</p>
      <span className={styles.hintColorYellow}></span>
      <p className={styles.hintText}>neutral</p>
      <span className={styles.hintColorGreenOrange}></span>
      <p className={styles.hintText}>disagree</p>
      <span className={styles.hintColorGreenRed}></span>
      <p className={styles.hintText}>strongly disagree</p>
    </div>
  );
}

export default AnswersLineComponent;

import React, { forwardRef, memo } from 'react';
import Box, { splitBoxProps } from 'ui-box';
import style from './TextInput.module.scss';

const pseudoSelectors = {
  _focus: '&:focus',
  _disabled: '&:disabled',
  _invalid: '&[aria-invalid="true"]:not(:focus)',
  _placeholder: '&::placeholder',
  _placeholderHover: '&:hover::placeholder',
  _placeholderFocus: '&:focus::placeholder',
};

const internalStyles = {
  border: 'none',
  MozAppearance: 'none',
  outline: 'none',
  textDecoration: 'none',
  WebkitAppearance: 'none',
  WebkitFontSmoothing: 'antialiased',
};

/**
 * This is a dummy component of TextInput
 * @param { string } className - Class name passed to the component
 * @param { string } placeholder - The placeholder text when there is no value present.
 * @param { 'default' | 'primary' | 'secondary'  } appearance - The appearance of the textinput
 * @param { string | number } width - The width of the TextInput
 * @param { bool } required - Makes the input element required
 * @param { bool } disabled - Makes the input element disabled
 * @param { bool } isInvalid -Sets visual styling of _only_ the text input to be "invalid".
 * @param { bool } spellCheck - Use the native spell check functionality of the browser.
 */
export const TextInput = memo(
  forwardRef(function TextInput(props, ref) {
    const {
      appearance = 'default',
      className,
      disabled = false,
      isInvalid = false,
      placeholder,
      required,
      spellCheck = true,
      width = 'auto',
      ...restProps
    } = props;

    const { className: themedClassName, ...boxProps } = splitBoxProps(
      'Input',
      { appearance, size: restProps.size || 'm' },
      pseudoSelectors,
      internalStyles,
    );

    const height = restProps.height || boxProps.height;

    return (
      <Box
        is='input'
        className={[style.TextInput, themedClassName, className].join(' ')}
        type='text'
        width={width}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        spellCheck={spellCheck}
        aria-invalid={isInvalid}
        ref={ref}
        {...boxProps}
        {...restProps}
        height={height}
      />
    );
  }),
);

import React, {useMemo} from 'react';
import styles from './FeedbackError.module.scss';
import { NavLink } from 'react-router-dom';
import { TitleComponent } from '../../../helpers/TitleComponent/TitleComponent';
import studentidError from '../../../../images/studentidError.svg';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
function FeedbackError() {
  TitleComponent('Feedback ID Error');
  let location = useLocation();
  const role = useSelector((state) => state.token.role);
  const email = useSelector((state) => state.token.email);
  useMemo(()=>{
    if(email && email !== 'k.anikeev@innopolis.ru'){
      if (process.env.REACT_APP_PROD == 'true') {
      axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT}&text=NEW Error PROD - The feedback does not exist  - ${email}  - ${role} - ${location.pathname} `)
      }else{
      axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT}&text=NEW Error DEV - The feedback does not exist - ${email}  - ${role} - ${location.pathname} `)
      }
    }
      },[email])

  return (
    <div className={styles.mainPage}>
      <img
        className={styles.img}
        src={studentidError}
      />

      <h3 className={styles.title}>The feedback does not exist or the data is being processed</h3>
      <p className={styles.secondTitle}>Check the link and try again</p>
      <NavLink to='/profile_search'>
        {' '}
        <button className={styles.button}>
          <p className={styles.buttonText}>main page</p>
        </button>
      </NavLink>
    </div>
  );
}

export default FeedbackError;

import React, { useEffect } from 'react';
import { getCookie } from 'react-use-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getInstructorPositions } from '../../redux/generatorSlice';
import styles from './InstructorPositions.module.scss';
import PermissionPage from '../../components/stubs/commonStubs/PermitionPage/PermissionPage';
import Page404 from '../../components/stubs/commonStubs/Page404/Page404';
function InstructorPositions() {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.token.role);

  useEffect(() => {
    if (permissions.includes(process.env.REACT_APP_PERMISSION_AD) && process.env.REACT_APP_PROD !== 'true') {
      let token = getCookie('access_token');
      dispatch(getInstructorPositions(token));
    }
  }, [permissions]);
  return (
    <div className={styles.InstructorPositions}>
      {process.env.REACT_APP_PROD !== 'true' ? (
        <div>
          {permissions.includes(process.env.REACT_APP_PERMISSION_AD) ? (
            <p className={styles.text}>Page for downloading a file about instructors</p>
          ) : (
            <div className={styles.permissions}>
              <PermissionPage />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.permissions}>
          <Page404 />
        </div>
      )}
    </div>
  );
}

export default InstructorPositions;

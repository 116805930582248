import { useEffect, useState } from "react";

export function useGetLastYear (data){
    const [counter, setCounter] = useState('')
    let grades = [...data.mapped_grades, ...data.unmapped_one_c_grades];

    useEffect(()=>{
         const master = grades.filter((item) => item.study_plan.split('').slice(0, 2).join('') == 'MS')
        const postgraduate = grades.filter((item) => item.study_plan.split('').slice(0, 2).join('') == 'PG')

        if(postgraduate.length){
                 const counter = postgraduate.reduce((acum, item)=>{
                let semestr = item.control_period
                return acum.includes(semestr) ? acum : [...acum, semestr]
            },[])
            setCounter(counter.length/2)
        }else{
            const counter = master.reduce((acum, item)=>{
                let semestr = item.control_period
                return acum.includes(semestr) ? acum : [...acum, semestr]
            },[])
            setCounter(counter.length/2)
        }
      },[])

    return Math.trunc(counter)
}
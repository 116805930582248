import React, { useEffect, useState } from 'react';
import { useGetCourseTeamQuery } from '../../../../redux/Api';
import { getCookie } from 'react-use-cookie';
import styles from './Team.module.scss';
import lector from '../../../../images/instractor/Team/lector.svg';
import Mentor from '../../../../images/instractor/Team/Mentor.svg';
import primeryInst from '../../../../images/instractor/Team/primeryInst.svg';
import Supervisor from '../../../../images/instractor/Team/Supervisor.svg';
import TA from '../../../../images/instractor/Team/TA.svg';
import teacher from '../../../../images/instractor/Team/teacher.svg';
import tutorialInst from '../../../../images/instractor/Team/tutorialInst.svg';
import { useDispatch } from 'react-redux';
import { getStudentsCount, getTAname } from '../../../../redux/instructorSlice';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
function Team({ details, moodle }) {
  const [newData, setNewData] = useState([]);
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.token.role);
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  let moodleId = details.course_id;
  let tokenPlusId = { getAccess, moodleId };
  const { data = [], isLoading } = useGetCourseTeamQuery(tokenPlusId);
  useEffect(() => {
    if (data.instructors) {
      let sortArr0 = JSON.parse(JSON.stringify(data.instructors));
      let sortArr = sortArr0.sort((prev, next) => next.role_id - prev.role_id);
      setNewData(sortArr);
      dispatch(getStudentsCount(data.students_count));
      let filter = data.instructors.filter((item) => item.user.moodle_id == moodle);
      if (filter.length > 0) {
        dispatch(getTAname(filter[0].user.full_name));
      }
    }
  }, [data]);

  return (
    <div className={styles.Team}>
      <h5 className={styles.titleTeam}>instructors team</h5>
      {isLoading ? (
        <div></div>
      ) : (
        <div>
          {permissions.includes(process.env.REACT_APP_PERMISSION_AD) ? (
            <div className={styles.blocks}>
              {data &&
                newData &&
                newData.map((item) => {
                  return (
                    <NavLink    
                      to={`/instructor/${item.user.moodle_id}/information`}
                      key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                      className={styles.block}  target='_blank'
                    >
                      <div className={styles.flex}>
                        {item.role_name == 'Teacher assistant' && (
                          <img
                            src={TA}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Primary Instructor' && (
                          <img
                            src={primeryInst}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Tutorial Instructor' && (
                          <img
                            src={tutorialInst}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Teacher' && (
                          <img
                            src={teacher}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Lector' && (
                          <img
                            src={lector}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Lecturer' && (
                          <img
                            src={lector}
                            className={styles.img}
                          />
                        )}
                         {item.role_name == 'Supervisor' && (
                          <img
                            src={Supervisor}
                            className={styles.img}
                          />
                        )}
                          {item.role_name == 'Mentor' && (
                          <img
                            src={Mentor}
                            className={styles.img}
                          />
                        )}
                        <p className={styles.textRole}>{item.role_name}</p>
                      </div>
                      <p className={styles.textNeme}>{item.user.full_name}</p>
                    </NavLink>
                  );
                })}
            </div>
          ) : (
            <div className={styles.blocks}>
              {data &&
                newData &&
                newData.map((item) => {
                  return (
                    <div
                      key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                      className={styles.block}
                      to={`/instructor/${item.user.moodle_id}/information`}
                    >
                      <div className={styles.flex}>
                        {item.role_name == 'Teacher assistant' && (
                          <img
                            src={TA}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Primary Instructor' && (
                          <img
                            src={primeryInst}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Tutorial Instructor' && (
                          <img
                            src={tutorialInst}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Teacher' && (
                          <img
                            src={teacher}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Lector' && (
                          <img
                            src={lector}
                            className={styles.img}
                          />
                        )}
                        {item.role_name == 'Lecturer' && (
                          <img
                            src={lector}
                            className={styles.img}
                          />
                        )}
                         {item.role_name == 'Supervisor' && (
                          <img
                            src={Supervisor}
                            className={styles.img}
                          />
                        )}
                          {item.role_name == 'Mentor' && (
                          <img
                            src={Mentor}
                            className={styles.img}
                          />
                        )}
                        <p className={styles.textRole}>{item.role_name}</p>
                      </div>
                      <p className={styles.textNeme}>{item.user.full_name}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Team;

import styled from "@emotion/styled";

export const Wrapper = styled.button<{ hasSelected: boolean }>`
    height: 60px;
    padding: 18px 22px;
    display: flex;
    align-items: center;
    border: ${({ hasSelected }) =>
      hasSelected
        ? "1px solid rgba(56, 82, 222, 1)"
        : "1px solid rgba(35, 35, 35, 0.2)"};
    border-radius: 8px;
    position: relative;
    background-color: ${({ hasSelected }) =>
      hasSelected ? "rgba(236, 239, 255, 1)" : "white"};
    color: ${({ hasSelected }) =>
      hasSelected ? "rgba(56, 82, 222, 1)" : "black"};
    white-space: nowrap;
    white-space: break-spaces;
}   
`;

export const TitleWrapper = styled.div`
  margin: 0 30px 0 12px;
  text-align: start;
`;


export const ArrowIcon = styled.img`
  position: absolute;
  right: 8px;
`;

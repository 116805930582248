import { Chat } from '@chat-bot/entities/API-service';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: block;
  width: 490px;
  background: rgba(247, 247, 247, 0.65);
  border-radius: 8px;
  height: 207px;
  padding: 30px;
  text-decoration: none;
  position: relative;

  & > a {
    color: blue;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #232323;
  margin-top: 18px;
  margin-bottom: 13px;
`;

export const ActionButtons = styled.div`
  position: absolute;
  top: 15px;
  right: 16px;
  display: flex;
  gap: 15px;
`;

export const Status = styled.span<{ status: Chat['status'] }>`
  color: ${({ status }) => {
    switch (status) {
      case 'deleting':
        return 'red';
      case 'synced':
        return 'green';
      case 'creating':
      case 'updating':
        return 'blue';
    }
  }};
  position: absolute;
  bottom: 16px;
  right: 19px;
`;

export const ImageAndType = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
`
import React from 'react';
import styles from './popupGenerateStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getDataGenerator, getError } from '../../../redux/generatorSlice';
import { useEffect, useState } from 'react';
import successPopup from './../../../images/rpdSuccess.svg';
import wrongSyllabus from './../../../images/wrongSyllabus.svg';
import Syllabus from './../../../images/The template of Syllabus.docx';
import links from './../../../images/links.svg';

function RpdPopup({ popup, sesPopup }) {
  const dispatch = useDispatch();
  const [errorPopup, setErrorPopup] = useState(false);
  const link = useSelector((state) => state.generator.error);

  useEffect(() => {
    if (link != 'the selected file is not suitable') {
      setErrorPopup(false);
    } else {
      setErrorPopup(true);
    }
  }, [link]);

  const btnOK = () => {
    sesPopup(false);
    dispatch(getDataGenerator(''));
    dispatch(getError(''));
  };

  return (
    <section
      className={popup ? styles.active : styles.hidden}
      onClick={btnOK}
    >
      <div className={styles.overlay}>
        {!errorPopup ? (
          <div
            className={popup ? styles.container : styles.hiddenContainer}
            id='successRpd'
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={styles.btnClose}
              type='button'
              onClick={btnOK}
            />
            <img
              src={successPopup}
              className={styles.img}
            />
            <h3 className={styles.title}>Your rpd file was created!</h3>
            <div className={styles.block}>
              <p className={styles.text}>The download will begin automatically</p>
              <button
                className={styles.btnOk}
                type='button'
                onClick={btnOK}
              >
                done
              </button>
            </div>
          </div>
        ) : (
          <div
            className={popup ? styles.container : styles.hiddenContainer}
            id='errorRpd'
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={styles.btnClose}
              type='button'
              onClick={btnOK}
            />
            <img
              src={wrongSyllabus}
              className={styles.img}
            />
            <h3 className={styles.title}>Something went wrong...</h3>
            <div className={styles.block}>
              <p className={styles.text}>
                Check that the uploaded document follows the template and try again
              </p>
              <div className={styles.linkDiv}>
                <img
                  src={links}
                  className={styles.linksImg}
                  alt='links'
                />
                <a
                  href={Syllabus}
                  download=''
                  className={styles.linkText}
                >
                  <p className={styles.linkText}>Link to template</p>
                </a>
              </div>
              <button
                className={styles.btnOk}
                type='button'
                onClick={btnOK}
              >
                try again
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default RpdPopup;

import React, { useState, useEffect, useMemo } from 'react';
import styles from './RPD.module.css';
import { postRPD, getCourse, getError } from '../../../redux/generatorSlice';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import ThirdStep from './Steps/ThirdStep';
import { useGetStudyPlansQuery } from '../../../redux/Api';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from 'react-use-cookie';
import { TitleComponent } from '../../helpers/TitleComponent/TitleComponent';
import RpdPopup from '../../stubs/generateStubs/RpdPopup';
import Preloader from '../../../components/stubs/Preloader/Preloader';
import TelegramErrorMessage from '../../stubs/commonStubs/TelegramErrorMessage/TelegramErrorMessage';

function RPD({ data, isLoading }) {
  const dispatch = useDispatch();
  TitleComponent(`DP - generator/rpd`);
  const [search, setSearch] = useState('');
  const [study_plansID, setStudy_plansID] = useState([]);

  const [filterData, setFilterData] = useState('');
  const [translation, setTranslation] = useState('1');
  const [correctUrl, setCorrectUrl] = useState('');
  const [value, setValue] = useState('1');
  const [filesText, setFilesText] = useState(null);
  const [errorURL, setErrorURL] = useState(false);
  const [clearPlans, setClearPlans] = useState(false);
  const [newDataPlans, setNewDataPlans] = useState([]);
  const [docx, setDocx] = useState(false);
  const [popup, sesPopup] = useState(false);
  const link = useSelector((state) => state.generator.error);
  const permissions = useSelector((state) => state.token.role);
  const email = useSelector((state) => state.token.email);
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  let dataGenerator = {
    getAccess: getAccess,
    discipline: filterData.id,
  };
  const { data: dataStudyPlans = [], isLoading: isLoadingStudyPlans, error } =
    useGetStudyPlansQuery(dataGenerator, {
      skip: !filterData || !getCookie('access_token'),
    });

  useEffect(() => {
    setStudy_plansID('');
    setCorrectUrl('');
    setFilesText('');
    setTranslation('1');
  }, [dataStudyPlans]);
  useEffect(() => {
    if (dataStudyPlans.study_plans) {
      let newArray = dataStudyPlans.study_plans.filter((item, index) => {
        if (dataStudyPlans.study_plans[index + 1]) {
          return item.id !== dataStudyPlans.study_plans[index + 1].id;
        }
      });
      newArray = [...newArray, dataStudyPlans.study_plans.slice(-1)[0]];
      setNewDataPlans(newArray);
    }
  }, [dataStudyPlans, isLoadingStudyPlans]);

  const generateHandler = () => {
    if (value == '1') {
      const dataGenerator = {
        getAccess: `${getCookie('access_token')}`,
        course_id: dataStudyPlans.course_id,
        translate: translation,
        plans_id: `[${study_plansID}]`,
        document: correctUrl,
      };

      dispatch(postRPD(dataGenerator));
      setFilesText(null);
      dispatch(getCourse(''));
      sesPopup(true);
      dispatch(getError(''));
    } else {
      const dataGenerator = {
        getAccess: `${getCookie('access_token')}`,
        course_id: dataStudyPlans.course_id,
        translate: translation,
        plans_id: `[${study_plansID}]`,
        document: filesText[0],
      };

      dispatch(postRPD(dataGenerator));
      setFilesText(null);
      dispatch(getCourse(''));
      sesPopup(true);
      dispatch(getError(''));
    }
  };
  useMemo(()=>{
    if(error){
      const request = '/generator/api/rpd/find_study_plans/?course_id'
      TelegramErrorMessage(error, dataGenerator, request, email, permissions)
    }
    },[error])
  return (
    <div className={styles.RPD}>
      {link == '' && popup ? (
        <div className={styles.preloader}>
          <Preloader />
        </div>
      ) : (
        <div>
          <h3 className={styles.title}>RPD Generator</h3>

          <FirstStep
            data={data}
            setFilterData={setFilterData}
            search={search}
            setSearch={setSearch}
            setClearPlans={setClearPlans}
          />
          <SecondStep
            dataStudyPlans={dataStudyPlans}
            search={search}
            study_plansID={study_plansID}
            setStudy_plansID={setStudy_plansID}
            clearPlans={clearPlans}
            newDataPlans={newDataPlans}
            isLoadingStudyPlans={isLoadingStudyPlans}
            isLoading={isLoading}
          />
          <ThirdStep
            study_plansID={study_plansID}
            translation={translation}
            setTranslation={setTranslation}
            setCorrectUrl={setCorrectUrl}
            value={value}
            setValue={setValue}
            setFilesText={setFilesText}
            filesText={filesText}
            dataStudyPlans={dataStudyPlans}
            errorURL={errorURL}
            setErrorURL={setErrorURL}
            clearPlans={clearPlans}
            docx={docx}
            setDocx={setDocx}
          />

          <div className={styles.buttonDiv}>
            {((value === '2' && filesText && docx) || (value === '1' && correctUrl && !errorURL)) &&
            study_plansID &&
            dataStudyPlans ? (
              <button
                className={styles.button}
                onClick={generateHandler}
              >
                <p className={styles.buttonText}>generate RPD</p>
              </button>
            ) : (
              <button className={styles.buttonDis}>
                <p className={styles.buttonText}>generate RPD</p>
              </button>
            )}
          </div>
          {popup && (
            <RpdPopup
              popup={popup}
              sesPopup={sesPopup}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default RPD;

import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const SpinningAnimation = keyframes`
100% { 
    transform:rotate(360deg); 
}
`
export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 25px;
`

export const LoaderBody = styled.img`
    animation: ${SpinningAnimation} 4s linear infinite;
    width: 90px;
`
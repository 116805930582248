import styled from "@emotion/styled";

export const Wrapper = styled.button<{ isError: boolean }>`
    height: 53px;
    padding: 18px 5px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    position: relative;
    white-space: nowrap;
    white-space: break-spaces;
    border: 1px solid ${({ isError }) => isError ? '#FF522D' : 'rgba(35, 35, 35, 0.2)'};
}   
`;

export const TitleWrapper = styled.div`
  margin: 0 30px 0 12px;
  text-align: start;
`;


export const ArrowIcon = styled.img`
  position: absolute;
  right: 8px;
`;

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 58px;
`;

export const Header = styled.h3`
  font-family: 'Platform LC Web';
  font-weight: 400;
  font-size: 34px;
  color: #000000;
  margin-bottom: 40px;
`;

export const ListOfChats = styled.div`
  display: flex;
  gap: 30px;
`;

import React from 'react';
import styles from './studentStubs.module.css';
import noGit from '../../../images/noGit.svg';
import { motion } from 'framer-motion';

function NoGit() {
  const textVis = {
    hidden: {
      opacity: 0,
    },
    visible: () => ({
      opacity: 1,

      transition: {
        delay: 0.3,
        type: 'just',
        duration: 0.5,
      },
    }),
  };

  return (
    <motion.div
      className={styles.studentStubs}
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.2 }}
    >
      <motion.img
        variants={textVis}
        src={noGit}
        alt='img Search'
        className={styles.img}
      />
      <motion.h5
        variants={textVis}
        className={styles.title}
      >
        Github is not specified
      </motion.h5>
    </motion.div>
  );
}

export default NoGit;

import React from 'react';
import styles from '../Grades.module.css';
import GradeColor from '../InternalComponents/GradeColor';
function NotSortedMoodle({ NotSort, handlerHint, setHint }) {
  return (
    <div>
      {NotSort.length != 0 && (
        <div className={styles.oneSemester}>
          <div className={styles.bachelorSemester}>
            <h6 className={styles.titleSemester}>Not sorted</h6>
            <div className={styles.trSemester}>
              <p className={styles.courseTh}>Course</p>
              <p className={styles.sourceTh}>Source</p>
              <p className={styles.gradeTh}>Grade</p>
            </div>
            {NotSort &&
              NotSort.map((item) => {
                return (
                  <div
                    className={styles.list}
                    key={Math.random()}
                  >
                    <p
                      className={styles.course}
                      onMouseEnter={() => handlerHint(item)}
                      onMouseLeave={() => {
                        setHint('');
                      }}
                    >
                      {item.moodle_course_name}
                    </p>
                    <p className={styles.source}>Moodle</p>
                    <div className={styles.grade}>
                      <GradeColor item={item.system_grade} />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

export default NotSortedMoodle;

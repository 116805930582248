function Track({ item }) {
  if (item.length < 7) {
    return '';
  }
  let track = item.split('').slice(4, 6).join('');
  if (track === 'DA') {
    return 'DAAI';
  } else {
    return track;
  }
}

export default Track;

import styled from "@emotion/styled";

export const StyledButton = styled.button`
    background-color: #3852DE;
    border-radius: 8px;
    color: white;
    padding: 14px 44px;
    height: 54px;
    width: 160px;
    font-size: 20px;
`
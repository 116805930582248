import { createChat, fetchAllCourses, fetchChatsByCourseID, loadChatsViaCSV, loadCoursesViaCSV, getCreatedChatsInCSV, deleteChat } from "../api";
import { Course } from "./types";

export const getAllCourses = async () => {
  let response: Course[] = [];
  try {
    response = (await fetchAllCourses()).courses;
  } catch {
    alert("не удалось получить курсы");
  }

  return response;
};

export const getChatsByCourseID = fetchChatsByCourseID

export { createChat, loadChatsViaCSV, loadCoursesViaCSV, getCreatedChatsInCSV, deleteChat }
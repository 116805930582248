import styled from "@emotion/styled";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
`

export const FiltrationBlock = styled.div`
    display: flex;
    gap: 14px;
`
import styled from '@emotion/styled';

export const TemplateFeature = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

export const ChatTemplateBody = styled.div`
  background-color: rgba(247, 247, 247, 0.7);
  width: 490px;
  height: 213px;
  padding: 30px;
  border-radius: 8px;
  cursor: pointer;
`;

export const Features = styled.div`
  display: flex;
  column-gap: 16px;
  row-gap: 10px;
  flex-wrap: wrap;
`;

export const Title = styled.span`
  margin-bottom: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #232323;
  display: block;
`;

export const Category = styled.div`
  background: #3852de;
  border-radius: 11px;
  padding: 8px 13px;
  width: fit-content;
  margin-bottom: 16px;
  color: #ffffff;
`;

export const ListOfTemplates = styled.div`
  display: flex;
  gap: 30px;
`;
export const Hint = styled.span`
  font-size: 22px;
  margin-bottom: 30px;
  display: block;
`;

import React, { useMemo, useState, useEffect } from 'react';
import styles from './Information.module.scss';
import man from '../../../images/instractor/position.svg';
import portfolio from '../../../images/instractor/office.svg';
import time from '../../../images/instractor/hours.svg';
import mail from '../../../images/instractor/email.svg';
import telegram from '../../../images/instractor/telegram1.svg';
import interestsImg from '../../../images/instractor/professional interests.svg';
import languagesImg from '../../../images/instractor/languages.svg';
import google from '../../../images/instractor/google scolar.svg';
import gate from '../../../images/instractor/research gate.svg';
import scopus from '../../../images/instractor/scopus.svg';
import institute from '../../../images/instractor/institute.svg';
import fotoMale from '../../../images/maleF.svg';
import labs from '../../../images/instractor/labs.svg';
import Preloader from '../../stubs/Preloader/Preloader';
import editsInst from '../../../images/instractor/editsInst.svg';
import { SideCard } from '../../common/SideCard/SideCard';
import EditCard from './EditCard/EditCard';
import { useSelector } from 'react-redux';

function Information({ data, isLoading, tokenPlusId, email, isError, dataEdit }) {
  const [sideCardOpen, setSideCardOpen] = useState(false);
  const [interests, setInterests] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  const foto = useSelector((state) => state.students.foto);

  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  useMemo(() => {
    if (dataEdit?.professional_interests) {
      let interests = dataEdit.professional_interests.split(';');
      setInterests(interests);
    }
    if (dataEdit?.languages) {
      let languages = dataEdit.languages.split(';');
      setLanguages(languages);
    }
  }, [dataEdit]);
  setTimeout(() => {
    setIsOpenLazy(true);
  }, '300');

  return (
    <div className={styles.information}>
      {!isLoading && isOpenLazy ? (
        <div>
          <div className={styles.informationFlex}>
            <div className={styles.informationImage}>
             {foto.length != 0 ? (
              <div>
                {foto.includes('data:text/html;base64') || foto.includes('data:application/json;base64')  ? (
                  <img
                    src={fotoMale}
                    className={styles.img}
                    alt='male'
                  />
                ) : (
                  <img
                    src={foto}
                    className={styles.img}
                    alt='foto'
                  />
                )}
              </div>
            ) : (
              <div>
                {!isOpenLazy ? (
                  <div className={styles.imgPreloader}></div>
                ) : (
                  <img
                    src={fotoMale}
                    className={styles.img}
                    alt='male'
                  />
                )}
              </div>
            )}
              {/* <div className={styles.status}>
                <div className={styles.statusGreen}></div>
                <p className={styles.statusText}>open to collaboration</p>
              </div> */}
            </div>
            <div className={styles.informationContacts}>
              <h5 className={styles.titleName}>{data.full_name_latin}</h5>
              <h6 className={styles.titleNameRu}>{data.full_name}</h6>

              <div className={styles.contacts}>
                <div className={styles.contactsLine}>
                  <div className={styles.key}>
                    <img
                      className={styles.icon}
                      src={man}
                      alt='man'
                    />
                    <div className={styles.descript}>position:</div>
                  </div>
                  {data.position.length > 0 && (
                    <p className={styles.value}>{data.position[0].position_latin}</p>
                  )}
                </div>
                <div className={styles.contactsLine}>
                  <div className={styles.key}>
                    <img
                      className={styles.icon}
                      src={portfolio}
                      alt='portfolio'
                    />
                    <div className={styles.descript}>office:</div>
                  </div>
                  {!isError && dataEdit?.office && <p className={styles.value}>{dataEdit.office}</p>}
                </div>

                <div className={styles.contactsLine}>
                  <div className={styles.key}>
                    <img
                      className={styles.icon}
                      src={time}
                      alt='time'
                    />
                    <div className={styles.descript}>open hours:</div>
                  </div>
                  {!isError && dataEdit?.open_hours &&  <p className={styles.value}>{dataEdit.open_hours}</p>}
                </div>

                <div className={styles.contactsLine}>
                  <div className={styles.key}>
                    <img
                      className={styles.icon}
                      src={mail}
                      alt='mail'
                    />
                    <div className={styles.descript}>email:</div>
                  </div>
                  <p className={styles.value}>{data.email}</p>
                </div>
                <div className={styles.contactsLine}>
                  <div className={styles.key}>
                    <img
                      className={styles.icon}
                      src={telegram}
                      alt='telegram'
                    />
                    <div className={styles.descript}>telegram:</div>
                  </div>
                  {data.telegram ? (
                    <a
                      href={`https://t.me/${data.telegram.split('').splice(1).join('')}`}
                      target='_blank'
                      className={styles.valueLink}
                      rel='noreferrer'
                    >
                      {data.telegram}
                    </a>
                  ) : (
                    <p className={styles.value}></p>
                  )}
                </div>
              </div>
              {data?.email == email && (
                <div className={styles.editDiv}>
                  <img
                    src={editsInst}
                    className={styles.editImg}
                  />
                  <p
                    className={styles.editText}
                    onClick={() => setSideCardOpen(true)}
                  >
                    edit
                  </p>
                </div>
              )}
            </div>
          </div>

          <h4 className={styles.title}>Personal Information</h4>
          <div className={styles.informationFlex}>
            <div
              className={interests?.length && !isError ? styles.informationFlex : styles.informationFlexStubs}
            >
              <div className={styles.bigBlock}>
                <div className={styles.informationFlexBlock}>
                  <img
                    className={styles.interest_icon}
                    src={interestsImg}
                    alt='interests'
                  />
                  <p className={styles.text}>Professional interests</p>
                </div>
                {interests && !isError && (
                  <div className={styles.listOfInterest}>
                    {interests.map((item) => {
                      return (
                        <div
                          className={styles.interstDiv}
                          key={
                            Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
                          }
                        >
                          <p className={styles.interst}>{item}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div
              className={languages?.length && !isError ? styles.informationFlex : styles.informationFlexStubs}
            >
              <div className={styles.bigBlock}>
                <div className={styles.informationFlexBlock}>
                  <img
                    className={styles.language_iconLang}
                    src={languagesImg}
                    alt='languages'
                  />
                  <p className={styles.text}>Languages</p>
                </div>
                {languages && !isError && (
                  <div className={styles.listOfInterest}>
                    {languages.map((item) => {
                      return (
                        <div
                          key={
                            Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
                          }
                          className={
                            item.includes('A1') ||
                            item.includes('A2') ||
                            item.includes('a2') ||
                            item.includes('a1') ||
                            item.includes('B2') ||
                            item.includes('b2')
                              ? styles.interstDivYellow
                              : styles.interstDivGrin
                          }
                        >
                          <p className={styles.interst}>{item}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <h4 className={styles.title}>Researcher Links</h4>

          <div className={styles.informationFlex}>
            {dataEdit?.research_gate_link && !isError  ? (
              <a
                href={dataEdit.research_gate_link}
                target='_blank'
                className={styles.minBlockLink}
                rel='noreferrer'
              >
                <div className={styles.minBlock}>
                  <img
                    className={styles.researchIcon}
                    src={gate}
                    alt='gate'
                  />
                  <p className={styles.nameResearch}>research gate</p>
                </div>
              </a>
            ) : (
              <div className={styles.minBlockLinkStubs}>
                <div className={styles.minBlock}>
                  <img
                    className={styles.researchIcon}
                    src={gate}
                    alt='gate'
                  />
                  <p className={styles.nameResearch}>research gate</p>
                </div>
              </div>
            )}

            {dataEdit?.google_scholar_link && !isError  ? (
              <a
                href={dataEdit.google_scholar_link}
                target='_blank'
                className={styles.minBlockLink}
                rel='noreferrer'
              >
                <div className={styles.minBlock}>
                  <img
                    className={styles.researchIcon}
                    src={google}
                    alt='google'
                  />
                  <p className={styles.nameResearch}>google scolar</p>
                </div>
              </a>
            ) : (
              <div className={styles.minBlockLinkStubs}>
                <div className={styles.minBlock}>
                  <img
                    className={styles.researchIcon}
                    src={google}
                    alt='google'
                  />
                  <p className={styles.nameResearch}>google scolar</p>
                </div>
              </div>
            )}

            {dataEdit?.scopus_link && !isError  ? (
              <a
                href={dataEdit.scopus_link}
                target='_blank'
                className={styles.minBlockLink}
                rel='noreferrer'
              >
                <div className={styles.minBlock}>
                  <img
                    className={styles.researchIcon}
                    src={scopus}
                    alt='scopus'
                  />
                  <p className={styles.nameResearch}>scopus</p>
                </div>
              </a>
            ) : (
              <div className={styles.minBlockLinkStubs}>
                <div className={styles.minBlock}>
                  <img
                    className={styles.researchIcon}
                    src={scopus}
                    alt='scopus'
                  />
                  <p className={styles.nameResearch}>scopus</p>
                </div>
              </div>
            )}
          </div>

          {data.position.length > 0 && (
            <div>
              {' '}
              <h4 className={styles.title}>Positions at Innopolis University</h4>
              <div className={styles.informationFlex}>
                <div className={styles.bigBlock}>
                  <div className={styles.info}>
                    {data.position.length > 0 && (
                      <p className={styles.textLab}>{data.position[0].position_latin}</p>
                    )}
                  </div>
                  <div className={styles.info}>
                    {data.position.length > 0 && data.position[0].department_latin.split('/')[1] && (
                      <div>
                        <div className={styles.flex}>
                          <img
                            className={styles.iconGroup}
                            src={institute}
                            alt='institute'
                          />
                          <p className={styles.textPosition}>
                            {data.position[0].department_latin.split('/')[0]}
                          </p>
                        </div>
                        <div className={styles.flex}>
                          <img
                            className={styles.iconGroup2}
                            src={labs}
                            alt='institute'
                          />
                          <p className={styles.textPosition}>
                            {data.position[0].department_latin.split('/')[1]}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {data.position.length > 1 && (
                  // <div className={styles.bigBlock}>
                  //   <div className={styles.info}>
                  //     <img
                  //       className={styles.iconGroup}
                  //       src={institute}
                  //       alt="institute"
                  //     />
                  //     <p className={styles.textLab}>
                  //       {data.position[1].position_latin}
                  //     </p>
                  //   </div>
                  //   <img className={styles.iconGroup2} src={labs} alt="institute" />
                  //   <p className={styles.textPosition}>
                  //     {data.position[1].department_latin}
                  //   </p>
                  // </div>
                  <div className={styles.bigBlock}>
                    <div className={styles.info}>
                      {data.position.length > 1 && (
                        <p className={styles.textLab}>{data.position[1].position_latin}</p>
                      )}
                    </div>
                    <div className={styles.info}>
                      {data.position.length > 1 && data.position[0].department_latin.split('/')[1] && (
                        <div>
                          <div className={styles.flex}>
                            <img
                              className={styles.iconGroup}
                              src={institute}
                              alt='institute'
                            />
                            <p className={styles.textPosition}>
                              {data.position[1].department_latin.split('/')[0]}
                            </p>
                          </div>
                          <div className={styles.flex}>
                            <img
                              className={styles.iconGroup2}
                              src={labs}
                              alt='institute'
                            />
                            <p className={styles.textPosition}>
                              {data.position[1].department_latin.split('/')[1]}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
      <SideCard
        isOpen={sideCardOpen}
        onClose={() => setSideCardOpen(false)}
        header={'Editing personal data'}
      >
        <EditCard
          dataEdit={dataEdit}
          interestsDef={interests}
          languagesDef={languages}
          setSideCardOpen={setSideCardOpen}
          tokenPlusId={tokenPlusId}
        />
      </SideCard>
    </div>
  );
}

export default Information;

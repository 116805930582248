import { TemplateFeature } from '../CreateChatViaTemplate.styles';
import Picture from '@shared/assets/icons/picture.svg';
import LaughingFace from '@shared/assets/icons/laughing-face.svg';
import Turtle from '@shared/assets/icons/turtle.svg';
import ThumbsUp from '@shared/assets/icons/thumbs-up.svg';

export const TemplateFeatures = {
  SLOW_MODE: (
    <TemplateFeature>
      <img
        src={Turtle}
        alt=''
      />
      Slow mode
    </TemplateFeature>
  ),
  ALLOW_ALL_REACTIONS: (
    <TemplateFeature>
      <img
        src={LaughingFace}
        alt=''
      />
      Allow all reactions
    </TemplateFeature>
  ),
  GENERATE_IMAGE: (
    <TemplateFeature>
      <img
        src={Picture}
        alt=''
      />
      Generate image
    </TemplateFeature>
  ),
  ALLOW_NECESSARY_REACTIONS: (
    <TemplateFeature>
      <img
        src={ThumbsUp}
        alt=''
      />
      Allow necessary reactions
    </TemplateFeature>
  ),
};


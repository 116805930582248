import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './BackComponent.module.scss';
import back from '../../../../images/instractor/back.svg';
function BackComponent() {
  const location = useLocation() 
  const projectPath = location.pathname.slice(0,24)
  const history = useHistory();
  return (
    <section className={styles.backSection}  onClick={() => projectPath.includes('projects') ? history.push(projectPath) : history.goBack()}>
      <img
        src={back}
        className={styles.backImg}
        onClick={() => projectPath.includes('projects') ? history.push(projectPath) : history.goBack()}
      />
      <p
        className={styles.backText}
        onClick={() => projectPath.includes('projects') ? history.push(projectPath) : history.goBack()}
      >
        back
      </p>
    </section>
  );
}

export default BackComponent;

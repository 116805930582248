import { useEffect, useState } from "react";

export function useGrandesFilter (data) {
    const [bachelorYear, setBachelorYear] = useState('')
    const [masterYear, setMasterYear] = useState('')
    const [postgraduateYear, setPostgraduateYear] = useState('')

    let grades = [...data.mapped_grades, ...data.unmapped_one_c_grades];

    const bachelor = grades.filter((item) => item.study_plan.split('').slice(0, 2).join('') == 'BS' && item.control_period == 'First semester').sort((a, b) => b.document_date.slice(0, 4) < a.document_date.slice(0, 4)? 1 : -1)
    const master = grades.filter((item) => item.study_plan.split('').slice(0, 2).join('') == 'MS' && item.control_period == 'First semester').sort((a, b) => b.document_date.slice(0, 4) < a.document_date.slice(0, 4)? 1 : -1)
    const postgraduate = grades.filter((item) => item.study_plan.split('').slice(0, 2).join('') == 'PG' && item.control_period == 'First semester').sort((a, b) => b.document_date.slice(0, 4) < a.document_date.slice(0, 4)? 1 : -1)

    useEffect(()=>{
        if(bachelor && bachelor[0]?.document_date.slice(5, 7) < 8){
            setBachelorYear(bachelor[1]?.document_date.slice(0, 4) - 1)
        }else{
            setBachelorYear(bachelor[1]?.document_date.slice(0, 4))
        }
    },[])

    useEffect(()=>{
        if(master && master[0]?.document_date.slice(5, 7) < 8){
            setMasterYear(master[1]?.document_date.slice(0, 4) - 1)
        }else{
            setMasterYear(master[1]?.document_date.slice(0, 4))
        }
    },[])

    useEffect(()=>{
        if(postgraduate && postgraduate[0]?.document_date.slice(5, 7) < 8){
            setPostgraduateYear(postgraduate[1]?.document_date.slice(0, 4) - 1)
        }else{
            setPostgraduateYear(postgraduate[1]?.document_date.slice(0, 4))
        }
    },[])

    return [bachelorYear, masterYear, postgraduateYear]
}
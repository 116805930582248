import React, { FC } from "react";
import { Option } from "../../lib";
import { Wrapper } from "./Dropdown.styles";
interface DropdownProps {
  options: Option[];
  onOptionClick: (optionValue: string) => void;
}

export const Dropdown: FC<DropdownProps> = ({ options, onOptionClick }) => {
  return (
    <Wrapper>
      {options.map((option) => (
        <button onClick={() => onOptionClick(option.label)}>
          <span>
            {option.label}
          </span>
        </button>
      ))}
    </Wrapper>
  );
};

import React, { memo, forwardRef } from 'react';
import { Button, getIconSizeForButton } from './Button';
import { Icon } from '../Icon/Icon';

/**
 * This is a dummy component of Button only with icon
 * @param { string } className - Class name passed to the component
 * @param { 'default' | 'primary' | 'secondary'  } appearance - The appearance of the button
 * @param { 's' | 'm' | 'l' } size - The size of the button
 * @param { bool } isActive - Forcefully set the active state of a button
 * @param { bool } disabled - When true, the button is disabled
 * @param { svgPaths16 } icon - icon data
 */
export const IconButton = memo(
  forwardRef(function IconButton(props, ref) {
    const { icon, ...restProps } = props;
    const relativeIconSize = getIconSizeForButton(props.size);

    return (
      <Button
        ref={ref}
        paddingLeft={0}
        paddingRight={0}
        height={relativeIconSize}
        width={relativeIconSize}
        minWidth={relativeIconSize}
        flex='none'
        {...restProps}
      >
        <Icon
          data={icon}
          size={relativeIconSize}
        />
      </Button>
    );
  }),
);

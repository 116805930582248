import React, {useMemo} from 'react';
import styles from './ErrorGlobal.module.css';
import { TitleComponent } from '../../../helpers/TitleComponent/TitleComponent';
import permission from '../../../../images/permition.svg';
import axios from 'axios';
import { useSelector } from 'react-redux';
function ErrorGlobal() {
  TitleComponent('ErrorGlobal');
  const role = useSelector((state) => state.token.role);
  const email = useSelector((state) => state.token.email);

  useMemo(()=>{
    if(email && email !== 'k.anikeev@innopolis.ru'){
      if (process.env.REACT_APP_PROD == 'true') {
      axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT}&text=NEW Error PROD - check the array of students/professors -            The service is temporarily unavailable!!!   - ${email}  - ${role}`)
      }else{
      axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT}&text=NEW Error DEV - check the array of students/professors -            The service is temporarily unavailable!!!   - ${email}  - ${role}`)
      }
    }
      },[email])

  return (
    <div className={styles.mainPage}>
      <img
        className={styles.img}
        src={permission}
      />

      <h3 className={styles.title}>The service is temporarily unavailable</h3>
      <p className={styles.secondTitle}>
        We are fixing a problem or deploying a new version. Please try again later
      </p>
    </div>
  );
}

export default ErrorGlobal;

import React, { useState, useEffect } from 'react';
import styles from './Github.module.css';
import Preloader from '../../stubs/Preloader/Preloader';
import searchImg from '../../../images/24.svg';
import GithubData from './GithubData';
import chevron from '../../../images/25.svg';
import EmptyGit from '../../stubs/studentStubs/EmptyGit';
import NoGit from '../../stubs/studentStubs/NoGit';
function Github({ data, isLoading }) {
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLanguages, setFilterLanguages] = useState('allLanguages');
  const [filterYears, setFilterYears] = useState('allYears');
  const [filterData, setFilterData] = useState(data.github_info);
  const [languages, setLanguages] = useState('');
  const [yearRep, setYearRep] = useState('');
  const [showYear, setShowYear] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [wordLanguages, setWordLanguages] = useState('All languages');
  const [wordYear, setWordYear] = useState('All years');

  useEffect(() => {
    if (data.github_info) {
      setFilterData(
        data.github_info.filter(
          (item) =>
            (filterLanguages == 'allLanguages'
              ? item
              : item.repository_language == filterLanguages) &&
            (filterSearch == '' ? item : item.repository_full_name.includes(filterSearch)) &&
            (filterYears == 'allYears'
              ? item
              : item.repository_updated_at.split('').slice(0, 4).join('') == filterYears),
        ),
      );
    }
  }, [filterLanguages, filterSearch, filterYears]);
  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  useEffect(() => {
    const languagesRep = data.github_info.reduce((accum, item) => {
      return accum.includes(item.repository_updated_at.split('').slice(0, 4).join('')) ||
        item.repository_updated_at == ''
        ? accum
        : [...accum, item.repository_updated_at.split('').slice(0, 4).join('')];
    }, []);

    const yearRep = data.github_info.reduce((accum, item) => {
      return accum.includes(item.repository_language) || item.repository_language == ''
        ? accum
        : [...accum, item.repository_language];
    }, []);
    let sortArr = languagesRep.sort((a, b) => b - a);
    setYearRep(sortArr);
    setLanguages(yearRep);
  }, [data.github_info]);

  const handlerLanguages = (item) => {
    setFilterLanguages(item);
    if (item == 'allLanguages') {
      setWordLanguages('All languages');
    } else {
      setWordLanguages(item);
    }
  };

  const handlerYears = (item) => {
    setFilterYears(item);
    if (item == 'allYears') {
      setWordYear('All years');
    } else {
      setWordYear(item);
    }
  };

  let arr = JSON.parse(JSON.stringify(filterData));
  let result = arr.sort(
    (a, b) => b.repository_updated_at.slice(0, 4) - a.repository_updated_at.slice(0, 4),
  );
  return (
    <div className={styles.github}>
      <section className={styles.titleSection}>
        <h4 className={styles.title}>Github</h4>
      </section>
      {!isLoading ? (
        <div>
          {data.contacts.length == 0 ? (
            <EmptyGit />
          ) : (
            <div>
              {data.contacts[0].github_username != null ? (
                <div>
                  {data.github_info.length ? (
                    <div>
                      <section className={styles.searchSection}>
                        <div className={styles.inputArea}>
                          <div className={styles.borderFilter}>
                            <div className={styles.borderFilterDiv}>
                              <img
                                src={searchImg}
                                alt='Search'
                                className={styles.img}
                              />
                              <input
                                type='text'
                                className={styles.input}
                                onChange={(e) => setFilterSearch(e.target.value)}
                                value={filterSearch}
                                placeholder='Find a repository...'
                              />
                            </div>
                            <div
                              className={styles.filterLanguagesDiv}
                              onClick={() => setShowLanguages(!showLanguages)}
                            >
                              <p className={styles.filterLanguages}>{wordLanguages}</p>
                              <img
                                src={chevron}
                                alt='chevron'
                                className={styles.imgSelect}
                              />

                              {showLanguages && languages && (
                                <div className={styles.optionlanguagesDiv}>
                                  <div
                                    className={styles.optionRow}
                                    onClick={() => handlerLanguages('allLanguages')}
                                  >
                                    <p className={styles.optionLanguagesAll}>All languages</p>
                                  </div>
                                  {languages &&
                                    languages.map((item) => {
                                      return (
                                        <div
                                          key={Math.random()}
                                          className={styles.optionRow}
                                          onClick={() => handlerLanguages(item)}
                                        >
                                          <img
                                            className={styles.iconMiniLang}
                                            src={process.env.PUBLIC_URL + `/languages/${item}.svg`}
                                          />
                                          <p className={styles.optionLanguages}>{item}</p>
                                        </div>
                                      );
                                    })}
                                  {showLanguages && (
                                    <div
                                      className={styles.optionLanguagessOver}
                                      onClick={() => setShowLanguages(!showLanguages)}
                                    ></div>
                                  )}
                                </div>
                              )}
                            </div>

                            <div
                              className={styles.filterYearsDiv}
                              onClick={() => setShowYear(!showYear)}
                            >
                              <p className={styles.filterYears}>{wordYear}</p>
                              <img
                                src={chevron}
                                alt='chevron'
                                className={styles.imgSelect}
                              />

                              {showYear && (
                                <div className={styles.optionyearDiv}>
                                  <div
                                    className={styles.optionRow}
                                    onClick={() => handlerYears('allYears')}
                                  >
                                    <p className={styles.optionYears}>All years</p>
                                  </div>

                                  {yearRep &&
                                    yearRep.map((item) => {
                                      return (
                                        <div
                                          key={Math.random()}
                                          className={styles.optionRow}
                                          onClick={() => handlerYears(item)}
                                        >
                                          <p className={styles.optionYears}>{item}</p>
                                        </div>
                                      );
                                    })}
                                  {showYear && (
                                    <div
                                      className={styles.optionYearsOver}
                                      onClick={() => setShowYear(!showYear)}
                                    ></div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </section>
                      <GithubData
                        data={data}
                        filterData={result}
                        isLoading={isLoading}
                      />
                    </div>
                  ) : (
                    <EmptyGit />
                  )}
                </div>
              ) : (
                <NoGit />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
    </div>
  );
}

export default Github;

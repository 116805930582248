import styled from "@emotion/styled";

export const Wrapper = styled.label`
    position: relative;
    padding-left: 31px;
`

export const Mark = styled.div`
    border: 2px solid #3852DE;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 11px;
    position: absolute;
    left: 0;
`

export const HiddenCheckbox = styled.input`
    &:checked + div{
        background-color: #3852DE;
    }
`
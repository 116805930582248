import React, { forwardRef, memo, useMemo } from 'react';
import style from './ProjectSideCard.module.scss';
import Box from 'ui-box';
import { Text } from '../../common/Text/Text';
import { Badge } from '../../common/Badge/Badge';
import { Icon } from '../../common/Icon/Icon';
import {ReactComponent as File} from '../../../images/collaboration/pdf.svg';
import userIcon from '../../../images/filledUser.svg';
import { fileRequest } from '../DownloadFile/DownloadFile';
import { useState } from 'react';

/**
 * This is a dummy component for display project in side card
 *
 * @param { string } className - Class name passed to the component
 * @param { project } item - entity of the project to display
 */
export const ProjectSideCard = memo(
  forwardRef(function ProjectTableCard({ className, item, responrHandler, dataResponses}, ref) {
    const [responses, setResponses] = useState(false)
    useMemo(()=>{
      setResponses(dataResponses.find(e=>e.project === item.id))
    },[dataResponses, item])
    const basicParams = [
      {
        title: 'Status',
        data: item.active ? 'active' : 'archive',
      },
      {
        title: 'Is sutable for thesis',
        data: item.is_thesis_suitable ? 'yes' : 'no',
      },
      {
        title: 'Supervisor',
        data: item.supervisor.full_name,
      },
      {
        title: 'Cosupervisor',
        data: item.co_supervisor || `not defined`,
      },
      item.deadline && {
        title: 'Project deadline',
        data: item.deadline?.slice(0,10),
      },
      {
        title: 'Learning level',
        data: item.study_levels.length ?  item.study_levels?.join(', ') : 'any',
      },
    ];

    const tagsParams = [
      {
        title: 'Fields',
        data: item.subject_areas,
      },
      {
        title: 'Stack',
        data: item.skills,
      },
    ];

    
    const contactsParams = [
      {
        title: 'email',
        data: item.email,
      },
      {
        title: 'telegram',
        data: item.telegram,
      },
      {
        title: 'office',
        data: item.office,
      },
      {
        title: 'open hours',
        data: item.open_hours,
      },
    ];


    return (
      <Box
        ref={ref}
        className={[className, style.ProjectSideCard].join(' ')}
      >
       {item.description && <Text
          fontWeight={500}
          fontSize={18}
          className={style.title}
        >
          Description
        </Text>}
        <Text className={style.Description}>{item.description}</Text>


        {item.expected_outcome && <Text
          fontWeight={500}
          fontSize={18}
          className={style.title}
        >
          Expected outcome
        </Text>}
        <Text className={style.Description}>{item.expected_outcome}</Text>

        <Text
          fontWeight={500}
          fontSize={18}
          className={style.title}
        >
          Parametrs
        </Text>
        <Box
          display='flex'
          flexDirection='column'
          gap={18}
        >
          {basicParams.filter(Boolean).map((param) => (
           param.data.length && <Box
              key={param?.data}
              display='flex'
              gap={8}
              className={style.paraments}
            >
              <Text className={style.paramName}>{param?.title}</Text>
           {param?.title === 'Status' && <span className={item.active ? style.active : style.archive}></span>}
              <Text       
              className={style.paramentsData}> {param?.data}</Text>
            </Box>
          ))}
          <Box
            display='flex'
            alignItems='center'
            className={style.sizeDiv}
          >
            <Text
              className={style.paramName}
              marginRight={8}
            >
              Project team size
            </Text>
            <div   className={style.size}>
            <Icon
              data={userIcon}
              marginRight={6}
              size='m'
              className={style.iconBody}
            />
            <Text textAlign='center' className={style.sizeText}>{`${
              item.count_participants !==null ? item.count_participants : `not defined`
            }`}</Text>
            </div>
          </Box>
         <div 
         className={style.params}
         >
          {tagsParams.map((param) => (
            param.data.length ? <Box
              key={param.title}
              display='flex'
              gap={8}
              className={style.paramsField}
            >
             
              <Text className={style.paramName} >{param.title}</Text>
              <div className={style.parametr}>
              {param.data.map((field) => (
                <Badge
                  appearance='secondary'
                  key={field}
                  
                >
                  {field}
                </Badge>
                
              ))}
              </div>
            </Box>
            :<></>
          ))}
          </div>
        </Box>
        <Text
          fontWeight={500}
          fontSize={18}
          className={style.title}
        >
          Contacts
        </Text>
        <Box
          display='flex'
          flexDirection='column'
          gap={12}
        >
             {contactsParams.map((param) => {
              if(param.data){
            return (
              <>
            <Box
              key={param?.data}
              display='flex'
              gap={8}
              className={style.paraments}
            >
              <Text className={style.paramName}>{param?.title}</Text>
              <Text
                is={(param?.title === 'telegram' || param?.title === 'email') && 'a'}
                href={
                  param?.title === 'telegram'
                    ? `https://t.me/${param?.data}`
                    :  param?.title === 'email'
                    ? `mailto:${param?.data}`
                    : ''
                }
                target='_blank'
                className={(param?.title === 'telegram' || param?.title === 'email') ? style.link : style.text}
              >
                {param?.data}
              </Text>
            </Box>
            </>
          )}
        }
          )}
        </Box>
        {item?.attachment !== null && 
        <>
        <Text
          fontWeight={500}
          fontSize={20}
          className={style.title}
        >
          Attachment
        </Text>
        <div onClick={()=>fileRequest(item)} className={style.fileDiv}>
        <File className={style.icon}/>
        <p className={style.fileText}>Attached file</p>
        </div>
        </>}
       {!responses 
       ? <button className={style.button} onClick={()=>responrHandler(item.id)}>respond</button>
       : <button className={style.buttonDis}>dispatched</button>}
      </Box>
    );
  }),
);

import React, { useEffect, useState } from 'react';
import styles from '../RPD.module.css';
import arrow from '../../../../images/32.svg';
import file from '../../../../images/file.svg';
import checkboxON from '../../../../images/Component 5.svg';
import checkboxOFF from '../../../../images/Component 6.svg';
import fileDropError from '../../../../images/fileDropError.svg';
import crossNew from '../../../../images/crossNew.svg';
import radioON from '../../../../images/Frame 6634.svg';
import radioOFF from '../../../../images/offb.svg';
import links from '../../../../images/links.svg';
import fileDrop from '../../../../images/fileDrop.svg';
import Syllabus from '../../../../images/The template of Syllabus.docx';
import { useMatchMedia } from '../../../../hooks/useMatchMedia';
function ThirdStep({
  study_plansID,
  translation,
  setTranslation,
  setCorrectUrl,
  value,
  setValue,
  filesText,
  setFilesText,
  dataStudyPlans,
  errorURL,
  setErrorURL,
  clearPlans,
  docx,
  setDocx,
}) {
  const { isDesktop } = useMatchMedia();
  const [inputUrl, setInputUrl] = useState('');
  const [drag, setDrag] = useState(false);
  const [drop, setDrop] = useState(false);

  useEffect(() => {
    setCorrectUrl(inputUrl.slice(47).trim());
    if (
      inputUrl.slice(0, 47) != 'https://eduwiki.innopolis.university/index.php/' &&
      inputUrl != ''
    ) {
      setErrorURL(true);
    } else {
      setErrorURL(false);
    }
  }, [inputUrl]);

  useEffect(() => {
    setDrag(false);
    setDrop(false);
    setFilesText(null);
    setDocx(false);
    setInputUrl('');
  }, [dataStudyPlans]);

  const cancelFile = () => {
    setDrag(false);
    setDrop(false);
    setFilesText(null);
  };

  const dragStartHandler = (e) => {
    e.preventDefault();
    setDrag(true);
  };
  const dragLeavetHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const onDropHandler = (e) => {
    e.preventDefault();

    let files = [...e.dataTransfer.files];
    let newFiles = files[0].name.split('').slice(-4).join('');
    if (newFiles.includes('docx')) {
      setFilesText(files);
      setDrag(false);
      setDrop(true);
      setDocx(true);
    } else {
      setDocx(false);
      setDrag(false);
      setDrop(true);
      setFilesText(files);
    }
  };

  const selectFile = (e) => {
    let files = e.target.files;
    let newFiles = files[0].name.split('').slice(-4).join('');
    if (newFiles.includes('docx')) {
      setFilesText(files);
      setDrag(false);
      setDrop(true);
      setDocx(true);
    } else {
      setDocx(false);
      setDrag(false);
      setDrop(true);
      setFilesText(files);
    }
  };

  const translationCheckbox = () => {
    let translate = '';
    if (translation != '0') {
      translate = '0';
    } else {
      translate = '1';
    }
    setTranslation(translate);
  };

  return (
    <div>
      <div className={styles.steps}>
        <div className={styles.stepsHeader}>
          <div className={styles.stepsNumber}>
            <p className={styles.stepsNumberText}>3</p>
          </div>
          <h5 className={styles.headerTitle}>Choose the source</h5>
          <img
            src={arrow}
            className={styles.arrow}
            alt='arrow'
          />
        </div>
        {study_plansID.length != 0 && clearPlans && (
          <div className={styles.stepsBody}>
            <div className={styles.linkDiv}>
              <img
                src={links}
                className={styles.linksImg}
                alt='links'
              />
              <a
                href={Syllabus}
                download=''
                className={styles.linkText}
              >
                <p className={styles.linkText}>Link to template</p>
              </a>
            </div>
            <div className={styles.radioDiv}>
              <div className={styles.radioinputDiv}>
                {value == '1' && (
                  <img
                    src={radioON}
                    onClick={() => setValue('1')}
                    className={styles.checkboxAct}
                  />
                )}
                {value == '2' && (
                  <img
                    src={radioOFF}
                    onClick={() => setValue('1')}
                    className={styles.checkboxAct}
                  />
                )}

                <label
                  className={styles.radiolabel}
                  onClick={() => setValue('1')}
                >
                  from Eduwiki (URL)
                </label>
              </div>
              <div className={styles.radioinputDiv}>
                {value == '1' && (
                  <img
                    src={radioOFF}
                    onClick={() => setValue('2')}
                    className={styles.checkboxAct}
                  />
                )}
                {value == '2' && (
                  <img
                    src={radioON}
                    onClick={() => setValue('2')}
                    className={styles.checkboxAct}
                  />
                )}
                <label
                  className={styles.radiolabel}
                  onClick={() => setValue('2')}
                >
                  from a file
                </label>
              </div>
            </div>

            {value == '1' && (
              <div className={styles.inputUrlDiv}>
                <input
                  type='text'
                  className={errorURL ? styles.inputUrlRed : styles.inputUrl}
                  value={inputUrl}
                  onChange={(e) => setInputUrl(e.target.value)}
                  placeholder='Enter the URL from the syllabus page'
                />
                {errorURL && inputUrl && (
                  <span className={styles.inputUrlError}>
                    The link is not valid. Please check and try again
                  </span>
                )}
              </div>
            )}

            {value == '2' && (
              <div>
                {isDesktop && (
                  <div className={styles.dropFild}>
                    {drop ? (
                      <div className={styles.dropArea}>
                        {docx ? (
                          <div className={styles.dropDocx}>
                            <img
                              src={fileDrop}
                              className={styles.fileImgDrop}
                            />
                            <p className={styles.fileTextFormat}>{filesText[0].name}</p>
                            <span
                              className={styles.spanDeleteFile}
                              onClick={cancelFile}
                            >
                              <img
                                src={crossNew}
                                className={styles.cross}
                              />
                              delete a file
                            </span>
                          </div>
                        ) : (
                          <div className={styles.dropDocxNo}>
                            <img
                              src={fileDropError}
                              className={styles.fileImgDrop}
                            />
                            <p className={styles.fileTextFormat}>{filesText[0].name}</p>
                            <p className={styles.fileTextError}>
                              invalid file format use&nbsp;the&nbsp;docx&nbsp;extension
                            </p>
                            <span
                              className={styles.spanDeleteFile}
                              onClick={cancelFile}
                            >
                              <img
                                src={crossNew}
                                className={styles.cross}
                              />
                              delete a file
                            </span>
                          </div>
                        )}
                      </div>
                    ) : drag ? (
                      <div
                        className={styles.dropArea2}
                        onDragStart={(e) => dragStartHandler(e)}
                        onDragLeave={(e) => dragLeavetHandler(e)}
                        onDragOver={(e) => dragStartHandler(e)}
                        onDrop={(e) => onDropHandler(e)}
                      ></div>
                    ) : (
                      <div
                        className={styles.dropArea}
                        onDragStart={(e) => dragStartHandler(e)}
                        onDragLeave={(e) => dragLeavetHandler(e)}
                        onDragOver={(e) => dragStartHandler(e)}
                      ></div>
                    )}{' '}
                    {!drop && (
                      <div>
                        <img
                          src={file}
                          className={styles.fileImg}
                        />
                        {drag ? (
                          <p className={styles.fileTextDrop2}>Select the file and drag it here</p>
                        ) : (
                          <p className={styles.fileTextDrop}>
                            Select the file and drag it&nbsp;here (only .docx extension)
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {!isDesktop && (
                  <div className={styles.fileInputDiv}>
                    {!filesText && (
                      <label className={styles.customfileupload}>
                        <input
                          type='file'
                          onChange={selectFile}
                        />
                        <button className={styles.customfilButton}>
                          <p className={styles.customfilText}>select a file</p>
                        </button>
                      </label>
                    )}
                    {filesText && (
                      <div>
                        {docx ? (
                          <div className={styles.dropDocx}>
                            <img
                              src={fileDrop}
                              className={styles.fileImgDrop}
                            />
                            <p className={styles.fileTextFormat}>{filesText[0].name}</p>
                            <span
                              className={styles.spanDeleteFile}
                              onClick={cancelFile}
                            >
                              <img
                                src={crossNew}
                                className={styles.cross}
                              />
                              delete a file
                            </span>
                          </div>
                        ) : (
                          <div className={styles.dropDocxNo}>
                            <img
                              src={fileDropError}
                              className={styles.fileImgDrop}
                            />
                            <p className={styles.fileTextFormat}>{filesText[0].name}</p>
                            <p className={styles.fileTextError}>
                              invalid file format use&nbsp;the&nbsp;docx&nbsp;extension
                            </p>
                            <span
                              className={styles.spanDeleteFile}
                              onClick={cancelFile}
                            >
                              <img
                                src={crossNew}
                                className={styles.cross}
                              />
                              delete a file
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <div className={styles.checkboxTranslate}>
              {translation === '0' && (
                <img
                  src={checkboxOFF}
                  onClick={translationCheckbox}
                  type='checkbox'
                  className={styles.checkbox}
                />
              )}
              {translation === '1' && (
                <img
                  src={checkboxON}
                  onClick={translationCheckbox}
                  type='checkbox'
                  className={styles.checkbox}
                />
              )}

              <label className={styles.labelTranslate}>translate the document</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ThirdStep;

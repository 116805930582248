import React, { useState, useEffect } from 'react';
import styles from './Interests.module.css';
import Preloader from '../../stubs/Preloader/Preloader';
import searchImg from '../../../images/24.svg';
import InterestsData from './InterestsData';
import starts0 from '../../../images/img44.svg';
import starts1 from '../../../images/img45.svg';
import starts2 from '../../../images/Frame 414.svg';
import starts3 from '../../../images/Frame 416.svg';
import chevron from '../../../images/25.svg';
import EmptyInteres from '../../stubs/studentStubs/EmptyInteres';
function Interests({ data, isLoading }) {
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLevel, setFilterLevel] = useState('allLevel');
  const [filterDescription, setFilterDescription] = useState('All');
  const [filterData, setFilterData] = useState(data.hobbies);
  const [showLevel, setSowLevel] = useState(false);
  const [showDiscription, setSowDiscription] = useState(false);
  const [wordLevel, setWordLevel] = useState('All level');
  const [wordDiscription, setWordDiscription] = useState('All variants');

  useEffect(() => {
    setFilterData(
      data.hobbies.filter(
        (item) =>
          (filterLevel == 'allLevel' ? item : item.degree_of_passion == filterLevel) &&
          (filterSearch == '' ? item : item.name.includes(filterSearch)) &&
          (filterDescription == 'All'
            ? item
            : filterDescription == 'withDescription'
            ? item.description
            : !item.description),
      ),
    );
  }, [filterLevel, filterDescription, filterSearch]);
  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  const handlerLevel = (item) => {
    setFilterLevel(item);
    if (item == 'allLevel') {
      setWordLevel('All level');
    } else if (item == 'Опытный участник') {
      setWordLevel('Advance');
    } else if (item == 'Имеется небольшой опыт') {
      setWordLevel('Middle');
    } else if (item == 'Нет опыта, но хочу получить') {
      setWordLevel('Beginner');
    }
  };
  const handlerDiscription = (item) => {
    setFilterDescription(item);
    if (item == 'All') {
      setWordDiscription('All variants');
    } else if (item == 'withDescription') {
      setWordDiscription('With description');
    } else if (item == 'withoutDescription') {
      setWordDiscription('Without description');
    }
  };
  return (
    <div className={styles.interests}>
      <section className={styles.titleSection}>
        <h4 className={styles.title}>Interests</h4>
      </section>
      {!isLoading ? (
        <div>
          {filterData.length == '1' && !filterData[0].name ? (
            <EmptyInteres />
          ) : (
            <div>
              <section className={styles.searchSection}>
                <div className={styles.inputArea}>
                  <div className={styles.borderFilter}>
                    <div className={styles.borderFilterDiv}>
                      <img
                        src={searchImg}
                        alt='Search'
                        className={styles.img}
                      />
                      <input
                        type='text'
                        className={styles.input}
                        onChange={(e) => setFilterSearch(e.target.value)}
                        value={filterSearch}
                        placeholder='Search by hobbies'
                      />
                    </div>
                    <div
                      className={styles.filterLevelDiv}
                      onClick={() => setSowLevel(!showLevel)}
                    >
                      <p className={styles.filterLevel}>{wordLevel}</p>
                      <img
                        src={chevron}
                        alt='chevron'
                        className={styles.imgSelect}
                      />

                      {showLevel && (
                        <div className={styles.optionLevelDiv}>
                          <div
                            className={styles.optionRow}
                            onClick={() => handlerLevel('allLevel')}
                          >
                            <img
                              src={starts0}
                              alt='starts0'
                              className={styles.imgOption}
                            />
                            <p className={styles.optionLevel}>All level</p>
                          </div>
                          <div
                            className={styles.optionRow}
                            onClick={() => handlerLevel('Опытный участник')}
                          >
                            <img
                              src={starts3}
                              alt='starts0'
                              className={styles.imgOption}
                            />
                            <p className={styles.optionLevel}>Advance</p>
                          </div>
                          <div
                            className={styles.optionRow}
                            onClick={() => handlerLevel('Имеется небольшой опыт')}
                          >
                            <img
                              src={starts2}
                              alt='starts0'
                              className={styles.imgOption}
                            />
                            <p className={styles.optionLevel}>Middle</p>
                          </div>
                          <div
                            className={styles.optionRow}
                            onClick={() => handlerLevel('Нет опыта, но хочу получить')}
                          >
                            <img
                              src={starts1}
                              alt='starts0'
                              className={styles.imgOption}
                            />
                            <p className={styles.optionLevel}>Beginner</p>
                          </div>
                          {showLevel && (
                            <div
                              className={styles.optionLevelOver}
                              onClick={() => setSowLevel(!showLevel)}
                            ></div>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className={styles.filterDescriptionDiv}
                      onClick={() => setSowDiscription(!showDiscription)}
                    >
                      <p className={styles.filterDescription}>{wordDiscription}</p>
                      <img
                        src={chevron}
                        alt='chevron'
                        className={styles.imgSelect}
                      />

                      {showDiscription && (
                        <div className={styles.optionDiscriptionDiv}>
                          <div
                            className={styles.optionRow}
                            onClick={() => handlerDiscription('All')}
                          >
                            <p className={styles.optionLevel}>All variants</p>
                          </div>
                          <div
                            className={styles.optionRow}
                            onClick={() => handlerDiscription('withDescription')}
                          >
                            <p className={styles.optionLevel}>With description</p>
                          </div>
                          <div
                            className={styles.optionRow}
                            onClick={() => handlerDiscription('withoutDescription')}
                          >
                            <p className={styles.optionLevel}>Without description</p>
                          </div>
                          {showDiscription && (
                            <div
                              className={styles.optionLevelOver}
                              onClick={() => setSowDiscription(!showDiscription)}
                            ></div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
              <InterestsData
                filterData={filterData}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
    </div>
  );
}

export default Interests;

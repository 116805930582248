import { FC, forwardRef, InputHTMLAttributes, useRef, useState } from 'react';
import Check from '@shared/assets/icons/check.svg';
import { HiddenCheckbox, Mark, Wrapper } from './Checkbox.styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({label, ...rest}, ref) => {

  return (
    <Wrapper>
      <HiddenCheckbox
        type='checkbox'
        hidden
        ref={ref}
        {...rest}
      />
      <Mark >
        <img src={Check} />
      </Mark>
      <span>{label}</span>
    </Wrapper>
  );
})

import React, { memo } from 'react';
import styles from './CheckboxGroup.module.scss';
import { Checkbox } from '../Checkbox/Checkbox';

/**
 * dummy component of CheckboxGroup which
 * it is optimized with React.memo, so do not implement inner state for the component
 *
 * @param { String } id  - ID of checkboxes group
 * @param { String } title  - title of checkboxes group
 * @param {{ label: String, checked: boolean, disabled?: boolean }[]} items  - checkboxes to display
 * @param { Function } onChange - callback on checkboxes state inside group changed
 */
export const CheckboxGroup = memo(({ id, title, items, onChange, className }) => {
  const onCheckboxChange = (index, prevState) => () => {
    onChange?.(
      items.map((checkbox, localIndex) => {
        if (localIndex === index) {
          return {
            ...checkbox,
            checked: !prevState,
          };
        }
        if(localIndex !== index){
          return {
            ...checkbox,
            checked: false,
          };
        }
        return checkbox;
      }),
    );
  };

  return (
    <div className={[styles.CheckboxGroup, className].join(' ')}>
      <h3 className={styles.title}>{title}</h3>
      {items.map((checkbox, index) => (
        <Checkbox
          key={`${id}_${index}`}
          id={`${id}_${index}`}
          label={checkbox.label}
          checked={checkbox.checked}
          disabled={checkbox.disabled}
          onChange={checkbox.disabled ? undefined : onCheckboxChange(index, checkbox.checked)}
        />
      ))}
    </div>
  );
});

CheckboxGroup.displayName = 'CheckboxGroup';

import styled from "@emotion/styled";

export const Title = styled.h1`
    font-family: Platform LC Web;
    font-size: 44px;
    margin-bottom: 40px;
`

export const Wrapper = styled.div`
    padding: 60px 195px 86px;

    @media (max-width: 1440px) {
        padding: 60px 105px 86px;
      }
  
      @media (max-width: 1280px) {
        padding: 60px 55px 86px;
      }
`
import React from 'react';
import styles from './ButtonsOld.module.scss';
import { NavLink } from 'react-router-dom';
import OverOld from '../../../../../images/instractor/OverOld.svg';
import over2 from '../../../../../images/instractor/over2.svg';

function ButtonsOld({ feedbackId }) {
  return (
    <div className={styles.Buttons}>
      <div className={styles.block}>
        <p className={styles.title}>feedback page</p>
        <div className={styles.flexDiv}>
          <ul className={styles.ul}>
            <li className={styles.li}>{"person's quality answers"}</li>
            <li className={styles.li}>general quantitative answers</li>
          </ul>
          <ul className={styles.ul}>
            <li className={styles.li}>{'TA’s total feedback'}</li>
            <li className={styles.li}>{'feedback for all TA’s'}</li>
          </ul>
        </div>
        <NavLink
          to={`/course_feedback/${feedbackId}`}
          className={styles.link}
        >
          <button className={styles.button}>
            <p className={styles.textButton}>see more</p>
          </button>
        </NavLink>
        <div className={styles.overflow}>
          <img
            src={OverOld}
            className={styles.img}
          />
          <img
            src={over2}
            className={styles.img2}
          />
        </div>
      </div>
    </div>
  );
}

export default ButtonsOld;

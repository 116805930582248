import React, { forwardRef, memo } from 'react';
import headerStyle from './ProjectsHeader.module.scss';
import cellsStyle from '../ProjectTableCard/ProjectTableCard.module.scss';
import Box from 'ui-box';
import { Text } from '../../common/Text/Text';

/**
 * This is a dummy component for display projects table Header
 *
 * @param { boolean } showSupervizor if true - shows supervisor as a separate column
 */
export const ProjectsHeader = memo(
  forwardRef(function ProjectsHeader({ showSupervizor }, ref) {
    return (
      <Box
        is='div'
        ref={ref}
        className={headerStyle.ProjectsHeader}
      >
        <Text className={[cellsStyle.cell, cellsStyle.titleCell].join(' ')}>Title</Text>
        {showSupervizor && (
          <Text className={[cellsStyle.cell, cellsStyle.supervizorCell].join(' ')}>Supervisor</Text>
        )}
        <Text className={[cellsStyle.cell, cellsStyle.levelCell].join(' ')}>Level</Text>
        <Text className={[cellsStyle.cell, cellsStyle.tagsCell].join(' ')}>Field</Text>
        <Text className={[cellsStyle.cell, cellsStyle.tagsCell].join(' ')}>Stack</Text>
      </Box>
    );
  }),
);

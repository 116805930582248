import React, { useState, useMemo, memo } from 'react';
import FeedbackAnswersAll from './FeedbackAnswersAll';
import styles from './SummaryFeedback.module.scss';
import { useSelector } from 'react-redux';
import Recharts from './Recharts';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import TAEvaluationComponent from '../../TAEvaluationComponents/TAEvaluationComponent/TAEvaluationComponent';

// eslint-disable-next-line react/display-name
const SummaryFeedback = memo(function ({
  data,
  dataQuestion,
  students_count,
  isLoading,
  isLoadingCourse,
  dataCourse,
  getAccess,
  nameTA,
  setNameTA,
}) {
  const [resultStronglyAgree, setResultStronglyAgree] = useState('');
  const [resultAgree, setResultAgree] = useState('');
  const [resultNeutral, setResultNeutral] = useState('');
  const [resultDisagree, setResultDisagree] = useState('');
  const [resultStronglyDisagree, setResultStronglyDisagreee] = useState('');
  const [arrTA, setArrTA] = useState([]);
  const [, setArrResult] = useState('');
  const [filterTA, setFilterTA] = useState([]);
  const [newData, setNewData] = useState('');
  const [filterCourse, setFilterCourse] = useState([]);
  const [filterLecture, setFilterLecture] = useState([]);
  const [filterTutor, setFilterTutor] = useState([]);
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  const feedbackGiven = useSelector((state) => state.instructor.feedbackGiven);
  const { isMobile } = useMatchMedia();

  useMemo(() => {
    if (data && Object.keys(data).length !== 0) {
      let stronglyAgree = data.reduce((accum, item) => {
        return item.printed_value === 'strongly agree' ? accum + 1 : accum;
      }, 0);
      let agree = data.reduce((accum, item) => {
        return item.printed_value === 'agree' ? accum + 1 : accum;
      }, 0);
      let neutral = data.reduce((accum, item) => {
        return item.printed_value === 'neutral' ? accum + 1 : accum;
      }, 0);
      let disagree = data.reduce((accum, item) => {
        return item.printed_value === 'disagree' ? accum + 1 : accum;
      }, 0);
      let stronglyDisagree = data.reduce((accum, item) => {
        return item.printed_value === 'strongly disagree' ? accum + 1 : accum;
      }, 0);
      let sum = data.reduce((accum, item) => {
        return item.printed_value === 'strongly disagree' ||
          item.printed_value === 'disagree' ||
          item.printed_value === 'neutral' ||
          item.printed_value === 'agree' ||
          item.printed_value === 'strongly agree'
          ? accum + 1
          : accum;
      }, 0);
      let resultStronglyAgree = ((100 * stronglyAgree) / sum).toFixed(0);
      let resultAgree = ((100 * agree) / sum).toFixed(0);
      let resultNeutral = ((100 * neutral) / sum).toFixed(0);
      let resultDisagree = ((100 * disagree) / sum).toFixed(0);
      let resultStronglyDisagree = ((100 * stronglyDisagree) / sum).toFixed(0);

      setResultStronglyAgree(resultStronglyAgree);
      setResultAgree(resultAgree);
      setResultNeutral(resultNeutral);
      setResultDisagree(resultDisagree);
      setResultStronglyDisagreee(resultStronglyDisagree);
      let arrResult = [
        resultStronglyAgree,
        resultAgree,
        resultNeutral,
        resultDisagree,
        resultStronglyDisagree,
      ];

      setArrResult(arrResult);
    }
    setNewData(data);
  }, [data]);

  useMemo(() => {
    if (dataQuestion) {
      let filterArrTA = dataQuestion.filter((item) => {
        return (
          (item.question_label.includes('lab') ||
            item.question_label.includes('TA') ||
            item.question_label.includes('Techer assistant') ||
            item.question_label.includes('Teacher assistant') ||
            item.question_label.includes('Moodle') ||
            item.question_label.includes('Activities') ||
            item.question_label.includes('teacher assistant') ||
            item.question_name.includes('name') ||
            item.question_name.includes('lab') ||
            item.question_name.includes('eacher assistant')) &&
          item.question_type == 'multichoice' &&
          item.question_name !== 'Teacher assistant name' &&
          item.question_name !== 'Ta name'
        );
      });

      let filterArr = dataQuestion.filter((item) => {
        return (
          !item.question_label.includes('lab') &&
          !item.question_label.includes('TA') &&
          !item.question_label.includes('Techer assistant') &&
          !item.question_label.includes('Teacher assistant') &&
          !item.question_label.includes('Moodle') &&
          !item.question_label.includes('Activities') &&
          !item.question_label.includes('teacher assistant') &&
          !item.question_name.includes('name') &&
          !item.question_name.includes('lab') &&
          !item.question_name.includes('eacher assistant') &&
          item.question_type == 'multichoice'
        );
      });

      let filterCourse = filterArr.filter((item) => {
        return item.question_name.includes('course');
      });
      let filterLecture = filterArr.filter((item) => {
        return item.question_name.includes('ecture');
      });
      let filterTutor = filterArr.filter((item) => {
        return item.question_name.includes('tutor') || item.question_name.includes('Tutor');
      });

      setFilterTA(filterArrTA);
      setFilterCourse(filterCourse);
      setFilterLecture(filterLecture);
      setFilterTutor(filterTutor);
    }
  }, [dataQuestion]);

  setTimeout(() => {
    setIsOpenLazy(true);
  }, '1000');

  useMemo(() => {
    if (dataCourse.instructors) {
      let filterCourse = dataCourse.instructors.filter((item) => item.role_id == '12');
      setArrTA(filterCourse);
    }
  }, [dataCourse]);

  return (
    <div className={styles.SummaryFeedback}>
      <div className={styles.SummaryFeedbackBlock}>
        <h4 className={styles.title}>Course over view</h4>

        {isMobile && (
          <div className={styles.divHint}>
            <span className={styles.spanFlex}>
              <p className={styles.titelValue}>course taken:</p>{' '}
              <p className={styles.textValue}>{students_count}</p>
            </span>
            <span className={styles.spanFlex}>
              <p className={styles.titelValue}>feedback given:</p>{' '}
              <p className={styles.textValue}>{feedbackGiven}</p>
            </span>

            <span className={styles.spanFlex}>
              <span className={styles.colorGreen}></span>{' '}
              <p className={styles.textColorGreen}>
                {' '}
                <span className={styles.fontWeight}>{resultStronglyAgree}%</span> strongly agree
              </p>
            </span>
            <span className={styles.spanFlex}>
              <span className={styles.colorGreen2}></span>{' '}
              <p className={styles.textColor}>
                {' '}
                <span className={styles.fontWeight}>{resultAgree}%</span> agree
              </p>
            </span>
            <span className={styles.spanFlex}>
              <span className={styles.colorYellow}></span>{' '}
              <p className={styles.textColor}>
                {' '}
                <span className={styles.fontWeight}>{resultNeutral}%</span> neutral
              </p>
            </span>
            <span className={styles.spanFlex}>
              <span className={styles.colorOrange}></span>{' '}
              <p className={styles.textColor}>
                {' '}
                <span className={styles.fontWeight}>{resultDisagree}%</span> disagree
              </p>
            </span>
            <span className={styles.spanFlex}>
              <span className={styles.colorRed}></span>{' '}
              <p className={styles.textColor}>
                {' '}
                <span className={styles.fontWeight}>{resultStronglyDisagree}%</span> strongly
                disagree
              </p>
            </span>
          </div>
        )}

        {!isMobile && (
          <div className={styles.divHint}>
            <div className={styles.hintLine}>
              <span className={styles.spanFlex}>
                <p className={styles.titelValue}>students:</p>{' '}
                <p className={styles.textValue}>{students_count}</p>
              </span>
              <span className={styles.spanFlex}>
                <p className={styles.titelValue}>feedback given:</p>{' '}
                <p className={styles.textValue}>{feedbackGiven}</p>
              </span>
            </div>
            <div className={styles.hintLine}>
              <span className={styles.spanFlex}>
                <span className={styles.colorGreen}></span>{' '}
                <p className={styles.textColorGreen}> strongly agree</p>
              </span>
              <span className={styles.spanFlex}>
                <span className={styles.colorGreen2}></span>{' '}
                <p className={styles.textColor}>agree</p>
              </span>
              <span className={styles.spanFlex}>
                <span className={styles.colorYellow}></span>{' '}
                <p className={styles.textColor}>neutral</p>
              </span>
              <span className={styles.spanFlex}>
                <span className={styles.colorOrange}></span>{' '}
                <p className={styles.textColor}> disagree</p>
              </span>
              <span className={styles.spanFlex}>
                <span className={styles.colorRed}></span>{' '}
                <p className={styles.textColor}> strongly disagree</p>
              </span>
            </div>
          </div>
        )}

        <div className={styles.divChart}>
          <div className={styles.Chart}>
            {!isLoading && !isLoadingCourse && isOpenLazy ? (
              <Recharts
                resultStronglyAgree={resultStronglyAgree}
                resultAgree={resultAgree}
                resultNeutral={resultNeutral}
                resultDisagree={resultDisagree}
                resultStronglyDisagree={resultStronglyDisagree}
              />
            ) : (
              <div className={styles.Preloader}></div>
            )}
          </div>
        </div>
        <section className={styles.sectionIssue}>
          {filterCourse.length > 0 && (
            <div className={styles.divLabel}>
              <h5 className={styles.titleLabel}>Course</h5>
              <div className={styles.flexDiv}>
                {filterCourse.map((item) => {
                  return (
                    item.question_type == 'multichoice' &&
                    item.question_name !== "Course lector's name" &&
                    item.question_name !== 'Ta name' &&
                    item.question_name !== 'Teacher assistant name' &&
                    item.question_name !== '(TA evaluation) TA name' && (
                      <div
                        className={styles.particularIssue}
                        key={
                          Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
                        }
                      >
                        <p className={styles.questionName}>{item.question_name}</p>
                        <FeedbackAnswersAll
                          answer={item.moodle_feedback_item_id}
                          newData={newData}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          )}

          {filterLecture.length > 0 && (
            <div className={styles.divLabel}>
              <h5 className={styles.titleLabel}>Lecture</h5>
              <div className={styles.flexDiv}>
                {filterLecture.map((item) => {
                  return (
                    item.question_type == 'multichoice' &&
                    item.question_name !== "Course lector's name" &&
                    item.question_name !== 'Ta name' &&
                    item.question_name !== 'Teacher assistant name' &&
                    item.question_name !== '(TA evaluation) TA name' && (
                      <div
                        className={styles.particularIssue}
                        key={
                          Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
                        }
                      >
                        <p className={styles.questionName}>{item.question_name}</p>
                        <FeedbackAnswersAll
                          answer={item.moodle_feedback_item_id}
                          newData={newData}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          )}

          {filterTutor.length > 0 && (
            <div className={styles.divLabel}>
              <h5 className={styles.titleLabel}>Tutor</h5>
              <div className={styles.flexDiv}>
                {filterTutor.map((item) => {
                  return (
                    item.question_type == 'multichoice' &&
                    item.question_name !== "Course lector's name" &&
                    item.question_name !== 'Ta name' &&
                    item.question_name !== 'Teacher assistant name' &&
                    item.question_name !== '(TA evaluation) TA name' && (
                      <div
                        className={styles.particularIssue}
                        key={
                          Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
                        }
                      >
                        <p className={styles.questionName}>{item.question_name}</p>
                        <FeedbackAnswersAll
                          answer={item.moodle_feedback_item_id}
                          newData={newData}
                        />
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          )}
        </section>
        {arrTA.length > 0 && (
          <TAEvaluationComponent
            dataQuestion={dataQuestion}
            getAccess={getAccess}
            arrTA={arrTA}
            setNameTA={setNameTA}
            filterTA={filterTA}
            newDataAnswers={data}
            nameTA={nameTA}
          />
        )}
      </div>
    </div>
  );
});

export default SummaryFeedback;

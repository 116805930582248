import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Button } from '../../common/Button/Button.js';
import styles from './Project.module.scss';
import { Text } from '../../common/Text/Text';
import { Container } from '@mui/system';
import {
  useGetProjectQuery,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useArchiveProjectMutation,
} from '../../../redux/Api';
import ProjectNotFound from '../../stubs/instructorStubs/ProjectNotFound';
import { ModifyProjectSideCard } from '../../Projects/ModifyProjectSideCard/ModifyProjectSideCard';
import { SideCard } from '../../common/SideCard/SideCard';
import { useProject } from '../../Projects/useProject';
import { useProjectOptions } from '../../Projects/useProjectOptions';
import { ProjectFullPage } from '../../Projects/ProjectFullPage/ProjectFullPage';
import Preloader from '../../stubs/Preloader/Preloader';
import BackComponent from '../../common/elements/BackComponent/BackComponent.js';

function Project({getAccess, projectId, ...supervisor }) {
  const [sideCardOpen, setSideCardOpen] = useState(false);
  const [fileHandler, setFileHandler] = useState('')
  const [showFile, setShowFile] = useState(false);
  const history = useHistory();

  const { isLoading, data, error } = useGetProjectQuery({getAccess, projectId});
  const [deleteProject] = useDeleteProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [archiveProject] = useArchiveProjectMutation();
  supervisor = {...supervisor,
    contactData: data}
  const { projectData, ...handlers } = useProject(supervisor, data);
  const { projectOptions } = useProjectOptions(getAccess);

  const handleDeleteProject = useCallback(async () => {
    await deleteProject({getAccess, projectId});
    setSideCardOpen(false);
    history.push(`/instructor/${supervisor?.data?.moodle_id}/projects`);
  }, [projectId, supervisor]);

  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  
  const handleUpdateProject = useCallback(async () => {
let storage = {};
for (let property in projectData) {
  if(property == 'is_thesis_suitable' || property == 'telegram'|| property == 'email' || property == 'office' || property == 'open_hours' || property == 'skills' || property == 'subject_areas' || property == 'study_levels'){
    storage[property] = projectData[property]
    }
    if(property == 'deadline' && projectData[property] == null){
      storage[property] = projectData[property]
      }
  for (let prop in projectData[property]) {
if (property !== 'supervisor' && property !== 'deadline') {
  storage[property] = projectData[property]
} else if (projectData[property][prop].length != 0 && property == 'deadline') {
  storage[property] = projectData[property]
}
  }
}

await updateProject({
  storage,
  id: projectId,
  getAccess: getAccess,
});

if(fileHandler && showFile){
  let formData = new FormData();
  formData.append('attachment', fileHandler)
  await updateProject({
    formData,
    id: projectId,
    getAccess: getAccess,
  });
}else if(fileHandler == null && showFile){
  let formData = {'attachment' : null}
  await updateProject({
    formData,
    id: projectId,
    getAccess: getAccess,
  });
}

    setSideCardOpen(false);
  }, [projectId, supervisor, projectData, fileHandler]);

  const handleArchiveProject = useCallback(async () => {
    let data = {projectId, projectData, getAccess}

    await archiveProject(data).then(() => handlers.onArchive(projectData.active));
    setSideCardOpen(false);
  }, [projectId, supervisor, projectData]);

  const handleOpenSidecard = useCallback(() => setSideCardOpen(true), []);
  const handleCloseSidecard = useCallback(() => setSideCardOpen(false), []);

  if (isLoading) {
    return (
      <Container className={styles.Project}>
        <Preloader />
      </Container>
    );
  }

  if (error?.status === 404) {
    return <Container className={styles.Project}><ProjectNotFound/></Container>;
  }

  return (
    <>
      <Container className={styles.Project}>
        <BackComponent/>
        <Stack spacing={1}>
          <Stack
            spacing={1}
            className={styles.controls}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            alignContent='center'
            direction={{ sm: 'row' }}
          >
            <h1 className={styles.title}>{projectData.title}</h1>
            <div className={styles.position}>
            <Button
              className={styles.createButton}
              onClick={handleOpenSidecard}
            >
              <Text
                color='#3852DE'
                fontWeight={500}
              >
                edit project
              </Text>
            </Button>
            </div>
          </Stack>
        </Stack>
        <ProjectFullPage item={data} />
      </Container>
      <SideCard
        isOpen={sideCardOpen}
        onClose={handleCloseSidecard}
      >
        <ModifyProjectSideCard
          projectData={projectData}
          handlers={handlers}
          onUpdate={handleUpdateProject}
          onArchive={handleArchiveProject}
          onDelete={handleDeleteProject}
          options={projectOptions}
          fileData={data?.attachment}
          setFileHandler={setFileHandler}
          setShowFile={setShowFile}
        />
      </SideCard>
    </>
  );
}

export default Project;

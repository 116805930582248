import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import React, { FC, useCallback, useState } from 'react';
import Button from '@atlaskit/button/standard-button';
import Edit from '@shared/assets/icons/edit.svg';
import { Chat } from '@chat-bot/entities/API-service';
import {
  ChatConfigurationFieldControls,
  ChatConfigurationFormFields,
} from '@chat-bot/entities/ChatConfigurationFormFields';
import { useForm } from 'react-hook-form';
import { ChatConfigurationFieldNames } from '@chat-bot/entities/ChatConfigurationFormFields/config';
import { updateChatInfo } from '@chat-bot/entities/API-service/api';

interface EditChatProps {
  chat: Chat;
  onAfterEdit: () => void;
}

export const EditChat: FC<EditChatProps> = ({ chat, onAfterEdit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  type CreateChatFormValues = {
    [ChatConfigurationFieldNames.TITLE]: string;
    [ChatConfigurationFieldNames.DESCRIPTION]: string;
    [ChatConfigurationFieldNames.SLOW_MODE]: boolean;
    [ChatConfigurationFieldNames.ALL_REACTIONS]: 'true' | 'false';
  };

  const { register, handleSubmit} = useForm<CreateChatFormValues>({
    defaultValues: {
      [ChatConfigurationFieldNames.TITLE]: chat.title,
      [ChatConfigurationFieldNames.DESCRIPTION]: chat.description,
      [ChatConfigurationFieldNames.SLOW_MODE]: !!chat.slow_mode,
      [ChatConfigurationFieldNames.ALL_REACTIONS]: chat.all_reactions ? 'true' : 'false',
    },
  });

  const formControls: ChatConfigurationFieldControls = {
    courseTitle: register(ChatConfigurationFieldNames.TITLE),
    courseDescription: register(ChatConfigurationFieldNames.DESCRIPTION),
    slowMode: register(ChatConfigurationFieldNames.SLOW_MODE),
    reactions: register(ChatConfigurationFieldNames.ALL_REACTIONS),
  };

  const onSubmit = handleSubmit(async (data) => {
    await updateChatInfo({id: chat.id, slow_mode: data.slow_mode ? 30 : 0, description: data.description, all_reactions: data.all_reactions ==='true'? true : false, title: data.title});
    closeModal();
    onAfterEdit();
  })

  return (
    <>
      <button onClick={openModal}>
        <img
          src={Edit}
          alt=''
          height='27px'
        />
      </button>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={closeModal}
            width='x-large'
          >
            <ModalHeader>
              <ModalTitle>Edit chat parameters</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={onSubmit}>
                <ChatConfigurationFormFields
                  isEditing
                  formControls={formControls}
                />
                <ModalFooter>
                  <Button
                    appearance='primary'
                    type='submit'
                  >
                    Save
                  </Button>
                  <Button
                    appearance='subtle'
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </ModalBody>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

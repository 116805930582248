import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
    padding: 60px 195px 180px;

    @media (max-width: 1440px) {
      padding: 60px 105px 180px;
    }

    @media (max-width: 1280px) {
      padding: 60px 55px 180px;
    }
`

export const Title = styled.h1`
    font-size: 44px;
    font-family: Platform LC Web;
    font-weight: 400;
    margin-bottom: 40px;
`

export const CourseLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    color: rgba(56, 82, 222);
  }
`;

export const CourseList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 18px;
`
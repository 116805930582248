import { forwardRef, InputHTMLAttributes } from 'react';
import { StyledInput } from './Input.styles';

export const Input = forwardRef<HTMLInputElement>(
  (props: InputHTMLAttributes<HTMLInputElement>, ref) => {
    return (
      <StyledInput
        {...props}
        ref={ref}
      />
    );
  },
);

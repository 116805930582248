import React, { forwardRef, memo, useState, useCallback } from 'react';
import style from './ProjectTableCard.module.scss';
import Box from 'ui-box';
import { Text } from '../../common/Text/Text';
import { Badge } from '../../common/Badge/Badge';
import { ReactComponent as Edit } from '../../../images/collaboration/EditCollabs.svg';
import { SideCard } from '../../common/SideCard/SideCard';
import { ModifyProjectSideCard } from '../ModifyProjectSideCard/ModifyProjectSideCard';
import { getCookie } from 'react-use-cookie';
import { useProject } from '../useProject';
import { useProjectOptions } from '../useProjectOptions';
import { useSelector } from 'react-redux';
import { useArchiveProjectMutation, useUpdateProjectMutation, useDeleteProjectMutation } from '../../../redux/Api';
import { usePermission } from '../../../hooks/usePermission';
/**
 * This is a dummy component for display project Card
 *
 * @param { string } className - Class name passed to the component
 * @param { project } item - project data
 * @param { () => void } onClick - callback on project card clicked
 * @param { boolean } showSeparateSupervizor - if true shows supervisor column separetly
 */
export const ProjectTableCard = memo(
  forwardRef(function ProjectTableCard({ className, item, onClick, showSeparateSupervizor }, ref) {
const [sideCardOpen, setSideCardOpen] = useState(false);
const [fileHandler, setFileHandler] = useState('')
const [showFile, setShowFile] = useState(false);
let email = useSelector((state) => state.token.email);
const [deleteProject] = useDeleteProjectMutation();
const [updateProject] = useUpdateProjectMutation();
const [archiveProject] = useArchiveProjectMutation();
let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
let permit = usePermission(
  process.env.REACT_APP_PERMISSION_AD)
let supervisor = {
  data: {
    email:email
  },
  getAccess: getAccess
}

const { projectData, ...handlers } = useProject(supervisor, item);
const { projectOptions } = useProjectOptions(getAccess);

const handleDeleteProject = useCallback(async () => {
  await deleteProject({getAccess, projectId: item.id});
  setSideCardOpen(false);
}, [item, supervisor]);

const handleUpdateProject = useCallback(async () => {
  let storage = {};
  for (let property in projectData) {
    if(property == 'is_thesis_suitable' || property == 'telegram'|| property == 'email' || property == 'office' || property == 'open_hours' || property == 'skills' || property == 'subject_areas' || property == 'study_levels'){
      storage[property] = projectData[property]
      }
      if(property == 'deadline' && projectData[property] == null){
        storage[property] = projectData[property]
        }
    for (let prop in projectData[property]) {
  if (property !== 'supervisor' && property !== 'deadline') {
    storage[property] = projectData[property]
  } else if (projectData[property][prop].length != 0 && property == 'deadline') {
    storage[property] = projectData[property]
  }
    }
  }
  await updateProject({
    storage,
    id: item.id,
    getAccess: getAccess,
  });
  
  if(fileHandler && showFile){
    let formData = new FormData();
    formData.append('attachment', fileHandler)
    await updateProject({
      formData,
      id: item.id,
      getAccess: getAccess,
    });
  }else if(fileHandler == null && showFile){
    let formData = {'attachment' : null}
    await updateProject({
      formData,
      id: item.id,
      getAccess: getAccess,
    });
  }
  
      setSideCardOpen(false);
    }, [item, supervisor, projectData, fileHandler]);


    const handleArchiveProject = useCallback(async () => {
      let data = {projectId: item.id, projectData, getAccess}
  
      await archiveProject(data).then(() => handlers.onArchive(projectData.active));
      setSideCardOpen(false);
    }, [item, supervisor, projectData]);

    const handleOpenSidecard = useCallback(() => setSideCardOpen(true), []);
    const handleCloseSidecard = useCallback(() => setSideCardOpen(false), []);

    const handleEdit = (e) =>{
      handleOpenSidecard()
      e.stopPropagation()
    }
    return (
      <>
        <Box
        is='div'
        ref={ref}
        className={[className, style.ProjectCard].join(' ')}
        onClick={onClick}
      >
        <div className={[style.cell, style.titleCell].join(' ')}>
          <Text className={style.titleTable}>{item.title}</Text>
          {item.is_thesis_suitable && <Text color='#3852DE' className={style.titleTable}>Thesis</Text>}
          {!showSeparateSupervizor && (
            <>
              <Box
                as='div'
                display='flex'
                flexDirection='column'
              >
                <Text className={style.titleTableSupervisor} fontWeight={500}>supervisor:</Text>
                <Text className={style.titleTable}>{item.supervisor.full_name}</Text>
                {item.co_supervisor && 
                <>
                <Text className={style.titleTableCoSupervisor}>cosupervisor:</Text>
                 <Text className={style.titleTable}>{item.co_supervisor}</Text>
                 </>}
              </Box>
            </>
          )}
        </div>

        {showSeparateSupervizor && (
          <div className={[style.cell, style.supervizorCell].join(' ')}>
            <Text className={style.titleTable}>{item.supervisor.full_name}</Text>
            {item.co_supervisor && 
            <>
            <Text className={style.cosupervisor}>cosupervisor:</Text>
            <Text className={style.cosupervisor}>{item.co_supervisor}</Text>
            </> }
          </div>
        )}

        <div className={[style.cell, style.levelCell].join(' ')}>
          {item.study_levels.length 
          ?  item.study_levels.map((study_levels) => (
            <Text className={style.titleTable} key={study_levels}>{study_levels}</Text>
          ))
          :<Text className={style.titleTable}>Any</Text>}
        </div>

        <div className={[style.cell, style.tagsCell].join(' ')}>
          {item.subject_areas.length 
          ? item.subject_areas.map((subject_area) => (
            <Badge fontWeight={500}
              appearance='secondary'
              key={subject_area}
            >
              {subject_area}
            </Badge>
          ))
          : '-'}
        </div>

        <div className={[style.cell, style.tagsCell].join(' ')}>
        {item.skills.length 
          ? item.skills.map((skill) => (
            <Badge fontWeight={500}
              appearance='secondary'
              key={skill}
            >
              {skill}
            </Badge>
          ))
         : '-'}
        </div>
       {(permit || item.is_created_by_current_user || (email === item?.supervisor?.email)) &&<Edit className={style.Edit}
           onClick={(e)=> handleEdit(e)}/>}
      </Box>
      <section className={style.mobile}  onClick={onClick}>
      {(permit || item.is_created_by_current_user || (email === item?.supervisor?.email)) &&<Edit className={style.Edit}
           onClick={(e)=> handleEdit(e)}/>}
          <div  className={style.blocksLine}>
          {item.is_thesis_suitable && <div color='#3852DE' className={style.block}>
            <p>
            Thesis
            </p>
            </div>}
            {item.study_levels.length 
          ?  item.study_levels.map((study_levels) => (
            <Text className={style.block} key={study_levels}> <p>{study_levels} </p></Text>
          ))
          :<Text className={style.block}><p>Any</p></Text>}
          </div>
          <Text className={style.titleTable}>{item.title}</Text>
          <Text className={style.titleTableSupervisor} fontWeight={500}>supervisor:</Text>
           <Text className={style.titleSupervisor}>{item.supervisor.full_name}</Text>
           {item.co_supervisor && 
            <>
           <Text className={style.titleTableSupervisor} fontWeight={500}>cosupervisor:</Text>
           <Text className={style.titleSupervisor}>{item.co_supervisor}</Text>
           </>}
           {item.subject_areas?.length  ? <>
           <p className={style.textFields}>fields:</p>
           <div className={style.Badges}>
           {item.subject_areas.length 
          ? item.subject_areas.map((subject_area) => (
            <Badge fontWeight={500}
              appearance='secondary'
              key={subject_area}
              className={style.Badge}
            >
              {subject_area}
            </Badge>
          ))
          : '-'}
          </div>
          </> : <></>}
          {item.skills?.length ? <>
           <p className={style.textFields}>stack:</p>
           <div className={style.Badges}>
           {item.skills.length 
          ? item.skills.map((skill) => (
            <Badge fontWeight={500}
              appearance='secondary'
              key={skill}
              className={style.Badge}
            >
              {skill}
            </Badge>
          ))
         : '-'}
         </div>
         </> : <></>}
      </section>


      <SideCard
isOpen={sideCardOpen}
onClose={handleCloseSidecard}
>
<ModifyProjectSideCard
  projectData={projectData}
  handlers={handlers}
  onUpdate={handleUpdateProject}
  onArchive={handleArchiveProject}
  onDelete={handleDeleteProject}
  options={projectOptions}
  fileData={item?.attachment}
  setFileHandler={setFileHandler}
  setShowFile={setShowFile}
/>
</SideCard>
      </>
    );
  }),
);

import React, { forwardRef, InputHTMLAttributes, useRef, useState } from "react";
import { ArrowIcon, TitleWrapper, Wrapper } from "./Select.styles";
import { Option } from "./lib";
import { Dropdown } from "./ui";
import { useOutsideClick } from "rooks";
import Arrow from "@shared/assets/icons/arrow.svg";

interface SelectProps extends InputHTMLAttributes<HTMLInputElement> {
  options: Option[];
  isError: boolean;
  setValueInForm?: (optionValue: any) => void;
}

export const Select = forwardRef<HTMLInputElement, SelectProps>(({ options, isError, setValueInForm, ...rest }: SelectProps, ref) => {
  const [value, setValue] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLButtonElement | null>(null);

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false)
  })

  return (
    <Wrapper
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      ref={dropdownRef}
      type='button'
      isError={isError}
    >
      <input hidden value={value} {...rest} ref={ref} />
      <TitleWrapper>
        <span >{value} </span>
      </TitleWrapper>

      <ArrowIcon src={Arrow} />
      {isOpen && (
        <Dropdown
          onOptionClick={(optionValue: string) => { setValue(optionValue); setValueInForm && setValueInForm(optionValue); }}
          options={options}
        />
      )}
    </Wrapper>
  );
}) 

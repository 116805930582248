import React, { useState, useCallback, useRef, useEffect } from 'react';
import styles from './Download.module.scss';
import { TitleComponent } from '../../helpers/TitleComponent/TitleComponent';
import { TextInput } from '../../common/TextInput/TextInput';
import { Button } from '../../common/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { downloadSyllabus } from '../../../redux/generatorSlice';
import { getCookie } from 'react-use-cookie';
import PopupDowload from './PopupDowload';
import { Text } from '../../common/Text/Text';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
function Download() {
  const dispatch = useDispatch();
  const [popup, sesPopup] = useState(false);
  TitleComponent(`DP - generator/download_syllabu`);
  const [reason, setReason] = useState('');
  const link = useSelector((state) => state.generator.error);
  let correctLink = 'https://eduwiki.innopolis.university/index.php/';
  const { isMobile } = useMatchMedia();
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const handleReasonChange = useCallback((event) => {
    if (event.target) {
      setReason(event.target.value);
    }
  }, []);

  const handleButton = useCallback(() => {
    let getAccess = getCookie('access_token');
    let dataGenerator = {
      getAccess: getAccess,
      page: reason,
    };

    dispatch(downloadSyllabus(dataGenerator));
    sesPopup(true);
  }, [reason]);

  return (
    <div className={styles.Download}>
      <h3 className={styles.title}>Download syllabus</h3>

      <div className={styles.mainSection}>
        <TextInput
          placeholder='https://eduwiki.innopolis.university/index.php/BSc:_Introduction_To_Programming'
          className={styles.Input}
          width='100%'
          type='text'
          onChange={handleReasonChange}
          required
          ref={inputRef}
        />
        {reason.length > 0 && reason.slice(0, 47) !== correctLink && (
          <Text className={styles.Text}>
            incorrect link format, example:
            https://eduwiki.innopolis.university/index.php/BSc:_Introduction_To_Programming
          </Text>
        )}
        <Button
          appearance='primary'
          size={isMobile ? 's' : 'm'}
          className={styles.Button}
          onClick={handleButton}
          disabled={reason.slice(0, 47) !== correctLink}
        >
          generate docx
        </Button>
      </div>
      {!link == '' && popup && (
        <PopupDowload
          popup={popup}
          sesPopup={sesPopup}
        />
      )}
    </div>
  );
}

export default Download;

import React, { useState, useEffect } from 'react';
import styles from './Grades.module.css';
import Preloader from '../../stubs/Preloader/Preloader';
import searchImg from '../../../images/24.svg';
import GradesMoodle from './Moodle/GradesMoodle';
import GradesRecordBook from './RecordBook/GradesRecordBook';
import chevron from '../../../images/25.svg';
function Grades({ data, isLoading }) {
  const [filterSearch, setFilterSearch] = useState('');
  const [filterGrades, setFilterGrades] = useState('allGrades');
  const [filterSemesters, setFilterSemesters] = useState('allSemesters');
  const [semestrs, setSemestrs] = useState('');
  const [showSemesters, setShowSemesters] = useState(false);
  const [showGrades, setShowGrades] = useState(false);
  const [wordSemesters, setWordSemesters] = useState('All semesters');
  const [wordGrades, setWordGrades] = useState('All grades');
  const [toggleGrades, setToggleGrades] = useState(true);
  const [filter, setFilter] = useState({
    semestr: 'allSemesters',
  });
  let recordBookArr = [...data.mapped_grades, ...data.unmapped_one_c_grades];
  let moodleArr = [...data.mapped_grades];
  let NotSort = data.unmapped_moodle_grades;
  const [filterData, setFilterData] = useState(recordBookArr);
  const [filterDataMoodle, setFilterDataMoodle] = useState(moodleArr);
  const [hint, setHint] = useState('');
  const handlerHint = (item) => {
    if (toggleGrades) {
      setHint(item.course_id);
    } else {
      setHint(item.moodle_course_id);
    }
  };
  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  useEffect(() => {
    setFilter({
      semestr: filterSemesters,
    });
    setFilterData(
      recordBookArr.filter(
        (item) =>
          (filterGrades == 'allGrades' ? item : item.control_result == filterGrades) &&
          (filterSearch == '' ? item : item.course_name.includes(filterSearch)),
      ),
    );
  }, [filterGrades, filterSearch, filterSemesters]);

  useEffect(() => {
    const semestrs = recordBookArr.reduce((accum, item) => {
      return accum.includes(item.control_period) || item.control_period == ''
        ? accum
        : [...accum, item.control_period];
    }, []);

    let semestrArr = [
      'First semester',
      'Second semester',
      'Third semester',
      'Fourth semester',
      'Fifth semester',
      'Sixth semester',
      'Seventh semester',
      'Eighth semester',
    ];
    let dinamicArr = semestrArr.slice(0, `${semestrs.length}`);

    setSemestrs(dinamicArr);
  }, [filterData]);

  useEffect(() => {
    setFilter({
      semestr: filterSemesters,
    });
    setFilterDataMoodle(
      moodleArr.filter(
        (item) =>
          (filterGrades == 'allGrades' ? item : item.system_grade == filterGrades) &&
          (filterSearch == '' ? item : item.moodle_course_name.includes(filterSearch)),
      ),
    );
  }, [filterGrades, filterSearch, filterSemesters]);

  const handlerSemesters = (item) => {
    setFilterSemesters(item);
    if (item == 'allSemesters') {
      setWordSemesters('All semesters');
    } else {
      setWordSemesters(item);
    }
  };
  const handlerGrades = (item) => {
    setFilterGrades(item);
    if (item == 'allGrades') {
      setWordGrades('All grades');
    } else if (item == 'P') {
      setWordGrades('Pass');
    } else if (item == 'F') {
      setWordGrades('Fail');
    } else {
      setWordGrades(item);
    }
  };
  return (
    <div className={styles.grades}>
      {!isLoading ? (
        <div>
          <section className={styles.titleSection}>
            <h4 className={styles.title}>Actual grades</h4>
            {data?.total_gpa ?<div className={styles.gpaSection}>
              <p className={styles.gpaTitle}>total gpa: </p>
              {data.total_gpa ? (
                <p className={styles.gpaValue}>{data.total_gpa.toFixed(2)}</p>
              ) : (
                <p className={styles.gpaValue}></p>
              )}
            </div>
            : <></>}
          </section>
          <section className={styles.searchSection}>
            <div className={styles.inputArea}>
              <div className={styles.borderFilter}>
                <div className={styles.borderFilterDiv}>
                  <img
                    src={searchImg}
                    alt='Search'
                    className={styles.img}
                  />
                  <input
                    type='text'
                    className={styles.input}
                    onChange={(e) => setFilterSearch(e.target.value)}
                    value={filterSearch}
                    placeholder='Search by discipline'
                  />
                </div>

                <div
                  className={styles.filterSemestersDiv}
                  onClick={() => setShowSemesters(!showSemesters)}
                >
                  <p className={styles.filterSemesters}>{wordSemesters}</p>
                  <img
                    src={chevron}
                    alt='chevron'
                    className={styles.imgSelect}
                  />
                  {showSemesters && (
                    <div className={styles.optionSemestersDiv}>
                      <div
                        className={styles.optionRow}
                        onClick={() => handlerSemesters('allSemesters')}
                      >
                        <p className={styles.optionSemesters}>All semesters</p>
                      </div>

                      {semestrs &&
                        semestrs.map((item) => {
                          return (
                            <div
                              key={Math.random()}
                              className={styles.optionRow}
                              onClick={() => handlerSemesters(item)}
                            >
                              <p className={styles.optionSemesters}>{item}</p>
                            </div>
                          );
                        })}
                      {showSemesters && (
                        <div
                          className={styles.optionSemestersOver}
                          onClick={() => setShowSemesters(!showSemesters)}
                        ></div>
                      )}
                    </div>
                  )}
                </div>

                <div
                  className={styles.filterGradesDiv}
                  onClick={() => setShowGrades(!showGrades)}
                >
                  <p className={styles.filterGrades}>{wordGrades}</p>
                  <img
                    src={chevron}
                    alt='chevron'
                    className={styles.imgSelect}
                  />

                  {showGrades && (
                    <div className={styles.optionGradesDiv}>
                      <div
                        className={styles.optionRowG}
                        onClick={() => handlerGrades('allGrades')}
                      >
                        <p className={styles.optionLevel}>All grades</p>
                      </div>
                      <div
                        className={styles.optionRowG}
                        onClick={() => handlerGrades('A')}
                      >
                        <p className={styles.optionLevel}>A</p>
                      </div>
                      <div
                        className={styles.optionRowG}
                        onClick={() => handlerGrades('B')}
                      >
                        <p className={styles.optionLevel}>B</p>
                      </div>
                      <div
                        className={styles.optionRowG}
                        onClick={() => handlerGrades('C')}
                      >
                        <p className={styles.optionLevel}>C</p>
                      </div>
                      <div
                        className={styles.optionRowG}
                        onClick={() => handlerGrades('D')}
                      >
                        <p className={styles.optionLevel}>D</p>
                      </div>
                      <div
                        className={styles.optionRowG}
                        onClick={() => handlerGrades('P')}
                      >
                        <p className={styles.optionLevel}>Pass</p>
                      </div>
                      <div
                        className={styles.optionRowG}
                        onClick={() => handlerGrades('F')}
                      >
                        <p className={styles.optionLevel}>Fail</p>
                      </div>
                      {showGrades && (
                        <div
                          className={styles.optionGradesOver}
                          onClick={() => setShowGrades(!showGrades)}
                        ></div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <span className={styles.buttonToggle}>
              <span>Record book</span>
              <label className={styles.switch}>
                <input
                  type='checkbox'
                  onChange={() => setToggleGrades(!toggleGrades)}
                />
                <span className={`${styles.slider} ${styles.round}`}></span>
              </label>
              <span>Moodle</span>
            </span>
            {hint && <div className={styles.hint}>{hint}</div>}
          </section>

          {toggleGrades && (
            <GradesRecordBook
              filterData={filterData}
              isLoading={isLoading}
              filter={filter}
              handlerHint={handlerHint}
              setHint={setHint}
            />
          )}
          {!toggleGrades && (
            <GradesMoodle
              filterData={filterDataMoodle}
              isLoading={isLoading}
              filter={filter}
              NotSort={NotSort}
              handlerHint={handlerHint}
              setHint={setHint}
            />
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
    </div>
  );
}

export default Grades;

import React, { useState, useMemo } from 'react';
import styles from './IndividualAnswers.module.scss';
import one from '../../../images/instractor/1.svg';
import two from '../../../images/instractor/2.svg';
import three from '../../../images/instractor/3.svg';
import name from '../../../images/instractor/4.svg';
function IndividualAnswers({
  answersStudent,
  nameAnswers,
  minusTextAnswers,
  plusTextAnswers,
  commentsTextAnswers,
  lecturer,
  course_evaluation,
  ta_evaluation,
  gading_and_Moodle,
  dataQuestion,
  instructor,
  primary_Instructor,
  student_self_evaluation,
  teacher_assistant,
  tutorial_Instructor,
}) {
  const [students, setStudents] = useState([]);
  const [hint, setHint] = useState('');
  useMemo(() => {
    if (answersStudent) {
      let arr = [];
      for (let student in answersStudent) {
        arr.push(student);
      }
      setStudents(arr);
    }
  }, [answersStudent]);
  const handlerHint = (item, e) => {
    setHint(`${item}${e}`);
  };

  return (
    <div className={styles.IndividualAnswers}>
      <h4 className={styles.title}>Individual answers</h4>
      {students &&
        students.length > 0 &&
        students.map((e, index) => {
          return (
            <div
              key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
              className={styles.divStudent}
            >
              <h5 className={styles.titleStudent}>student {index + 1}</h5>
              <section className={styles.colorBlocksSection}>
                {Object.keys(lecturer).length !== 0 && lecturer[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {lecturer[e].map((item) => {
                        return (
                          <span
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              item.includes('strongly agree')
                                ? styles.blockColorGreen
                                : item.slice(0, 5) == 'agree'
                                ? styles.blockColorGreen2
                                : item.includes('neutral')
                                ? styles.blockColorYellow
                                : item.includes('strongly disagree')
                                ? styles.blockColorRed
                                : styles.blockColorOrange
                            }
                            onMouseEnter={() => handlerHint(item, e)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {dataQuestion.map((element) => {
                              if (item.includes(element.moodle_feedback_item_id)) {
                                return (
                                  <div
                                    key={
                                      Math.random().toString(16).slice(2) +
                                      new Date().getTime().toString(36)
                                    }
                                    className={
                                      hint == `${item}${e}`
                                        ? styles.blocksHint
                                        : styles.blocksHintHidden
                                    }
                                  >
                                    <p className={styles.textHint}>{element.question_name}</p>
                                  </div>
                                );
                              }
                            })}
                          </span>
                        );
                      })}
                    </div>
                    <p className={styles.textLabel}>Lecturer</p>
                  </div>
                )}

                {Object.keys(course_evaluation).length !== 0 && course_evaluation[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {course_evaluation &&
                        course_evaluation !== undefined &&
                        course_evaluation !== null &&
                        course_evaluation[e].map((item) => {
                          return (
                            <span
                              key={
                                Math.random().toString(16).slice(2) +
                                new Date().getTime().toString(36)
                              }
                              className={
                                item.includes('strongly agree')
                                  ? styles.blockColorGreen
                                  : item.slice(0, 5) == 'agree'
                                  ? styles.blockColorGreen2
                                  : item.includes('neutral')
                                  ? styles.blockColorYellow
                                  : item.includes('strongly disagree')
                                  ? styles.blockColorRed
                                  : styles.blockColorOrange
                              }
                              onMouseEnter={() => handlerHint(item, e)}
                              onMouseLeave={() => {
                                setHint('');
                              }}
                            >
                              {dataQuestion.map((element) => {
                                if (item.includes(element.moodle_feedback_item_id)) {
                                  return (
                                    <div
                                      key={
                                        Math.random().toString(16).slice(2) +
                                        new Date().getTime().toString(36)
                                      }
                                      className={
                                        hint == `${item}${e}`
                                          ? styles.blocksHint
                                          : styles.blocksHintHidden
                                      }
                                    >
                                      <p className={styles.textHint}>{element.question_name}</p>
                                    </div>
                                  );
                                }
                              })}
                            </span>
                          );
                        })}
                    </div>
                    <p className={styles.textLabel}>Course evaluation</p>
                  </div>
                )}

                {Object.keys(gading_and_Moodle).length !== 0 && gading_and_Moodle[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {gading_and_Moodle[e].map((item) => {
                        return (
                          <span
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              item.includes('strongly agree')
                                ? styles.blockColorGreen
                                : item.slice(0, 5) == 'agree'
                                ? styles.blockColorGreen2
                                : item.includes('neutral')
                                ? styles.blockColorYellow
                                : item.includes('strongly disagree')
                                ? styles.blockColorRed
                                : styles.blockColorOrange
                            }
                            onMouseEnter={() => handlerHint(item, e)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {dataQuestion.map((element) => {
                              if (item.includes(element.moodle_feedback_item_id)) {
                                return (
                                  <div
                                    key={
                                      Math.random().toString(16).slice(2) +
                                      new Date().getTime().toString(36)
                                    }
                                    className={
                                      hint == `${item}${e}`
                                        ? styles.blocksHint
                                        : styles.blocksHintHidden
                                    }
                                  >
                                    <p className={styles.textHint}>{element.question_name}</p>
                                  </div>
                                );
                              }
                            })}
                          </span>
                        );
                      })}
                    </div>
                    <p className={styles.textLabel}>Gading and Moodle</p>
                  </div>
                )}

                {Object.keys(instructor).length !== 0 && instructor[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {instructor[e].map((item) => {
                        return (
                          <span
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              item.includes('strongly agree')
                                ? styles.blockColorGreen
                                : item.slice(0, 5) == 'agree'
                                ? styles.blockColorGreen2
                                : item.includes('neutral')
                                ? styles.blockColorYellow
                                : item.includes('strongly disagree')
                                ? styles.blockColorRed
                                : styles.blockColorOrange
                            }
                            onMouseEnter={() => handlerHint(item, e)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {dataQuestion.map((element) => {
                              if (item.includes(element.moodle_feedback_item_id)) {
                                return (
                                  <div
                                    key={
                                      Math.random().toString(16).slice(2) +
                                      new Date().getTime().toString(36)
                                    }
                                    className={
                                      hint == `${item}${e}`
                                        ? styles.blocksHint
                                        : styles.blocksHintHidden
                                    }
                                  >
                                    <p className={styles.textHint}>{element.question_name}</p>
                                  </div>
                                );
                              }
                            })}
                          </span>
                        );
                      })}
                    </div>
                    <p className={styles.textLabel}>Instructor</p>
                  </div>
                )}

                {Object.keys(student_self_evaluation).length !== 0 &&  student_self_evaluation[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {student_self_evaluation[e].map((item) => {
                        return (
                          <span
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              item.includes('strongly agree')
                                ? styles.blockColorGreen
                                : item.slice(0, 5) == 'agree'
                                ? styles.blockColorGreen2
                                : item.includes('neutral')
                                ? styles.blockColorYellow
                                : item.includes('strongly disagree')
                                ? styles.blockColorRed
                                : styles.blockColorOrange
                            }
                            onMouseEnter={() => handlerHint(item, e)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {dataQuestion.map((element) => {
                              if (item.includes(element.moodle_feedback_item_id)) {
                                return (
                                  <div
                                    key={
                                      Math.random().toString(16).slice(2) +
                                      new Date().getTime().toString(36)
                                    }
                                    className={
                                      hint == `${item}${e}`
                                        ? styles.blocksHint
                                        : styles.blocksHintHidden
                                    }
                                  >
                                    <p className={styles.textHint}>{element.question_name}</p>
                                  </div>
                                );
                              }
                            })}
                          </span>
                        );
                      })}
                    </div>
                    <p className={styles.textLabel}>Student self-evaluation</p>
                  </div>
                )}
                {Object.keys(teacher_assistant).length !== 0 && teacher_assistant[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {teacher_assistant[e].map((item) => {
                        return (
                          <span
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              item.includes('strongly agree')
                                ? styles.blockColorGreen
                                : item.slice(0, 5) == 'agree'
                                ? styles.blockColorGreen2
                                : item.includes('neutral')
                                ? styles.blockColorYellow
                                : item.includes('strongly disagree')
                                ? styles.blockColorRed
                                : styles.blockColorOrange
                            }
                            onMouseEnter={() => handlerHint(item, e)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {dataQuestion.map((element) => {
                              if (item.includes(element.moodle_feedback_item_id)) {
                                return (
                                  <div
                                    key={
                                      Math.random().toString(16).slice(2) +
                                      new Date().getTime().toString(36)
                                    }
                                    className={
                                      hint == `${item}${e}`
                                        ? styles.blocksHint
                                        : styles.blocksHintHidden
                                    }
                                  >
                                    <p className={styles.textHint}>{element.question_name}</p>
                                  </div>
                                );
                              }
                            })}
                          </span>
                        );
                      })}
                    </div>
                    <p className={styles.textLabel}>Teacher assistant</p>
                  </div>
                )}

                {Object.keys(ta_evaluation).length !== 0 && ta_evaluation[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {ta_evaluation[e].map((item) => {
                        return (
                          <span
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              item.includes('strongly agree')
                                ? styles.blockColorGreen
                                : item.slice(0, 5) == 'agree'
                                ? styles.blockColorGreen2
                                : item.includes('neutral')
                                ? styles.blockColorYellow
                                : item.includes('strongly disagree')
                                ? styles.blockColorRed
                                : styles.blockColorOrange
                            }
                            onMouseEnter={() => handlerHint(item, e)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {dataQuestion.map((element) => {
                              if (item.includes(element.moodle_feedback_item_id)) {
                                return (
                                  <div
                                    key={
                                      Math.random().toString(16).slice(2) +
                                      new Date().getTime().toString(36)
                                    }
                                    className={
                                      hint == `${item}${e}`
                                        ? styles.blocksHint
                                        : styles.blocksHintHidden
                                    }
                                  >
                                    <p className={styles.textHint}>{element.question_name}</p>
                                  </div>
                                );
                              }
                            })}
                          </span>
                        );
                      })}
                    </div>
                    <p className={styles.textLabel}>TA evaluation</p>
                  </div>
                )}

                {Object.keys(primary_Instructor).length !== 0 && primary_Instructor[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {primary_Instructor[e].map((item) => {
                        return (
                          <span
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              item.includes('strongly agree')
                                ? styles.blockColorGreen
                                : item.slice(0, 5) == 'agree'
                                ? styles.blockColorGreen2
                                : item.includes('neutral')
                                ? styles.blockColorYellow
                                : item.includes('strongly disagree')
                                ? styles.blockColorRed
                                : styles.blockColorOrange
                            }
                            onMouseEnter={() => handlerHint(item, e)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {dataQuestion.map((element) => {
                              if (item.includes(element.moodle_feedback_item_id)) {
                                return (
                                  <div
                                    key={
                                      Math.random().toString(16).slice(2) +
                                      new Date().getTime().toString(36)
                                    }
                                    className={
                                      hint == `${item}${e}`
                                        ? styles.blocksHint
                                        : styles.blocksHintHidden
                                    }
                                  >
                                    <p className={styles.textHint}>{element.question_name}</p>
                                  </div>
                                );
                              }
                            })}
                          </span>
                        );
                      })}
                    </div>
                    <p className={styles.textLabel}>Primary Instructor</p>
                  </div>
                )}

                {Object.keys(tutorial_Instructor).length !== 0 &&  tutorial_Instructor[e] && (
                  <div className={styles.divColor}>
                    <div className={styles.blocksFlexColor}>
                      {tutorial_Instructor[e].map((item) => {
                        return (
                          <span
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              item.includes('strongly agree')
                                ? styles.blockColorGreen
                                : item.slice(0, 5) == 'agree'
                                ? styles.blockColorGreen2
                                : item.includes('neutral')
                                ? styles.blockColorYellow
                                : item.includes('strongly disagree')
                                ? styles.blockColorRed
                                : styles.blockColorOrange
                            }
                            onMouseEnter={() => handlerHint(item, e)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            {dataQuestion.map((element) => {
                              if (item.includes(element.moodle_feedback_item_id)) {
                                return (
                                  <div
                                    key={
                                      Math.random().toString(16).slice(2) +
                                      new Date().getTime().toString(36)
                                    }
                                    className={
                                      hint == `${item}${e}`
                                        ? styles.blocksHint
                                        : styles.blocksHintHidden
                                    }
                                  >
                                    <p className={styles.textHint}>{element.question_name}</p>
                                  </div>
                                );
                              }
                            })}
                          </span>
                        );
                      })}
                    </div>
                    <p className={styles.textLabel}>Tutorial Instructor</p>
                  </div>
                )}
              </section>
              {plusTextAnswers[e].map((item) => {
                return (
                  <div
                    key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                    className={styles.divTextAnswers}
                  >
                    <div className={styles.imgDiv}>
                      {' '}
                      <img
                        src={one}
                        className={styles.img}
                      />
                      <p className={styles.titleAnswers}>
                        Provide 1-3 examples of tasks and activities that helped you learn in this
                        course
                      </p>
                    </div>
                    <p className={styles.textAnswers}>{item}</p>
                  </div>
                );
              })}
              {minusTextAnswers[e].map((item) => {
                return (
                  <div
                    key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                    className={styles.divTextAnswers}
                  >
                    <div className={styles.imgDiv}>
                      {' '}
                      <img
                        src={two}
                        className={styles.img}
                      />
                      <p className={styles.titleAnswers}>
                        {"Provide 1-3 examples of tasks and activities that weren't helpful"}
                      </p>
                    </div>
                    <p className={styles.textAnswers}>{item}</p>
                  </div>
                );
              })}
              {nameAnswers[e].map((item) => {
                return (
                  <div
                    key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                    className={styles.divTextAnswers}
                  >
                    <div className={styles.imgDiv}>
                      {' '}
                      <img
                        src={name}
                        className={styles.img}
                      />
                      <p className={styles.titleAnswers}>Who is your TA?</p>
                    </div>
                    <p className={styles.textAnswers}>{item}</p>
                  </div>
                );
              })}
              {commentsTextAnswers[e].map((item) => {
                return (
                  <div
                    key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                    className={styles.divTextAnswers}
                  >
                    <div className={styles.imgDiv}>
                      {' '}
                      <img
                        src={three}
                        className={styles.img}
                      />{' '}
                      <p className={styles.titleAnswers}>Additional comments (optional)</p>
                    </div>
                    <p className={styles.textAnswers}>{item}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

export default IndividualAnswers;

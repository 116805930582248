import React, { useState, useEffect, useRef } from 'react';
import styles from './Editor.module.scss';
import plusClose from '../../../../images/plusClose.svg';
import { useSelector } from 'react-redux';
import { useAddNotesMutation, useEdditNotesMutation } from '../../../../redux/Api';
import { useAddStudentNotesMutation, useEdditStudentNotesMutation } from '../../../../redux/Api';
function Editor({ setShowPopup, showPopup, indicatePopap, tokenPlusId, position}) {
  const [addNates] = useAddNotesMutation();
  const [editNates] = useEdditNotesMutation();
  const [editStudent] = useEdditStudentNotesMutation();
  const [addStudent] = useAddStudentNotesMutation();
  const [description, setDescription] = useState(indicatePopap?.description);
  const [noteName, setNoteName] = useState(indicatePopap?.note_name);
  const name = useSelector((state) => state.token.name);
  const family = useSelector((state) => state.token.family);
  let getAccess = tokenPlusId.getAccess;
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const handleAdd = async () => {
    let fullТame = `${name} ${family}`;
    if (noteName) {
      let body = {
        note_name: noteName,
        last_edited_by: fullТame,
        edited_by_email: tokenPlusId.email,
        description: description,
      };
      let tokenPlusBody = { getAccess, body };
      if(position === 'instructor'){
      await addNates(tokenPlusBody).unwrap();
      }
      if(position === 'student'){
        await addStudent(tokenPlusBody).unwrap();
        }
    } else {
      let body = {
        last_edited_by: fullТame,
        edited_by_email: tokenPlusId.email,
        description: description,
      };
      let tokenPlusBody = { getAccess, body };
      if(position === 'instructor'){
      await addNates(tokenPlusBody).unwrap();
      }
      if(position === 'student'){
        await addStudent(tokenPlusBody).unwrap();
        }
    }
    setShowPopup(false);
  };
  const handleEdit = async () => {
    let fullТame = `${name} ${family}`;
    let body = {
      note_name: noteName,
      last_edited_by: fullТame,
      edited_by_email: tokenPlusId.email,
      description: description,
    };
    let id = indicatePopap.id;
    let tokenPlusBody = { getAccess, body, id };
    if(position === 'instructor'){
    await editNates(tokenPlusBody).unwrap();
    }
    if(position === 'student'){
      await editStudent(tokenPlusBody).unwrap();
      }
    setShowPopup(false);
  };

  return (
    <div
      className={showPopup ? styles.Editor : styles.EditorHidden}
      onClick={() => setShowPopup(false)}
    >
      <div
        className={showPopup ? styles.popup : styles.popupHidden}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={plusClose}
          className={styles.img}
          onClick={() => setShowPopup(false)}
        />
        <h4 className={styles.title}>
          {indicatePopap == 'add' ? 'Adding a note' : 'Editing a note'}
        </h4>
        <div className={styles.mainSection}>
          <p className={styles.label}>Title</p>
          <input
            className={styles.input}
            placeholder='Note'
            value={noteName}
            onChange={(event) => setNoteName(event.target.value)}
            ref={inputRef}
          />

          <p className={styles.label}>Description</p>
          <textarea
            className={styles.textarea}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
          <button
            className={styles.button}
            onClick={indicatePopap == 'add' ? () => handleAdd() : () => handleEdit()}
          >
            done
          </button>
        </div>
      </div>
    </div>
  );
}

export default Editor;

import React from 'react';
import style from './InstructorProjects.module.scss';
import InstructorProject from '../InstructorProject/InstructorProject';

export const InstructorProjects = ({projects, onProjectClick})=>  {
  return (
<div className={style.projects}>
{projects && projects.sort((a, b) =>
        b.id > a.id ? 1 : -1,
      ).map(item=>{
    return(
        <div
        key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
        className={style.projectItem}
        onClick={onProjectClick(item)}>
         <InstructorProject item={item}/>   
        </div>
    )
})}
</div>
  )
}

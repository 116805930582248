import React, { useEffect, useState } from 'react';
import styles from './Github.module.css';
import Preloader from '../../stubs/Preloader/Preloader';
import EmptySearch from '../../stubs/studentStubs/EmptySearch';
import DateComponent from '../../common/DateComponent/DateComponent';

function GithubData({ isLoading, filterData, data }) {
  const [sortArr, setSortArr] = useState(filterData);

  useEffect(() => {
    if (filterData.length) {
      let sortArr = filterData.sort((a, b) =>
        b.repository_updated_at.slice(0, 10) > a.repository_updated_at.slice(0, 10) ? 1 : -1,
      );
      setSortArr(sortArr);
    }
  }, [filterData]);

  return (
    <section className={styles.GithubSection}>
      {!isLoading ? (
        <div>
          {sortArr.length > 0 ? (
            <div>
              <div className={styles.tableGithub}>
                <div className={styles.trGithub}>
                  <p className={styles.repositoryTh}>Repository</p>
                  <p className={styles.languageslTh}>Language</p>
                  <p className={styles.dateTh}>Last update</p>
                </div>
                {sortArr.length &&
                  sortArr.map((item) => {
                    return (
                      <div
                        className={styles.list}
                        key={Math.random()}
                      >
                        {data.contacts[0].github_username ? (
                          <a
                            href={`https://github.com/${item.repository_full_name}`}
                            target='_blank'
                            className={styles.repository}
                            rel='noreferrer'
                          >
                            {item.repository_full_name
                              .split(`${data.contacts[0].github_username}/`)
                              .join('')}
                          </a>
                        ) : (
                          <p className={styles.repository}>{item.repository_full_name}</p>
                        )}
                        <img
                          className={styles.iconLang}
                          src={
                            process.env.PUBLIC_URL + `/languages/${item.repository_language}.svg`
                          }
                        />
                        <p className={styles.language}>{item.repository_language}</p>
                        <p className={styles.updated}>
                          <DateComponent item={item.repository_updated_at} />
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <EmptySearch />
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
    </section>
  );
}

export default GithubData;

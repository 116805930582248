import React from 'react'
import { CourseSelecting, CourseChats } from '@chat-bot/pages';
import { ThemeProvider } from '@emotion/react';
import { Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { theme } from './theme';

export const ChatBotRoutes = () => (
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <Route path='/chatBot/course-info/:courseID' exact>
        <CourseChats />
      </Route>
      <Route path='/chatBot' exact>
        <CourseSelecting />
      </Route>
    </ThemeProvider>
  </RecoilRoot>
);

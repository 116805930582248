import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

export const useBodyDesabler = (disable, onClick) => {
  useEffect(() => {
    if (!disable) {
      document.body.style.overflow = 'auto';

      document.body.style.padding = `0`
      return;
    }
    document.body.style.overflow = 'hidden';

    document.body.style.padding = `0 calc(17px - (100vw - 100%)) 0 0`

  }, [disable]);

  if (!disable) {
    return;
  }

  return (
    <motion.div
      variants={{
        visible: { opacity: 1, pointerEvents: 'all' },
        hidden: { opacity: 0, pointerEvents: 'none' },
      }}
      animate={disable ? 'visible' : 'hidden'}
      onClick={onClick}
      style={{
        background: 'rgba(0,0,0, 0.5)',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 45,
      }}
    />
  );
};

import React, { useState, useEffect, useMemo } from 'react';
import styles from './Generator.module.css';
import { useSelector } from 'react-redux';
import { TitleComponent } from '../../components/helpers/TitleComponent/TitleComponent';
import rpdImg from '../../images/Frame 6690.svg';
import syllImg from '../../images/Frame 6689.svg';
import download from '../../images/download.svg';
import RPD from '../../components/GeneratorComponents/RPD/RPD';
import Syllabus from '../../components/GeneratorComponents/Syllabus/Syllabus';
import PermissionPage from '../../components/stubs/commonStubs/PermitionPage/PermissionPage';
import { useGetCoursesListQuery } from '../../redux/Api';
import { getCookie } from 'react-use-cookie';
import GenerateError from '../../components/stubs/commonStubs/GenerateError/GenerateError';
import ErrorGlobal from '../../components/stubs/commonStubs/ErrorGlobal/ErrorGlobal';
import { NavLink } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import Download from '../../components/GeneratorComponents/Download/Download';
import TelegramErrorMessage from '../../components/stubs/commonStubs/TelegramErrorMessage/TelegramErrorMessage';
import { usePermission } from '../../hooks/usePermission';
function Generators({ setNavBurger }) {
  TitleComponent('DP - generator');
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  const permissions = useSelector((state) => state.token.role);
  const email = useSelector((state) => state.token.email);
  const [isOpenLazy, setIsOpenLazy] = useState(false);

  let permit = usePermission(
    process.env.REACT_APP_PERMISSION_AD,
     process.env.REACT_APP_PERMISSION_PR,
     process.env.REACT_APP_PERMISSION_STA)
  setNavBurger('generator');
  let location = useLocation();
  let history = useHistory();
  const pathLocation = location.pathname;
  let access_token = getCookie('access_token');

  useEffect(() => {
    if (pathLocation == '/generate') {
      history.push('/generate/rpd');
    }
  }, []);
  const {
    data = [],
    error,
    isLoading,
    isError,
  } = useGetCoursesListQuery(getAccess, {
    skip: permit == false,
  });

  setTimeout(() => {
    setIsOpenLazy(true);
  }, '3000');

  useMemo(()=>{
    if(error){
      const request = '/generator/api/courses-list/'
      TelegramErrorMessage(error, getAccess, request, email, permissions)
    }
    },[error])
  return (
    <div className={styles.Generators}>
      {(data.length == 0 && !isLoading && permit == true) ||
      isError ||
      access_token == '' ? (
        <div className={styles.ErrorGlobal}>{isOpenLazy && <ErrorGlobal />}</div>
      ) : (
        <div>
          {permissions.includes(process.env.REACT_APP_PERMISSION_AD) ||
          permissions.includes(process.env.REACT_APP_PERMISSION_PR) ||
          permissions.includes(process.env.REACT_APP_PERMISSION_STA) ? (
            <div>
              {!error ? (
                <div className={styles.Generators}>
                  <div className={styles.navBlock}>
                    <nav className={styles.nav}>
                      <NavLink
                        to={`/generate/rpd`}
                        className={styles.link}
                      >
                        <button
                          className={
                            pathLocation !== '/generate/rpd' ? styles.button : styles.buttonActive
                          }
                        >
                          <img
                            src={rpdImg}
                            className={
                              pathLocation !== '/generate/rpd' ? styles.img : styles.imgActive
                            }
                            alt='icon'
                          />
                          <p className={pathLocation == '/generate/rpd' && styles.pActive}>
                            rpd generator
                          </p>
                        </button>
                      </NavLink>
                      <NavLink
                        to={`/generate/syllabus`}
                        className={styles.link}
                      >
                        <button
                          className={
                            pathLocation !== `/generate/syllabus`
                              ? styles.button
                              : styles.buttonActive
                          }
                        >
                          <img
                            src={syllImg}
                            className={
                              pathLocation !== `/generate/syllabus` ? styles.img : styles.imgActive
                            }
                            alt='icon'
                          />
                          <p className={pathLocation == `/generate/syllabus` && styles.pActive}>
                            upload syllabus
                          </p>
                        </button>
                      </NavLink>

                      <NavLink
                        to={`/generate/download_syllabus`}
                        className={styles.link}
                      >
                        <button
                          className={
                            pathLocation !== `/generate/download_syllabus`
                              ? styles.button
                              : styles.buttonActive
                          }
                        >
                          <img
                            src={download}
                            className={
                              pathLocation !== `/generate/download_syllabus`
                                ? styles.img
                                : styles.imgActive
                            }
                            alt='icon'
                          />
                          <p
                            className={
                              pathLocation == `/generate/download_syllabus` && styles.pActive
                            }
                          >
                            download syllabus
                          </p>
                        </button>
                      </NavLink>
                    </nav>
                    <div className={styles.navButtom}></div>
                  </div>

                  <div className={styles.main}>
                    {(pathLocation == '/generate/rpd' ||
                      pathLocation == '/generate' ||
                      pathLocation == '/generate/') && (
                      <RPD
                        data={data}
                        isLoading={isLoading}
                      />
                    )}
                    {pathLocation == '/generate/syllabus' && <Syllabus />}
                    {pathLocation == '/generate/download_syllabus' && <Download />}
                  </div>
                </div>
              ) : (
                <GenerateError />
              )}
            </div>
          ) : (
            permissions && (
              <div className={styles.permissions}>
                <PermissionPage />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default Generators;

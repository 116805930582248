import { Search, SelectFilter } from "@shared/ui";

import BachelorHat from "../../shared/assets/icons/BachelorHat.svg";
import Books from "../../shared/assets/icons/books.svg";
import Calendar from "../../shared/assets/icons/calendar.svg";

import { FiltrationBlock, Wrapper } from "./SearchCourses.styles";
import { ChangeEvent, useEffect, useState } from "react";
import { Course } from "@chat-bot/entities/API-service";
import { useDebounce } from "rooks";
import { useRecoilState, useRecoilValue } from "recoil";
import { AllCoursesState } from "@chat-bot/pages/CourseSelecting/model";
import { FilteredCourses } from "./model";

export const SearchCourses = () => {
  const courses = useRecoilValue(AllCoursesState);
  const [, setFilteredCourses] = useRecoilState(FilteredCourses)
  const [filterParameters, setFilterParameters] = useState<
    Pick<Course, "degree" | "full_name" | "type" | "year">
  >({
    degree: null,
    full_name: '',
    type: null,
    year: null
  })


  const updateFullNameFilter = (full_name: string) => setFilterParameters({ ...filterParameters, full_name });

  const debouncedUpdateFullNameFilter = useDebounce(updateFullNameFilter, 1000)


  useEffect(() => {
    const filterOutCoursesBySelectedParameters = () => {
      const isCourseNameMatching = (course: Course) => {
        if (!filterParameters.full_name){
          return true
        }
        const courseName = course.full_name.toLowerCase()
        const enteredName = filterParameters.full_name.toLowerCase()

        return courseName.includes(enteredName)
      }

      const isDegreeMatching = (course: Course) => (
        filterParameters.degree? course.degree === filterParameters.degree : true
      )

      const isYearMatching = (course: Course) => (
        filterParameters.year? course.year === filterParameters.year : true
      )

      const isTypeMatching = (course: Course) => (
        filterParameters.type? course.type === filterParameters.type: true
      )

      const coursesAfterFiltration = courses?.filter((course) => (isTypeMatching(course) && isYearMatching(course) && isDegreeMatching(course) && isCourseNameMatching(course)))
      
      setFilteredCourses(coursesAfterFiltration)
    }

    filterOutCoursesBySelectedParameters()
  }, [courses, filterParameters, setFilteredCourses])


 

  return (
    <Wrapper>
      <FiltrationBlock>
        <SelectFilter
          icon={Books}
          title={"course type"}
          options={[
            { value: "core" },
            { value: "technical elective" },
            { value: "humanitaric elective" },
          ]}
          onOptionClick={(type: Course["type"]) => setFilterParameters({ ...filterParameters, type })}
        />
        <SelectFilter
          icon={BachelorHat}
          title={"degree"}
          options={[{ value: "bachelors" }, { value: "masters" }]}
          onOptionClick={(degree: Course["degree"]) => setFilterParameters({ ...filterParameters, degree })}
        />
        <SelectFilter
          icon={Calendar}
          title={"year"}
          options={[
            { value: 1 },
            { value: 2 },
            { value: 3 },
            { value: 4 },
          ]}
          onOptionClick={(year: Course["year"]) => setFilterParameters({ ...filterParameters, year })}
        />
      </FiltrationBlock>


      <Search placeholder={"Search by course names"} onChange={(e: ChangeEvent<HTMLInputElement>) => debouncedUpdateFullNameFilter(e.target.value)} />
    </Wrapper>
  );
};

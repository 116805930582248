import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Badge } from '../Badge/Badge';
import { IconButton } from '../Button/IconButton';
import style from './Dropdown.module.scss';
import crossIcon from '../../../images/close.svg';

/**
 * Component for render Dropdown
 *
 * @param { String } placeholder  - placeholder for dpopdown control
 * @param { { label: string, value: string }[] } options  - options inside dropdown select
 * @param { boolean } isMulti  - allow multiple select items
 * @param {{ label: string, value: string }[]} initialSelected  - initialy selected items
 * @param { Function } onChange - callback on change
 */

export const Dropdown = ({ placeholder, options, isMulti, onChange, initialSelected }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(isMulti ? [...initialSelected] : null);
  const searchRef = useRef();
  const inputRef = useRef();
options.sort((a,b)=>a.label > b.label ? 1 : -1)
  useEffect(() => {
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });

  const handleInputClick = useCallback(() => {
    // if(initialSelected.length == 0 && filterItem == 'Supervisor'){
    //   setShowMenu(!showMenu);
    // }else if(filterItem !== 'Supervisor'){
      setShowMenu(!showMenu);
    // }
  
  }, [showMenu, initialSelected]);

  const display = useMemo(() => {
    if (!selectedValue || selectedValue.length === 0 || isMulti) {
      return placeholder;
    }
    return selectedValue.label;
  }, [selectedValue]);

  const removeOption = useCallback(
    (option) => {
      return selectedValue.filter((o) => o.value !== option.value);
    },
    [selectedValue],
  );

  const handleTagRemove = useCallback(
    (option) => (e) => {
      e.stopPropagation();
      const newValue = removeOption(option);
      setSelectedValue(newValue);
      onChange(newValue);
    },
    [onChange, removeOption],
  );

  const handleItemClick = useCallback(
    (option) => () => {
      let newValue;
      if (isMulti) {
        if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
          newValue = removeOption(option);
        } else {
          newValue = [...selectedValue, option];
        }
      } else {
        newValue = option;
      }
      setSelectedValue(newValue);
      onChange(newValue);
    },
    [onChange, selectedValue, removeOption, isMulti],
  );

  const isSelected = useCallback(
    (option) => {
      if (isMulti) {
        return selectedValue.filter((o) => o.value === option.value).length > 0;
      }

      if (!selectedValue) {
        return false;
      }

      return selectedValue.value === option.value;
    },
    [isMulti, selectedValue],
  );

  return (
    <div className={style.dropdownContainer}>
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className={style.dropdownInput}
      >
  
      {(selectedValue && selectedValue.length !== 0) ? (
        <div className={style.dropdownTags}>
          {selectedValue.map((option) => (
            <Badge
              key={option.value}
              className={style.dropdownTagItem}
              appearance='secondary'
            >
              {option.label}
              <IconButton
                icon={crossIcon}
                className={style.dropdownTagClose}
                onClick={handleTagRemove(option)}
                size='s'
              />
            </Badge>
          ))}
        </div>
      )
    :<div className={style.dropdownSelectedValue}>{display}</div>}
      </div>
      {showMenu && (
        <ul className={style.dropdownMenu}>
          {options.map((option) => {
            if (isSelected(option)) {
              return null;
            }
            return (
              <li
                onClick={handleItemClick(option)}
                key={option.value}
                className={[style.dropdownItem, isSelected(option) && style.selected].join(' ')}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

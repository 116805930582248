import React from 'react';
import { Chart } from 'react-google-charts';
import { useMatchMedia } from '../../../hooks/useMatchMedia';

function Recharts({
  resultStronglyAgree,
  resultAgree,
  resultNeutral,
  resultDisagree,
  resultStronglyDisagree,
}) {
  const { isMobile } = useMatchMedia();
  const data = [
    ['estimation', 'indicator'],
    ['strongly agree', Number(resultStronglyAgree)],
    ['agree', Number(resultAgree)],
    ['neutral', Number(resultNeutral)],
    ['disagree', Number(resultDisagree)],
    ['strongly disagree', Number(resultStronglyDisagree)],
  ];

  const pieOptions = {
    slices: [
      {
        color: '#069542',
      },
      {
        color: '#0ddc65',
      },
      {
        color: 'FECD1A',
      },
      {
        color: '#FF9900',
      },
      {
        color: '#FF613F',
      },
    ],
    legend: 'none',
    tooltip: {
      showColorCode: true,
    },

    fontName: 'Inter',
    fontSize: 15,
  };
  return (
    <div>
      {!isMobile && (
        <Chart
          chartType='PieChart'
          data={data}
          options={pieOptions}
          width={'500px'}
          height={'500px'}
        />
      )}
      {isMobile && (
        <Chart
          chartType='PieChart'
          data={data}
          options={pieOptions}
          width={'300px'}
          height={'280px'}
        />
      )}
    </div>
  );
}
export default Recharts;

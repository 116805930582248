import React from 'react';
import styles from './TAError.module.scss';
import { NavLink } from 'react-router-dom';
import { TitleComponent } from '../../../helpers/TitleComponent/TitleComponent';
import TAErrorimg from '../../../../images/instractor/TAError.svg';

function TAError() {
  TitleComponent('TA ID Error');
  return (
    <div className={styles.mainPage}>
      <img
        className={styles.img}
        src={TAErrorimg}
      />

      <h3 className={styles.title}>This TA’s page does not exist</h3>
      <p className={styles.secondTitle}>Please check the url and try again</p>
      <NavLink to='/profile_search'>
        {' '}
        <button className={styles.button}>
          <p className={styles.buttonText}>main page</p>
        </button>
      </NavLink>
    </div>
  );
}

export default TAError;

import { FC, HTMLAttributes } from 'react';
import { Title, Error } from './FormField.styles';

interface FormFieldProps extends HTMLAttributes<HTMLElement> {
  title: string;
  required?: boolean;
  error?: string
}

export const FormField: FC<FormFieldProps> = ({ title, required, children, error }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Title>{title} {required && <span style={{ color: 'red' }}>*</span>}</Title>
      {children}
      {error && <Error>{error}</Error>}
    </div>
  );
};

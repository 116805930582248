import styles from './ProgressOfEducation.module.scss';
import React from 'react';
import line from '../../../../images/Line.svg';
import lineMin from '../../../../images/LineMin.svg';
import Degree from '../InternalComponents/Degree';
import Track from '../InternalComponents/Track';
import { useGrandesFilter } from '../../../../hooks/useGradesFilter';
import { useGetLastYear } from '../../../../hooks/useGetLastYear';

function ProgressOfEducation({ data, status }) {
  const [bachelorYear, masterYear, postgraduateYear] = useGrandesFilter(data)
  const lastYear = useGetLastYear(data)
  data = data?.graduation_history_entities
  return (
    <div className={styles.main}>
      <h5 className={styles.title}>Progress of education</h5>
      <div className={styles.display}>
        <div className={styles.sausagSection}>
          <div className={styles.sausagPoint}>
            <p className={styles.sausagPointText}>1</p>
          </div>
          <img
            src={line}
            className={styles.line}
          />
          <img
            src={lineMin}
            className={styles.lineMin}
          />
          <div className={data.length == 2 && (status === "Является студентом" 
                  || status === 'Является аспирантом') ? styles.sausagPointActiv : styles.sausagPoint}>
            <p className={data.length == 2 && (status === "Является студентом" 
                  || status === 'Является аспирантом') ? styles.sausagPointTextActiv : styles.sausagPointText}>
              2
            </p>
          </div>

          {data.length == 3 && (
            <img
              src={line}
              className={styles.line}
            />
          )}
          {data.length == 3 && (
            <img
              src={lineMin}
              className={styles.lineMin}
            />
          )}
          {data.length == 3 && (
            <div className={status === "Является аспирантом" ? styles.sausagPointActiv : styles.sausagPoint}>
              <p className={status === "Является аспирантом" ? styles.sausagPointTextActiv : styles.sausagPointText}>3</p>
            </div>
          )}
        </div>

        <div className={styles.dataSection}>
          {data.length == 3 && (
            <div className={styles.educationItem}>
              <div className={styles.firstLine}>
                <p
                  className={data[data.length - 2] == data[0] ? styles.degreeActive : styles.degree}
                >
                  <Degree item={data[data.length - 2].degree} />
                </p>
                <p className={styles.track}>
                  {' '}
                  <Track item={data[data.length - 2].group} />
                </p>
              </div>
              {data[data.length - 2].graduation_date && (
                <p className={styles.year}>years of study</p>
              )}
              <p className={styles.date}>{(data[data.length - 2]?.graduation_date.slice(6, 10) - bachelorYear) < 4 ? data[data.length - 2]?.graduation_date.slice(6, 10) - 4 : bachelorYear} - {data[data.length - 2]?.graduation_date.slice(6, 10)}</p>
            </div>
          )}
          <div className={styles.educationItem}>
            <div className={styles.firstLine}>
              <p className={styles.degree}>
                <Degree item={data[data.length - 1].degree} />
              </p>
              <p className={styles.track}>
                {' '}
                <Track item={data[data.length - 1].group} />
              </p>
            </div>
            <p className={styles.year}>years of study</p>
            <p className={styles.date}>{data.length == 3 ? masterYear : data[data.length - 1]?.course == "Четвертый" ? bachelorYear : masterYear} - {data[data.length - 1]?.graduation_date.slice(6, 10)}</p>
          </div>
          {data.length == 2 && (
            <div className={styles.educationItem}>
              <div className={styles.firstLine}>
                <p
                  className={
                     (status === "Является студентом" 
                  || status === 'Является аспирантом') ? styles.degreeActive : styles.degree}
                >
                  <Degree item={data[data.length - 2].degree} />
                </p>
                <p className={styles.track}>
                  {' '}
                  <Track item={data[data.length - 2].group} />
                </p>
              </div>
                <p className={styles.year}>years of study</p>
                
              <p className={styles.date}>{data[data.length - 1]?.course == "Четвертый" ? (masterYear ? masterYear : 'no data') : (postgraduateYear ? postgraduateYear : 'no data')} - {(status === "Закончил обучение" 
              || status === 'Является выпускником' 
              || status === 'Отчислен') ? (data[data.length - 1]?.course == "Четвертый" ? (masterYear ? (Number(masterYear) + lastYear) : 'no data') : (postgraduateYear? (Number(postgraduateYear) + lastYear) : 'no data') ) : "..."}</p>
            </div>
          )}

          {data[data.length - 2] !== data[0] && (
            <div className={styles.educationItem}>
              <div className={styles.firstLine}>
                <p className={status === "Является аспирантом" ? styles.degreeActive : styles.degree}>
                  <Degree item={data[0].degree} />
                </p>
                <p className={styles.track}>
                  {' '}
                  <Track item={data[0].group} />
                </p>
              </div>
              <p className={styles.year}>years of study</p>
              
              <p className={styles.date}>{postgraduateYear} - {status === "Является аспирантом" ? "... " : Number(postgraduateYear) + lastYear}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProgressOfEducation;

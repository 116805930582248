import React, {useState, useEffect, useMemo} from 'react'
import styles from './NewSwrchPage.module.scss';
import { ReactComponent as SearchImg } from '../../../images/24.svg';
import { useGetStudentsQuery } from '../../../redux/Api';
import { getCookie } from 'react-use-cookie';
import { useSelector } from 'react-redux';
import { useFilter } from '../../../hooks/useFilter';
import stud from '../../../images/stud.svg';
import instr from '../../../images/instr.svg';
import plus from '../../../images/pluseClose.svg';
import { NavLink, useHistory } from 'react-router-dom';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import search from '../../../images/mainSearch.svg';
import { TitleComponent } from '../../../components/helpers/TitleComponent/TitleComponent';
import Preloader from '../../../components/stubs/Preloader/Preloader';
import ErrorGlobal from '../../../components/stubs/commonStubs/ErrorGlobal/ErrorGlobal';
import PermissionPage from '../../../components/stubs/commonStubs/PermitionPage/PermissionPage';
import TelegramErrorMessage from '../../../components/stubs/commonStubs/TelegramErrorMessage/TelegramErrorMessage';
import { usePermission } from '../../../hooks/usePermission';

function NewSwrchPage({setNavBurger, navBurger}) {
  TitleComponent('DP - search');
     let history = useHistory();
    const [filter, setFilter] = useState('');
    const [selectorShow, setSelectorShow] = useState(false);
    const [getAccess, setGetAccess] = useState('');
    const permissions = useSelector((state) => state.token.role);
    const email = useSelector((state) => state.token.email);
    const [dataFilter, setDataFilter] = useState({});
    const { isTablet } = useMatchMedia();
    const [isOpenLazy, setIsOpenLazy] = useState(false);
    const [isLazy, setIsLazy] = useState(false);
    let permit = usePermission(
      process.env.REACT_APP_PERMISSION_AD,
       process.env.REACT_APP_PERMISSION_PR,
       process.env.REACT_APP_PERMISSION_SV,
       process.env.REACT_APP_PERMISSION_STA)
    useEffect(()=>{
        if(permissions == process.env.REACT_APP_PERMISSION_STU){
          history.push('/projects');
        }
    },[permissions])

    useEffect(()=>{
      setTimeout(() => {
        setIsOpenLazy(true);
      }, '800');
    },[])

    useEffect(() => {
        let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
        setGetAccess(getAccess);
      }, [permissions]);


      useEffect(() => {
        if (navBurger) {
          setNavBurger('');
        }
      }, [navBurger]);
    const {
        data = [], isLoading, error
      } = useGetStudentsQuery(getAccess, {
        skip: permit == false || !getCookie('access_token'),
      });
      useMemo(() => {
        if (data?.users) {
          setDataFilter(
            data.users.filter((item) =>
            (permissions.includes(process.env.REACT_APP_PERMISSION_PR) || permissions.includes(process.env.REACT_APP_PERMISSION_AD)) ? item : item.status == 'student',
            ),
          );
        }
      }, [data]);
    const result = useFilter(filter, dataFilter);
    useMemo(() => {
        if(filter.length > 2 && Array.isArray(result) && result.length > 0){
            setSelectorShow(true)
        }else{
            setSelectorShow(false)
            
        }
      }, [filter]);
      const click = ()=>{
        if(filter.length > 2 && Array.isArray(result) && result.length > 0){
            setSelectorShow(true)
        }else{
            setSelectorShow(false)
            
        }
      }
      const clickHandle = () =>{
        setSelectorShow(false)
      }
      useEffect(()=>{
        setTimeout(() => {
          setIsLazy(true);
        }, '2000');
      },[])
 
    useMemo(()=>{
  if(error){
    const request = '/api/moodle_users/one_c_user_info'
    TelegramErrorMessage(error, getAccess, request, email, permissions)
  }
  },[error])
  return (
    <div  className={styles.main}>

     {isOpenLazy && (!isLoading && data) && getAccess && email
     ?<div>
      {!permit
      ? isLazy && <PermissionPage/>
      : <div>
      {data && Object.keys(data?.users || {}).length === 0 
      ?   isLazy && <ErrorGlobal/>
      :   <div className={styles.main}>
                <img
          src={search}
          className={styles.imgSearch}
          alt='SEARCH ICON'
        />
                {(permissions.includes(process.env.REACT_APP_PERMISSION_PR) || permissions.includes(process.env.REACT_APP_PERMISSION_AD)) ? (
          <h3 className={styles.formName}>Look up an instructor or a student</h3>
        ) : (
          <h3 className={ styles.formName}>Look up a student</h3>
        )}
        <h6 className={styles.text}>
          Use name, email or telegram in&nbsp;Russian&nbsp;or&nbsp;English.
        </h6>
    <div className={styles.searchArea}>
          <input
          placeholder='Search'
          className={!selectorShow ? (filter.length > 2 && result.length === 0 ? styles.errorInput : (filter.length > 0 ? styles.activeInput : styles.Input)) : styles.activeInput}
          type='text'
          height={isTablet ? 40 : 50}
          onChange={(e)=>{setFilter(e.target.value)}}
          required
          value={filter}
          onClick={click}
        />
        <SearchImg   className={!selectorShow ? (filter.length > 2 && result.length === 0 ? styles.errorSearchImg : (filter.length > 0 ? styles.activeSearchImg : styles.searchImg)) : styles.activeSearchImg}/>
        {filter.length > 0 &&  <img src={plus} className={styles.plus} onClick={()=>{setFilter('')}}/>}
       {selectorShow && <div className={styles.emailsDiv}>
        {filter.length > 2 &&
            result.map((item) => {
              return (
                <div
                  key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                  className={styles.emailsFlex} onClick={()=>clickHandle()}
                >
                         <NavLink
                            to={
                            item.status !== 'instructor'
                                ? `/student/${item.moodle_id}`
                                : `/instructor/${item.moodle_id}`
                            }
                            className={styles.emailsFlex}
                        >
                    {item.status == 'student' && (
                      <img
                        src={stud}
                        alt='SEARCH ICON'
                      />
                    )}
                    {item.status !== 'student' && (
                      <img
                        src={instr}
                        alt='SEARCH ICON'
                      />
                    )}
                    <p>{item.email_value}</p>
                  </NavLink>
                  
                </div>
              );
            })}
        </div>}
    </div>
    </div>}
    </div>}
    </div>
    : <div className={styles.Preloader}>
    <Preloader />
  </div>}
    </div>
  )
}

export default NewSwrchPage
import React, { forwardRef } from 'react';
import style from './ProjectsTable.module.scss';
import Box from 'ui-box';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { ProjectTableCard } from '../ProjectTableCard/ProjectTableCard';
import { ProjectsHeader } from '../ProjectsHeader/ProjectsHeader';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import usePagination from '../usePagination';
/**
 * This is a component for display projects table
 *
 * @params { project[] } projects - array of project to display
 * @params { () => void } onProjectClick - callback when ckicked op project in the table
 */
export const ProjectsTable = forwardRef(function ProjectsTable({ projects, setPage, page, onProjectClick }, ref) {
  const { isMobile, isTablet, isDesktop } = useMatchMedia();
  const PER_PAGE = 8;
  const count = Math.ceil(projects.length / PER_PAGE);
  const _DATA = usePagination(projects, PER_PAGE, page);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <Box
      is='div'
      ref={ref}
      className={style.ProjectsTable}
    >
      <ProjectsHeader showSupervizor={isDesktop} />
      {_DATA && _DATA.currentData()?.map((project) => (
        <ProjectTableCard
          key={project.id}
          item={project}
          onClick={onProjectClick(project)}
          showSeparateSupervizor={isDesktop}
          touchView={isMobile || isTablet}
        />
      ))}
<div className={style.poginationDiv}>
{count > 1 && <Stack spacing={2}>
      <Pagination       
      count={count}
        size={isDesktop ? "large" : "small"}
        page={page} color="primary" 
        onChange={handleChange}/>
    </Stack>}
    </div>
    </Box>
  );
});

import React, { FC, useCallback, useState } from 'react';
import TrashCan from '@shared/assets/icons/trashcan.svg';
import { Chat, deleteChat } from '@chat-bot/entities/API-service';
import Modal, {
  ModalHeader,
  ModalTitle,
  ModalTransition,
  ModalFooter,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';

interface DeleteChatProps {
  chat: Chat;
  onAfterDeletion: () => void;
}

export const DeleteChat: FC<DeleteChatProps> = ({ chat , onAfterDeletion}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onChatDeletion = async () => {
    await deleteChat(chat.id);
    onAfterDeletion()
    closeModal()
  }

  return (
    <>
      <button onClick={openModal}>
        <img
          src={TrashCan}
          alt=''
        />
      </button>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle>Delete selected chat?</ModalTitle>
            </ModalHeader>
            <ModalFooter>
              <Button
                appearance='primary'
                onClick={onChatDeletion}
                
              >
                Yes
              </Button>
              <Button
                appearance='subtle'
                onClick={closeModal}
              >
                No
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

function Degree({ item }) {
  switch (item) {
    case 'Бакалавр':
      return 'bachelor';
    case 'Бакалавриат':
      return 'bachelor';
    case 'Магистратура':
      return 'master';
    case 'Магистр':
      return 'master';
    case 'Аспирант':
      return 'postgraduate';
    case 'Аспирантура':
      return 'postgraduate';
    case 'Исследователь.Преподаватель-исследователь':
      return 'postgraduate';
    case 'Исследователь. Преподаватель-исследователь':
      return 'postgraduate';
    default:
      return '-';
  }
}

export default Degree;

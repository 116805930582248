import React, { useState, useEffect } from 'react';
import styles from './Courses.module.scss';
import Feedback from './Feedback/Feedback';
import EmptySearch from '../../stubs/studentStubs/EmptySearch';
import Team from './Team/Team';
import PersonalFitback from './PersonalFitback/PersonalFitback';
import OldPersonalFitback from './OldPersonalFitback/OldPersonalFitback';
import { ReactComponent as CourseID } from '../../../images/instractor/courseID.svg'
import { NavLink } from 'react-router-dom';
function CoursesData({ newFilterArr, moodleId }) {
  const [semestr, setSemestr] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [courseDetail, setCourseDetail] = useState('');
  useEffect(() => {
    const semestr = newFilterArr.reduce((accum, item) => {
      let arr = item.course_name.indexOf(']');
      let slice = item.course_name.slice(1, arr);
      return accum.includes(slice) || slice == '' || item.course_name.includes('RESEARCH SEMINAR')
        ? accum
        : [...accum, slice];
    }, []);

    let obj = {};
    let regexp = /-?\d+(\.\d+)?/g;
    let plus = 0;
    semestr.forEach((element) => {
      if (element.includes('F')) {
        plus = `${element.match(regexp).join().slice(0, 2)}3`;
      } else if (element.includes('S')) {
        plus = `${element.match(regexp).join().slice(0, 2)}1`;
      } else if (element.includes('Sum')) {
        plus = `${element.match(regexp).join().slice(0, 2)}2`;
      } else {
        plus = `${element.match(regexp).join().slice(0, 2)}4`;
      }
      obj[element] = Number(plus);
    });

    setSemestr(semestr.reverse());
  }, [newFilterArr]);

  const handlerCourse = (item) => {
    if (courseDetail == item) {
      setShowDetails(false);
      setCourseDetail('');
    } else {
      setShowDetails(true);
      setCourseDetail(item);
    }
  };

  return (
    <div>
      {newFilterArr && newFilterArr.length > 0 ? (
        <div>
          {semestr &&
            newFilterArr &&
            semestr.map((sem) => {
              return (
                <div key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}>
                  <h4 className={styles.titleSimestr}>{sem}</h4>
                  <div className={styles.tableCourse}>
                    <div className={styles.trCourse}>
                      <p className={styles.courseTh}>Course</p>
                      <p className={styles.roleTh}>Role</p>
                      <p className={styles.feedbackTh}>Feedback &nbsp;&nbsp;&nbsp;%</p>
                    </div>
                  </div>
                  {newFilterArr
                    .filter(
                      (e) =>
                        (sem.includes('-') &&
                          e.course_name.includes(sem) &&
                          e.course_name.includes('-')) ||
                        (e.course_name.slice(1, 7).includes(sem) && !e.course_name.includes('-S')),
                    )
                    .map((item) => {
                      return (
                        <div
                          key={
                            Math.random().toString(16).slice(2) + new Date().getTime().toString(36)
                          }
                        >
                          <div
                            key={
                              Math.random().toString(16).slice(2) +
                              new Date().getTime().toString(36)
                            }
                            className={
                              courseDetail == item
                                ? styles.divSemestrDataFon
                                : styles.divSemestrData
                            }
                            onClick={() => handlerCourse(item)}
                          ><div>
                            <p
                              className={
                                courseDetail !== item ? (Object.keys(item.feedbacks_counter).length !== 0 ? styles.courseName : styles.courseNameNoData) : (Object.keys(item.feedbacks_counter).length !== 0 ? styles.courseNameActive : styles.courseNameActiveNoData)
                              }
                            >
                              {item.course_name}
            
                            </p>
                            {item?.feedback_ids.length > 0 && Object.keys(item.feedbacks_counter).length !== 0 &&
                            <div className={styles.link}>
                             {item.feedback_ids.map((i, index)=>{
                              return (
                              <div key={
                                Math.random().toString(16).slice(2) +
                                new Date().getTime().toString(36)
                              } > 
                              <NavLink
                              to={`/course_feedback/${i}`} className={styles.linkSummary}
                            > 
                            <p
                              className={styles.SummaryFeedback}
                            >Summary feedback {item.feedback_ids.length > 1 && (index + 1)}</p>

                                <CourseID className={styles.courseID}/>
                                </NavLink>
                                </div>)})
                                }
                                </div>}
                                </div>
                            <p className={styles.courseRole}>{item.role_name}</p>
                            <Feedback grades={item.feedbacks_counter} />
                          </div>
                          {showDetails && courseDetail == item && (
                            <Team
                              details={item}
                              moodle={moodleId}
                            />
                          )}
                          {Object.keys(item.feedbacks_counter).length !== 0 &&
                            showDetails &&
                            (item.role_id === 3 || item.role_id === 18) &&
                            courseDetail == item && (
                              <OldPersonalFitback
                                details={item}
                                instructorId={moodleId}
                              />
                            )}
                          {Object.keys(item.feedbacks_counter).length !== 0 &&
                            showDetails &&
                            item.role_id !== 3 &&
                            item.role_id !== 18 &&
                            courseDetail == item && (
                              <PersonalFitback
                                details={item}
                                instructorId={moodleId}
                              />
                            )}
                        </div>
                      );
                    })}
                </div>
              );
            })}
        </div>
      ) : (
        <EmptySearch />
      )}
    </div>
  );
}

export default CoursesData;

import React, { forwardRef, memo } from 'react';
import { Text } from '../../common/Text/Text';
import { Badge } from '../../common/Badge/Badge';
import { Icon } from '../../common/Icon/Icon';
import userIcon from '../../../images/filledUser.svg';
import { Stack, Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {ReactComponent as File} from '../../../images/collaboration/pdf.svg';
import {ReactComponent as Infinity} from '../../../images/Body.svg';
import style from './ProjectFullPage.module.scss';
import { fileRequest } from '../DownloadFile/DownloadFile';

/**
 * This is a dummy component for display project data on full page
 *
 * @param { string } className - Class name passed to the component
 * @param { project } item - entity of the project to display
 */
export const ProjectFullPage = memo(
  forwardRef(function ProjectTableCard({ className, item }, ref) {

    const tagsParams = [
      {
        title: 'Fields',
        data: item.subject_areas,
      },
      {
        title: 'Stack',
        data: item.skills,
      },
    ];
    const contactsParams = [
      {
        title: 'email',
        data: item.email,
      },
      {
        title: 'telegram',
        data: item.telegram,
      },
      {
        title: 'office',
        data: item.office,
      },
      {
        title: 'open hours',
        data: item.open_hours,
      },
 
    ];
    return (
      <Stack
        spacing={1}
        ref={ref}
        className={[className, style.ProjectFullPage].join(' ')}
        justifyContent='flex-start'
        alignItems='flex-start'
        flexDirection='column'
      >
        {item.description && <Text
          fontWeight={500}
          fontSize={20}
          className={style.titleDescription}
        >
          Description
        </Text>}
        <Text fontSize={18} className={style.textDescription}>{item.description}</Text>

        {item.expected_outcome && (
          <>
            <Text
              fontWeight={500}
              fontSize={20}
              className={style.titleDescription}
            >
              Expected outcome
            </Text>
            <Text fontSize={18} className={style.textDescription}>{item.expected_outcome}</Text>
          </>
        )}

        <TableContainer
          component={Paper}
          className={style.table}
        >
          <Table
            // sx={{ minWidth: 650 }}
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
              }
            }}
            aria-label='simple table'
            className={style.table}
          >
            <TableHead className={style.TableHead}>
              <TableRow className={style.tableHeader}>
                <TableCell className={style.tableHeader}>
                  <Text fontSize={18} className={style.textHeader}>Status</Text>
                </TableCell>
                <TableCell className={style.tableHeader}>
                  <Text fontSize={18} className={style.textHeader}>Thesis</Text>
                </TableCell>
                <TableCell className={style.tableHeader}>
                  <Text fontSize={18} className={style.textHeader}>Supervisor</Text>
                </TableCell>
                <TableCell className={style.tableHeader}>
                  {' '}
                  <Text fontSize={18} className={style.textHeader}>Project deadline</Text>
                </TableCell>
                <TableCell className={style.tableHeader}>
                  <Text fontSize={18} className={style.textHeader}>Study level</Text>
                </TableCell>
                <TableCell className={style.tableHeader}>
                  <Text fontSize={18} className={style.textHeader}>Team size</Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody  className={style.tableBody}>
              <TableRow className={style.tableBody}>
                <TableCell className={style.tableStatys}>
                <Text fontSize={18} className={style.flex}>
                <div className={item.active ? style.active : style.archive}></div>
                  <Text className={style.status} fontSize={18} >{item.active ? 'active' : 'archive'}</Text></Text>
                </TableCell>
                <TableCell className={style.tableeThesis}>
                  <Text fontSize={18} className={style.textBody}>{item.is_thesis_suitable ? 'yes' : 'no'}</Text>
                </TableCell>
                <TableCell className={style.texSupervisor}>
                  <Stack flexDirection='column'>
                    <Text fontSize={18} className={style.textBody}>{item.supervisor?.full_name}</Text>
                  </Stack>
                </TableCell>
                <TableCell className={style.tableDeadline}>
                  <Text fontSize={18} className={style.textBody}>
                    {item.deadline ? item.deadline?.slice(0,10) : <Infinity className={style.infinity}/>}
                  </Text>
                </TableCell>
                <TableCell className={style.tableLevel}>
                  <Text fontSize={18} className={style.textBody}>{item.study_levels.length ? item.study_levels.join(', ') : 'any'}</Text>
                </TableCell>
                <TableCell fontSize={18} className={style.tableSize}>
                  <Box
                    display='flex'
                    alignItems='flex-end'
                    flexDirection='row'
                  >
                    <Icon
                      data={userIcon}
                      marginRight={8}
                      size='m'
                      className={style.IconSize}
                    />
                    <Text className={style.textBody} fontSize={18}>{item.count_participants}</Text>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          display='flex'
          flexDirection='column'
          gap={3}
        >
          {tagsParams.map((param) =>  {
              if(param?.data?.length){
            return (
              <>
            <Stack
              key={param.title}
              display='flex'
              gap={1}
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent='flex-start'
              className={style.paramsFlexColumn}
            >
              <Text
                className={style.paramName}
                fontSize={18}
              >
                {param.title}
              </Text>
              <Stack
                flexDirection='row'
                gap={1}
                flexWrap='wrap'
                className={style.stackArea}
              >
                {param.data.map((field) => (
                  <Badge
                    appearance='secondary'
                    key={field}
                    size='m'
                    className={style.param}
                  >
                    {field}
                  </Badge>
                ))}
              </Stack>
            </Stack>
            </>
          )}
        }
          )}
        </Box>
        <Text
          fontWeight={500}
          fontSize={20}
          className={style.title}
        >
          Contacts
        </Text>
        <Box
          display='flex'
          flexDirection='column'
          gap={2}
        >
          {contactsParams.map((param) => {
              if(param.data){
            return (
              <>
              <Stack
              key={param?.data}
              display='flex'
              flexDirection='row'
              gap={1}
              className={style.paramsFlexColumn}
            >
              <Text
                color='#232323'
                fontSize={18}
                className={style.paramTitle}
              >
                {param?.title}:
              </Text>
              <Text
                is={(param?.title === 'telegram' || param?.title === 'email') ? 'a' : 'span'}
                fontSize={18}
                href={
                  param?.title === 'telegram'
                    ? `https://t.me/${param?.data}`
                    : `mailto:${param?.data}`
                }
                className={(param?.title === 'telegram' || param?.title === 'email') ? style.link : style.text}
              >
                {param?.data}
              </Text>
            </Stack>
            </>
          )}
        }
          )}
        </Box>
        {item?.attachment !== null && 
        <>
        <Text
          fontWeight={500}
          fontSize={20}
          className={style.title}
        >
          Attachment
        </Text>
          <div onClick={()=>fileRequest(item)} className={style.fileDiv}>
        <File className={style.icon}/>
        <p className={style.fileText}>Attached file</p>
        </div>
        </>}
      </Stack>
    );
  }),
);

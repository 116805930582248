import React, {useState, useEffect, useMemo} from 'react'
import styles from './HeaderSearch.module.scss';
import searchImg from '../../../images/24.svg';
import { useGetStudentsQuery } from '../../../redux/Api';
import { getCookie } from 'react-use-cookie';
import { useSelector } from 'react-redux';
import { useFilter } from '../../../hooks/useFilter';
import stud from '../../../images/stud.svg';
import instr from '../../../images/instr.svg';
import plus from '../../../images/pluseClose.svg';
import { NavLink } from 'react-router-dom';
import ModalOverlay from '../../common/ModalOverlay/ModalOverlay';
import { useMatchMedia } from '../../../hooks/useMatchMedia';
import { usePermission } from '../../../hooks/usePermission';

function HeaderSearch() {
    const [filter, setFilter] = useState('');
    const [selectorShow, setSelectorShow] = useState(false);
    const [getAccess, setGetAccess] = useState('');
    const permissions = useSelector((state) => state.token.role);
    const [dataFilter, setDataFilter] = useState({});
    const { isTablet } = useMatchMedia();
    let permit = usePermission(
      process.env.REACT_APP_PERMISSION_AD,
       process.env.REACT_APP_PERMISSION_PR,
       process.env.REACT_APP_PERMISSION_SV,
       process.env.REACT_APP_PERMISSION_STA)
    useEffect(() => {
        let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
        setGetAccess(getAccess);
      }, [permissions]);
 
    const {
        data = [],
      } = useGetStudentsQuery(getAccess, {
        skip: permit == false || !getCookie('access_token'),
      });
      useMemo(() => {
        if (data?.users) {
          setDataFilter(
            data.users.filter((item) =>
            (permissions.includes(process.env.REACT_APP_PERMISSION_PR) || permissions.includes(process.env.REACT_APP_PERMISSION_AD)) ? item : item.status == 'student',
            ),
          );
        }
      }, [data]);

    const result = useFilter(filter, dataFilter);
    useMemo(() => {
        if(filter.length > 2 && Array.isArray(result) && result.length > 0){
            setSelectorShow(true)
        }else{
            setSelectorShow(false)
            
        }
      }, [filter]);
      const click = ()=>{
        if(filter.length > 2 && Array.isArray(result) && result.length > 0){
            setSelectorShow(true)
        }else{
            setSelectorShow(false)
            
        }
      }
      const handleClick = () =>{
        setSelectorShow(false)
        setFilter('')
      }
  return (
    <div className={styles.searchArea}>
       {permit && 
       <>
          <input
          placeholder='Search'
          className={!selectorShow ? (filter.length > 2 && result.length === 0 ? styles.errorInput : (filter.length > 0 ? styles.activeInput : styles.Input)) : styles.activeInput}
          type='text'
          icon={searchImg}
          height={isTablet ? 40 : 50}
          onChange={(e)=>{setFilter(e.target.value)}}
          required
          value={filter}
          onClick={click}
        />
        <img src={searchImg} className={styles.searchImg} />
        {filter.length > 0 &&  <img src={plus} className={styles.plus} onClick={()=>{setFilter('')}}/>}
       {selectorShow && <div className={styles.emailsDiv}>
        {filter.length > 2 &&
            result.map((item) => {
              return (
                <div
                  key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                  className={styles.emailsFlex} onClick={()=>handleClick()}
                >
                         <NavLink
                            to={
                            item.status !== 'instructor'
                                ? `/student/${item.moodle_id}`
                                : `/instructor/${item.moodle_id}`
                            }
                            className={styles.emailsFlex}
                        >
                    {item.status == 'student' && (
                      <img
                        src={stud}
                        alt='SEARCH ICON'
                      />
                    )}
                    {item.status !== 'student' && (
                      <img
                        src={instr}
                        alt='SEARCH ICON'
                      />
                    )}
                    <p>{item.email_value}</p>
                  </NavLink>
                  
                </div>
              );
            })}
        </div>}
        {selectorShow && filter.length > 2 && <ModalOverlay setSelectorShow={setSelectorShow}/>}
        </>}
    </div>
  )
}

export default HeaderSearch
function Status({ item }) {
  switch (item) {
    case 'Находится в академическом отпуске':
      return 'academic leave';
    case 'Является аспирантом':
      return 'is a postgraduate';
    case 'Направлен в другой ВУЗ по обмену':
      return 'exchange students';
    case 'Является студентом':
      return 'is a student';
    case 'Закончил обучение':
      return 'finished studying';
    case 'Является выпускником':
      return 'is a graduate';
    case 'Отчислен':
      return 'expelled';
    default:
      return '-';
  }
}

export default Status;

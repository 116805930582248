import React, { useState, useEffect} from 'react';
import Projects from '../../components/StudentComponents/Projects/Projects';
import styles from './Collaboration.module.scss';
import { useSelector } from 'react-redux';
import Preloader from '../../components/stubs/Preloader/Preloader';
import PermissionPage from '../../components/stubs/commonStubs/PermitionPage/PermissionPage';
import { usePermission } from '../../hooks/usePermission';
const Collaboration = () => {
  const permissions = useSelector((state) => state.token.role);
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  let permit = usePermission(
    process.env.REACT_APP_PERMISSION_AD,
     process.env.REACT_APP_PERMISSION_PR,
     process.env.REACT_APP_PERMISSION_STU,
     process.env.REACT_APP_PERMISSION_STA)
  useEffect(()=>{
    setTimeout(() => {
      setIsOpenLazy(true);
    }, '300');
},[])
 
  return (
    <div className={styles.Collaboration}>
      {permissions && isOpenLazy
      ?<>
      {permit
      ? <Projects />
      :<PermissionPage/>}
      </>
      :<div className={styles.Preloader}>
        <Preloader/>
        </div>}
    </div>
  );
};

export default Collaboration;

import React from 'react';
import styles from './popupGenerateStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getDataGenerator, getError } from '../../../redux/generatorSlice';
import { useEffect, useState } from 'react';
import successPopup from './../../../images/successPopup.svg';
import wrongSyllabus from './../../../images/wrongSyllabus.svg';
import copy from './../../../images/copy.svg';

function SyllabusPopup({ popup, sesPopup }) {
  const dispatch = useDispatch();
  const [errorPopup, setErrorPopup] = useState(false);
  const link = useSelector((state) => state.generator.data);
  const [, setCopySuccess] = useState('');

  useEffect(() => {
    if (link.includes('https://eduwiki') ) {
      setErrorPopup(false);
    } else {
      setErrorPopup(true);
    }
  }, [link]);

  const btnOK = () => {
    sesPopup(false);
    dispatch(getDataGenerator(''));
    dispatch(getError(''));
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  return (
    <section
      className={popup ? styles.active : styles.hidden}
      onClick={btnOK}
    >
      <div className={styles.overlay}>
        {!errorPopup ? (
          <div
            className={popup ? styles.container : styles.hiddenContainer}
            id='successSyllabus'
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={styles.btnClose}
              type='button'
              onClick={btnOK}
            />
            <img
              src={successPopup}
              className={styles.img}
            />
            <h3 className={styles.title}>The page is successfully created!</h3>
            <div className={styles.block}>
              <div className={styles.divCopy}>
                <a
                  className={styles.text}
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                >
                  {link}
                </a>{' '}
                <img
                  src={copy}
                  onClick={() => copyToClipBoard(link)}
                  className={styles.imgCopy}
                />
              </div>
              <button
                className={styles.btnOk}
                type='button'
                onClick={btnOK}
              >
                done
              </button>
            </div>
          </div>
        ) : (
          <div
            className={popup ? styles.container : styles.hiddenContainer}
            id='errorSyllabus'
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={styles.btnClose}
              type='button'
              onClick={btnOK}
            />
            <img
              src={wrongSyllabus}
              className={styles.img}
            />
            <h3 className={styles.title}>{link.includes('post an error') ? link.slice(14) : 'Something went wrong...'}</h3>
            <div className={styles.block}>
              <p className={styles.text}>please try again or contact us on telegram </p>
              <a
                className={styles.text}
                href={`https://t.me/IU_DP_support`}
                target='_blank'
                rel='noreferrer'
              >
                https://t.me/IU_DP_support
              </a>
              <button
                className={styles.btnOk}
                type='button'
                onClick={btnOK}
              >
                try again
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
export default SyllabusPopup;

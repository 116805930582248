import styled from "@emotion/styled";

export const Wrapper = styled.div`
  position: relative;
`;

export const SearchInput = styled.input`
  padding: 19px 0 19px 52px;
  color: rgb(129, 129, 129);
  border-radius: 8px;
  border: 1px rgb(129, 129, 129) solid;
  font-size: 16px;
  width: 425px;
`;

export const SearchIcon = styled.img`
  left: 23px;
  position: absolute;
  top: 18px;
`;

export const projectsEndpoints = (build) => ({
  getStudentProjects: build.query({
    query: (tokenPlusData) => ({
      url: `/api/collab/project/list/`,
     params: tokenPlusData?.filterProperty,
      // params: {
      //   skills: queryData?.skills,
      //   subject_areas: queryData?.subjectAreas,
      //   study_levels: queryData?.study_levels,
      //   supervisor: queryData?.supervisor,
      //   active: queryData?.active,
      //   is_thesis_suitable: queryData?.is_thesis_suitable,
      //   search: queryData?.search,
      // },
      methods: 'GET',
      headers: tokenPlusData.getAccess,
    }),
    providesTags:  ['Project'],
}),
  getProjectsSkillFilterData: build.query({
    query: (getAccess) => ({
      url: `/api/collab/collection/skill/`,
      methods: 'GET',
      headers: getAccess,
    }),
  }),
  getProjectsStudyLevelFilterData: build.query({
    query: (getAccess) => ({
      url: `/api/collab/collection/study_level/`,
      methods: 'GET',
      headers: getAccess,
    }),
  }),
  getProjectsSubjectAreaFilterData: build.query({
    query: (getAccess) => ({
      url: `/api/collab/collection/subject_area/`,
      methods: 'GET',
      headers: getAccess,
    }),
  }),
  getProjectsSupervisorFilterData: build.query({
    query: (getAccess) => ({
      url: `/api/collab/collection/supervisor/`,
      methods: 'GET',
      headers: getAccess,
    }),
  }),

  applyForTheProject: build.query({
    query: (payload) => ({
      // url: `/collab/api/v1/submission/create/`,
      url: `/api/collab/project/create/`,
      body: payload,
      method: 'POST',
    }),
  }),

  getProject: build.query({
    query: (tokenPlusData) => ({
      url: `/api/collab/project/${tokenPlusData.projectId}/`,
      method: 'GET',
      headers: tokenPlusData.getAccess,
    }),
    providesTags:  ['Project'],
  }),

  createProject: build.mutation({
    query: (formData) => ({
      url: '/api/collab/project/create/',
      method: 'POST',
      body: formData.formData,
      headers: formData.getAccess,
    }),
    invalidatesTags: ['Project'],
  }),

  updateProject: build.mutation({
    query: (data) => {
      return {
        url: `/api/collab/project/${data.id}/update/`,
        method: 'PATCH',
        headers: data.getAccess,
        body: data?.storage ? data?.storage : data?.formData,
      };
    },
    invalidatesTags: ['Project'],
  }),


  archiveProject: build.mutation({
    query: (data) => ({
      url: `/api/collab/project/${data.projectId}/update/`,
      method: 'PATCH',
      body: {
        active: data.projectData.active ? false : true,
      },
      headers: data.getAccess,
    }),
    invalidatesTags: ['Project'],
  }),

  deleteProject: build.mutation({
    query: (tokenPlusData) => ({
      url: `/api/collab/project/${tokenPlusData.projectId}/`,
      method: 'DELETE',
      headers: tokenPlusData.getAccess,
    }),
    invalidatesTags: ['Project'],
  }),
  getResponses: build.query({
    query: (getAccess) => ({
      url: `/api/collab/project/responses/`,
      methods: 'GET',
      headers: getAccess,
    }),
    providesTags:  ['Responses'],
  }),
  createResponses: build.mutation({
    query: (data) => {
      return {
        url: `/api/collab/project/create_response/`,
        method: 'POST',
        headers: data.getAccess,
        body: data.id,
      };
    },
    invalidatesTags: ['Responses'],
  }),
});

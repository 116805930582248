import axios from 'axios';
function TelegramErrorMessage(error, params, request, email, role) {
    if(error){
        if (process.env.REACT_APP_PROD == 'true') {
            axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT_FRONT}&text= PROD request-  ${request}  || ${JSON.stringify(error)}, ||  params-  ${JSON.stringify(params)} ||  email-  ${email} ||  role-  ${role}`)
        }else{
            axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT_FRONT}&text= DEV request-  ${request}  || ${JSON.stringify(error)}, ||  params-  ${JSON.stringify(params)} ||  email-  ${email} ||  role-  ${role}`)
        }
    }
}

export default TelegramErrorMessage;
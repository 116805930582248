import React, { useState, useEffect, useMemo } from 'react';
import styles from './Instructor.module.scss';
import { getCookie } from 'react-use-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TitleComponent } from '../../components/helpers/TitleComponent/TitleComponent';
import Preloader from '../../components/stubs/Preloader/Preloader';
import PermissionPage from '../../components/stubs/commonStubs/PermitionPage/PermissionPage';
import informationImg from '../../images/instractor/information.svg';
import notesImg from '../../images/instractor/notes.svg';
import backgroundImg from '../../images/instractor/background.svg';
import coursesImg from '../../images/instractor/courses.svg';
import projectsImg from '../../images/projects.svg';
import Information from '../../components/InstractorComponents/Information/Information';
import Background from '../../components/InstractorComponents/Background/Background';
import Publications from '../../components/InstractorComponents/Publications/Publications';
import Courses from '../../components/InstractorComponents/Courses/Courses';
import Projects from '../../components/InstractorComponents/Projects/Projects';
import Project from '../../components/InstractorComponents/Project/Project';
import Notes from '../../components/InstractorComponents/Notes/Notes';
import ErrorGlobal from '../../components/stubs/commonStubs/ErrorGlobal/ErrorGlobal';
import { NavLink } from 'react-router-dom';
import { useGetMoodleInstructorQuery, useGetCourseInstructorQuery, useGetEditableInstructorQuery } from '../../redux';
import InstructorError from '../../components/stubs/commonStubs/InstructerError/InstructerError';
import { ReactComponent as Logo } from '../../images/instractor/Edits.svg';
import Editor from '../../components/InstractorComponents/Notes/Editor/Editor';
import { getStudentFoto } from '../../redux/studentSlise';
import TelegramErrorMessage from '../../components/stubs/commonStubs/TelegramErrorMessage/TelegramErrorMessage';
import { usePermission } from '../../hooks/usePermission';
function Instructor({ setNavBurger }) {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.token.role);
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  const [newArr, setnewArr] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isLazy, setIsLazy] = useState(false);
  const [contactData, setContactData] = useState('')
  let location = useLocation();
  const email = useSelector((state) => state.token.email);
  const foto = useSelector((state) => state.students.fotoInstructor);
  let idLocation = location.pathname.split('').slice(12).join('').split('/')[0];
  let permit = usePermission(process.env.REACT_APP_PERMISSION_AD, process.env.REACT_APP_PERMISSION_PR)
  let projectId = location.pathname.includes('projects/')
    ? location.pathname.split('projects/')?.[1]
    : '';
  let pathLocation = location.pathname.split('').slice(12).join('');
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  let moodleId = Number(idLocation);
  let history = useHistory();
  let tokenPlusId = { getAccess, moodleId };
  useMemo(()=>{
    setIsOpenLazy(false);
},[moodleId])

  useEffect(() => {
    if (pathLocation == `${idLocation}`) {
      history.push(`${idLocation}/information`);
    }
  }, []);
  useEffect(()=>{
    setTimeout(() => {
      setIsLazy(true);
    }, '1500');
  },[])
  const {
    data = [],
    error,
    isLoading,
  } = useGetMoodleInstructorQuery(tokenPlusId, {
    skip: permit == false,
  });
  const {
    data: courseData = [],
    error: courseError,
    isLoading: courseLoading,
  } = useGetCourseInstructorQuery(tokenPlusId, {
    skip: permit == false,
  });
  TitleComponent(`DP - instructor/${pathLocation}`);
  setNavBurger('instructor');
  let access_token = getCookie('access_token');

  useEffect(() => {
    if (!error && !courseError && !courseLoading && courseData && courseData.length !== 0) {
      const newArr = courseData.courses.reduce((accum, item) => {
        return accum.includes(item.role_name) || item.role_name == '' || item.role_name == 'Student'
          ? accum
          : [...accum, item];
      }, []);
      setnewArr(newArr);
    }
  }, [courseData]);
  let tokenPlusEmail = { getAccess, email: data?.email };
  useMemo(() => {
    if (tokenPlusEmail && data?.email && data.moodle_id !== null) {
      const email = data?.email;
      const access = getCookie('access_token');
      let tokenPlusemail = { email, access,  url: "instructor" };
      dispatch(getStudentFoto(tokenPlusemail));
    }
  
  }, [data]);

  const { data: dataEdit = [], isError, isSuccess } = useGetEditableInstructorQuery(tokenPlusId, {
    skip: permit == false && Object.keys(data).length !== 0,
  });

  useMemo(()=>{
    if(Object.keys(data).length !== 0 && (isSuccess || isError)){
      setContactData({
        telegram: data?.telegram,
        email: data?.email,
        office: dataEdit?.office,
        open_hours: dataEdit?.open_hours,
      })
    }
},[isError,isSuccess, data, projectId ])

  useMemo(()=>{
    setTimeout(() => {
      setIsOpenLazy(true);
    }, '1300');
},[foto])

useMemo(()=>{
  if(error){
    const request = '/api/personal_accounts/instructor_account_info?moodle_user_id'
    TelegramErrorMessage(error, tokenPlusId, request, email, permissions)
  }
  if(courseError){
  
    const request = `api/moodle_users/instructor_courses_with_feedbacks?moodle_user_id=${tokenPlusId.moodleId}`
    TelegramErrorMessage(courseError, tokenPlusId, request, email, permissions)
  }
  },[error, courseError])
  return (
    <div className={styles.instructor}>
      {access_token == '' ? (
        <div className={styles.ErrorGlobal}>{isOpenLazy && <ErrorGlobal />}</div>
      ) : (
        <div>
          {permissions.includes(process.env.REACT_APP_PERMISSION_PR) || permissions.includes(process.env.REACT_APP_PERMISSION_AD) || data?.email == email ? (
            <div className={styles.instructor}>
              {error ? (
               isLazy && <InstructorError />
              ) : (
                <div>
                  {isOpenLazy && data && !courseLoading && !isLoading ? (
                    <div className={styles.instructor}>
                      <div className={styles.navBlock}>
                        <nav className={styles.nav}>
                          <NavLink
                            to={`/instructor/${idLocation}/information`}
                            className={styles.link}
                          >
                            <button
                              className={
                                pathLocation !== `${idLocation}/information`
                                  ? styles.button
                                  : styles.buttonActive
                              }
                            >
                              <img
                                src={informationImg}
                                className={
                                  pathLocation !== `${idLocation}/information`
                                    ? styles.img
                                    : styles.imgActive
                                }
                                alt='icon'
                              />
                              <p
                                className={
                                  pathLocation == `${idLocation}/information` && styles.pActive
                                }
                              >
                                information
                              </p>
                            </button>
                          </NavLink>
                          {(data?.email == email || permissions.includes(process.env.REACT_APP_PERMISSION_AD)) &&  <NavLink
                            to={`/instructor/${idLocation}/background`}
                            className={styles.link}
                          >
                            <button
                              className={
                                pathLocation !== `${idLocation}/background`
                                  ? styles.button
                                  : styles.buttonActive
                              }
                            >
                              <img
                                src={backgroundImg}
                                className={
                                  pathLocation !== `${idLocation}/background`
                                    ? styles.img
                                    : styles.imgActive
                                }
                                alt='icon'
                              />
                              <p
                                className={
                                  pathLocation == `${idLocation}/background` && styles.pActive
                                }
                              >
                                background
                              </p>
                            </button>
                          </NavLink>}
                          {(data?.email == email || permissions.includes(process.env.REACT_APP_PERMISSION_AD)) &&  <NavLink
                            to={`/instructor/${idLocation}/courses`}
                            className={styles.link}
                          >
                            <button
                              className={
                                pathLocation !== `${idLocation}/courses`
                                  ? styles.button
                                  : styles.buttonActive
                              }
                            >
                              <img
                                src={coursesImg}
                                className={
                                  pathLocation !== `${idLocation}/courses`
                                    ? styles.img
                                    : styles.imgActive
                                }
                                alt='icon'
                              />
                              <p
                                className={
                                  pathLocation == `${idLocation}/courses` && styles.pActive
                                }
                              >
                                courses
                              </p>
                            </button>
                          </NavLink>}
                         {((email == 'k.anikeev@innopolis.ru' || email == 'z.aizatova@innopolis.ru') || data?.email == email )&& <NavLink
                            to={`/instructor/${idLocation}/projects`}
                            className={styles.link}
                          >
                            <button
                              className={
                                pathLocation !== `${idLocation}/projects`
                                  ? styles.button
                                  : styles.buttonActive
                              }
                            >
                              <img
                                src={projectsImg}
                                className={
                                  pathLocation !== `${idLocation}/projects`
                                    ? styles.img
                                    : styles.imgActive
                                }
                                alt='icon'
                              />
                              <p
                                className={
                                  pathLocation == `${idLocation}/projects` && styles.pActive
                                }
                              >
                                projects
                              </p>
                            </button>
                          </NavLink>}
                          {permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
                            <NavLink
                              to={`/instructor/${idLocation}/notes`}
                              className={styles.link}
                            >
                              <button
                                className={
                                  pathLocation !== `${idLocation}/notes`
                                    ? styles.buttonNotes
                                    : styles.buttonActive
                                }
                              >
                                <img
                                  src={notesImg}
                                  className={
                                    pathLocation !== `${idLocation}/notes`
                                      ? styles.img
                                      : styles.imgActive
                                  }
                                  alt='icon'
                                />
                                <p
                                  className={
                                    pathLocation == `${idLocation}/notes` && styles.pActiveNotes
                                  }
                                >
                                  notes
                                </p>
                              </button>
                            </NavLink>
                          )}
                          {permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
                            <Logo  className={styles.notessvg} onClick={() => setShowPopup(true)} />
                          )}
                        </nav>
                        <div className={styles.navButtom}></div>
                      </div>

                      <section className={styles.main}>
                        {((data && pathLocation == `${idLocation}/information`) ||
                          (data && pathLocation == `${idLocation}`) ||
                          (data && pathLocation == `${idLocation}/`)) && (
                          <Information
                            data={data}
                            isLoading={isLoading}
                            email={email}
                            tokenPlusId={tokenPlusId}
                            dataEdit={dataEdit}
                            isError={isError}
                          />
                        )}
                        {(data?.email == email || permissions.includes(process.env.REACT_APP_PERMISSION_AD)) && data && pathLocation == `${idLocation}/background` && (
                          <Background
                            data={data}
                            isLoading={isLoading}
                          />
                        )}
                       {(data?.email == email || permissions.includes(process.env.REACT_APP_PERMISSION_AD)) && data && pathLocation == `${idLocation}/publications` && (
                          <Publications
                            data={data}
                            isLoading={isLoading}
                          />
                        )}
                      {(data?.email == email || permissions.includes(process.env.REACT_APP_PERMISSION_AD)) && data && pathLocation == `${idLocation}/courses` && !courseLoading && (
                          <Courses
                            courseData={newArr}
                            courseLoading={courseLoading}
                            moodleId={moodleId}
                          />
                        )}
                        {((email == 'k.anikeev@innopolis.ru' || email == 'z.aizatova@innopolis.ru') || data?.email == email && data) && pathLocation == `${idLocation}/projects` && (
                          <Projects
                            data={data}
                            tokenPlusId={tokenPlusId}
                            contactData={contactData}
                            getAccess={getAccess}
                            moodleId={moodleId}
                          />
                        )}
                        {((email == 'k.anikeev@innopolis.ru' || email == 'z.aizatova@innopolis.ru') || data?.email == email && data) && pathLocation == `${idLocation}/projects/${projectId}` && (
                          <Project
                            data={data}
                            isLoading={isLoading}
                            moodleId={moodleId}
                            projectId={projectId}
                            getAccess={getAccess}
                          />
                        )}
                        {data &&
                          pathLocation == `${idLocation}/notes` &&
                          permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
                            <Notes
                              dataNotes={data?.email}
                              isLoading={isLoading}
                              position='instructor'
                            />
                          )}
                      </section>
                    </div>
                  ) : (
                    <div>
                      {!isOpenLazy || isLoading || courseLoading ? (
                        <div className={styles.Preloader}>
                          <Preloader />
                        </div>
                      ) : (
                        isLazy && isOpenLazy &&  <InstructorError />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : isOpenLazy && permissions ? (
            <div className={styles.permissions}>
              <PermissionPage />
            </div>
          ) : (
            <div className={styles.Preloader}>
              <Preloader />
            </div>
          )}
        </div>
      )}
      {showPopup && permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
        <Editor
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          indicatePopap={'add'}
          tokenPlusId={tokenPlusEmail}
          position='instructor'
        />
      )}
    </div>
  );
}

export default Instructor;

import { getAllCourses } from '@chat-bot/entities/API-service';
import { LoadCoursesViaCSV, SearchCourses } from '@chat-bot/features';
import { Global } from '@emotion/react';
import { fonts } from '@shared/assets/fonts.styles';
import { globalStyles } from '@shared/assets/global.styes';
import { Loader } from '@shared/ui';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { CourseLink, CourseList, Title, Wrapper } from './CourseSelecting.styles';
import { AllCoursesState} from './model';
import { FilteredCourses } from '@chat-bot/features/SearchCourses/model';


export const CourseSelecting = () => {
  const [courses, setCourses] = useRecoilState(AllCoursesState);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredCourses , setFilteredCourses] = useRecoilState(FilteredCourses)

  useEffect(() => {
    const fetchAllCourses = async () => {
      setIsLoading(true)
      let response = await getAllCourses();
      setCourses(response);
      setFilteredCourses(response)
      setIsLoading(false)
    };

    if (!courses) {  
      fetchAllCourses();
    }
  }, [courses, setCourses, setFilteredCourses]);

  return (
    <Wrapper>
      <Global styles={[globalStyles, fonts]} />
      <Title>Select the course</Title>
      <LoadCoursesViaCSV />
      <SearchCourses />
      {isLoading ? <Loader /> : <CourseList>
        {filteredCourses && filteredCourses?.map((course) => (
          <CourseLink
            to={{ pathname: `/chatBot/course-info/${course.id}`, state: { courseName: course.full_name } }}
            key={course.id}
          >
            {course.full_name}
          </CourseLink>
        ))}
      </CourseList>}
    </Wrapper>
  );
};

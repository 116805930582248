import React from 'react';
import styles from '../Grades.module.css';
function GradeColor({ item }) {
  return (
    <p
      className={`${item.length > 1 && styles.gradeMin} ${
      item == 'A' ||
      item == 'a' ||
      item == 'А' ||
      item.includes('B') ||
      item.includes('A (') ||
      item.includes('P') ||
      item.includes('Pass') ||
      item.includes('PASS')
        ? styles.gradeA
        : item.includes('C') || item.includes('С') || item.includes('с') || item.includes('c')
        ? styles.gradeC
        : item == 'SEE FINAL LETTER'
        ? styles.gradeAny
        : item.includes('D') ||
          item.includes('Fail') ||
          item.includes('FAIL') ||
          item.includes('F') ||
          item.includes('Failed') ||
          item.includes('Неявка') ||
          item.includes('FAILED')
        ? styles.gradeD
        : styles.gradeAny}`
      }
    >
    {item.includes('Неявка') ? "absence"  : item}
    </p>
  );
}

export default GradeColor;

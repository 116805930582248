import { Chat } from '@chat-bot/entities/API-service';
import { CreatedChat } from '@chat-bot/entities/Chat';
import { FC, useMemo } from 'react';
import { Header, ListOfChats, Wrapper } from './CreatedChatsAndChannels.styles';
import { DeleteChat, EditChat } from '@chat-bot/features';
import Button from '@atlaskit/button/';

export const CreatedChatsAndChannels: FC<{
  chatsAndChannels: Chat[];
  reloadChatsAndChannels: () => void;
}> = ({ chatsAndChannels, reloadChatsAndChannels }) => {
  const channels = useMemo(
    () => chatsAndChannels.filter((item) => item.type === 'Channel'),
    [chatsAndChannels],
  );
  const studentsAndTAChats = useMemo(
    () => chatsAndChannels.filter((item) => item.type === 'Students' || item.type === 'TA'),
    [chatsAndChannels],
  );

  const getActionButtons = (chat: Chat) => (
    <>
      <EditChat
        chat={chat}
        onAfterEdit={reloadChatsAndChannels}
      />
      <DeleteChat
        chat={chat}
        onAfterDeletion={reloadChatsAndChannels}
      />
    </>
  );

  return (
    <Wrapper>
      <Button
        appearance='primary'
        onClick={reloadChatsAndChannels}
        style={{ marginBottom: '10px', width: 'fit-content' }}
      >
        Reload chat and channels info
      </Button>
      {studentsAndTAChats.length !== 0 && (
        <div>
          <Header>Chats</Header>
          <ListOfChats>
            {studentsAndTAChats.map((item) => (
              <CreatedChat
                chat={item}
                actionButtons={getActionButtons(item)}
              />
            ))}
          </ListOfChats>
        </div>
      )}

      {channels.length !== 0 && (
        <div>
          <Header>Channels</Header>
          <ListOfChats>
            {channels.map((item) => (
              <CreatedChat
                chat={item}
                actionButtons={getActionButtons(item)}
              />
            ))}
          </ListOfChats>
        </div>
      )}
    </Wrapper>
  );
};

import React, { useMemo, useState, memo } from 'react';
import styles from './FeedbackAnswersStaff.module.scss';
import { useGetFeedbacksByResponsesRelatedToStuffQuery } from '../../../../../redux/Api';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedback, getLoadingData } from '../../../../../redux/instructorSlice';

// eslint-disable-next-line react/display-name
const FeedbackAnswersStaff = memo(function ({
  getAccess,
  item,
  role_id,
  instructorId,
  filterName,
  newData,
}) {
  const dispatch = useDispatch();
  const [tokenPlusId, setTokenPlusId] = useState('');
  const [data, setData] = useState('');
  const [resultStronglyAgree, setResultStronglyAgree] = useState('');
  const [resultAgree, setResultAgree] = useState('');
  const [resultNeutral, setResultNeutral] = useState('');
  const [resultDisagree, setResultDisagree] = useState('');
  const [resultStronglyDisagree, setResultStronglyDisagreee] = useState('');
  useMemo(() => {
    if (item && instructorId && filterName && getAccess) {
      let feedbackId = item.feedback;
      let question_id = filterName;
      let roles_ids = role_id;
      let staff_id = instructorId;
      let item_id = item.moodle_feedback_item_id;
      let tokenPlusId = {
        getAccess,
        feedbackId,
        question_id,
        roles_ids,
        staff_id,
        item_id,
      };
      setTokenPlusId(tokenPlusId);
    }
  }, [instructorId]);
  const nameTA = useSelector((state) => state.instructor.name);

  const { data: dataAnswers = [], isLoading } = useGetFeedbacksByResponsesRelatedToStuffQuery(
    tokenPlusId,
    {
      skip: !tokenPlusId,
    },
  );

  useMemo(() => {
    if (dataAnswers.length > 0) {
      setData(dataAnswers);
    }
  }, [dataAnswers]);

  useMemo(() => {
    if (newData && item) {
      let itemData = newData.filter((e) => {
        return e.item == item.moodle_feedback_item_id;
      });
      setData(itemData);
    }
  }, [item]);

  useMemo(() => {
    if (data.length > 0) {
      let stronglyAgree = data.reduce((accum, item) => {
        return item.printed_value === 'strongly agree' ? accum + 1 : accum;
      }, 0);
      let agree = data.reduce((accum, item) => {
        return item.printed_value === 'agree' ? accum + 1 : accum;
      }, 0);
      let neutral = data.reduce((accum, item) => {
        return item.printed_value === 'neutral' ? accum + 1 : accum;
      }, 0);
      let disagree = data.reduce((accum, item) => {
        return item.printed_value === 'disagree' ? accum + 1 : accum;
      }, 0);
      let stronglyDisagree = data.reduce((accum, item) => {
        return item.printed_value === 'strongly disagree' ? accum + 1 : accum;
      }, 0);

      let resultStronglyAgree = ((100 * stronglyAgree) / data.length).toFixed(1);
      let resultAgree = ((100 * agree) / data.length).toFixed(1);
      let resultNeutral = ((100 * neutral) / data.length).toFixed(1);
      let resultDisagree = ((100 * disagree) / data.length).toFixed(1);
      let resultStronglyDisagree = ((100 * stronglyDisagree) / data.length).toFixed(1);

      setResultStronglyAgree(resultStronglyAgree);
      setResultAgree(resultAgree);
      setResultNeutral(resultNeutral);
      setResultDisagree(resultDisagree);
      setResultStronglyDisagreee(resultStronglyDisagree);
    }
  }, [data]);

  useMemo(() => {
    if (nameTA && data.length > 0) {
      let nameTACaunt = data.reduce((accum, item) => {
        return item.printed_value === nameTA ? accum + 1 : accum;
      }, 0);
      if (nameTACaunt > 90 && data.length > 0) {
        dispatch(getFeedback(nameTACaunt));
      }
    }
  }, [nameTA]);
  useMemo(() => {
    if (!isLoading) {
      dispatch(getLoadingData(true));
    } else {
      dispatch(getLoadingData(false));
    }
  }, [isLoading]);

  return (
    <div className={styles.FeedbackAnswers}>
      {resultStronglyAgree && resultStronglyAgree !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.greenM}
            style={{ width: resultStronglyAgree * 1.8 }}
          ></div>
          <p className={styles.textM}>{resultStronglyAgree}%</p>
        </div>
      )}
      {resultAgree && resultAgree !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.green2M}
            style={{ width: resultAgree * 1.8 }}
          ></div>
          <p className={styles.textM}>{resultAgree}%</p>
        </div>
      )}
      {resultNeutral && resultNeutral !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.yellowM}
            style={{ width: 1.8 * resultNeutral }}
          ></div>
          <p className={styles.textM}>{resultNeutral}%</p>
        </div>
      )}
      {resultDisagree && resultDisagree !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.orangeM}
            style={{ width: 1.8 * resultDisagree }}
          ></div>
          <p className={styles.textM}>{resultDisagree}%</p>
        </div>
      )}
      {resultStronglyDisagree && resultStronglyDisagree !== '0.0' && (
        <div className={styles.flexM}>
          <div
            className={styles.redM}
            style={{ width: 1.8 * resultStronglyDisagree }}
          ></div>
          <p className={styles.textM}>{resultStronglyDisagree}%</p>
        </div>
      )}
    </div>
  );
});

export default FeedbackAnswersStaff;

import React, {useMemo}  from 'react';
import styles from './StudentIdError.module.css';
import { NavLink } from 'react-router-dom';
import { TitleComponent } from '../../../helpers/TitleComponent/TitleComponent';
import studentidError from '../../../../images/studentidError.svg';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function StudentIdError() {
  TitleComponent('Student ID Error');
  let location = useLocation();
  const role = useSelector((state) => state.token.role);
  const email = useSelector((state) => state.token.email);

  useMemo(()=>{
    if(email && email !== 'k.anikeev@innopolis.ru'){
    if (process.env.REACT_APP_PROD == 'true') {
    axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT}&text=NEW Error PROD - check student data -  The student does not exist   ${location.pathname}    - ${email}  - ${role}`)
    }else{
      axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT}&text=NEW Error DEV - check student data -  The student does not exist   ${location.pathname}    - ${email}  - ${role}`)
    }
  }
    },[email])


  return (
    <div className={styles.mainPage}>
      <img
        className={styles.img}
        src={studentidError}
      />

      <h3 className={styles.title}>The student does not exist</h3>
      <p className={styles.secondTitle}>Check the link and try again</p>
      <NavLink to='/profile_search'>
        {' '}
        <button className={styles.button}>
          <p className={styles.buttonText}>main page</p>
        </button>
      </NavLink>
    </div>
  );
}

export default StudentIdError;

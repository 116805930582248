import React, {useMemo}  from 'react';
import styles from './PermissionPage.module.css';
import { TitleComponent } from '../../../helpers/TitleComponent/TitleComponent';
import plane from '../../../../images/plane.svg';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PermissionPage() {
  TitleComponent('Permission Page');
  let location = useLocation();
  const role = useSelector((state) => state.token.role);
  const email = useSelector((state) => state.token.email);

  useMemo(()=>{
    if(email && email !== "k.anikeev@innopolis.ru"){
      if (process.env.REACT_APP_PROD == 'true') {
        axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT}&text=NEW Error PROD - don’t have the access rights  ${location.pathname}    - ${email}  - ${role}`)
        }else{
        axios.get(`https://api.telegram.org/${process.env.REACT_APP_BOT}&text=NEW Error DEV - don’t have the access rights   ${location.pathname}    - ${email}  - ${role}`)
        }
    }
    },[email])

  return (
    <div className={styles.mainPage}>
      <img
        className={styles.img}
        src={plane}
      />

      <h3 className={styles.title}>You don’t have the access rights to view this page</h3>
      <p className={styles.secondTitle}>
        Please contact our support team if you have any questions:
      </p>
      <a
        href={`https://t.me/IU_DP_support`}
        className={styles.contactValue}
        target='_blank'
        rel='noreferrer'
      >
        t.me/DP_support
      </a>
    </div>
  );
}

export default PermissionPage;

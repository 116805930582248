import React from 'react';
import { Switch } from 'react-router';
// import StudentProfileSearch from '../../../Pages/StudentProfileSearch/StudentProfileSearch';
import Generators from '../../../Pages/Generator/Generators';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import Student from '../../../Pages/Student/Student';
import Page404 from '../../stubs/commonStubs/Page404/Page404';
import Instructor from '../../../Pages/Instructor/Instructor';
import Collaboration from '../../../Pages/Collaboration/Collaboration';
import CourseFeedback from '../../../Pages/CourseFeedback/CourseFeedback';
import TAEvaluation from '../../../Pages/TAEvaluation/TAEvaluation';
import InstructorPositions from '../../../Pages/InstructorPositions/InstructorPositions';
import NewSwrchPage from '../../../Pages/StudentProfileSearch/NewSwrchPage/NewSwrchPage';
import { ChatBotRoutes } from '../../../chat-bot/src/app/App';

function Route({ logInEnter, setNavBurger, navBurger }) {
  return (
    <Switch>
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/generate'
        component={Generators}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/generate/rpd'
        component={Generators}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/generate/syllabus'
        component={Generators}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/generate/download_syllabus'
        component={Generators}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        navBurger={navBurger}
        exact
        path='/profile_search'
        component={NewSwrchPage}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        navBurger={navBurger}
        exact
        path='/projects'
        component={Collaboration}
      />

      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id'
        component={Student}
      />

      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/information'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/grades'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/predicted'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/interests'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/student/:id/github'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/student/:id/notes'
        component={Student}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id/information'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id/background'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id/courses'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/instructor/:id/projects'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        path='/instructor/:id/projects/:projectId'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor/:id/notes'
        component={Instructor}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/course_feedback/:id'
        component={CourseFeedback}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/ta_evaluation/:id'
        component={TAEvaluation}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        exact
        path='/'
        component={NewSwrchPage}
      />
      <ProtectedRoute
        logInEnter={logInEnter}
        setNavBurger={setNavBurger}
        exact
        path='/instructor_positions_table'
        component={InstructorPositions}
      />
      {!process.env.REACT_APP_PROD && <ChatBotRoutes />}

      <ProtectedRoute
        logInEnter={logInEnter}
        path='*'
        component={Page404}
      />
    </Switch>
  );
}

export default Route;

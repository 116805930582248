import React, { useState, useEffect } from 'react';
import styles from './Courses.module.scss';
import Preloader from '../../stubs/Preloader/Preloader';
import searchImg from '../../../images/24.svg';
import chevron from '../../../images/25.svg';
import CoursesData from './CoursesData';

function Courses({ courseData, courseLoading, moodleId }) {
  const [filterSearch, setFilterSearch] = useState('');
  const [showRoles, setShowRoles] = useState(false);
  const [wordRoles, setWordRoles] = useState('All roles');
  const [newFilterArr, setNewFilterArr] = useState(courseData);
  const [roles, setRoles] = useState([]);
  const [filterRoles, setFilterRoles] = useState('All roles');
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  setTimeout(() => {
    setIsOpenLazy(true);
  }, '1000');
  useEffect(() => {
    window.scrollBy(0, -3000);
  }, []);
  useEffect(() => {
    if (!courseLoading && courseData && courseData.length > 0) {
      const roles = courseData.reduce((accum, item) => {
        return accum.includes(item.role_name) || item.role_name == '' || item.role_name == 'Student'
          ? accum
          : [...accum, item.role_name];
      }, []);
      setRoles(roles);
    } else {
      setRoles([]);
    }
  }, [courseData]);

  useEffect(() => {
    setNewFilterArr(
      courseData.filter(
        (item) =>
          (filterRoles === 'All roles' ? item : item.role_name == filterRoles) &&
          item.course_name.includes(filterSearch),
      ),
    );
  }, [filterSearch, filterRoles, isOpenLazy]);

  const handlerRoles = (item) => {
    setFilterRoles(item);
    if (item == 'All roles') {
      setWordRoles('All roles');
    } else {
      setWordRoles(item);
    }
  };

  return (
    <div className={styles.Courses}>
      {!isOpenLazy ? (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      ) : (
        <div>
          <h4 className={styles.title}>Taught courses</h4>

          <div className={styles.filterArea}>
            <img
              src={searchImg}
              className={styles.searchImg}
              alt='searchImg'
            />
            <input
              type='text'
              className={styles.input}
              onChange={(e) => setFilterSearch(e.target.value)}
              value={filterSearch}
              placeholder='Search by courses'
            />
            <div
              className={styles.filterRolesDiv}
              onClick={() => setShowRoles(!showRoles)}
            >
              <p className={styles.filterRoles}>{wordRoles}</p>
              <img
                src={chevron}
                alt='chevron'
                className={styles.imgSelect}
              />

              {showRoles && roles && (
                <div className={styles.optionRolesDiv}>
                  <div
                    className={styles.optionRow}
                    onClick={() => handlerRoles('All roles')}
                  >
                    <p className={styles.optionRoles}>All Roles</p>
                  </div>
                  {roles &&
                    roles.map((item) => {
                      return (
                        <div
                          key={Math.random()}
                          className={styles.optionRow}
                          onClick={() => handlerRoles(item)}
                        >
                          <p className={styles.optionRoles}>{item}</p>
                        </div>
                      );
                    })}
                  {showRoles && (
                    <div
                      className={styles.optionRolessOver}
                      onClick={() => setShowRoles(!showRoles)}
                    ></div>
                  )}
                </div>
              )}
            </div>
          </div>

          {!courseLoading && (
            <CoursesData
              moodleId={moodleId}
              newFilterArr={newFilterArr}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Courses;

import React, { useEffect, useRef, useCallback } from 'react';
import { motion, useAnimation } from 'framer-motion';
import plusClose from '../../../images/plusClose.svg';
import styles from './SideCard.module.scss';
import Header from './Header/Header';
import { useBodyDesabler } from '../../../hooks/useBodyDesabler';

/**
 * dummy component of SideCard
 * @param { string } className - Class name passed to the component
 * @param { bool } isOpen - When True, shows SideCard
 * @param { function } onClose - Callback on when SideCard closes
 * @param { string } header - Title of SideCard
 * @param { ReactNode } children - Element to display inside SideCard
 */
export const SideCard = ({ className, isOpen, onClose, children, header }) => {
  const contentRef = useRef(null);
  const controls = useAnimation();
  const bodyDisabler = useBodyDesabler(isOpen, onClose);

  useEffect(() => {
    if (!isOpen) {
      controls.start('hidden');
      if (contentRef.current) {
        contentRef.current.scrollTo(0, 0);
      }
      return;
    }
    controls.start('visible');
  }, [controls, isOpen]);


  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      onClose()
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction])



  return (
    <>
      {bodyDisabler}
      <motion.div
        animate={controls}
        initial='hidden'
        className={[className, styles.SideCard].join(' ')}
        transition={{
          type: 'spring',
          damping: 60,
          stiffness: 600,
        }}
        variants={{
          visible: { right: 0 },
          hidden: { right: '-200vw' },
        }}
      >
          <img
          src={plusClose}
          className={styles.img}
          onClick={() => onClose()}
        />
        {header && <Header text={header} />}
        {children}
      </motion.div>
    </>
  );
};

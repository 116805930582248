import { Chat, createChat, CreateChatDetails } from '@chat-bot/entities/API-service';
import { TemplateFeatures } from '../config';

export const getChatTemplates = (
  course_id: string,
  title: string,
  setChats: (chatsState: { chats: Chat[]; isLoading?: boolean }) => void,
  setAreChatsLoading: () => void,
) => [
  {
    category: 'advanced',
    title: 'Course community + Channel + TA`s chat',
    features: [TemplateFeatures.SLOW_MODE, TemplateFeatures.ALLOW_ALL_REACTIONS],
    onClick: async () => {
      const commonFeatures = {
        course_id,
        title: title,
        slow_mode: 30 as const,
        all_reactions: true,
        description: '',
      };
      const chatDetails: CreateChatDetails[] = [
        {
          type: 'Students',
          ...commonFeatures,
        },
        {
          type: 'Channel',
          ...commonFeatures,
        },
        {
          type: 'TA',
          ...commonFeatures,
        },
      ];
      setAreChatsLoading();
      const createdChats = await createChats(chatDetails);
      setChats({ chats: createdChats });
    },
  },
  {
    category: 'optimal',
    title: 'Course community + Channel',
    features: [TemplateFeatures.GENERATE_IMAGE, TemplateFeatures.ALLOW_ALL_REACTIONS],
    onClick: async () => {
      const commonFeatures = {
        course_id,
        title: title,
        slow_mode: 0 as const,
        all_reactions: true,
        description: '',
      };
      const chatDetails: CreateChatDetails[] = [
        {
          type: 'Students',
          ...commonFeatures,
        },
        {
          type: 'Channel',
          ...commonFeatures,
        },
      ];
      setAreChatsLoading();
      const createdChats = await createChats(chatDetails);
      setChats({ chats: createdChats });
    },
  },
  {
    category: 'simple',
    title: 'Course community',
    features: [
      TemplateFeatures.SLOW_MODE,
      TemplateFeatures.GENERATE_IMAGE,
      TemplateFeatures.ALLOW_NECESSARY_REACTIONS,
    ],
    onClick: async () => {
      const commonFeatures = {
        course_id,
        title: title,
        slow_mode: 30 as const,
        all_reactions: false,
        description: '',
      };
      const chatDetails: CreateChatDetails[] = [
        {
          type: 'Students',
          ...commonFeatures,
        },
      ];
      setAreChatsLoading();

      const createdChats = await createChats(chatDetails);

      setChats({ chats: createdChats });
    },
  },
];

const createChats = async (chatDetails: CreateChatDetails[]) => {
  const createdChats: Chat[] = [];

  for (let chatInfo of chatDetails) {
    try {
      createdChats.push(await createChat(chatInfo));
    } catch {
      alert(`Ошибка при создании чата/канала для ${chatInfo.title} с типом ${chatInfo.type}`);
    }
  }

  return createdChats;
};

import React, { useState, useEffect, useMemo } from 'react';
import styles from './Student.module.scss';
import Information from '../../components/StudentComponents/Information/Information';
import Grades from '../../components/StudentComponents/Grades/Grades';
import Prediction from '../../components/StudentComponents/Prediction/Prediction';
import Interests from '../../components/StudentComponents/Interests/Interests';
import Github from '../../components/StudentComponents/Github/Github';
import heart from '../../images/heart.svg';
import git from '../../images/git.svg';
import eye from '../../images/eye.svg';
import star from '../../images/start.svg';
import user from '../../images/user.svg';
import heart2 from '../../images/inter2.svg';
import git2 from '../../images/git2.svg';
import eye2 from '../../images/pred2.svg';
import star2 from '../../images/grades2.svg';
import user2 from '../../images/info2.svg';
import { getCookie } from 'react-use-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useGetMoodleStudentsQuery, useGetPredictionGradesQuery } from '../../redux/Api';
import { useSelector, useDispatch } from 'react-redux';
import { TitleComponent } from '../../components/helpers/TitleComponent/TitleComponent';
import Preloader from '../../components/stubs/Preloader/Preloader';
import { getStudentFoto } from '../../redux/studentSlise';
import PermissionPage from '../../components/stubs/commonStubs/PermitionPage/PermissionPage';
import StudentIdError from '../../components/stubs/commonStubs/StudentIdError/StudentIdError';
import ErrorGlobal from '../../components/stubs/commonStubs/ErrorGlobal/ErrorGlobal';
import notesImg from '../../images/instractor/notes.svg';
import { ReactComponent as Logo } from '../../images/instractor/Edits.svg';
import natesStudent from '../../images/natesStudent.svg'
import Notes from '../../components/InstractorComponents/Notes/Notes';
import Editor from '../../components/InstractorComponents/Notes/Editor/Editor';
import TelegramErrorMessage from '../../components/stubs/commonStubs/TelegramErrorMessage/TelegramErrorMessage';
import { usePermission } from '../../hooks/usePermission';
function Student({ setNavBurger }) {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [courses, setCourses] = useState('');
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  const [email, setEmail] = useState('');
  const [tokenPlusCourses, setTokenPlusCourses] = useState('');
  let access_token = getCookie('access_token');
  const permissions = useSelector((state) => state.token.role);
  const emailUser = useSelector((state) => state.token.email);
  let location = useLocation();
  let idLocation = location.pathname.split('').slice(9).join('').split('/')[0];
  let pathLocation = location.pathname.split('').slice(9).join('');
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  let moodleId = Number(idLocation);
  let tokenPlusId = { getAccess, moodleId };
  let history = useHistory();
  const foto = useSelector((state) => state.students.foto);
  let permit = usePermission(
    process.env.REACT_APP_PERMISSION_AD,
     process.env.REACT_APP_PERMISSION_PR,
     process.env.REACT_APP_PERMISSION_SV,
     process.env.REACT_APP_PERMISSION_STA)

  useMemo(()=>{
      setIsOpenLazy(false);
  },[moodleId])
  useEffect(() => {
    if (pathLocation == `${idLocation}`) {
      history.push(`${idLocation}/information`);
    }

  }, []);

  const {
    data = [],
    error,
    isLoading,
  } = useGetMoodleStudentsQuery(tokenPlusId, {
    skip: permit == false,
  });

  useEffect(() => {
    if(courses && courses !== 'is not a current student'){
      let tokenPlusCourses = { getAccess, moodleId, courses };
      setTokenPlusCourses(tokenPlusCourses)
    }
  }, [moodleId, courses]);

  const {
    data: predictData = [],
    error: predictError,
    isLoading: predictIsLoading,
  } = useGetPredictionGradesQuery(tokenPlusCourses, {
    skip: permit == false || !tokenPlusCourses || !courses || permissions == process.env.REACT_APP_PERMISSION_SV,
  });

  useEffect(() => {
    if (data.contacts && data.contacts.length > 0 && data.student_id != null) {
      const email = data.contacts[0].email;
      const access = getCookie('access_token');
      let tokenPlusemail = { email, access };
      dispatch(getStudentFoto(tokenPlusemail));
    }
    if(data?.contacts && data.contacts.length > 0){
      let emailItem = data.contacts.find((item) => item.email)
      let emailData = emailItem.email
      setEmail(emailData)
    }
  
  }, [data]);
  let tokenPlusEmail = { getAccess, email: email };
  TitleComponent(`DP - student/${pathLocation}`);

  useEffect(() => {
    setNavBurger('student');
    if (!isLoading && !predictIsLoading && Object.keys(data).length !== 0) {
      if (data?.graduation_history_entities && (data?.graduation_history_entities[0]?.status == 'Является студентом' || data?.graduation_history_entities[0]?.status == 'Является аспирантом')) {
        const sortArr = data.mapped_grades.filter(
          (item) => item.system_grade == '-' && item.idnumber,
        );
        const sortArrUnmap = data.unmapped_moodle_grades.filter(
          (item) => item.system_grade == '-' && item.idnumber,
        );
        const commonSortArr = [...sortArr, ...sortArrUnmap];

        const coursesArr = commonSortArr.map((item) => {
          return item.moodle_course_id;
        });
        setCourses(coursesArr.join(','));
      } else {
        setCourses('is not a current student');
      }
    }
  }, [isLoading, tokenPlusId, predictIsLoading]);

  useMemo(()=>{
      setTimeout(() => {
        setIsOpenLazy(true);
      }, '1500');
  },[foto])

  useMemo(()=>{
if(error){
  const request = 'api/personal_accounts/account_info?moodle_user_id'
  TelegramErrorMessage(error, tokenPlusId, request, emailUser, permissions)
}
if(predictError && process.env.REACT_APP_PROD == 'true'){

  const request = `/ml/prediction/students/${moodleId}`
  TelegramErrorMessage(predictError, tokenPlusCourses, request, emailUser, permissions)
}
},[error, predictError])
  return (
    <div className={styles.student}>
      {access_token == '' ? (
        <div className={styles.ErrorGlobal}>{isOpenLazy && <ErrorGlobal />}</div>
      ) : (
        <div>
          {permissions.includes(process.env.REACT_APP_PERMISSION_AD) ||
          permissions.includes(process.env.REACT_APP_PERMISSION_PR) ||
          permissions == process.env.REACT_APP_PERMISSION_SV ||
          permissions.includes(process.env.REACT_APP_PERMISSION_STA) ? (
            <div className={styles.student}>
              {error ? (
                <StudentIdError />
              ) : (
                <div>
                  {data.full_name &&  isOpenLazy ? (
                    <div className={styles.student}>
                      <div className={styles.navBlock}>
                        <nav className={styles.nav}>
                          <NavLink
                            to={`/student/${idLocation}/information`}
                            className={styles.link}
                          >
                            <button
                              className={
                                pathLocation !== `${idLocation}/information`
                                  ? styles.button
                                  : styles.buttonActive
                              }
                            >
                              <img
                                src={user}
                                className={
                                  pathLocation !== `${idLocation}/information`
                                    ? styles.img
                                    : styles.imgActive
                                }
                                alt='icon'
                              />
                              <p
                                className={
                                  pathLocation == `${idLocation}/information` && styles.pActive
                                }
                              >
                                information
                              </p>
                            </button>
                          </NavLink>
                          {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
                            <NavLink
                              to={`/student/${idLocation}/grades`}
                              className={styles.link}
                            >
                              <button
                                className={
                                  pathLocation !== `${idLocation}/grades`
                                    ? styles.button
                                    : styles.buttonActive
                                }
                              >
                                <img
                                  src={star}
                                  className={
                                    pathLocation !== `${idLocation}/grades`
                                      ? styles.img
                                      : styles.imgActive
                                  }
                                  alt='icon'
                                />
                                <p
                                  className={
                                    pathLocation == `${idLocation}/grades` && styles.pActive
                                  }
                                >
                                  grades
                                </p>
                              </button>
                            </NavLink>
                          )}
                          {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
                            <NavLink
                              to={`/student/${idLocation}/predicted`}
                              className={styles.link}
                            >
                              <button
                                className={
                                  pathLocation !== `${idLocation}/predicted`
                                    ? styles.button
                                    : styles.buttonActive
                                }
                              >
                                <img
                                  src={eye}
                                  className={
                                    pathLocation !== `${idLocation}/predicted`
                                      ? styles.img
                                      : styles.imgActive
                                  }
                                  alt='icon'
                                />
                                <p
                                  className={
                                    pathLocation == `${idLocation}/predicted` && styles.pActive
                                  }
                                >
                                  prediction
                                </p>
                              </button>
                            </NavLink>
                          )}
                          {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
                            <NavLink
                              to={`/student/${idLocation}/interests`}
                              className={styles.link}
                            >
                              <button
                                className={
                                  pathLocation !== `${idLocation}/interests`
                                    ? styles.button
                                    : styles.buttonActive
                                }
                              >
                                <img
                                  src={heart}
                                  className={
                                    pathLocation !== `${idLocation}/interests`
                                      ? styles.img
                                      : styles.imgActive
                                  }
                                  alt='icon'
                                />
                                <p
                                  className={
                                    pathLocation == `${idLocation}/interests` && styles.pActive
                                  }
                                >
                                  interests
                                </p>
                              </button>
                            </NavLink>
                          )}
                          {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
                            <NavLink
                              to={`/student/${idLocation}/github`}
                              className={styles.link}
                            >
                              <button
                                className={
                                  pathLocation !== `${idLocation}/github`
                                    ? styles.button
                                    : styles.buttonActive
                                }
                              >
                                <img
                                  src={git}
                                  className={
                                    pathLocation !== `${idLocation}/github`
                                      ? styles.img
                                      : styles.imgActive
                                  }
                                  alt='icon'
                                />
                                <p
                                  className={
                                    pathLocation == `${idLocation}/github` && styles.pActive
                                  }
                                >
                                  github
                                </p>
                              </button>
                            </NavLink>
                          )}
                            {permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
                            <NavLink
                              to={`/student/${idLocation}/notes`}
                              className={styles.link}
                            >
                              <button
                                className={
                                  pathLocation !== `${idLocation}/notes`
                                    ? styles.buttonNotes
                                    : styles.buttonActive
                                }
                              >
                                <img
                                  src={notesImg}
                                  className={
                                    pathLocation !== `${idLocation}/notes`
                                      ? styles.img
                                      : styles.imgActive
                                  }
                                  alt='icon'
                                />
                                <p
                                  className={
                                    pathLocation == `${idLocation}/notes` && styles.pActiveNotes
                                  }
                                >
                                  notes
                                </p>
                              </button>
                            </NavLink>
                          )}
                          {permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
                            <Logo  className={styles.notessvg} onClick={() => setShowPopup(true)} />
                          )}
                        </nav>
                        <div className={styles.navButtom}></div>
                      </div>

                      <section className={styles.main}>
                        {((data && pathLocation == `${idLocation}/information`) ||
                          (data && pathLocation == `${idLocation}`) ||
                          (data && pathLocation == `${idLocation}/`)) && (
                          <Information
                            data={data}
                            isLoading={isLoading}
                          />
                        )}
                        {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) &&
                          data &&
                          pathLocation == `${idLocation}/grades` && (
                            <Grades
                              data={data}
                              isLoading={isLoading}
                            />
                          )}
                        {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) &&
                          data &&
                          pathLocation == `${idLocation}/predicted` && (
                            <Prediction
                              predictData={predictData}
                              predictError={predictError}
                              predictIsLoading={predictIsLoading}
                              courses={courses}
                            />
                          )}
                        {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) &&
                          data &&
                          pathLocation == `${idLocation}/interests` && (
                            <Interests
                              data={data}
                              isLoading={isLoading}
                            />
                          )}
                        {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) &&
                          data &&
                          pathLocation == `${idLocation}/github` && (
                            <Github
                              data={data}
                              isLoading={isLoading}
                            />
                          )}
                          {data &&
                          pathLocation == `${idLocation}/notes` &&
                          permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
                            <Notes
                              dataNotes={email}
                              isLoading={isLoading}
                              position='student'
                            />
                          )}

                        {!permissions.includes(process.env.REACT_APP_PERMISSION_SV) && (
                          <nav className={styles.navFooter}>
                            <button
                              className={
                                pathLocation !== `${idLocation}/information`
                                  ? styles.buttonFooter
                                  : styles.buttonActiveFooter
                              }
                            >
                              <NavLink
                                to={`/student/${idLocation}/information`}
                                className={styles.link}
                              >
                                <img
                                  src={user2}
                                  className={
                                    pathLocation !== `${idLocation}/information`
                                      ? styles.imgFooter
                                      : styles.imgActiveFooter
                                  }
                                  alt='icon'
                                />
                                <p className={styles.pActiveFooter}>information</p>
                              </NavLink>
                            </button>

                            <button
                              className={
                                pathLocation !== `${idLocation}/grades`
                                  ? styles.buttonFooter
                                  : styles.buttonActiveFooter
                              }
                            >
                              <NavLink
                                to={`/student/${idLocation}/grades`}
                                className={styles.link}
                              >
                                <img
                                  src={star2}
                                  className={
                                    pathLocation !== `${idLocation}/grades`
                                      ? styles.imgFooter
                                      : styles.imgActiveFooter
                                  }
                                  alt='icon'
                                />
                                <p className={styles.pActiveFooter}>grades</p>
                              </NavLink>
                            </button>

                            <button
                              className={
                                pathLocation !== `${idLocation}/predicted`
                                  ? styles.buttonFooter
                                  : styles.buttonActiveFooter
                              }
                            >
                              <NavLink
                                to={`/student/${idLocation}/predicted`}
                                className={styles.link}
                              >
                                <img
                                  src={eye2}
                                  className={
                                    pathLocation !== `${idLocation}/predicted`
                                      ? styles.imgFooter
                                      : styles.imgActiveFooter
                                  }
                                  alt='icon'
                                />
                                <p className={styles.pActiveFooter}>prediction</p>
                              </NavLink>
                            </button>

                            <button
                              className={
                                pathLocation !== `${idLocation}/interests`
                                  ? styles.buttonFooter
                                  : styles.buttonActiveFooter
                              }
                            >
                              <NavLink
                                to={`/student/${idLocation}/interests`}
                                className={styles.link}
                              >
                                <img
                                  src={heart2}
                                  className={
                                    pathLocation !== `${idLocation}/interests`
                                      ? styles.imgFooter
                                      : styles.imgActiveFooter
                                  }
                                  alt='icon'
                                />
                                <p className={styles.pActiveFooter}>interests </p>
                              </NavLink>
                            </button>

                            <button
                              className={
                                pathLocation !== `${idLocation}/github`
                                  ? styles.buttonFooter
                                  : styles.buttonActiveFooter
                              }
                            >
                              <NavLink
                                to={`/student/${idLocation}/github`}
                                className={styles.link}
                              >
                                <img
                                  src={git2}
                                  className={
                                    pathLocation !== `${idLocation}/github`
                                      ? styles.imgFooter
                                      : styles.imgActiveFooter
                                  }
                                  alt='icon'
                                />
                                <p className={styles.pActiveFooter}>github</p>
                              </NavLink>
                            </button>

                            {permissions.includes(process.env.REACT_APP_PERMISSION_AD) && ( <button
                              className={
                                pathLocation !== `${idLocation}/notes`
                                  ? styles.buttonFooter
                                  : styles.buttonActiveFooter
                              }
                            >
                              <NavLink
                                to={`/student/${idLocation}/notes`}
                                className={styles.link}
                              >
                                <img
                                  src={natesStudent}
                                  className={
                                    pathLocation !== `${idLocation}/github`
                                      ? styles.imgFooter
                                      : styles.imgActiveFooter
                                  }
                                  alt='icon'
                                />
                                <p className={styles.pActiveFooter}>notes</p>
                              </NavLink>
                            </button>)}
                          </nav>
                        )}

                        <div className={styles.navFooterHelper}></div>
                      </section>
                    </div>
                  ) : (
                    <div>
                      {!isOpenLazy || isLoading || !permit ? (
                        <div className={styles.Preloader}>
                          <Preloader />
                        </div>
                      ) : (
                        <StudentIdError />
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            permissions && (
              <div className={styles.permissions}>
                <PermissionPage />
              </div>
            )
          )}
        </div>
      )}
            {showPopup && permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
        <Editor
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          indicatePopap={'add'}
          tokenPlusId={tokenPlusEmail}
          position='student'
        />
      )}
    </div>
  );
}

export default Student;

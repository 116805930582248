import React, { useState, useEffect } from 'react';
import styles from './Information.module.css';
import blocks from './blocks.module.css';
import fotoMale from '../../../images/maleF.svg';
import email from '../../../images/mail.svg';
import github from '../../../images/github.svg';
import telegram from '../../../images/send.svg';
import group from '../../../images/группа.svg';
import degree from '../../../images/степень.svg';
import track from '../../../images/трек.svg';
import year from '../../../images/год.svg';
import status from '../../../images/статус.svg';
import Preloader from '../../stubs/Preloader/Preloader';
import { useSelector } from 'react-redux';
import YearOfStudy from './InternalComponents/YearOfStudy';
import Degree from './InternalComponents/Degree';
import Status from './InternalComponents/Status';
import Track from './InternalComponents/Track';
import ProgressOfEducation from './ProgressOfEducation/ProgressOfEducation';

function Information({ data, isLoading }) {
  const foto = useSelector((state) => state.students.foto);
  const [isOpenLazy, setIsOpenLazy] = useState(false);

  setTimeout(() => {
    setIsOpenLazy(true);
  }, '1000');
  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  return (
    <div className={styles.information}>
      {!isLoading ? (
        <div>
          <section className={styles.generalInformation}>
            {foto.length != 0 ? (
              <div>
                {foto.includes('data:text/html;base64') || foto.includes('data:application/json;base64') ? (
                  <img
                    src={fotoMale}
                    className={styles.img}
                    alt='male'
                  />
                ) : (
                  <img
                    src={foto}
                    className={styles.img}
                    alt='foto'
                  />
                )}
              </div>
            ) : (
              <div>
                {!isOpenLazy ? (
                  <div className={styles.imgPreloader}></div>
                ) : (
                  <img
                    src={fotoMale}
                    className={styles.img}
                    alt='male'
                  />
                )}
              </div>
            )}
            <div className={styles.rightSection}>
              <div className={styles.titleSection}>
                <h3 className={styles.title}>{data.full_name_latin}</h3>
              </div>
              <h4 className={styles.title2}>{data.full_name}</h4>

              { data?.total_gpa ? <div className={styles.gpaSection}>
                <p className={styles.gpaTitle}>total gpa: </p>
                {data.total_gpa ? (
                  <p className={styles.gpaValue}>{data.total_gpa.toFixed(2)}</p>
                ) : (
                  <p className={styles.gpaValue}></p>
                )}
              </div>
              : <></>}

              <div className={styles.contactSection}>
                <div className={styles.grayContact}>
                  <img
                    src={email}
                    className={styles.iconContact}
                    alt='email'
                  />
                  <p className={styles.grayTitle}>email:</p>
                </div>
                {data.contacts.length > 0 ? (
                  <p className={styles.contactValue}>{data.contacts.map((item) => item.email)}</p>
                ) : (
                  <p className={styles.contactValue}>-</p>
                )}
              </div>

              <div className={styles.contactSection}>
                <div className={styles.grayContact}>
                  <img
                    src={github}
                    className={styles.iconContact}
                    alt='github'
                  />
                  <p className={styles.grayTitle}>github:</p>
                </div>
                {data.contacts.length > 0 ? (
                  <a
                    href={`https://github.com/${data.contacts[0].github_username}`}
                    target='_blank'
                    className={styles.contactValueA}
                    rel='noreferrer'
                  >
                    {data.contacts[0].github_username}
                  </a>
                ) : (
                  <p className={styles.contactValueA}>-</p>
                )}
              </div>

              <div className={styles.contactSection}>
                <div className={styles.grayContact}>
                  <img
                    src={telegram}
                    className={styles.iconContact}
                    alt='telegram'
                  />
                  <p className={styles.grayTitle}>telegram:</p>
                </div>
                {data.contacts.length > 0 ? (
                  <a
                    href={`https://t.me/${data.contacts[0].telegram_alias
                      .split('')
                      .splice(1)
                      .join('')}`}
                    target='_blank'
                    className={styles.contactValueA}
                    rel='noreferrer'
                  >
                    {data.contacts[0].telegram_alias}
                  </a>
                ) : (
                  <p className={styles.contactValueA}>-</p>
                )}
              </div>
            </div>
          </section>

          {data?.graduation_history_entities[0] && (
            <section className={blocks.blocks}>
              <h4 className={blocks.title}>General information</h4>
              <div className={blocks.mainBlocks}>
                <div className={blocks.block}>
                  <p className={blocks.blockTitle}>birthday</p>
                  <p className={blocks.valueFirst}>
                    {data.birthday.slice(0, 10).split(`.`).reverse().join('.')}
                  </p>
                </div>
                <div className={blocks.block}>
                  <p className={blocks.blockTitle}>country</p>
                  <div className={blocks.divlags}>
                    <img
                      className={blocks.iconFlags}
                      src={
                        process.env.PUBLIC_URL +
                        `/country/${`${data.citizenship.slice(0, 1)}${data.citizenship
                          .slice(1)
                          .toLowerCase()}`}.svg`
                      }
                      alt=''
                    />
                    <p className={blocks.valueFlags}>{data.citizenship}</p>
                  </div>
                </div>
                <div className={blocks.block}>
                  <p className={blocks.blockTitle}>year of admission</p>
                  {data?.graduation_history_entities[0].group.length > 0 ? (
                    <div>
                      {data.graduation_history_entities[0].group.split('').slice(1, 2) == '1' ||
                      data.graduation_history_entities[0].group.split('').slice(1, 2) == '2' ? (
                        <p className={blocks.valueFirst}>
                          20
                          {data.graduation_history_entities[0].group
                            .split('')
                            .splice(1, 2)
                            .join('')}
                        </p>
                      ) : (
                        <p className={blocks.valueFirst}>20{data.gradebook.slice(0, 2)}</p>
                      )}
                    </div>
                  ) : (
                    <p className={styles.valueFirst}></p>
                  )}
                </div>
              </div>
              <h4 className={blocks.title}>University</h4>
              <div className={blocks.mainBlocks}>
                <div className={blocks.bigBlock}>
                  <div className={blocks.divInfo}>
                    <img
                      src={group}
                      className={blocks.iconUp}
                      alt='group'
                    />
                    <p className={blocks.blockTitle2}>group</p>
                  </div>
                  {data?.graduation_history_entities[0].group ? (
                    <p className={blocks.value}>{data.graduation_history_entities[0].group}</p>
                  ) : (
                    <p className={blocks.value}>-</p>
                  )}

                  <div className={blocks.divInfo}>
                    <img
                      src={year}
                      className={blocks.icon}
                      alt='year'
                    />
                    <p className={blocks.blockTitle2}>year of study</p>
                  </div>
                  <p className={blocks.value}>
                    <YearOfStudy item={data?.graduation_history_entities[0].course} />
                  </p>
                </div>

                <div className={blocks.bigBlock}>
                  <div className={blocks.divInfo}>
                    <img
                      src={degree}
                      className={blocks.iconUp}
                      alt='degree'
                    />
                    <p className={blocks.blockTitle2}>degree</p>
                  </div>
                  <p className={blocks.value}>
                    <Degree item={data?.graduation_history_entities[0].degree} />
                  </p>
                  <div className={blocks.divInfo}>
                    <img
                      src={status}
                      className={blocks.icon}
                      alt='status'
                    />
                    <p className={blocks.blockTitle2}>status</p>
                  </div>
                  <p className={blocks.value}>
                    <Status item={data?.graduation_history_entities[0].status} />
                  </p>
                </div>

                <div className={blocks.bigBlock}>
                  <div className={blocks.divInfo}>
                    <img
                      src={track}
                      className={blocks.iconUp}
                      alt='track'
                    />
                    <p className={blocks.blockTitle2}>track</p>
                  </div>
                  <p className={blocks.value}>
                    <Track item={data?.graduation_history_entities[0].group} />
                  </p>
                </div>
              </div>
              {data?.graduation_history_entities.length > 1 && (
                <ProgressOfEducation data={data}  
                status={data?.graduation_history_entities[0].status}/>
              )}
            </section>
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
    </div>
  );
}

export default Information;

import React from 'react';
import styles from './studentStubs.module.css';
import inactivePrediction from '../../../images/InactivePrediction.svg';
import { motion } from 'framer-motion';

function InactivePrediction() {
  const textVis = {
    hidden: {
      opacity: 0,
    },
    visible: () => ({
      opacity: 1,

      transition: {
        delay: 0.3,
        type: 'just',
        duration: 0.5,
      },
    }),
  };

  return (
    <motion.div
      className={styles.studentStubsPrediction}
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.2 }}
    >
      <motion.img
        variants={textVis}
        src={inactivePrediction}
        alt='img Search'
        className={styles.img}
      />
      <motion.h5
        variants={textVis}
        className={styles.title}
      >
        Active courses not found
      </motion.h5>
      <motion.p
        variants={textVis}
        className={styles.secondTitle}
      >
        The student has completed his studies or does not have courses
      </motion.p>
    </motion.div>
  );
}

export default InactivePrediction;

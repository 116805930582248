import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { getCookie } from 'react-use-cookie';
import styles from './OldPersonalFitback.module.scss';
import {
  useGetFeedbacksListQuery,
  useGetFeedbackQuestionQuery,
  useGetFeedbackAllAnswersQuery,
} from '../../../../redux/Api';
import FeedbackAnswers from '../PersonalFitback/FeedbackAnswers/FeedbackAnswers';
import links from '../../../../images/instractor/links.svg';
import { useSelector } from 'react-redux';
import FeedbackAnswersStaff from '../PersonalFitback/FeedbackAnswersStaff/FeedbackAnswersStaff';
import ButtonsOld from './ButtonsOld/ButtonsOld';
import { ReactComponent as Chevron } from '../../../../images/25.svg';

function OldPersonalFitback({ details, instructorId }) {
  const [showToggle, setShowToggle] = useState(false);
  const [filter, setFilter] = useState([]);
  const [tokenPlusdata, setTokenPlusdata] = useState([]);
  const [newData, setNewData] = useState([]);
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  let moodleId = details.course_id;
  let tokenPlusId = { getAccess, moodleId };
  const { data = [], isLoading } = useGetFeedbacksListQuery(tokenPlusId);
  const studentsCount = useSelector((state) => state.instructor.courseTaken);
  const feedbackGiven = useSelector((state) => state.instructor.feedbackGiven);
  const [moodle_feedback_id, setMoodle_feedback_id] = useState([]);
  const [moodle_feedback_idName, setMoodle_feedback_idName] = useState('');
  const [allAnswersData, setAllAnswersData] = useState('');

  useLayoutEffect(() => {
    if (!isLoading && data && data.length > 0 && moodle_feedback_id.length == 0) {
      let feedbackId = data[0].moodle_feedback_id;
      let tokenPlusdata = { getAccess, feedbackId };
      setTokenPlusdata(tokenPlusdata);
      setMoodle_feedback_id(data[0].moodle_feedback_id);
      setNewData(data[0]);
      let feedbackIdName = data[0].name;
      setMoodle_feedback_idName(feedbackIdName);
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && data && data.length > 0 && moodle_feedback_id.length !== 0) {
      let feedbackId = moodle_feedback_id;
      let tokenPlusdata = { getAccess, feedbackId };
      setTokenPlusdata(tokenPlusdata);
    }
  }, [moodle_feedback_id]);

  const { data: dataQuestion = [] } = useGetFeedbackQuestionQuery(tokenPlusdata, {
    skip: data.length == 0,
  });

  useEffect(() => {
    if (dataQuestion) {
      let filterArr = dataQuestion.filter((e) => e.question_type === 'multichoice');

      let filter = filterArr.filter((item) => {
        switch (details.role_id) {
          case 16:
            return item.question_name.includes('ourse') || item.question_name.includes('ectur');
          case 15:
            return item.question_name.includes('tutor') || item.question_name.includes('Tutor');
          case 12:
            return (
              item.question_label.includes('lab') ||
              item.question_label.includes('TA') ||
              item.question_label.includes('Techer assistant') ||
              item.question_label.includes('Teacher assistant') ||
              item.question_label.includes('Moodle') ||
              item.question_label.includes('Activities') ||
              item.question_label.includes('teacher assistant') ||
              item.question_name.includes('name') ||
              item.question_name.includes('lab') ||
              item.question_name.includes('eacher assistant') ||
              item.question_name.includes('coursework')
            );
          case 14:
            return item.question_name.includes('name');
          case 3:
            return item;
          case 18:
            return item;
        }
      });

      setFilter(filter);
    }
  }, [dataQuestion]);

  const handlerToggle = (item) => {
    setMoodle_feedback_id(item.moodle_feedback_id);
    setMoodle_feedback_idName(item.name);
    setShowToggle(false);
    setNewData(item);
  };

  useMemo(() => {
    if (filter.length > 0 && getAccess) {
      let feedbackId = filter[0].feedback;
      let tokenPlusdata = { getAccess, feedbackId };
      setAllAnswersData(tokenPlusdata);
    }
  }, [filter]);

  const { data: dataAnswers = [] } = useGetFeedbackAllAnswersQuery(allAnswersData, {
    skip: !allAnswersData,
  });

  return (
    <div>
      <div className={styles.PersonalFitback}>
        <div className={styles.flexRow}>
          <div className={styles.select}>
          {data.length > 1 && <p className={styles.selectText}>choose feedback:</p>}
            <div className={styles.toggleDiv}>
              <span
           className={data.length > 1 ? styles.feedbackToggle : styles.feedbackToggleOne}
                onClick={() => setShowToggle(!showToggle)}
              >
                 <p className={data.length > 1 ? styles.feedbackTextButton : styles.feedbackToggleOne}>{moodle_feedback_idName}</p>
                {data.length > 1 && (
                <Chevron  className={styles.imgSelect}/>
                )}
              </span>
              {showToggle && data.length > 1 && (
                <div className={styles.feedbackToggleDIV}>
                  {data &&
                    data.map((item, index) => {
                      return (
                        <p
                          key={index}
                          className={styles.feedbackTextButtonList}
                          onClick={() => handlerToggle(item)}
                        >
                          {item.name}
                        </p>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          <div className={styles.link}>
            <img
              src={links}
              className={styles.img}
            />
            <p className={styles.linkGrey}>link on:</p>
            <a
              className={styles.linkText}
              href={`https://moodle.innopolis.university/mod/feedback/view.php?id=${newData.coursemodule}`}
              target='_blank'
              rel='noreferrer'
            >
              final feedback
            </a>
          </div>
        </div>

        <div className={styles.flexRowBottom}>
          <div className={styles.select}>
            <div className={styles.selectDiv}>
              <p className={styles.counterText}>students:</p>
              <p className={styles.counterStudents}>{studentsCount}</p>
            </div>
            {dataAnswers.length > 0 && feedbackGiven && (
              <div className={styles.selectDiv}>
                <p className={styles.counterText}>feedback given:</p>
                <p className={styles.counter}>{feedbackGiven}</p>
              </div>
            )}
          </div>
          <div className={styles.hints}>
            <span className={styles.hintColorGreen2}></span>
            <p className={styles.hintText}>strongly agree</p>
            <span className={styles.hintColorGreen}></span>
            <p className={styles.hintText}>agree</p>
            <span className={styles.hintColorYellow}></span>
            <p className={styles.hintText}>neutral</p>
            <span className={styles.hintColorGreenOrange}></span>
            <p className={styles.hintText}>disagree</p>
            <span className={styles.hintColorGreenRed}></span>
            <p className={styles.hintText}>strongly disagree</p>
          </div>
        </div>
        <section className={styles.sectionIssue}>
          {filter &&
            filter.map((item) => {
              return (
                item.question_type == 'multichoice' &&
                item.question_name !== "Course lector's name" &&
                item.question_name !== 'Ta name' &&
                item.question_name !== 'Teacher assistant name' &&
                item.question_name !== '(TA evaluation) TA name' && (
                  <div
                    className={styles.particularIssue}
                    key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                  >
                    <p className={styles.questionName}>{item.question_name}</p>
                    {(details.role_id == 15 ||
                      details.role_id == 16 ||
                      details.role_id == 3 ||
                      details.role_id == 18) && (
                      <FeedbackAnswers
                        answer={item.moodle_feedback_item_id}
                        newData={dataAnswers}
                      />
                    )}
                    {(details.role_id == 12 || details.role_id == 14) && (
                      <FeedbackAnswersStaff
                        item={item}
                        role_id={details.role_id}
                        instructorId={instructorId}
                      />
                    )}
                  </div>
                )
              );
            })}
        </section>

        {data.length !== 0 && <ButtonsOld feedbackId={moodle_feedback_id} />}
      </div>
    </div>
  );
}

export default OldPersonalFitback;

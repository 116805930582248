import React, {useCallback, useEffect} from 'react';
import styles from './Dialog.module.scss';
import plusClose from '../../../../images/plusClose.svg';

function Dialog({ onClose, handleDelete, openDialog, onArchive }) {

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          onClose()
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
    
        return () => {
          document.removeEventListener("keydown", escFunction, false);
        };
      }, [escFunction])

      const yesButton = () => {
        if(openDialog == 'delete'){
            handleDelete()
        }else{
            onArchive()  
        }
        onClose()
      }
  return (
    <div
      className={styles.Alert}
      onClick={() => onClose()}
    >
      <div
        className={ styles.popup}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={plusClose}
          className={styles.img}
          onClick={() => onClose()}
        />
        <h4 className={styles.title}>
          {openDialog == 'delete'
            ? 'Аre you sure you want to delete this project?'
            : 'Аre you sure you want to move this project to the archive?'}
        </h4>
        <div className={styles.mainSection}>
          <button
            className={styles.button}
            onClick={() => yesButton() }
          >
            yes
          </button>
          <button
            className={styles.button}
            onClick={() => onClose()}
          >
            no
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dialog;

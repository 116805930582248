import React from 'react';
import styles from './Interests.module.css';
import Preloader from '../../stubs/Preloader/Preloader';
import starts5 from '../../../images/i1.svg';
import starts3 from '../../../images/i2.svg';
import starts1 from '../../../images/i3.svg';
import EmptySearch from '../../stubs/studentStubs/EmptySearch';

function InterestsData({ isLoading, filterData }) {
  return (
    <section className={styles.interestsSection}>
      {!isLoading ? (
        <div>
          {filterData.length > 0 ? (
            <div>
              <div className={styles.tableHobbies}>
                <div className={styles.trHobbies}>
                  <p className={styles.hobbyTh}>Hobby</p>
                  <p className={styles.levelTh}>Level</p>
                  <p className={styles.descriptionTh}>Description</p>
                </div>
                {filterData.length &&
                  filterData.map((item) => {
                    return (
                      <div
                        className={styles.list}
                        key={Math.random()}
                      >
                        <div className={styles.hobbyDiv}>
                          <div className={styles.hobby}>
                            <p className={styles.hobbyType}>{item.type}</p>
                            {item.name ? (
                              <p className={styles.hobbyName}>{item.name}</p>
                            ) : (
                              <p className={styles.hobbyName}></p>
                            )}
                          </div>

                          {(item.degree_of_passion == 'Опытный участник' && (
                            <img
                              src={starts5}
                              alt='starts-5'
                              className={styles.level}
                            />
                          )) ||
                            (item.degree_of_passion == 'Имеется небольшой опыт' && (
                              <img
                                src={starts3}
                                alt='starts-3'
                                className={styles.level}
                              />
                            )) ||
                            (item.degree_of_passion == 'Нет опыта, но хочу получить' && (
                              <img
                                src={starts1}
                                alt='starts-1'
                                className={styles.level}
                              />
                            )) ||
                            (item.degree_of_passion == '' && (
                              <p className={styles.level}>&nbsp;-</p>
                            ))}
                        </div>
                        <p className={styles.description}>{item.description}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <EmptySearch />
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
    </section>
  );
}

export default InterestsData;

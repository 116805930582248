import React from 'react';
import styles from './Feedback.module.scss';
import noData from '../../../../images/instractor/noData.svg';
function Feedback({ grades, name }) {
  let sum = Object.values(grades).reduce((a, b) => a + b, 0);

  let resultStronglyAgree = ((100 * grades.strongly_agree) / sum).toFixed(1);
  let resultAgree = ((100 * grades.agree) / sum).toFixed(1);
  let resultNeutral = ((100 * grades.neutral) / sum).toFixed(1);
  let resultDisagree = ((100 * grades.disagree) / sum).toFixed(1);
  let resultStronglyDisagree = ((100 * grades.strongly_disagree) / sum).toFixed(1);

  return (
    <div>
      <div className={!name ? styles.block : styles.blockTA}>
        {Object.keys(grades).length == 0 && !name && (
          <div className={styles.noDataDiv}>
            <img src={noData} />
            <p className={styles.noDataText}>no data</p>
          </div>
        )}
        {grades.strongly_agree > 0 && (
          <div
            className={styles.greenDiv}
            style={{ width: 8 * resultStronglyAgree, marginRight: '4.5px' }}
          >
            {resultStronglyAgree > 1 && !name && (
              <p className={styles.text}>{resultStronglyAgree}</p>
            )}
            <div className={styles.green}></div>
            {resultStronglyAgree > 1 && name && (
              <p className={styles.textTA}>{resultStronglyAgree}</p>
            )}
          </div>
        )}
        {grades.agree > 0 && (
          <div style={{ width: 8 * resultAgree, marginRight: '4.5px' }}>
            {resultAgree > 1 && !name && <p className={styles.text}>{resultAgree}</p>}
            <div className={styles.green2}></div>
            {resultAgree > 1 && name && <p className={styles.textTA}>{resultAgree}</p>}
          </div>
        )}
        {grades.neutral > 0 && (
          <div style={{ width: 8 * resultNeutral, marginRight: '4.5px' }}>
            {resultNeutral > 1 && !name && <p className={styles.text}>{resultNeutral}</p>}
            <div className={styles.yellow}></div>
            {resultNeutral > 1 && name && <p className={styles.textTA}>{resultNeutral}</p>}
          </div>
        )}
        {grades.disagree > 0 && (
          <div style={{ width: 8 * resultDisagree, marginRight: '4.5px' }}>
            {resultDisagree > 1.5 && !name && <p className={styles.text}>{resultDisagree}</p>}
            <div className={styles.orange}></div>
            {resultDisagree > 1 && name && <p className={styles.textTA}>{resultDisagree}</p>}
          </div>
        )}
        {grades.strongly_disagree > 0 && (
          <div style={{ width: 8 * resultStronglyDisagree }}>
            {resultStronglyDisagree > 1.5 && !name && (
              <p className={styles.text}>{resultStronglyDisagree}</p>
            )}
            <div className={styles.red}></div>
            {resultStronglyDisagree > 1 && name && (
              <p className={styles.textTA}>{resultStronglyDisagree}</p>
            )}
          </div>
        )}
      </div>
      {!name && (
        <div className={styles.blockMobile}>
          {Object.keys(grades).length == 0 && (
            <div className={styles.noDataDiv}>
              <img
                src={noData}
                className={styles.noDataImg}
              />
              <p className={styles.noDataText}>no data</p>
            </div>
          )}
          {grades.strongly_agree && (
            <div className={styles.flexM}>
              <div
                className={styles.greenM}
                style={{ width: resultStronglyAgree * 1.8 }}
              ></div>
              <p className={styles.textM}>{resultStronglyAgree}</p>
            </div>
          )}
          {grades.agree && (
            <div className={styles.flexM}>
              <div
                className={styles.green2M}
                style={{ width: resultAgree * 1.8 }}
              ></div>
              <p className={styles.textM}>{resultAgree}</p>
            </div>
          )}
          {grades.neutral && (
            <div className={styles.flexM}>
              <div
                className={styles.yellowM}
                style={{ width: 1.8 * resultNeutral }}
              ></div>
              <p className={styles.textM}>{resultNeutral}</p>
            </div>
          )}
          {grades.disagree && (
            <div className={styles.flexM}>
              <div
                className={styles.orangeM}
                style={{ width: 1.8 * resultDisagree }}
              ></div>
              <p className={styles.textM}>{resultDisagree}</p>
            </div>
          )}
          {grades.strongly_disagree && (
            <div className={styles.flexM}>
              <div
                className={styles.redM}
                style={{ width: 1.8 * resultStronglyDisagree }}
              ></div>
              <p className={styles.textM}>{resultStronglyDisagree}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Feedback;

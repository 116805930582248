import { Chat, createChat, CreateChatDetails } from '@chat-bot/entities/API-service';
import { createdChatsState } from '@chat-bot/pages/CourseChats/model';
import { useMemo, useState } from 'react';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getCreateChatFormDefaultValues } from './config';
import { CreateChatFormValues, validationSchema } from './lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChatConfigurationFieldControls } from '@chat-bot/entities/ChatConfigurationFormFields';
import { ChatConfigurationFieldNames } from '@chat-bot/entities/ChatConfigurationFormFields/config';

export const useCreateChatFormController = (courseName: string) => {
  const [pendingCreationChats, setPendingCreationChats] = useState<CreateChatDetails[]>([]);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [createdChats, setCreatedChats] = useRecoilState(createdChatsState);
  const formDefaultValues = useMemo(() => getCreateChatFormDefaultValues(courseName), []);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<CreateChatFormValues>({
    defaultValues: formDefaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { courseID } = useParams<{ courseID: string }>();
  // TODO: Попросить Ваню изменить слоумод и реакции на булевы
  const onSubmit = handleSubmit(
    async ({ title, slow_mode, all_reactions, description, channel_type }) => {
      if (!channel_type) {
        return;
      }

      setPendingCreationChats([
        ...pendingCreationChats,
        {
          course_id: courseID,
          title,
          slow_mode: slow_mode ? 30 : 0,
          all_reactions: all_reactions === 'true',
          description,
          type: channel_type,
        },
      ]);
      reset();
      setIsFormVisible(false);
    },
  );

  const onSaveAllButtonClick = async () => {
    setCreatedChats({ chats: createdChats.chats, isLoading: true });
    const newlyCreatedChats = await Promise.all(
      pendingCreationChats.map(async (chat) => await createChat(chat)),
    );
    setCreatedChats({ chats: [...createdChats.chats, ...newlyCreatedChats], isLoading: false });
    setPendingCreationChats([]);
  };


  

  const formControls: ChatConfigurationFieldControls = {
    courseTitle: register(ChatConfigurationFieldNames.TITLE),
    courseDescription: register(ChatConfigurationFieldNames.DESCRIPTION),
    slowMode: register(ChatConfigurationFieldNames.SLOW_MODE),
    reactions: register(ChatConfigurationFieldNames.ALL_REACTIONS),
    channelType: register(ChatConfigurationFieldNames.CHANNEL_TYPE),
  };

  const deletePendingCreationChat = (deleteIndex: number) => {
    const updatedChats = pendingCreationChats.filter((_, index) => index !== deleteIndex);
    setPendingCreationChats(updatedChats);
  };

  const channelTypeFieldError = errors.channel_type?.message;

  const onAddAnotherCourseButtonClick = () => {
    if (!isFormVisible) {
      setIsFormVisible(true);
      return;
    }
  };

  const onTypeSelect = (optionValue: Chat['type'] | '') => {
    setValue(ChatConfigurationFieldNames.CHANNEL_TYPE, optionValue);
    clearErrors();
  };

  return {
    formControls,
    onSubmit,
    pendingCreationChats,
    setPendingCreationChats,
    isFormVisible,
    onSaveAllButtonClick,
    deletePendingCreationChat,
    channelTypeFieldError,
    setValue,
    reset,
    clearErrors,
    onAddAnotherCourseButtonClick,
    onTypeSelect
  };
};

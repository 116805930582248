import React, { useState, useMemo, memo } from 'react';
import styles from './CourseFeedback.module.scss';
import { getCookie } from 'react-use-cookie';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TitleComponent } from '../../components/helpers/TitleComponent/TitleComponent';
import {
  useGetFeedbackQuestionQuery,
  useGetFeedbackAllAnswersQuery,
  useGetFeedbacksByFeedbackIDQuery,
  useGetCourseTeamQuery,
} from '../../redux/Api';
import ErrorGlobal from '../../components/stubs/commonStubs/ErrorGlobal/ErrorGlobal';
import FeedbackError from '../../components/stubs/commonStubs/FeedbackError/FeedbackError';
import Preloader from '../../components/stubs/Preloader/Preloader';
import PermissionPage from '../../components/stubs/commonStubs/PermitionPage/PermissionPage';
import IndividualAnswers from '../../components/FeedbackComponents/IndividualAnswers/IndividualAnswers';
import SummaryFeedback from '../../components/FeedbackComponents/SummaryFeedback/SummaryFeedback';
import BackComponent from '../../components/common/elements/BackComponent/BackComponent';
import { usePermission } from '../../hooks/usePermission';
// eslint-disable-next-line react/display-name
const CourseFeedback = memo(function ({ setNavBurger }) {
  const permissions = useSelector((state) => state.token.role);;
  const [courseId, setCourseId] = useState('');
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  const [labels, setLabels] = useState([]);
  const [answersStudent, setAnswersStudent] = useState([]);
  const [textAnswers, setTextAnswers] = useState([]);

  const [nameAnswers, setNameAnswers] = useState([]);
  const [nameTA, setNameTA] = useState([]);
  const [plusText, setPlusText] = useState([]);
  const [plusTextAnswers, setPlusTextAnswers] = useState([]);
  const [minusText, setMinusText] = useState([]);
  const [minusTextAnswers, setMinusTextAnswers] = useState([]);
  const [commentsText, setCommentsText] = useState([]);
  const [commentsTextAnswers, setCommentsTextAnswers] = useState([]);

  const [lecturer, setLecturer] = useState([]);
  const [course_evaluation, setCourse_evaluation] = useState([]);
  const [gading_and_Moodle, setGrading_and_Moodle] = useState([]);
  const [ta_evaluation, setTA_evaluation] = useState([]);

  const [instructor, setInstructor] = useState([]);
  const [primary_Instructor, setPrimary_Instructor] = useState([]);
  const [student_self_evaluation, setStudent_self_evaluation] = useState([]);
  const [teacher_assistant, setTeacher_assistant] = useState([]);
  const [tutorial_Instructor, setTutorial_Instructor] = useState([]);

  const [name, setName] = useState('All TA');
  let location = useLocation();
  let idLocation = location.pathname.split('').slice(17).join('');
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  let feedbackId = Number(idLocation);
  let tokenPlusdata = { getAccess, feedbackId };
  let permit = usePermission(
    process.env.REACT_APP_PERMISSION_AD,
     process.env.REACT_APP_PERMISSION_PR)
  TitleComponent(`DP - course_feedback/${feedbackId}`);
  setNavBurger('course_feedback');
  let access_token = getCookie('access_token');

  const {
    data = [],
    error,
    isLoading,
  } = useGetFeedbackQuestionQuery(tokenPlusdata, {
    skip: permit == false,
  });
  const { data: dataAnswers = [], isLoading: isLoadingAnswers } = useGetFeedbackAllAnswersQuery(
    tokenPlusdata,
    {
      skip: permit == false,
    },
  );
  const { data: dataFeedback = [], isLoading: isLoadingFeedback } =
    useGetFeedbacksByFeedbackIDQuery(tokenPlusdata, {
      skip: permit == false,
    });
  useMemo(() => {
    if (!isLoadingFeedback && dataFeedback && dataFeedback.length > 0) {
      setCourseId(dataFeedback[0].course);
    }
  }, [dataFeedback]);
  let moodleId = courseId;
  let tokenPluscourse = { getAccess, moodleId };

  const { data: dataCourse = [], isLoading: isLoadingCourse } = useGetCourseTeamQuery(
    tokenPluscourse,
    {
      skip: permit == false || moodleId == '',
    },
  );

  useMemo(() => {
    if (!isLoading && data && data.length > 0) {
      let labels = data.reduce((accum, item) => {
        return accum.includes(item.question_label) || item.question_label == ''
          ? accum
          : [...accum, item.question_label];
      }, []);
      let obj = {};
      labels.forEach((element) => {
        let arr = [];
        data.forEach((item) => {
          if (item.question_label == element) {
            arr.push(item.moodle_feedback_item_id);
            if (element == 'Course evaluation') {
              let el = 'Course_evaluation';
              obj[el] = arr;
            } else if (element == 'TA evaluation') {
              let el = 'TA_evaluation';
              obj[el] = arr;
            } else if (element == 'Grading and Moodle') {
              let el = 'Grading_and_Moodle';
              obj[el] = arr;
            } else if (element == 'Lecturer') {
              let el = 'Lecturer';
              obj[el] = arr;
            } else if (element == 'Primary Instructor') {
              let el = 'Primary_Instructor';
              obj[el] = arr;
            } else if (element == 'Tutorial Instructor') {
              let el = 'Tutorial_Instructor';
              obj[el] = arr;
            } else if (element == 'Instructor') {
              let el = 'Instructor';
              obj[el] = arr;
            } else if (element == 'Teacher assistant') {
              let el = 'Teacher_assistant';
              obj[el] = arr;
            } else if (element == 'Student self-evaluation') {
              let el = 'Student_self_evaluation';
              obj[el] = arr;
            }
          }
        });
      });

      setLabels(obj);
      let filterName = data.filter((e) => e.question_name.includes('name'));
      if (filterName && filterName.length > 0) {
        setNameTA(filterName[0].moodle_feedback_item_id);
      }

      let filterPlusText = data.filter((e) =>
        e.question_name.includes(
          'Provide 1-3 examples of tasks and activities that helped you learn in this course',
        ),
      );
      if (filterPlusText && filterPlusText.length > 0) {
        setPlusText(filterPlusText[0].moodle_feedback_item_id);
      }
      let filterMinusText = data.filter((e) =>
        e.question_name.includes(
          "Provide 1-3 examples of tasks and activities that weren't helpful",
        ),
      );
      if (filterMinusText && filterMinusText.length > 0) {
        setMinusText(filterMinusText[0].moodle_feedback_item_id);
      }
      let filterCommentsText = data.filter((e) =>
        e.question_name.includes('Additional comments (optional)'),
      );
      if (filterCommentsText && filterCommentsText.length > 0) {
        setCommentsText(filterCommentsText[0].moodle_feedback_item_id);
      }
    }
  }, [data]);

  useMemo(() => {
    if (!isLoadingAnswers && dataAnswers && dataAnswers.length > 0) {
      let student = dataAnswers.reduce((accum, item) => {
        return accum.includes(item.attempt_id) || item.attempt_id == ''
          ? accum
          : [...accum, item.attempt_id];
      }, []);

      let obj = {};
      let obj2 = {};
      let obj3 = {};
      let obj4 = {};
      let obj5 = {};
      let obj6 = {};
      student.forEach((element) => {
        let arr = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];
        let arr6 = [];

        dataAnswers.forEach((item) => {
          if (
            item.attempt_id == element &&
            (item.printed_value == 'strongly agree' ||
              item.printed_value == 'agree' ||
              item.printed_value == 'neutral' ||
              item.printed_value == 'disagree' ||
              item.printed_value == 'strongly disagree')
          ) {
            arr.push(`${item.printed_value} - ${item.item}`);
          } else if (item.attempt_id == element && item.item == nameTA) {
            arr3.push(`${item.printed_value}`);
          } else if (item.attempt_id == element && item.item == plusText) {
            arr4.push(`${item.printed_value}`);
          } else if (item.attempt_id == element && item.item == minusText) {
            arr5.push(`${item.printed_value}`);
          } else if (item.attempt_id == element && item.item == commentsText) {
            arr6.push(`${item.printed_value}`);
          } else if (
            item.attempt_id == element &&
            item.item !== nameTA &&
            (item.printed_value !== 'strongly agree' ||
              item.printed_value !== 'agree' ||
              item.printed_value !== 'neutral' ||
              item.printed_value !== 'disagree' ||
              item.printed_value !== 'strongly disagree' ||
              item.printed_value !== '.' ||
              item.printed_value !== '' ||
              item.printed_value !== '-' ||
              item.printed_value !== 'None' ||
              item.printed_value !== 'none')
          ) {
            if (item.raw_value?.length > 4) {
              arr2.push(`${item.printed_value}`);
            }
          }
        });
        obj[element] = arr;
        obj2[element] = arr2;
        obj3[element] = arr3;
        obj4[element] = arr4;
        obj5[element] = arr5;
        obj6[element] = arr6;
      });
      setAnswersStudent(obj);
      setTextAnswers(obj2);
      setNameAnswers(obj3);

      setPlusTextAnswers(obj4);
      setMinusTextAnswers(obj5);
      setCommentsTextAnswers(obj6);
    }
  }, [dataAnswers, nameTA, plusText]);

  useMemo(() => {
    if (labels && answersStudent) {
      let lecturer = {};
      let Course_evaluation = {};
      let Grading_and_Moodle = {};
      let TA_evaluation = {};

      let Instructor = {};
      let Primary_Instructor = {};
      let Student_self_evaluation = {};
      let Teacher_assistant = {};
      let Tutorial_Instructor = {};

      // TODO: Surely a double loop is needed here, given that the first one is not used?
      // eslint-disable-next-line no-unused-vars
      for (let student in answersStudent) {
        for (let stident of Object.keys(answersStudent)) {
          let answers = answersStudent[stident];
          let arr = [];
          let arr2 = [];
          let arr3 = [];
          let arr4 = [];
          let arr5 = [];
          let arr6 = [];
          let arr7 = [];
          let arr8 = [];
          let arr9 = [];
          answers.forEach((item) => {
            if (labels.Lecturer) {
              labels.Lecturer.forEach((e) => {
                if (item.includes(e)) {
                  arr.push(item);
                  lecturer[stident] = arr;
                }
              });
            }
            if (labels.Course_evaluation) {
              labels.Course_evaluation.forEach((e) => {
                if (item.includes(e)) {
                  arr2.push(item);
                  Course_evaluation[stident] = arr2;
                }
              });
            }
            if (labels.Grading_and_Moodle) {
              labels.Grading_and_Moodle.forEach((e) => {
                if (item.includes(e)) {
                  arr3.push(item);
                  Grading_and_Moodle[stident] = arr3;
                }
              });
            }
            if (labels.TA_evaluation) {
              labels.TA_evaluation.forEach((e) => {
                if (item.includes(e)) {
                  arr4.push(item);
                  TA_evaluation[stident] = arr4;
                }
              });
            }
            if (labels.Instructor) {
              labels.Instructor.forEach((e) => {
                if (item.includes(e)) {
                  arr5.push(item);
                  Instructor[stident] = arr5;
                }
              });
            }
            if (labels.Primary_Instructor) {
              labels.Primary_Instructor.forEach((e) => {
                if (item.includes(e)) {
                  arr6.push(item);
                  Primary_Instructor[stident] = arr6;
                }
              });
            }
            if (labels.Student_self_evaluation) {
              labels.Student_self_evaluation.forEach((e) => {
                if (item.includes(e)) {
                  arr7.push(item);
                  Student_self_evaluation[stident] = arr7;
                }
              });
            }
            if (labels.Teacher_assistant) {
              labels.Teacher_assistant.forEach((e) => {
                if (item.includes(e)) {
                  arr8.push(item);
                  Teacher_assistant[stident] = arr8;
                }
              });
            }
            if (labels.Tutorial_Instructor) {
              labels.Tutorial_Instructor.forEach((e) => {
                if (item.includes(e)) {
                  arr9.push(item);
                  Tutorial_Instructor[stident] = arr9;
                }
              });
            }
          });
        }
      }

      setLecturer(lecturer);
      setCourse_evaluation(Course_evaluation);
      setGrading_and_Moodle(Grading_and_Moodle);
      setTA_evaluation(TA_evaluation);

      setInstructor(Instructor);
      setPrimary_Instructor(Primary_Instructor);
      setStudent_self_evaluation(Student_self_evaluation);
      setTeacher_assistant(Teacher_assistant);
      setTutorial_Instructor(Tutorial_Instructor);
    }
  }, [labels, answersStudent]);

  setTimeout(() => {
    setIsOpenLazy(true);
  }, '3000');
  return (
    <div className={styles.CourseFeedback}>
      {access_token == '' ? (
        <div className={styles.ErrorGlobal}>{isOpenLazy && <ErrorGlobal />}</div>
      ) : (
        <div>
          {permissions.includes(process.env.REACT_APP_PERMISSION_AD) || permissions.includes(process.env.REACT_APP_PERMISSION_PR) ? (
            <div className={styles.Feedback}>
              {error ? (
                <FeedbackError />
              ) : (
                <div>
                  {isLoading || isLoadingAnswers || isLoadingFeedback || isLoadingCourse ? (
                    <div className={styles.Preloader}>
                      <Preloader />
                    </div>
                  ) : (
                    <div>
                      {Object.keys(labels).length == 0 || dataAnswers.length == 0 ? (
                        <FeedbackError />
                      ) : (
                        <div className={styles.Feedback}>
                          {data && dataCourse && dataFeedback && dataAnswers ? (
                            <div className={styles.Feedback}>
                              <BackComponent />
                              <h2 className={styles.titleText}>{dataCourse.course_name}</h2>
                              <SummaryFeedback
                                data={dataAnswers}
                                dataQuestion={data}
                                students_count={dataCourse.students_count}
                                isLoadingCourse={isLoadingCourse}
                                isLoading={isLoading}
                                dataCourse={dataCourse}
                                getAccess={getAccess}
                                setNameTA={setName}
                                nameTA={name}
                              />
                              {name == 'All TA' && (
                                <IndividualAnswers
                                  answersStudent={answersStudent}
                                  textAnswers={textAnswers}
                                  nameAnswers={nameAnswers}
                                  dataQuestion={data}
                                  plusTextAnswers={plusTextAnswers}
                                  minusTextAnswers={minusTextAnswers}
                                  commentsTextAnswers={commentsTextAnswers}
                                  lecturer={lecturer}
                                  course_evaluation={course_evaluation}
                                  gading_and_Moodle={gading_and_Moodle}
                                  ta_evaluation={ta_evaluation}
                                  instructor={instructor}
                                  primary_Instructor={primary_Instructor}
                                  student_self_evaluation={student_self_evaluation}
                                  teacher_assistant={teacher_assistant}
                                  tutorial_Instructor={tutorial_Instructor}
                                />
                              )}
                            </div>
                          ) : (
                            <div>
                              {isLoading ||
                              isLoadingAnswers ||
                              isLoadingFeedback ||
                              isLoadingCourse ? (
                                <div className={styles.Preloader}>
                                  <Preloader />
                                </div>
                              ) : (
                                <FeedbackError />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            permissions && (
              <div className={styles.permissions}>
                <PermissionPage />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
});

export default CourseFeedback;

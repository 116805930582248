import React, { useCallback, useMemo } from 'react';
import styles from './Filter.module.scss';
import { CheckboxGroup } from '../../common/CheckboxGroup/CheckboxGroup';
import { Dropdown } from '../../common/Dropdown/Dropdown';
import { Text } from '../../common/Text/Text';
import { Button } from '../../common/Button/Button';

/**
 * component for generation Filter for SideCard on Projects page with config
 *
 * @param filter array of filter data
 * @param { (filter) => void } onFilterChange callback on filter change
 * @param { (filter) => void } onApply callback when on apply clicked
 */
export const Filter = ({ filter, onFilterChange, onApply }) => {

  const onChange = useCallback(
    (changedFilterItemTitle) => (newValue) => {

      onFilterChange((prevState) =>
        prevState.map((filterItem) => {
          if (filterItem.title === changedFilterItemTitle) {
            return {
              ...filterItem,
              items: newValue,
            };
          }

          return filterItem;
        }),
      );
    },
    [onFilterChange],
  );

  const handleApply = useCallback(() => {
    onApply(filter);
  }, [filter, onApply]);

  const renderFilterItems = useMemo(
    () =>
      filter?.map((filterItem, index) => {
        if (filterItem.type === 'checkbox') {
          return (
            <CheckboxGroup
              key={index}
              title={filterItem.title}
              items={filterItem.items}
              onChange={onChange(filterItem.title)}
            />
          );
        }

        return (
          <div key={index}>
            <Text
              is='h2'
              className={styles.title}
            >
              {filterItem.title}
            </Text>
            <Dropdown
              isMulti
              placeholder={filterItem.placeholder}
              options={filterItem.options}
              initialSelected={filterItem.items}
              onChange={onChange(filterItem.title)}
              filterItem={filterItem.title}
            />
          </div>
        );
      }),
    [filter],
  );

  return (
    <div className={styles.ProjectsFilter}>
      {renderFilterItems}
      <Button
        appearance='primary'
        size='m'
        maxWidth='max-content'
        onClick={handleApply}
        className={styles.button}
      ><p>filter</p>
        
      </Button>
    </div>
  );
};

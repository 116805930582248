import { createdChatsState } from '@chat-bot/pages/CourseChats/model';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  Category,
  ChatTemplateBody,
  Features,
  Hint,
  ListOfTemplates,
  Title,
} from './CreateChatViaTemplate.styles';
import { getChatTemplates } from './lib/helpers';

export const CreateChatViaTemplate: FC<{ courseName: string }> = ({ courseName }) => {
  const [createdChats, setCreatedChats] = useRecoilState(createdChatsState);
  const { courseID } = useParams<{ courseID: string }>();

  const chatTempaltes = useMemo(
    () => {
      const setAreChatsLoading = () => setCreatedChats({ ...createdChats, isLoading: true });
      return getChatTemplates(courseID, courseName, setCreatedChats, setAreChatsLoading)
    },
    [courseID, courseName, createdChats, setCreatedChats],
  );

  return (
    <div>
      <Hint>
        You haven't created anything yet, let's fix it. Below are ready-made samples for creating
        multiple chats. You can also create the necessary chats manually
      </Hint>
      <ListOfTemplates>
        {chatTempaltes.map(({ category, title, features, onClick }) => (
          <ChatTemplateBody onClick={onClick}>
            <Category>{category}</Category>
            <Title>{title}</Title>
            <Features>{features}</Features>
          </ChatTemplateBody>
        ))}
      </ListOfTemplates>
    </div>
  );
};

import React, { memo } from 'react';
import styles from './Checkbox.module.scss';

/**
 * dummy component of Checkbox with ability to add label
 * @param { string } className - Class name passed to the component
 * @param { string } id - ID for checkbox input
 * @param { string } label - label for checkbox input
 * @param { bool } checked - state checkbox input
 * @param { function } onChange - callback when checkbox state changed
 */
export const Checkbox = memo(({ id, label, checked, onChange, className, ...props }) => {
  const controlClassName = [checked ? styles.checked : '', styles.control].join(' ');

  return (
    <div className={[className, styles.Checkbox].join(' ')}>
      <input
        id={id}
        type='checkbox'
        checked={checked}
        onChange={onChange}
        className={controlClassName}
        {...props}
      />
      <label
        // htmlFor={id}
        className={styles.label}
      >
        {label}
      </label>
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

import React from 'react'
import styles from './InstructorProject.module.scss'
import { ReactComponent as Description } from '../../../images/collaboration/description.svg';
import { ReactComponent as ExpectedOutcome } from '../../../images/collaboration/expectedOutcome.svg';
import { ReactComponent as Fields } from '../../../images/collaboration/fields.svg';
import { ReactComponent as Person } from '../../../images/collaboration/person.svg';

function InstructorProject({item}) {
  return (
    <>
    <div className={styles.mainInformation}>
    <h3 className={styles.title}>{item.title}</h3>
    <div className={styles.lineDiv}>
    <div className={styles.divFlex}>
    <Person className={styles.svg}/>
    {item.count_participants !== null && <p className={styles.text}>{item.count_participants} {item.count_participants > 1 ? 'persons' : 'person'}</p>}
    <span className={item.active ? styles.active : styles.archive}></span>
    <p className={styles.text}>{item.active ? 'active' : 'archive'}</p>
    </div>
    <div className={styles.divLevel}>
    {item.is_thesis_suitable && <div className={styles.level}><p>thesis</p></div>}
    {item.study_levels && item.study_levels.map(level=>{
            return(
                <div key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                className={styles.level}>
                    <p>{level}</p>
                </div>
            )
        })}
    </div>
    </div>
    </div>
    {item.description && <div className={styles.divFlex}>
    <Description className={styles.svg}/>
    <h4 className={styles.titleParagraph}>description</h4>
    </div>}
    <p className={styles.textParagraph}>{item.description}</p>

    {item.expected_outcome && <div className={styles.divFlex}>
    <ExpectedOutcome className={styles.svg}/>
    <h4 className={styles.titleParagraph}>expected outcome</h4>
    </div>}
    <p className={styles.textParagraph}>{item.expected_outcome}</p>
    {item.subject_areas.length ? <> 
    <div className={styles.divFlex}>
    <Fields className={styles.svg}/>
    <h4 className={styles.titleParagraph}>fields</h4>
    </div>
    <div className={styles.divFields}>
        {item.subject_areas && item.subject_areas.map(element=>{
            return(
                <div key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                className={styles.element}>
                    <p>{element}</p>
                </div>
            )
        })}
    </div>
    </>
    :<></>}
        {item.skills.length ? <> 
    <div className={styles.divFlex}>
    <Fields className={styles.svg}/>
    <h4 className={styles.titleParagraph}>technological stack</h4>
    </div>
    <div className={styles.divFields}>
        {item.skills && item.skills.map(element=>{
            return(
                <div key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                className={styles.element}>
                    <p>{element}</p>
                </div>
            )
        })}
    </div>
    </>
    :<></>}
    </>
  )
}

export default InstructorProject

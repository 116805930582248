import React, { useState, useCallback } from 'react';
import { TextInput } from '../../../common/TextInput/TextInput';
import { Text } from '../../../common/Text/Text';
import { Button } from '../../../common/Button/Button';
import styles from './EditCard.module.scss';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  useCreateInstructorInfoMutation,
  useChangeInstructorInfoMutation,
} from '../../../../redux/Api';
function EditCard({ dataEdit, interestsDef, languagesDef, setSideCardOpen, tokenPlusId }) {
  const [office, setOffice] = useState(dataEdit.office);
  const [openHours, setOpenHours] = useState(dataEdit?.open_hours);

  const [interests, setInterests] = useState(interestsDef);
  const [languages, setLanguages] = useState(languagesDef);

  const [google, setGoogle] = useState(dataEdit?.google_scholar_link);
  const [scopus, setScopus] = useState(dataEdit?.scopus_link);
  const [research, setResearch] = useState(dataEdit?.research_gate_link);

  const [isOpenLazy, setIsOpenLazy] = useState(false);
  const [createEdit] = useCreateInstructorInfoMutation();
  const [changeEdit] = useChangeInstructorInfoMutation();
  const handleOfficeChange = useCallback((event) => {
    if (event.target) {
      setOffice(event.target.value);
    }
  }, []);
  const handleOpenHoursChange = useCallback((event) => {
    if (event.target) {
      setOpenHours(event.target.value);
    }
  }, []);
  const handleGoogleChange = useCallback((event) => {
    if (event.target) {
      setGoogle(event.target.value);
    }
  }, []);
  const handleScopusChange = useCallback((event) => {
    if (event.target) {
      setScopus(event.target.value);
    }
  }, []);
  const handleResearchChange = useCallback((event) => {
    if (event.target) {
      setResearch(event.target.value);
    }
  }, []);

  const handleCreateEdit = async () => {
    let body = {
      instructor_id: tokenPlusId.moodleId,
      office: office,
      open_hours: openHours,
      professional_interests: interests.join(';'),
      languages: languages.join(';'),
      google_scholar_link: google,
      scopus_link: scopus,
      research_gate_link: research,
    };
    let tokenPlusBody = {
      getAccess: tokenPlusId.getAccess,
      body: body,
    };
    await createEdit(tokenPlusBody).unwrap();
    setSideCardOpen(false);
  };
  const handleChangeEdit = async () => {
    let body = {
      office: office,
      open_hours: openHours,
      professional_interests: interests.length == 0 ? interestsDef.join(';') : interests.join(';'),
      languages: languages.length == 0 ? languagesDef.join(';') : languages.join(';'),
      google_scholar_link: google,
      scopus_link: scopus,
      research_gate_link: research,
    };

    let tokenPlusMoodleId = {
      moodleId: tokenPlusId.moodleId,
      getAccess: tokenPlusId.getAccess,
      body: body,
    };
    await changeEdit(tokenPlusMoodleId).unwrap();
    setSideCardOpen(false);
  };
  setTimeout(() => {
    setIsOpenLazy(true);
  }, '500');
  return (
    <>
      {isOpenLazy && (
        <div className={styles.mainSection}>
          <Text className={styles.TextTitle}>Contacts</Text>
          <Text className={styles.TextInput}>Office</Text>
          <TextInput
            className={styles.Input}
            width='100%'
            type='text'
            maxLength='25'
            onChange={handleOfficeChange}
            value={office}
            placeholder='example: room 403'
            defaultValue={dataEdit.office}
          />
          <Text className={styles.TextInput}>Open hours</Text>
          <TextInput
            className={styles.Input}
            width='100%'
            type='text'
            maxLength='45'
            onChange={handleOpenHoursChange}
            placeholder='example: 11:00-15:00 every Tuesday'
            value={openHours}
            defaultValue={dataEdit.open_hours}
          />

          <Text className={styles.TextTitle}>Personal information</Text>
          <Text className={styles.TextInput}>Professional interests</Text>

          <Autocomplete
            multiple
            className={styles.Autocomplete}
            options={[]}
            defaultValue={interestsDef}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size='small'
                  style={{
                    backgroundColor: '#3852DE',
                    color: 'white',
                    borderRadius: '9px',
                    border: 'none',
                  }}
                  className={styles.Chip}
                  key={index}
                  variant='outlined'
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                className={styles.TextField}
                {...params}
                size='medium'
                label='example: robotics (press enter after each value)'
              />
            )}
            onChange={(event, values) => {
              setInterests(values);
            }}
          />

          <Text className={styles.TextTitleLanguages}>Languages</Text>

          <Autocomplete
            multiple
            className={styles.Autocomplete}
            options={[]}
            defaultValue={languagesDef}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size='small'
                  style={{
                    backgroundColor: '#3852DE',
                    color: 'white',
                    borderRadius: '9px',
                    border: 'none',
                  }}
                  className={styles.Chip}
                  key={index}
                  variant='outlined'
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                className={styles.TextField}
                {...params}
                size='medium'
                label='example: english C1 (press enter after each value)'
                style={{ color: 'white' }}
              />
            )}
            onChange={(event, values) => {
              setLanguages(values);
            }}
          />

          <Text className={styles.TextTitleReseacrher}>Reseacrher links</Text>
          <Text className={styles.TextInput}>google scolar</Text>
          <TextInput
            className={styles.Input}
            width='100%'
            type='text'
            onChange={handleGoogleChange}
            placeholder='https://example.org'
            value={google}
            defaultValue={dataEdit.google_scholar_link}
          />
          <Text className={styles.TextInput}>scopus</Text>
          <TextInput
            className={styles.Input}
            width='100%'
            type='text'
            onChange={handleScopusChange}
            placeholder='https://example.org'
            value={scopus}
            defaultValue={dataEdit.scopus_link}
          />
          <Text className={styles.TextInput}>research gate</Text>
          <TextInput
            className={styles.Input}
            width='100%'
            type='text'
            onChange={handleResearchChange}
            placeholder='https://example.org'
            value={research}
            defaultValue={dataEdit.research_gate_link}
          />

          <Button
            appearance='primary'
            className={styles.Button}
            onClick={dataEdit.length == 0 ? () => handleCreateEdit() : () => handleChangeEdit()}
            disabled={
              !office &&
              !openHours &&
              interests.length == 0 &&
              languages.length == 0 &&
              !google &&
              !scopus &&
              !research
            }
          >
            save
          </Button>
        </div>
      )}
    </>
  );
}

export default EditCard;

import { Input, Select, Textarea, Checkbox } from '@shared/ui';
import {
  AdvancedOptionsBlock,
  ChatDescriptionBlock,
  CheckboxBlock,
  Heading,
  ReactionsSubtext,
  ReactionsTextWrapper,
  TopFieldsWrapper,
} from './ChatConfigurationFormFields.styles';
import { FormField } from '@shared/ui/FormField';
import Like from '@shared/assets/icons/like.svg';
import Dislike from '@shared/assets/icons/dislike.svg';
import { ChatConfigurationFieldControls } from './lib';
import { Chat } from '../API-service';
import { FC } from 'react';

type ChatConfigurationFormFieldsCommonProps = {
  formControls: ChatConfigurationFieldControls;
  isEditing?: boolean;
};

type ChatCreatingFormFieldsProps = ChatConfigurationFormFieldsCommonProps & {
  isEditing: false;
  channelTypeFieldError?: string;
  onTypeSelect: (optionValue: Chat['type'] | '') => void;
};

type ChatEditingFormFieldsProps = ChatConfigurationFormFieldsCommonProps & {
  isEditing: true;
};

export const ChatConfigurationFormFields: FC<
  ChatCreatingFormFieldsProps | ChatEditingFormFieldsProps
> = (props) => (
  <>
    <ChatDescriptionBlock>
      <TopFieldsWrapper>
        <FormField title='Name'>
          <Input {...props.formControls.courseTitle} />
        </FormField>
        {!props.isEditing && (
          <FormField
            error={props.channelTypeFieldError}
            title='Select channel type'
            required
          >
            <Select
              setValueInForm={props.onTypeSelect}
              isError={!!props.channelTypeFieldError}
              options={[{ label: 'Students' }, { label: 'TA' }, { label: 'Channel' }]}
              {...props.formControls.channelType}
            />
          </FormField>
        )}
      </TopFieldsWrapper>

      <FormField title='Description'>
        <Textarea {...props.formControls.courseDescription} />
      </FormField>
    </ChatDescriptionBlock>
    <AdvancedOptionsBlock>
      <Heading>
        <b>Select advanced options:</b>
      </Heading>
      <CheckboxBlock>
        <Checkbox
          label='Slow mode'
          {...props.formControls.slowMode}
        />
        <Checkbox
          label='Allow all reactions'
          type='radio'
          value='true'
          {...props.formControls.reactions}
        />
        <div>
          <Checkbox
            label='Allow necessary reactions'
            type='radio'
            value='false'
            {...props.formControls.reactions}
          />
          <ReactionsTextWrapper>
            <ReactionsSubtext>Necessary reactions include:</ReactionsSubtext>
            <img
              src={Like}
              alt=''
            />
            <img
              src={Dislike}
              alt=''
            />
          </ReactionsTextWrapper>
        </div>
      </CheckboxBlock>
    </AdvancedOptionsBlock>
  </>
);

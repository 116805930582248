import styled from '@emotion/styled';

export const FileInputWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  gap: 10px;

  & > span {
    flex-basis: 200px;
    flex-shrink: 0;
  }

  & > input {
    display: block;
  }
`;

export const StatusBanner = styled.div<{ isSucceded: boolean }>`
  background-color: ${({ isSucceded }) => (isSucceded ? 'limegreen' : 'red')};
  padding: 10px 15px;
  border-radius: 33px;
  margin-top: 10px;
  color: white;
`;


import React, { memo } from 'react';
import styles from './Header.module.scss';

/**
 * dummy component for Header inside SideCard
 * @param { string } className - Class name passed to the component
 * @param { string } text - text for the title
 */
const Header = memo(({ className, text }) => (
  <h2 className={[className, styles.Header].join(' ')}>{text}</h2>
));

Header.displayName = 'SideCardHeader';

export default Header;

import React, { useState, useEffect } from 'react';
import styles from './Prediction.module.css';
import Preloader from '../../stubs/Preloader/Preloader';
import fail from '../../../images/plus.svg';
import pass from '../../../images/check.svg';
import book from '../../../images/book-open.svg';
import InactivePrediction from '../../stubs/studentStubs/InactivePrediction';
import ErrorPrediction from '../../stubs/studentStubs/ErrorPrediction';

function Prediction({ predictData, predictError, predictIsLoading, courses }) {
  const [, setNewDate] = useState('');
  const [hint, setHint] = useState('');
  const [predictDataNew, setPredictDataNew] = useState(predictData);
  const handlerHint = (item) => {
    setHint(item);
  };
  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  useEffect(() => {
    let date = new Date();
    let output = String(date.getMonth() + 1).padStart(2, '0');
    let outputNew = date.getFullYear();
    let outputNewPrev = date.getFullYear();
    if (output < 3) {
      outputNewPrev = date.getFullYear() - 1;
    }
    let strungOutputNew = String(outputNew);
    let sliseArr = strungOutputNew.split('').slice(2).join('');
    let strungOutputNewPrev = String(outputNewPrev);
    let sliseArrPrev = strungOutputNewPrev.split('').slice(2).join('');
    let filterrArr = predictData.filter(
      (item) => item.full_name.includes(sliseArr) || item.full_name.includes(sliseArrPrev),
    );
    setPredictDataNew(filterrArr);
    setNewDate(outputNew);
  }, [predictData]);

  return (
    <div className={styles.tablePrediction}>
      <section className={styles.titleSection}>
        <h4 className={styles.title}>Prediction</h4>
      </section>
      {!predictIsLoading ? (
        <div>
          {courses == 'is not a current student' || courses == '' || predictDataNew.length == 0 ? (
            <InactivePrediction />
          ) : (
            <div>
              {predictError ? (
                <ErrorPrediction />
              ) : (
                <div>
                  <div className={styles.hintMainTitle}>
                    <div className={styles.coefficienFlex}>
                      <span className={styles.coefficientitle}>Prediction coefficient</span>{' '}
                      <img
                        src={book}
                        alt='fail'
                        className={styles.passIconUp}
                      />{' '}
                      <span className={styles.flash}>-</span>
                    </div>
                    <span className={styles.coefficienHint}>
                      shows how accurate the predictions of the{' '}
                    </span>{' '}
                    <span className={styles.coefficienHint}>
                      {' '}
                      assessment in this discipline are
                    </span>
                  </div>
                  <div className={styles.trPrediction}>
                    <p className={styles.courseTh}>Course</p>
                    <p className={styles.passTh}>Pass/fail</p>
                    <p
                      className={styles.accuracyTh}
                      onMouseEnter={() => handlerHint('icon')}
                      onMouseLeave={() => {
                        setHint('');
                      }}
                    >
                      <img
                        src={book}
                        className={styles.iconBook}
                        alt='icon'
                      />
                      Confidence
                    </p>
                    {hint == 'icon' && (
                      <div className={styles.divHint}>
                        <p className={styles.hintTitle}>Prediction coefficient</p>
                        <p className={styles.hint}>
                          A value that shows how accurate the prediction of the estimate is
                        </p>
                      </div>
                    )}
                  </div>
                  {predictDataNew.length &&
                    predictDataNew.map((item) => {
                      return (
                        <div
                          className={styles.list}
                          key={Math.random()}
                        >
                          <div className={styles.courseDiv}>
                            <p className={styles.course}>{item.full_name}</p>
                          </div>
                          <div className={styles.divMobile}>
                            <div className={styles.divMobileFlex}>
                              <img
                                src={book}
                                alt='fail'
                                className={styles.passIconUp2}
                              />
                              <p className={styles.passing}>{item.confidence_passing}</p>
                            </div>
                            {item.predict_passing == 'Passed' && (
                              <div className={styles.passDivMobile}>
                                <img
                                  src={pass}
                                  alt='pass'
                                  className={styles.passIcon}
                                />
                                <p className={styles.passFail}>
                                  {item.predict_passing.slice(0, -2)}
                                </p>
                              </div>
                            )}
                            {item.predict_passing == 'Failed' && (
                              <div className={styles.passDivMobilePass}>
                                <img
                                  src={fail}
                                  alt='fail'
                                  className={styles.passIcon}
                                />
                                <p className={styles.passFail}>
                                  {item.predict_passing.slice(0, -2)}
                                </p>
                              </div>
                            )}
                          </div>
                          {item.predict_passing == 'Passed' && (
                            <div className={styles.passDiv}>
                              <img
                                src={pass}
                                alt='pass'
                                className={styles.passIcon}
                              />
                              <p className={styles.passFail}>{item.predict_passing.slice(0, -2)}</p>
                            </div>
                          )}
                          {item.predict_passing == 'Failed' && (
                            <div className={styles.passDiv}>
                              <img
                                src={fail}
                                alt='fail'
                                className={styles.passIcon}
                              />
                              <p className={styles.passFail}>{item.predict_passing.slice(0, -2)}</p>
                            </div>
                          )}
                          <div
                            className={styles.onMouse}
                            onMouseEnter={() => handlerHint(item)}
                            onMouseLeave={() => {
                              setHint('');
                            }}
                          >
                            <p className={styles.accuracy}>
                              {item.confidence_passing == '0'
                                ? 'Low'
                                : item.confidence_passing == '1'
                                ? 'Middle'
                                : 'High'}
                            </p>
                            {hint == item && (
                              <div className={styles.divHint}>
                                <p className={styles.hintTitle}>Prediction coefficient</p>
                                <p className={styles.hint}>
                                  A value that shows how accurate the prediction of the estimate is
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.Preloader}>
          <Preloader />
        </div>
      )}
    </div>
  );
}

export default Prediction;

import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import Button from '@atlaskit/button/standard-button';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { FileInputWrapper, StatusBanner } from './LoadCoursesViaCSV.styles';
import { useForm, useWatch } from 'react-hook-form';
import {
  getCreatedChatsInCSV,
  loadChatsViaCSV,
  loadCoursesViaCSV,
} from '@chat-bot/entities/API-service';
import { AxiosError } from 'axios';

export const LoadCoursesViaCSV = () => {
  enum FormNames {
    courses = 'courses',
    chats = 'chats',
  }

  type Status = {
    message?: string;
    status?: 'FAILED' | 'SUCCESS';
  };

  const [isOpen, setIsOpen] = useState(false);
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => {
    setIsOpen(false);
    setStatus(undefined);
  }, []);
  const [isAllFilesLoaded, setIsAllFilesLoaded] = useState(false);
  const [status, setStatus] = useState<Status | undefined>();
  const { register, control, handleSubmit } = useForm<FieldTypes>();

  const fieldsControls = {
    [FormNames.courses]: register(FormNames.courses),
    [FormNames.chats]: register(FormNames.chats),
  };

  type FieldTypes = {
    [FormNames.chats]: FileList;
    [FormNames.courses]: FileList;
  };

  const { [FormNames.chats]: chatsFile, [FormNames.courses]: coursesFile } = useWatch({ control });

  useEffect(() => {
    const exctractedCoursesFile = coursesFile?.item(0);
    const exctractedChatsFile = chatsFile?.item(0);

    if (exctractedChatsFile && exctractedCoursesFile) {
      setIsAllFilesLoaded(true);
    } else {
      setIsAllFilesLoaded(false);
    }
  }, [chatsFile, coursesFile]);

  const onSubmit = async ({
    [FormNames.chats]: chatsFile,
    [FormNames.courses]: coursesFile,
  }: FieldTypes) => {
    const exctractedCoursesFile = coursesFile?.item(0);
    const exctractedChatsFile = chatsFile?.item(0);

    if (!exctractedCoursesFile || !exctractedChatsFile) {
      return;
    }

    try {
      let formData = new FormData();
      formData.append('file', exctractedCoursesFile);
      await loadCoursesViaCSV(formData);

      formData = new FormData();
      formData.append('file', exctractedChatsFile);

      await loadChatsViaCSV(formData);
    } catch (e) {
      setStatus({
        status: 'FAILED',
        message: (e as AxiosError<{ detail: string }>).response?.data?.detail ?? 'Произошла ошибка',
      });
      return;
    }

    setStatus({ status: 'SUCCESS' });
  };

  return (
    <div>
      <Button
        appearance='primary'
        onClick={openModal}
      >
        Load via CSV
      </Button>

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            <ModalHeader>
              <ModalTitle>Create chats via CSV</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FileInputWrapper>
                  <span>Courses info</span>

                  <input
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    {...fieldsControls[FormNames.courses]}
                  />
                </FileInputWrapper>

                <FileInputWrapper>
                  <span>Chats info</span>
                  <input
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    {...fieldsControls[FormNames.chats]}
                  />
                </FileInputWrapper>
                <div>
                  <Button
                    appearance='primary'
                    type='submit'
                    isDisabled={!isAllFilesLoaded}
                  >
                    Load
                  </Button>
                </div>
                {status && (
                  <StatusBanner isSucceded={status.status === 'SUCCESS'}>
                    {status.status === 'SUCCESS' ? 'Success!' : status?.message}
                  </StatusBanner>
                )}
              </form>
              <div style={{ padding: '40px 0 20px 0' }}>
                <ModalTitle>Download created chats info</ModalTitle>
              </div>

              <Button
                appearance='primary'
                onClick={async () => {
                  const download = function (data: string) {
                    const blob = new Blob([data], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.setAttribute('href', url);
                    a.setAttribute('download', 'chats.csv');
                    a.click();
                  };
                  const data = await getCreatedChatsInCSV();
                  download(data);
                }}
                type='submit'
              >
                Download
              </Button>
            </ModalBody>
            <ModalFooter>
              <Button
                appearance='subtle'
                onClick={closeModal}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

import React from 'react';
import styles from './Header.module.css';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
function HeaderNavInstructor({ miniBarOpen, setMiniBarOpen }) {
  let location = useLocation();
  let idLocation = location.pathname.split('').slice(12).join('').split('/')[0];
  const permissions = useSelector((state) => state.token.role);
  return (
    <nav className={styles.HeaderNavInstructor}>
      <NavLink
        to={`/instructor/${idLocation}/information`}
        className={styles.button}
      >
        <button
          className={styles.button}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
        >
          <p className={styles.pActive}>information</p>
        </button>
      </NavLink>
      <NavLink
        to={`/instructor/${idLocation}/background`}
        className={styles.button}
      >
        <button
          className={styles.button}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
        >
          <p className={styles.pActive}>background</p>
        </button>
      </NavLink>
      {/* <NavLink
      to={`/instructor/${idLocation}/publications`}
      className={styles.button}
      >
      <button className={styles.button}  onClick={()=>setMiniBarOpen(!miniBarOpen)}>
        <p className={styles.pActive}>publications</p>
      </button>
      </NavLink> */}
      <NavLink
        to={`/instructor/${idLocation}/courses`}
        className={styles.button}
      >
        <button
          className={styles.button}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
        >
          <p className={styles.pActive}>courses</p>
        </button>
      </NavLink>
      <NavLink
        to={`/instructor/${idLocation}/projects`}
        className={styles.button}
      >
        <button
          className={styles.button}
          onClick={() => setMiniBarOpen(!miniBarOpen)}
        >
          <p className={styles.pActive}>projects</p>
        </button>
      </NavLink>
      {permissions.includes(process.env.REACT_APP_PERMISSION_AD) && (
        <NavLink
          to={`/instructor/${idLocation}/notes`}
          className={styles.button}
        >
          <button
            className={styles.button}
            onClick={() => setMiniBarOpen(!miniBarOpen)}
          >
            <p className={styles.pActive}>notes</p>
          </button>
        </NavLink>
      )}
    </nav>
  );
}

export default HeaderNavInstructor;

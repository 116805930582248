import React, { useState, useMemo } from 'react';
import styles from './TAEvaluation.module.scss';
import { getCookie } from 'react-use-cookie';
import { useLocation } from 'react-router-dom';
import { TitleComponent } from '../../components/helpers/TitleComponent/TitleComponent';
import BackComponent from '../../components/common/elements/BackComponent/BackComponent';
import {
  useGetFeedbacksByFeedbackIDQuery,
  useGetCourseTeamQuery,
  useGetFeedbackQuestionQuery,
} from '../../redux/Api';
import TAEvaluationComponent from '../../components/TAEvaluationComponents/TAEvaluationComponent/TAEvaluationComponent';
import Preloader from '../../components/stubs/Preloader/Preloader';
import TAError from '../../components/stubs/commonStubs/TAError/TAError';
import { usePermission } from '../../hooks/usePermission';
function TAEvaluation({ setNavBurger }) {
  let location = useLocation();
  let idLocation = location.pathname.split('').slice(15).join('');
  let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
  let feedbackId = Number(idLocation);
  let tokenPlusdata = { getAccess, feedbackId };
  const [courseId, setCourseId] = useState('');
  const [arrTA, setArrTA] = useState([]);
  let permit = usePermission(process.env.REACT_APP_PERMISSION_AD, process.env.REACT_APP_PERMISSION_PR)
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  TitleComponent(`DP - ta__evaluationk/${feedbackId}`);
  setNavBurger('ta_evaluationk');
  setTimeout(() => {
    setIsOpenLazy(true);
  }, '800');
  const { data: dataFeedback = [], isLoading: isLoadingFeedback } =
    useGetFeedbacksByFeedbackIDQuery(tokenPlusdata, {
      skip: permit == false,
    });
  useMemo(() => {
    if (!isLoadingFeedback && dataFeedback && dataFeedback.length > 0) {
      setCourseId(dataFeedback[0].course);
    }
  }, [dataFeedback]);
  let moodleId = courseId;
  let tokenPluscourse = { getAccess, moodleId };
  const { data: dataCourse = [], isLoading: isLoadingCourse } = useGetCourseTeamQuery(
    tokenPluscourse,
    {
      skip: permit == false || moodleId == '',
    },
  );
  const { data: dataQuestion = [], isLoading: isLoadingQuestion } = useGetFeedbackQuestionQuery(
    tokenPlusdata,
    {
      skip: permit == false,
    },
  );
  useMemo(() => {
    if (dataCourse.instructors) {
      let filterCourse = dataCourse.instructors.filter((item) => item.role_id == '12');
      setArrTA(filterCourse);
    }
  }, [dataCourse]);
  return (
    <div className={styles.TAEvaluation}>
      <div className={styles.Feedback}>
        {isOpenLazy && !isLoadingQuestion && !isLoadingFeedback && !isLoadingCourse ? (
          <div>
            {arrTA.length > 0 ? (
              <div className={styles.main}>
                <div className={styles.mobileWidth}>
                  <BackComponent />
                  <h2 className={styles.titleText}>{dataCourse.course_name}</h2>
                </div>
                <TAEvaluationComponent
                  dataQuestion={dataQuestion}
                  getAccess={getAccess}
                  arrTA={arrTA}
                />
              </div>
            ) : (
              <TAError />
            )}
          </div>
        ) : (
          <div className={styles.Preloader}>
            <Preloader />
          </div>
        )}
      </div>
    </div>
  );
}

export default TAEvaluation;

import React from 'react';
import styles from '../Syllabus.module.css';
import arrow from '../../../../images/32.svg';
import file from '../../../../images/file.svg';
import fileDrop from '../../../../images/fileDrop.svg';
import fileDropError from '../../../../images/fileDropError.svg';
import crossNew from '../../../../images/crossNew.svg';
import links from '../../../../images/links.svg';
import Syllabus from '../../../../images/The template of Syllabus.docx';
import { useMatchMedia } from '../../../../hooks/useMatchMedia';
function SecondStep({
  filesText,
  setFilesText,
  drag,
  setDrag,
  drop,
  setDrop,
  docx,
  setDocx,
  courseName,
}) {
  const { isDesktop } = useMatchMedia();
  const cancelFile = () => {
    setDrag(false);
    setDrop(false);
    setFilesText(null);
  };

  const dragStartHandler = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const dragLeavetHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const onDropHandler = (e) => {
    e.preventDefault();

    let files = [...e.dataTransfer.files];
    let newFiles = files[0].name.split('').slice(-4).join('');
    if (newFiles.includes('docx')) {
      setFilesText(files);
      setDrag(false);
      setDrop(true);
      setDocx(true);
    } else {
      setDocx(false);
      setDrag(false);
      setDrop(true);
      setFilesText(files);
    }
  };

  const selectFile = (e) => {
    let files = e.target.files;
    let newFiles = files[0].name.split('').slice(-4).join('');
    if (newFiles.includes('docx')) {
      setFilesText(files);
      setDrag(false);
      setDrop(true);
      setDocx(true);
    } else {
      setDocx(false);
      setDrag(false);
      setDrop(true);
      setFilesText(files);
    }
  };

  return (
    <div>
      <div className={styles.steps}>
        <div className={styles.stepsHeader}>
          <div className={styles.stepsNumber}>
            <p className={styles.stepsNumberText}>2</p>
          </div>
          <h5 className={styles.headerTitle}>Upload the document</h5>
          <img
            src={arrow}
            className={styles.arrow}
            alt='arrow'
          />
        </div>

        {courseName && (
          <div className={styles.stepsBody}>
            <div className={styles.linkDiv}>
              <img
                src={links}
                className={styles.linksImg}
                alt='links'
              />
              <a
                href={Syllabus}
                download=''
                className={styles.linkText}
              >
                <p className={styles.linkText}>Link to template</p>
              </a>
            </div>
            {isDesktop && (
              <div>
                <div className={styles.dropFild}>
                  {drop ? (
                    <div className={styles.dropArea}>
                      {docx ? (
                        <div className={styles.dropDocx}>
                          <img
                            src={fileDrop}
                            className={styles.fileImgDrop}
                          />
                          <p className={styles.fileTextFormat}>{filesText[0].name}</p>
                          <span
                            className={styles.spanDeleteFile}
                            onClick={cancelFile}
                          >
                            <img
                              src={crossNew}
                              className={styles.cross}
                            />
                            delete a file
                          </span>
                        </div>
                      ) : (
                        <div className={styles.dropDocxNo}>
                          <img
                            src={fileDropError}
                            className={styles.fileImgDrop}
                          />
                          <p className={styles.fileTextFormat}>{filesText[0].name}</p>
                          <p className={styles.fileTextError}>
                            invalid file format use&nbsp;the&nbsp;docx&nbsp;extension
                          </p>
                          <span
                            className={styles.spanDeleteFile}
                            onClick={cancelFile}
                          >
                            <img
                              src={crossNew}
                              className={styles.cross}
                            />
                            delete a file
                          </span>
                        </div>
                      )}
                    </div>
                  ) : drag ? (
                    <div
                      className={styles.dropArea2}
                      onDragStart={(e) => dragStartHandler(e)}
                      onDragOver={(e) => dragStartHandler(e)}
                      onDrop={(e) => onDropHandler(e)}
                      onDragLeave={(e) => dragLeavetHandler(e)}
                    ></div>
                  ) : (
                    <div
                      className={styles.dropArea}
                      onDragStart={(e) => dragStartHandler(e)}
                      onDragOver={(e) => dragStartHandler(e)}
                      onDragLeave={(e) => dragLeavetHandler(e)}
                    ></div>
                  )}

                  {!drop && (
                    <div>
                      <img
                        src={file}
                        className={styles.fileImg}
                      />
                      {drag ? (
                        <p className={styles.fileTextDrop2}>Select the file and drag it here</p>
                      ) : (
                        <p className={styles.fileTextDrop}>
                          Select the file and drag it&nbsp;here (only .docx extension)
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {!isDesktop && (
              <div className={styles.fileInputDiv}>
                {!filesText && (
                  <label className={styles.customfileupload}>
                    <input
                      type='file'
                      onChange={selectFile}
                    />
                    <button className={styles.customfilButton}>
                      <p className={styles.customfilText}>select a file</p>
                    </button>
                  </label>
                )}
                {filesText && (
                  <div>
                    {docx ? (
                      <div className={styles.dropDocx}>
                        <img
                          src={fileDrop}
                          className={styles.fileImgDrop}
                        />
                        <p className={styles.fileTextFormat}>{filesText[0].name}</p>
                        <span
                          className={styles.spanDeleteFile}
                          onClick={cancelFile}
                        >
                          <img
                            src={crossNew}
                            className={styles.cross}
                          />
                          delete a file
                        </span>
                      </div>
                    ) : (
                      <div className={styles.dropDocxNo}>
                        <img
                          src={fileDropError}
                          className={styles.fileImgDrop}
                        />
                        <p className={styles.fileTextFormat}>{filesText[0].name}</p>
                        <p className={styles.fileTextError}>
                          invalid file format use&nbsp;the&nbsp;docx&nbsp;extension
                        </p>
                        <span
                          className={styles.spanDeleteFile}
                          onClick={cancelFile}
                        >
                          <img
                            src={crossNew}
                            className={styles.cross}
                          />
                          delete a file
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SecondStep;

import React, { useEffect, useState } from 'react';
import DateComponent from '../../common/DateComponent/DateComponent';
import styles from './Background.module.scss';
function Background({ data }) {
  const [education, setEducation] = useState([]);
  const [educationNoEnd, setEducationNoEnd] = useState([]);
  const [experience, setExperience] = useState([]);
  const [training, setTraining] = useState([]);

  const sort = (e) => {
    let arr = JSON.parse(JSON.stringify(e));
    let result = arr.sort((a, b) => b.end_date.slice(0, 4) - a.end_date.slice(0, 4));
    return result;
  };
  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  useEffect(() => {
    let educationEnd = data.education.filter((element) => {
      return element.end_date;
    });
    let educationNoEnd = data.education.filter((element) => {
      return !element.end_date;
    });
    if (educationEnd.length > 0) {
      let resultEducation = sort(educationEnd);
      setEducation(resultEducation);
    }
    setEducationNoEnd(educationNoEnd);

    if (data.work_experience) {
      let experienceEnd = data.work_experience.filter((element) => {
        return element.end_date;
      });

      let resultExperience = sort(experienceEnd);
      setExperience(resultExperience);
    }
    if (data.advanced_training) {
      let trainingEnd = data.advanced_training.filter((element) => {
        return element.end_date;
      });
      let resultTraining = sort(trainingEnd);
      setTraining(resultTraining);
    }
  }, [data]);

  return (
    <div className={styles.Background}>
      <div className={styles.upperBlock}>
        <section className={styles.education}>
          {data.education.length > 0 && <h4 className={styles.title}>Education</h4>}
          {education.length > 0 &&
            education.map((item) => {
              return (
                <div
                  className={styles.itemBlock}
                  key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                >
                  <div className={styles.imgSection}>
                    <span className={styles.circle}></span>
                    <span className={styles.line}></span>
                  </div>
                  <div className={styles.blockSection}>
                    <p className={styles.educationType}>{item.education_type}</p>
                    <p className={styles.institutionName}>{item.institution_name}</p>
                    <p className={styles.qualification}>
                      {item.speciality}, {item.qualification}
                    </p>
                    <p className={styles.educationDate}>
                      <DateComponent item={item.start_date} /> -{' '}
                      <DateComponent item={item.end_date} />
                    </p>
                  </div>
                </div>
              );
            })}
          {educationNoEnd.length > 0 &&
            educationNoEnd.map((item) => {
              return (
                <div
                  className={styles.itemBlock}
                  key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                >
                  <div className={styles.imgSection}>
                    <span className={styles.circle}></span>
                    <span className={styles.line}></span>
                  </div>
                  <div className={styles.blockSection}>
                    <p className={styles.educationType}>{item.education_type}</p>
                    <p className={styles.institutionName}>{item.institution_name}</p>
                    <p className={styles.qualification}>
                      {item.speciality}, {item.qualification}
                    </p>
                    {item.start_date && (
                      <p className={styles.educationDate}>
                        <DateComponent item={item.start_date} />
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
        </section>

        <section className={styles.experience}>
          {experience.length > 0 && <h4 className={styles.title}>Experience</h4>}
          {experience.length > 0 &&
            experience.map((item) => {
              return (
                <div
                  className={styles.itemBlock}
                  key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
                >
                  <div className={styles.imgSection}>
                    <span className={styles.circle}></span>
                    <span className={styles.line}></span>
                  </div>
                  <div className={styles.blockSection}>
                    <p className={styles.experienceName}>{item.name}</p>
                    <p className={styles.experiencePosition}>{item.position}</p>
                    <p className={styles.experienceDate}>
                      <DateComponent item={item.start_date} /> -{' '}
                      <DateComponent item={item.end_date} />
                    </p>
                  </div>
                </div>
              );
            })}
        </section>
      </div>
      <section className={styles.advancedTraining}>
        {training.length > 0 && <h4 className={styles.title}>Advanced training</h4>}
        {training.length > 0 &&
          training.map((item) => {
            return (
              <div
                className={styles.advancedBlock}
                key={Math.random().toString(16).slice(2) + new Date().getTime().toString(36)}
              >
                <div className={styles.advancedData}>
                  <p className={styles.advancedCourseName}>{item.course_name}</p>
                  <p className={styles.advancedInstitutionName}>{item.institution_name}</p>
                  <p className={styles.advancedDocumentType}>{item.document_type}</p>
                </div>
                <div className={styles.advancedTimeDiv}>
                  <p className={styles.advancedHours}>{item.hours} ч.</p>
                  <p className={styles.advancedEndDate}>{item.end_date.slice(0, 4)}</p>
                </div>
              </div>
            );
          })}
      </section>
    </div>
  );
}

export default Background;

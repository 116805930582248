import React, {useMemo, useState} from 'react';
import styles from './instructorStubs.module.scss';
import imgSearch from '../../../images/imgSearch.svg';
import { motion } from 'framer-motion';

function FeedbackNotFound() {
  const [isOpenLazy, setIsOpenLazy] = useState(false);
  const textVis = {
    hidden: {
      opacity: 0,
    },
    visible: () => ({
      opacity: 1,

      transition: {
        delay: 0.1,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  useMemo(()=>{
    setTimeout(() => {
      setIsOpenLazy(true);
    }, '1000');
},[])

  return (
    <div>
       {isOpenLazy &&<div>
        <motion.div
      className={styles.instructorStubs}
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.2 }}
    >

      <motion.img
        variants={textVis}
        src={imgSearch}
        alt='img Search'
        className={styles.img}
      />
      <motion.h5
        variants={textVis}
        className={styles.title}
      >
        Personal feedback not found
      </motion.h5>
    </motion.div>
      </div>}
    </div>
  );
}

export default FeedbackNotFound;

import { css } from '@emotion/react';

export const globalStyles = css`
  * {
    box-sizing: border-box;
    font-family: Inter;
    padding: 0;
    margin: 0;
    text-align: initial;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-align: center;
  }

  ul{
    padding: 0;
  }
`;

import React, { forwardRef, memo } from 'react';
import Box from 'ui-box';

/**
 * This is a dummy component for display Text
 * @param { string } className - Class name passed to the component
 * @param {  300 | 400 | 500 | 600 | `small` | `medium` | `large`  } size - Size of the text style
 * @param { string } fontFamily - Font family
 */
export const Text = memo(
  forwardRef(function Text(props, ref) {
    const {
      className,
      color: colorProp = 'default',
      fontFamily = 'Inter',
      size = 400,
      ...restProps
    } = props;

    const color = colorProp === 'none' || colorProp === 'default' ? 'default' : colorProp;

    return (
      <Box
        is='span'
        ref={ref}
        size={size}
        fontFamily={fontFamily}
        color={color}
        className={className}
        {...restProps}
      />
    );
  }),
);

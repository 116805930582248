import React from 'react';
import styles from './Page404.module.css';
import { NavLink } from 'react-router-dom';
import { TitleComponent } from '../../../helpers/TitleComponent/TitleComponent';
import error from '../../../../images/404.svg';
import { motion } from 'framer-motion';

function Page404() {
  const textVis = {
    hidden: {
      opacity: 0,
    },
    visible: () => ({
      opacity: 1,

      transition: {
        delay: 0.3,
        type: 'just',
        duration: 0.5,
      },
    }),
  };
  TitleComponent('Error page');
  return (
    <motion.div
      className={styles.mainPage}
      initial='hidden'
      whileInView='visible'
      viewport={{ amount: 0.2 }}
    >
      <motion.img
        variants={textVis}
        className={styles.img}
        src={error}
      />

      <motion.h3
        variants={textVis}
        className={styles.title}
      >
        Page not found
      </motion.h3>
      <motion.p
        variants={textVis}
        className={styles.secondTitle}
      >
        Either the address is wrong or the page no longer exists
      </motion.p>
      <NavLink to='/profile_search'>
        {' '}
        <motion.button
          variants={textVis}
          className={styles.button}
        >
          <p className={styles.buttonText}>main page</p>
        </motion.button>
      </NavLink>
    </motion.div>
  );
}

export default Page404;

import React, { useState } from 'react';
import styles from './Syllabus.module.css';
import { TitleComponent } from '../../helpers/TitleComponent/TitleComponent';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import alarmHint from '../../../images/alarmHint.svg';
import Preloader from '../../../components/stubs/Preloader/Preloader';
import { getGenerator, getStatus } from '../../../redux/generatorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from 'react-use-cookie';
import SyllabusPopup from '../../stubs/generateStubs/SyllabusPopup';

function Syllabus() {
  TitleComponent(`DP - generator/syllabus`);

  const dispatch = useDispatch();
  const [degree, setDegree] = useState('BS');
  const [courseType, setCourseType] = useState('TE');
  const [filesText, setFilesText] = useState(null);
  const [drag, setDrag] = useState(false);
  const [drop, setDrop] = useState(false);
  const [docx, setDocx] = useState(false);
  const link = useSelector((state) => state.generator.data);
  const [popup, sesPopup] = useState(false);
  const [courseName, sesCourseName] = useState([]);
  const [showStep, setshowStep] = useState('');

  const handleUpload = () => {
    dispatch(getStatus(true));
    const getAccess = getCookie('access_token');
    const dataGenerator = {
      getAccess: getAccess,
      degree: degree,
      courseType: courseType,
      formData: filesText[0],
      course_name: courseName,
    };
    dispatch(getGenerator(dataGenerator));
    setDrag(false);
    setDrop(false);
    setFilesText(null);
    sesPopup(true);
  };
  const handleUploadTrial = () => {
    dispatch(getStatus(true));
    const getAccess = getCookie('access_token');
    const dataGenerator = {
      getAccess: getAccess,
      degree: 'Project',
      courseType: courseType,
      formData: filesText[0],
      course_name: courseName,
    };
    dispatch(getGenerator(dataGenerator));
    setDrag(false);
    setDrop(false);
    setFilesText(null);
    sesPopup(true);
  };

  return (
    <div className={styles.Syllabus}>
      {link == '' && popup ? (
        <div className={styles.preloader}>
          <Preloader />
        </div>
      ) : (
        <div>
          <h3 className={styles.title}>Upload syllabus to Eduwiki</h3>
          <p className={styles.hint}>Generate a page on the Eduwiki portal from a .docx file</p>

          <FirstStep
            degree={degree}
            setDegree={setDegree}
            courseType={courseType}
            setCourseType={setCourseType}
            sesCourseName={sesCourseName}
            setshowStep={setshowStep}
          />
          <SecondStep
            setFilesText={setFilesText}
            filesText={filesText}
            drag={drag}
            setDrag={setDrag}
            drop={drop}
            setDrop={setDrop}
            docx={docx}
            setDocx={setDocx}
            courseName={showStep}
          />
          <div className={styles.alarmHintDiv}>
            <img
              src={alarmHint}
              alt='alarmHint'
              className={styles.alarmHintImg}
            />
            <p className={styles.alarmHintText}>
              Once you click on the “publish syllabus” button, a new page will be created in EduWiki
              and become available for everyone. Press “preview” button to see the result on the
              test page first
            </p>
          </div>
          <div className={styles.buttonDiv}>
            {filesText && docx && showStep ? (
              <button
                className={styles.buttonUpload}
                onClick={() => handleUpload()}
              >
                <p className={styles.textUpload}>publish syllabus</p>
              </button>
            ) : (
              <button className={styles.buttonUploadDis}>
                <p className={styles.textUpload}>publish syllabus</p>
              </button>
            )}
            {filesText && docx && showStep ? (
              <button
                className={styles.buttonTrialUpload}
                onClick={() => handleUploadTrial()}
              >
                <p className={styles.textTrialUpload}>preview</p>
              </button>
            ) : (
              <button className={styles.buttonTrialUploadDis}>
                <p className={styles.textTrialUpload}>preview</p>
              </button>
            )}
          </div>
          {popup && (
            <SyllabusPopup
              popup={popup}
              sesPopup={sesPopup}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Syllabus;

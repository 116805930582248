import React, { useState, useEffect, useRef } from 'react';
import styles from '../RPD.module.css';
import arrow from '../../../../images/32.svg';
import chevron from '../../../../images/25.svg';
import Preloader from '../../../stubs/Preloader/Preloader';
function FirstStep({ data, setFilterData, setSearch, search, setClearPlans, isLoading }) {
  const [filterClicked, setFilterClicked] = useState(false);
  const [searchNew, setSearchNew] = useState(data);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  const inputHandle = (event) => {
    setClearPlans(false);
    setSearch(event.target.value);
    setFilterClicked(true);
  };
  const listHandle = (item) => {
    setSearch(item.name_eng);
    setFilterData(item);
    setClearPlans(true);
  };
  useEffect(() => {
    if (data.length > 0) {
      let arrData = data.filter(
        (item) =>
          item.name_eng.toLowerCase().includes(search) ||
          item.name_eng.toUpperCase().includes(search) ||
          item.name_eng.includes(search),
      );
      setSearchNew(arrData);
    }
  }, [search, data]);

  return (
    <div>
      <div className={styles.steps}>
        <div className={styles.stepsHeader}>
          <div className={styles.stepsNumber}>
            <p className={styles.stepsNumberText}>1</p>
          </div>
          <h5 className={styles.headerTitle}>Choose the subject</h5>
          <img
            src={arrow}
            className={styles.arrow}
            alt='arrow'
          />
        </div>

        <div className={styles.stepsBody}>
          <div
            className={styles.inputDiv}
            onClick={() => setFilterClicked(!filterClicked)}
          >
            <input
              type='text'
              placeholder='To sort, write the first letters of the discipline'
              value={search}
              onChange={inputHandle}
              className={styles.inputText}
              ref={inputRef}
            />{' '}
            <img
              src={chevron}
              className={styles.chevron}
            />
          </div>
          <div
            className={
              filterClicked && searchNew.length ? styles.listSearch : styles.listSearchHidden
            }
          >
            {isLoading ? (
              <div className={styles.prelouderDiscipline}>
                <Preloader />
              </div>
            ) : (
              <div>
                {filterClicked &&
                  searchNew &&
                  searchNew.map((item) => {
                    return (
                      <div
                        key={Math.random()}
                        className={styles.items}
                        onClick={() => listHandle(item)}
                      >
                        <div
                          onClick={() => setFilterClicked(false)}
                          className={styles.itemsFlex}
                        >
                          <p className={styles.itemsText}>{item.name_eng}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstStep;

import { getCookie } from 'react-use-cookie';

export const fileRequest = async (item) =>{
    let getAccess = { Authorization: `Bearer ${getCookie('access_token')}` };
    
    fetch(`https://dev.digitalprofile.innopolis.university/api/collab/${item?.attachment.file}`, {
        headers: getAccess
      })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        let type = item?.attachment.file.slice(34)
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `DigitalProfileFile.${type}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
    }
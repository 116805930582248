import styled from "@emotion/styled";

export const Title = styled.span`
    display: block;
    margin-bottom: 18px;
`

export const Error = styled.span`
    color: #FF522D;
    position: absolute;
    bottom: -22px;
`
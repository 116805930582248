import { useEffect, useState } from 'react';
import {
  useGetProjectsSkillFilterDataQuery,
  useGetProjectsStudyLevelFilterDataQuery,
  useGetProjectsSubjectAreaFilterDataQuery,
  useGetProjectsSupervisorFilterDataQuery,
} from '../../redux/Api';

export const useProjectOptions = (getAccess) => {
  const [projectOptions, setProjectOptions] = useState(undefined);

  const skills = useGetProjectsSkillFilterDataQuery(getAccess);
  const studyLevels = useGetProjectsStudyLevelFilterDataQuery(getAccess);
  const subjectAreas = useGetProjectsSubjectAreaFilterDataQuery(getAccess);
  const supervisors = useGetProjectsSupervisorFilterDataQuery(getAccess);

  useEffect(() => {
    if (
      !skills.isLoading &&
      !studyLevels.isLoading &&
      !subjectAreas.isLoading &&
      !supervisors.isLoading
    ) {
      setProjectOptions({
        subjectAreas: subjectAreas.data?.map((item) => ({
          label: item,
          value: item,
        })),
        cosupervisors: supervisors.data?.map((item) => ({
          label: item,
          value: item,
        })),
        skills: skills.data?.map((item) => ({
          label: item,
          value: item,
        })),
        studyLevels: studyLevels.data?.map((item) => ({
          label: item,
          value: item,
        })),
      });
    }
  }, [skills, studyLevels, subjectAreas, supervisors]);

  return {
    isLoading:
      skills.isLoading || studyLevels.isLoading || subjectAreas.isLoading || supervisors.isLoading,
    projectOptions,
  };
};

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styles from './Projects.module.scss';
import { useGetStudentProjectsQuery, useCreateProjectMutation } from '../../../redux/Api';
// import { ProjectsTable } from '../../Projects/ProjectsTable/ProjectsTable';
import { ModifyProjectSideCard } from '../../Projects/ModifyProjectSideCard/ModifyProjectSideCard';
import EmptySearch from '../../stubs/studentStubs/EmptySearch';
import { SideCard } from '../../common/SideCard/SideCard';
import { useProject } from '../../Projects/useProject';
import { useProjectOptions } from '../../Projects/useProjectOptions';
import { useHistory } from 'react-router-dom';
import { InstructorProjects } from '../../Projects/InstructorProjects/InstructorProjects';
import Preloader from '../../stubs/Preloader/Preloader';

function Projects(supervisor) {
  const [filter, setFilter] = useState('all');
  const [sideCardOpen, setSideCardOpen] = useState(false);
  const history = useHistory();
  const [filterData, setFilterData] = useState([])
  let tokenPlusData = {getAccess : supervisor.getAccess}
  const projects = useGetStudentProjectsQuery(
    tokenPlusData
    // { refetchOnMountOrArgChange: true },
  );

  const { projectData, clearProjectData, ...handlers } = useProject(supervisor);
  const { projectOptions } = useProjectOptions(supervisor.getAccess);
  const [createProject] = useCreateProjectMutation();
  useEffect(() => {
    window.scrollBy(0, -2000);
  }, []);
  const handleProjectClick = useCallback(
    (projectData) => () => {
      history.push(`/instructor/${supervisor?.data?.moodle_id}/projects/${projectData.id}`);
    },
    [supervisor, projectData.id],
  );
  const handleProjectCreate = useCallback(
    async (formData) => {
      let dataToken = {getAccess: supervisor.getAccess, formData}
      await createProject(dataToken).then((data) => {
        if (data.error === undefined) {
          setSideCardOpen(false);
          clearProjectData();
          history.push(`/instructor/${supervisor?.data?.moodle_id}/projects/${data.data?.id}`);
        }
      });
    },
    [projectData],
  );

    useMemo(()=>{
      setFilterData(projects.data?.results.filter(item=>{
        switch (filter) {
          case 'all':
            return item?.supervisor?.full_name.trim() == supervisor?.data?.full_name_latin.trim();
          case 'active':
            return item?.supervisor?.full_name.trim() == supervisor?.data?.full_name_latin.trim() && item.active == true;
          case 'archive':
              return item?.supervisor?.full_name.trim() == supervisor?.data?.full_name_latin.trim() && item.active == false;
        }}))
      },[projects, filter])

  return (
    <>  
     <div className={styles.main}>
      <div className={styles.Projects}>
          <h1 className={styles.title}>Projects for collaboration</h1>
        <div className={styles.navDiv}>
        <div className={styles.leftDiv}>
        <p className={styles.text}>show:</p>
        <div className={styles.buttons}>
        <button className={filter === 'all' ? styles.buttonNavActiv : styles.buttonNav}
          onClick={() => setFilter('all')}>
          <p>all</p>
        </button>
        <button className={filter === 'active' ? styles.buttonNavActiv : styles.buttonNav}
          onClick={() => setFilter('active')}>
          <p>active projects</p>
        </button>
        <button className={filter === 'archive' ? styles.buttonNavActiv : styles.buttonNav}
          onClick={() => setFilter('archive')}>
          <p>archive projects</p>
        </button>
        </div>
        </div>
          <p onClick={() => setSideCardOpen(true)}
          className={styles.createButton}
          >create project</p>
        </div>
          {!projects.isLoading && projects.isSuccess
          ?
          <>
          {filterData && filterData.length ? (
            <InstructorProjects
              onProjectClick={handleProjectClick}
              projects={filterData}
            />
          ) : (
            <EmptySearch />
          )}
          </>
          : <Preloader/>}
      </div>
      </div>
      <SideCard
        isOpen={sideCardOpen}
        onClose={() => setSideCardOpen(false)}
        header={'Create new project for collaboration'}
      >
        <ModifyProjectSideCard
          create
          projectData={projectData}
          handlers={handlers}
          onSubmit={handleProjectCreate}
          options={projectOptions}
          supervisor={supervisor?.data.email}
        />
      </SideCard>
    </>
  );
}

export default Projects;
